import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/ui/Button";
import TabList, { Tab } from "components/ui/List/TabList";
import { FilterTab } from "../utils";
import { IconButton } from "../IconButton";
import { useState } from "react";
import { isEmpty, isNil } from "lodash";
import { toast } from "react-toastify";
import cn from "classnames";

export const DashboardHeader: React.FC<{
    isSidebarExpanded: boolean;
    onToggleSidebar: () => void;
}> = ({ isSidebarExpanded, onToggleSidebar }) => {
    return (
        <header className="flex-row fill-width py-4 px-8 border-bottom bg-white gap-2" role="banner">
            <SidebarToggleButton isSidebarExpanded={isSidebarExpanded} onToggleSidebar={onToggleSidebar} />
            <div className="flex-row gap-1 align-center">
                <FontAwesomeIcon icon={["fal", "grid"]} fixedWidth size="lg" />
                <h1 className="flex-one-in-row header-title--small text-dark">Dashboard</h1>
            </div>
        </header>
    );
};

export const NewFilterHeader: React.FC<{
    onSave: () => void;
    onCancel: () => void;
}> = ({ onSave, onCancel }) => {
    return (
        <header className="flex-row fill-width py-4 px-8 bg-white" role="banner">
            <h1 className="header-title flex-one-in-row text-dark">New Filter</h1>
            <div className="flex-row gap-2 align-center">
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onSave} primary>
                    Save Filter
                </Button>
            </div>
        </header>
    );
};

export const FilterHeader: React.FC<{
    title: string;
    activeTab: FilterTab;
    isSidebarExpanded: boolean;
    isFavorite: boolean;
    sharedBy?: string;
    onToggleSidebar: () => void;
    onDelete: () => void;
    onEdit: () => void;
    onClone: () => void;
    onFavorite: () => void;
    onChangeFilterName: (filterName: string, callback: (error?: string) => void) => void;
    onSelectTab: (tab: FilterTab) => void;
}> = ({
    title,
    activeTab,
    isSidebarExpanded,
    isFavorite,
    sharedBy,
    onDelete,
    onEdit,
    onClone,
    onFavorite,
    onToggleSidebar,
    onSelectTab,
    onChangeFilterName,
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const onEditFilterName = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const inputField = e.currentTarget.querySelector<HTMLInputElement>("[name='filter-name']");

        const filterName = inputField?.value;
        if (filterName && !isEmpty((filterName ?? "").trim())) {
            onChangeFilterName(filterName, (error) => {
                if (error) {
                    toast.error(error);
                    inputField?.focus();
                    return;
                }

                setIsEditing(false);
            });
        } else {
            toast.error("Filter name cannot be empty");
            inputField?.focus();
        }
    };

    return (
        <header className="flex-column fill-width pt-4 gap-3 bg-white" role="banner">
            <div className="flex-row fill-width px-8">
                {isEditing ? (
                    <form className="flex-row gap-2 align-center me-auto" onSubmit={onEditFilterName}>
                        <input className="p-2" name="filter-name" autoFocus maxLength={100} defaultValue={title} aria-label="Filter name" />
                        <Button small onClick={() => setIsEditing(false)}>
                            Cancel
                        </Button>
                        <Button small primary type="submit">
                            Save
                        </Button>
                    </form>
                ) : (
                    <div className="flex-one-in-row flex-row gap-1 align-center">
                        <SidebarToggleButton isSidebarExpanded={isSidebarExpanded} onToggleSidebar={onToggleSidebar} />
                        <button
                            className="wc-btn px-2"
                            onClick={() => setIsEditing(true)}
                            title={sharedBy ? undefined : "Rename filter"}
                            disabled={!isNil(sharedBy)}
                        >
                            <div className="flex-row gap-2 align-center">
                                <h1 className="header-title text-dark">{title}</h1>
                                {!sharedBy && <FontAwesomeIcon icon={["far", "pen"]} fixedWidth />}
                            </div>
                        </button>
                    </div>
                )}
                <div className="flex-row gap-2 align-center">
                    {sharedBy && (
                        <div className="flex-row gap-2 align-center px-2 text-secondary">
                            <FontAwesomeIcon icon={["fal", "share-nodes"]} fixedWidth />
                            <h2 className="fs-14 fw-400 text-secondary">Shared by: {sharedBy}</h2>
                        </div>
                    )}
                    <IconButton className="border-danger text-danger" onClick={onDelete} icon={["far", "trash"]} title="Delete filter" />
                    <IconButton onClick={onClone} icon={["far", "copy"]} title="Duplicate filter" />
                    {!sharedBy && <IconButton onClick={onEdit} icon={["far", "pen-to-square"]} title="Edit filter" />}
                    <IconButton
                        className={cn({ "text-amber-400": isFavorite })}
                        onClick={onFavorite}
                        icon={[isFavorite ? "fas" : "far", "star"]}
                        title="Favorite filter"
                    />
                </div>
            </div>
            <TabList
                className="px-8"
                bordered
                items={sharedBy ? tabs.slice(0, 2) : tabs}
                activeItem={{ id: activeTab, title: activeTab }}
                onClick={(tab) => onSelectTab(tab.id as FilterTab)}
            />
        </header>
    );
};

const SidebarToggleButton: React.FC<{ isSidebarExpanded: boolean; onToggleSidebar: () => void }> = ({
    isSidebarExpanded,
    onToggleSidebar,
}) => {
    const sidebarToggleTitle = isSidebarExpanded ? "Collapse sidebar" : "Expand sidebar";
    const sidebarToggleIcon: IconProp = isSidebarExpanded ? ["far", "arrow-left-to-line"] : ["far", "bars"];

    return <IconButton onClick={onToggleSidebar} icon={sidebarToggleIcon} title={sidebarToggleTitle} />;
};

const tabs: Tab[] = [
    {
        id: "Explore",
        title: "Explore",
        icon: ["far", "magnifying-glass"],
    },
    {
        id: "Manage",
        title: "Manage",
        icon: ["far", "sliders"],
    },
    {
        id: "Share",
        title: "Share",
        icon: ["far", "share-nodes"],
    },
];
