import React from "react";
import ClassNames from "classnames";

import IconWithLabel from "../Icons/IconWithLabel";

import "./style.scss";

/**
 * ViewPlaceholder
 *
 * @component
 * @param {object} props - props
 * @param {string} [props.className] - additional class names
 * @param {boolean} [props.placeholderSplitView] - is this placeholder used in split view?
 * @param {boolean} [props.viewPlaceholderSmall] - a small version of the placeholder
 * @param {string} [props.clickableText] - text to display next to clickable icon
 * @param {React.ReactNode} [props.clickableTextIcon] - icon to display next to clickable text
 * @param {function} [props.onClick] - callback to call when clicking on clickable text
 * @param {React.ReactNode} props.children - children to display
 * @returns {JSX.Element} ViewPlaceholder
 */
export const ViewPlaceholder = (props) => {
    const className = ClassNames("view-placeholder flex-one flex-column align-center", props.className, {
        "split-screen-placeholder": props.placeholderSplitView,
        small: props.viewPlaceholderSmall,
    });

    return (
        <div className={className}>
            {props.placeholderSplitView ? (
                <div className="split-view-inner-block">
                    {props.children}
                    {props.clickableText && (
                        <div className="view-placeholder__clickable-text">
                            <IconWithLabel icon={props.clickableTextIcon} onClick={props.onClick}>
                                {props.clickableText}
                            </IconWithLabel>
                        </div>
                    )}
                </div>
            ) : (
                <div className="view-placeholder--inner-block">
                    {props.children}
                    {props.clickableText && (
                        <div className="view-placeholder__clickable-text">
                            <IconWithLabel icon={props.clickableTextIcon} onClick={props.onClick}>
                                {props.clickableText}
                            </IconWithLabel>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ViewPlaceholder;
