import { IconButton } from "components/views/ApplicationProcessingWorkCenterV2/IconButton";
import {
    ApplicationDetailsConditions,
    FilterVariable,
    FilterVariableForFormField,
    FilterVariableForMeasureAttribute,
    FilterVariablesItem,
    FormFieldsConditions,
    getVariableConditionsDropdownList,
    isFormVariable,
    isMeasureVariable,
    Rule,
    Rules,
    useFieldVariableInfo,
    VariableCondition,
    VariableType,
} from "components/views/ApplicationProcessingWorkCenterV2/utils";
import { VariablePath } from "./VariablePath";
import Button from "components/ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownInput from "components/ui/Input/DropDownInput";
import { ChangeEvent, useEffect, useId, useState } from "react";
import cn from "classnames";
import { useProgramCatalog } from "store/resources/useResource";
import { isNil } from "lodash";
import { Switch } from "../../Switch";
import { ErrorMessage } from "./ErrorMessage";

export const ConditionBlock: React.FC<{
    item: FilterVariablesItem;
    readOnly?: boolean;
    onEdit: () => void;
    onDelete: () => void;
    onToggle?: () => void;
}> = ({ item, readOnly = false, onEdit, onDelete, onToggle }) => {
    return (
        <div className="flex-row align-center flex-grow gap-1" style={{ height: 60 }}>
            <h3 className="fs-14 fw-400">
                <ConditionTitle item={item} />
            </h3>
            {!readOnly && !onToggle && (
                <>
                    <IconButton
                        className="me-auto opacity-0 pointer-events-none focus:opacity-100 focus:pointer-events-auto group-hover:opacity-100 group-focus:opacity-100 group-active:opacity-100 group-focus-within:opacity-100 group-hover:pointer-events-auto group-focus:pointer-events-auto group-active:pointer-events-auto"
                        icon={["far", "pen-to-square"]}
                        title="Edit condition"
                        onClick={onEdit}
                    />
                    <IconButton
                        className="border-danger text-danger opacity-0 pointer-events-none focus:opacity-100 focus:pointer-events-auto group-hover:opacity-100 group-focus:opacity-100 group-active:opacity-100 group-focus-within:opacity-100 group-hover:pointer-events-auto group-focus:pointer-events-auto group-active:pointer-events-auto"
                        icon={["far", "trash"]}
                        title="Delete condition"
                        onClick={onDelete}
                    />
                </>
            )}
            {onToggle && <Switch id={item._id} className="ms-auto" checked={item._enabled} onChange={onToggle} />}
        </div>
    );
};

export const ConditionAddLine: React.FC<{
    variable: FilterVariable;
    configurable: boolean;
    onClick?: () => void;
}> = ({ variable, configurable, onClick }) => {
    return (
        <div
            className={cn(
                "flex-row fill-width align-center rounded-2 text-secondary group hover:bg-grey-200 focus-within:bg-grey-200 bg-opacity-50 gap-2",
                configurable ? "px-1" : "px-3"
            )}
            style={{ height: 60 }}
        >
            <VariablePath className="fill-height" icon={["far", "magnifying-glass-arrow-right"]} />
            <h3 className="fs-14 fw-400 flex-grow">
                <ShowResultsTitle variable={variable} />
            </h3>
            {onClick && (
                <Button
                    className="no-shrink opacity-0 pointer-events-none focus:opacity-100 focus:pointer-events-auto group-hover:opacity-100 group-focus:opacity-100 group-active:opacity-100 group-hover:pointer-events-auto group-focus:pointer-events-auto group-active:pointer-events-auto"
                    onClick={onClick}
                    title="Add condition"
                >
                    <div className="flex-row align-center gap-2">
                        <FontAwesomeIcon className="py-2 px-1" icon={["far", "square-dashed-circle-plus"]} fixedWidth size="lg" />
                        Add condition
                    </div>
                </Button>
            )}
        </div>
    );
};

export const RuleLine: React.FC<{
    item: FilterVariablesItem;
    readOnly?: boolean;
    configurable?: boolean;
    onChange: (item: FilterVariablesItem) => void;
}> = ({ item, readOnly, configurable, onChange }) => {
    if (readOnly || configurable) {
        return (
            <div
                className={cn(
                    "flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 focus-within:bg-grey-200 bg-opacity-50 gap-2",
                    configurable ? "px-1" : "px-3"
                )}
            >
                <VariablePath showTopLine={false} icon={["far", "lock"]} />
                <div className="flex-row align-center gap-2 py-3 fill-width text-dark fw-600 fs-14">{item.rule}</div>
            </div>
        );
    }

    return (
        <div className="flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 focus-within:bg-grey-200 bg-opacity-50 px-3 gap-2">
            <VariablePath topLineStyle={{ height: 11 }} icon={["far", "lock"]} />
            <div className="flex-row align-center gap-2 py-3 fill-width">
                <RuleButton text={Rules.And} primary={item.rule === Rules.And} onClick={() => onChange({ ...item, rule: Rules.And })} />
                <RuleButton text={Rules.Or} primary={item.rule === Rules.Or} onClick={() => onChange({ ...item, rule: Rules.Or })} />
            </div>
        </div>
    );
};

export const ConditionAddButton: React.FC<{
    onClick: () => void;
}> = ({ onClick }) => {
    return (
        <div className="flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 focus-within:bg-grey-200 bg-opacity-50 px-3 gap-2">
            <VariablePath icon={["far", "circle-plus"]} showBottomLine={false} topLineStyle={{ height: 22 }} />
            <div className="py-3 fill-width">
                <button
                    className="flex-row align-center justify-center gap-2 py-2 fill-width text-primary fw-500 fs-14 bg-blue border border-2 border-primary-light border-dashed rounded-2"
                    onClick={onClick}
                >
                    <FontAwesomeIcon className="py-2 px-1" icon={["far", "square-dashed-circle-plus"]} fixedWidth size="lg" />
                    Add condition
                </button>
            </div>
        </div>
    );
};

export const ConditionSelectLine: React.FC<{
    type: VariableType;
    showRule?: boolean;
    initialCondition?: VariableCondition;
    scrollContainerId?: string;
    error?: string;
    onSelect: (condition: VariableCondition, rule?: Rule) => void;
    onCancel: () => void;
    onDelete?: () => void;
}> = ({ type, showRule = false, initialCondition, scrollContainerId, error, onSelect, onCancel, onDelete }) => {
    const newItemId = useId();
    const [selectedRule, setSelectedRule] = useState<Rule | undefined>(showRule ? Rules.And : undefined);
    const [selectedCondition, setSelectedCondition] = useState<VariableCondition | undefined>(initialCondition);

    const onSave = () => {
        if (selectedCondition) {
            onSelect(selectedCondition, selectedRule);
        }
    };

    useEffect(() => {
        if (scrollContainerId) {
            const scrollParent = document.getElementById(scrollContainerId);
            if (scrollParent) {
                scrollParent.scrollTop = scrollParent.scrollHeight;
            }
        }
    }, [scrollContainerId]);

    return (
        <>
            {showRule && (
                <RuleLine
                    item={{
                        _id: newItemId,
                        rule: selectedRule,
                        condition: undefined!,
                        values: [],
                    }}
                    onChange={(item) => setSelectedRule(item.rule)}
                />
            )}
            <div className="flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 bg-opacity-50 px-3 gap-2 relative">
                <VariablePath icon={["far", "square-dashed"]} />
                <div className="flex-column flex-one-in-row py-3 gap-2">
                    <div className="flex-row align-center gap-2">
                        <DropDownInput
                            // @ts-ignore
                            defaultOpen
                            placeholder="Please select"
                            data={getVariableConditionsDropdownList(type)}
                            value={selectedCondition}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedCondition(e.target.value as VariableCondition)}
                        />
                        <Button small onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button small primary onClick={onSave} disabled={!selectedCondition}>
                            Apply
                        </Button>
                        {onDelete && (
                            <IconButton
                                className="border-danger text-danger invisible group-hover:visible group-focus:visible group-active:visible"
                                icon={["far", "trash"]}
                                title="Delete condition"
                                onClick={onDelete}
                            />
                        )}
                    </div>
                    {error && <ErrorMessage error={error} />}
                </div>
            </div>
        </>
    );
};

export const ConditionDeletedLine: React.FC<{
    title: string;
    deletedAt: number;
    onUndo: () => void;
    onDelete: () => void;
}> = ({ title, deletedAt, onUndo, onDelete }) => {
    const [remainingTime, setRemainingTime] = useState<number>(0);

    useEffect(() => {
        const timeout = 10000; // ms
        let cancelationToken = 0;
        let timer: NodeJS.Timeout;

        if (deletedAt) {
            const getRemainingTime = () => Math.max(0, deletedAt + timeout - Date.now());
            const checkTime = () => {
                setRemainingTime(Math.ceil(getRemainingTime() / 1000));
                cancelationToken = requestAnimationFrame(checkTime);
            };

            timer = setTimeout(() => {
                onDelete();
            }, getRemainingTime());

            checkTime();
        }

        return () => {
            cancelAnimationFrame(cancelationToken);
            clearTimeout(timer);
        };
    }, [deletedAt, onDelete]);

    return (
        <div
            className="flex-row fill-width align-center rounded-2 text-secondary group hover:bg-grey-200 bg-opacity-50 px-3 gap-2"
            style={{ height: 60 }}
        >
            <VariablePath className="fill-height" icon={["far", "trash"]} />
            <div className="flex-row flex-one-in-row align-center gap-2 rounded-2 bg-white py-1 px-4 border border-2">
                <span className="fs-14 fw-400 flex-grow">{title}</span>
                <div className="flex-row align-center gap-1 fs-14 fw-400">
                    <FontAwesomeIcon icon={["far", "timer"]} size="sm" />
                    <span>{remainingTime}</span>
                </div>
                <button
                    className={cn(
                        "rounded px-2 py-1 fw-600 fs-14 lh-1.5 bg-white text-primary hover:bg-grey-200 focus:bg-grey-200 active:bg-grey-200"
                    )}
                    onClick={onUndo}
                >
                    Undo
                </button>
            </div>
        </div>
    );
};

const RuleButton: React.FC<{
    text: string;
    primary: boolean;
    onClick: () => void;
}> = ({ text, primary, onClick }) => {
    return (
        <button
            className={cn("rounded px-2 py-1 fw-600 fs-14 lh-1.5 ", {
                "bg-primary text-white hover:bg-primary-hover focus:bg-primary-hover active:bg-primary-hover": primary,
                "bg-secondary text-primary hover:bg-grey-400 focus:bg-grey-400 active:bg-grey-400": !primary,
            })}
            onClick={onClick}
        >
            {text}
        </button>
    );
};

const ShowResultsTitle: React.FC<{
    variable: FilterVariable;
}> = ({ variable }) => {
    if (isMeasureVariable(variable)) {
        return <ShowMeasureAttributeResultsTitle variable={variable} />;
    }

    if (isFormVariable(variable)) {
        return <ShowFormFieldResultsTitle variable={variable} />;
    }

    return <>Show results where:</>;
};

const ShowMeasureAttributeResultsTitle: React.FC<{
    variable: FilterVariableForMeasureAttribute;
}> = ({ variable }) => {
    const [catalog, isLoading] = useProgramCatalog({
        programNumber: variable.programNumber,
        catalogNumber: variable.catalogNumber,
    });

    if (isLoading && isNil(catalog)) {
        return null;
    }

    if (isNil(catalog)) {
        return (
            <>
                Show results related to <strong>{variable.catalogNumber}</strong> where:
            </>
        );
    }

    return (
        <>
            Show results related to <strong>{catalog.name}</strong> where:
        </>
    );
};

const ShowFormFieldResultsTitle: React.FC<{
    variable: FilterVariableForFormField;
}> = ({ variable }) => {
    const { formName, fieldName, isLoading } = useFieldVariableInfo(variable);

    if (isLoading && (isNil(formName) || isNil(fieldName))) {
        return null;
    }

    return (
        <>
            Show results related to <strong>{`${formName} > ${fieldName}`}</strong> where:
        </>
    );
};

const ConditionTitle: React.FC<{
    item: FilterVariablesItem;
}> = ({ item }) => {
    if (item.condition === ApplicationDetailsConditions.SubmissionDateIsAfter) {
        return (
            <>
                Submission Date <strong>is after</strong>
            </>
        );
    }

    if (item.condition === ApplicationDetailsConditions.SubmissionDateIsBefore) {
        return (
            <>
                Submission Date <strong>is before</strong>
            </>
        );
    }

    if (item.condition === ApplicationDetailsConditions.SubmissionDateIsBetween) {
        return (
            <>
                Submission Date <strong>is between</strong>
            </>
        );
    }

    if (item.condition === ApplicationDetailsConditions.LastUpdateIsAfter) {
        return (
            <>
                Last Update <strong>is after</strong>
            </>
        );
    }

    if (item.condition === ApplicationDetailsConditions.LastUpdateIsBefore) {
        return (
            <>
                Last Update <strong>is before</strong>
            </>
        );
    }

    if (item.condition === ApplicationDetailsConditions.LastUpdateIsBetween) {
        return (
            <>
                Last Update <strong>is between</strong>
            </>
        );
    }

    if (
        item.condition === FormFieldsConditions.AnswerIs ||
        item.condition === FormFieldsConditions.AnswerIsNot ||
        item.condition === FormFieldsConditions.AnswerIsGreaterThan ||
        item.condition === FormFieldsConditions.AnswerIsGreaterThanOrEqualTo ||
        item.condition === FormFieldsConditions.AnswerIsLowerThan ||
        item.condition === FormFieldsConditions.AnswerIsLowerThanOrEqualTo ||
        item.condition === FormFieldsConditions.AnswerIsBetween ||
        item.condition === FormFieldsConditions.RequirementIs
    ) {
        return (
            <>
                Form field <strong>{item.condition.toLowerCase()}</strong>
            </>
        );
    }

    return (
        <>
            <strong>{item.condition}</strong> set as
        </>
    );
};
