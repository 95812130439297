import Radio from "components/ui/Input/Radio";

export const RadiosBlock: React.FC<{
    items: { label: string; isChecked: boolean; id: string }[];
    error?: string;
    readOnly: boolean;
    onChange: (values: string[]) => void;
}> = ({ items, readOnly, onChange }) => {
    if (readOnly) {
        return (
            <div className="p-2 border rounded-2 bg-white flex-column fill-width gap-3">
                {items
                    .filter((item) => item.isChecked)
                    .map((item) => (
                        <div
                            key={item.id}
                            className="flex-row fs-14 fw-400 gap-1 align-center me-auto border bg-grey-200 rounded px-2 py-1"
                        >
                            {item.label}
                        </div>
                    ))}
            </div>
        );
    }

    return (
        <div className="p-4 border rounded-2 bg-white flex-column fill-width gap-3">
            {items.map((item) => (
                <Radio
                    key={item.id}
                    label={item.label}
                    value={item.id}
                    checked={item.isChecked}
                    onChange={() => onChange([item.id])}
                    disabled={readOnly}
                />
            ))}
        </div>
    );
};
