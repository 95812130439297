import * as actionTypes from "../actionTypes";
import { windowActivate, windowRemove } from "../window/actions";
import { windowContainerTypes, resetWindowState, clearBrowserUrl } from "../../components/utils/window";
import { heartbeat, setServerTimezoneOffset } from "../system/actions";
import {
    createUser,
    getUser,
    getUserNameFromToken,
    hasPermission,
    refreshUser,
    saveUser,
    systemUserRights,
} from "../../components/utils/user";
import { deleteSubMenuState } from "../../components/utils/subMenu";
import { deleteDashboardState } from "../../components/utils/dashboard";
import { setSettings, setSettingsTheme } from "components/utils/settings";
import { isNil } from "lodash";
import { logError } from "components/utils/logger";
import { getResource } from "store/resources/actions";

export const login =
    ({ userName, password, authCode, oneTimeCode }) =>
    async (dispatch, getState) => {
        if (getState().login.isLoading) {
            return;
        }

        dispatch({
            type: actionTypes.API_POST,
            url: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL + "clienttokensv4",
            body: JSON.stringify({ userName, password, authCode, oneTimeCode }),
            recaptchaAction: "login",
            actionTypes: {
                pending: actionTypes.LOGIN_REQUEST,
                error: actionTypes.LOGIN_ERROR,
            },
            onSuccess: (action) => {
                dispatch({
                    type: actionTypes.LOGIN_RESPONSE,
                });
                updateUser(action, userName, actionTypes.LOGIN_ERROR, dispatch);
            },
        });
    };

export const loadThemes = () => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const userNumber = getState().user?.userNumber;
        if (userNumber && getState().resources.userThemes?.itemsById[userNumber]) {
            resolve();
            return;
        }

        dispatch(
            getResource({
                resourceName: "userThemes",
                key: userNumber,
                transform: (data) => data.themes.map((i) => i.theme),
                onComplete: () => {
                    setSettingsTheme();
                    resolve();
                },
            })
        );
    });
};

export const loginUser2fa =
    ({ userName }) =>
    async (dispatch, getState) => {
        const regex = RegExp("^[a-zA-Z0-9]+$");

        if (regex.test(userName)) {
            dispatch({
                type: actionTypes.API_GET,
                url: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL + "users/" + userName + "/twofactor",
                recaptchaAction: "user_2fa",
                actionTypes: {
                    response: actionTypes.LOGIN_USER_2FA_RESPONSE,
                    error: actionTypes.LOGIN_USER_2FA_ERROR,
                },
                passThroughData: { userName },
            });
        }
    };

export const loginClearQrCode = () => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const user = {
        ...getState().user,
        qrCode: undefined,
    };

    saveUser(user);

    dispatch({
        type: actionTypes.USER_SAVE,
        user,
    });
};

export const refresh =
    ({ action } = {}) =>
    async (dispatch, getState) => {
        if (action) {
            dispatch({
                type: actionTypes.LOGIN_ADD_REPEAT_ACTION,
                repeatAction: action,
            });
        }

        if (getState().login.isLoading) {
            return;
        }

        let accessToken = null;
        let refreshToken = null;

        // Get user from session storage and look into store as a fallback.
        const user = getUser() ?? getState().user;

        if (user) {
            accessToken = user.accessToken;
            refreshToken = user.refreshToken;
        }

        dispatch({
            type: actionTypes.LOGIN_REFRESH_REQUEST,
        });

        dispatch({
            type: actionTypes.API_PUT,
            url: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL + "tokensv2",
            body: JSON.stringify({ accessToken, refreshToken }),
            recaptchaAction: "login_refresh",
            skipErrorReport: true,
            onSuccess: (action) => {
                const { data } = action;
                const { repeatActions } = getState().login;
                const user = refreshUser(data);

                dispatch({
                    type: actionTypes.USER_SAVE,
                    user,
                });

                dispatch({
                    type: actionTypes.LOGIN_REFRESH_RESPONSE,
                    user,
                });

                repeatActions.forEach((repeatAction) => {
                    if (repeatAction?.passThroughData?.resourceName === "applicationTokens") {
                        try {
                            const body = JSON.parse(repeatAction.body);
                            repeatAction.body = JSON.stringify({
                                ...body,
                                accessToken: data.accessToken,
                                refreshToken: data.refreshToken,
                            });
                        } catch (error) {
                            logError(error);
                        }
                    }

                    dispatch(repeatAction);
                });

                dispatch({
                    type: actionTypes.LOGIN_CLEAR_REPEAT_ACTIONS,
                });
            },
            onError: (action) => {
                dispatch({
                    type: actionTypes.LOGIN_REFRESH_ERROR,
                    message: action.message,
                });

                dispatch(resetApp());
            },
        });
    };

export const logout = () => async (dispatch, getState) => {
    if (getState().login.logOutPending) {
        return;
    }

    const user = getState().user;
    const { accessToken, refreshToken } = user;

    dispatch({
        type: actionTypes.API_DELETE,
        url: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL + "tokens",
        body: JSON.stringify({ accessToken, refreshToken }),
        actionTypes: {
            pending: actionTypes.LOGOUT_REQUEST,
            response: actionTypes.LOGOUT_RESPONSE,
            error: actionTypes.LOGOUT_ERROR,
        },
    });

    // Call "Kill session" only if user has access to CRM and CRM is configured.
    if (hasPermission(user, systemUserRights.VISIONDSM_CRM_ACCESS) && process.env.REACT_APP_VISION_CRM_KILL_SESSION_URL) {
        dispatch({
            type: actionTypes.API_GET,
            url: process.env.REACT_APP_VISION_CRM_KILL_SESSION_URL,
        });
    }

    dispatch(resetApp());
};

export const updatePassword = (params) => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const { oldPassword, newPassword, authCode } = params;
    const { name: userName, accessToken } = getState().user;

    dispatch({
        type: actionTypes.API_PUT,
        url: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL + "password",
        body: JSON.stringify({
            userName,
            oldPassword,
            newPassword,
            accessToken,
            authCode,
        }),
        actionTypes: {
            pending: actionTypes.UPDATE_PASSWORD_REQUEST,
            error: actionTypes.UPDATE_PASSWORD_ERROR,
        },
        onSuccess: (action) => {
            const { data } = action;
            const { responseStatus } = data;

            if (responseStatus === "loginfailed") {
                dispatch({
                    type: actionTypes.UPDATE_PASSWORD_ERROR,
                    message: data.responseMessage,
                });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_PASSWORD_RESPONSE,
                });
                updateUser(action, userName, actionTypes.UPDATE_PASSWORD_ERROR, dispatch);
            }
        },
    });
};

export const updatePasswordSsrs = (params) => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const { newPassword, authorizationCode } = params;
    const { name: userName } = getState().user;

    dispatch({
        type: actionTypes.API_POST_AUTHORIZED,
        url: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL + "password/ssrs",
        body: JSON.stringify({
            newPassword,
            authorizationCode,
        }),
        actionTypes: {
            pending: actionTypes.UPDATE_PASSWORD_REQUEST,
            error: actionTypes.UPDATE_PASSWORD_ERROR,
        },
        onSuccess: (action) => {
            const { data } = action;
            const { responseStatus } = data;

            if (responseStatus === "loginfailed") {
                dispatch({
                    type: actionTypes.UPDATE_PASSWORD_ERROR,
                    message: data.responseMessage,
                });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_PASSWORD_RESPONSE,
                });
                updateUser(action, userName, actionTypes.UPDATE_PASSWORD_ERROR, dispatch);
            }
        },
    });
};

export const forgotPassword = (params) => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const { userName, recaptcha } = params;

    dispatch({
        type: actionTypes.API_POST,
        url: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL + "password",
        body: JSON.stringify({
            userName: userName,
            recaptcha: recaptcha,
        }),
        actionTypes: {
            pending: actionTypes.FORGOT_PASSWORD_REQUEST,
            response: actionTypes.FORGOT_PASSWORD_RESPONSE,
            error: actionTypes.FORGOT_PASSWORD_ERROR,
        },
    });
};

export const resetPassword =
    ({ userNumber, temporaryPassword, newPassword, recaptcha, authCode }) =>
    async (dispatch, getState) => {
        if (getState().resetPassword.isLoading) {
            return;
        }

        dispatch({
            type: actionTypes.API_POST,
            url: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}users/${userNumber}/password`,
            body: JSON.stringify({ temporaryPassword, newPassword, recaptcha, authCode }),
            actionTypes: {
                pending: actionTypes.RESET_PASSWORD_REQUEST,
                error: actionTypes.RESET_PASSWORD_ERROR,
            },
            onSuccess: (action) => {
                const { data } = action;
                const { responseStatus } = data;

                if (responseStatus === "loginfailed") {
                    dispatch({
                        type: actionTypes.RESET_PASSWORD_ERROR,
                        message: data.responseMessage,
                    });
                } else {
                    dispatch({
                        type: actionTypes.RESET_PASSWORD_RESPONSE,
                    });

                    updateUser(action, getUserNameFromToken(data.accessToken), actionTypes.RESET_PASSWORD_ERROR, dispatch);

                    clearBrowserUrl();

                    dispatch(
                        windowRemove({
                            name: "Reset Password",
                            containerName: windowContainerTypes.Login,
                        })
                    );
                }
            },
        });
    };

export const resetLogin = () => (dispatch) => {
    dispatch({
        type: actionTypes.LOGIN_RESET,
    });

    dispatch(
        windowActivate({
            name: "Login",
            containerName: windowContainerTypes.Login,
        })
    );
};

export const resetApp = () => async (dispatch, getState) => {
    resetWindowState();
    deleteSubMenuState();
    deleteDashboardState();

    dispatch({
        type: actionTypes.APP_RESET,
    });

    dispatch({
        type: actionTypes.MODAL_CLOSE,
    });

    dispatch(
        windowActivate({
            name: "Login",
            containerName: windowContainerTypes.Login,
        })
    );

    dispatch(heartbeat());
};

async function updateUser(action, userName, errorActionType, dispatch) {
    const { data } = action;
    const { serverTimezoneOffset, responseMessage } = data;

    const user = createUser({
        ...data,
        userName,
    });

    if (user.settings) {
        setSettings(user.settings);
    }

    if (!isNil(serverTimezoneOffset)) {
        dispatch(setServerTimezoneOffset({ serverTimezoneOffset }));
    }

    switch (user.status) {
        case "active":
            saveUser(user);

            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            break;

        case "expired":
            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            break;

        default:
            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            dispatch({
                type: errorActionType,
                message: responseMessage,
            });
            break;
    }
}
