import {
    ApplicationDetailsConditions,
    EntryPoints,
    FilterVariable,
    FilterVariablesItem,
    getConditionIcon,
} from "components/views/ApplicationProcessingWorkCenterV2/utils";
import { VariablePath } from "../VariablePath";
import { ConditionBlock } from "../ConditionBlock";
import { RadiosBlock } from "../RadiosBlock";
import { StatesBlock } from "../StatesBlock";
import { DateBlock, DateRangeBlock } from "../DateBlock";
import { useProgramEvents } from "store/resources/useResource";
import { CheckboxesBlock } from "../CheckboxesBlock";
import cn from "classnames";
import { isNil } from "lodash";

export const VariableBlockItemApplicationDetails: React.FC<{
    variable: FilterVariable;
    error?: string;
    item: FilterVariablesItem;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
    onEdit: () => void;
    onDelete: () => void;
    onToggle?: () => void;
    onToggleValues?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onEdit, onDelete, onToggle, onToggleValues }) => {
    return (
        <div
            className={cn(
                "flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 focus-within:bg-grey-200 bg-opacity-50 gap-2",
                isNil(onToggle) ? "px-3" : "px-1"
            )}
            data-testid={`variable-block-item-${variable.items.indexOf(item)}`}
        >
            <VariablePath icon={getConditionIcon(variable.type, item.condition)!} />
            <div className={cn("flex-column fill-width bg-opacity-100", isNil(onToggle) ? "pb-3" : "pb-1")}>
                <ConditionBlock item={item} readOnly={readOnly} onEdit={onEdit} onDelete={onDelete} onToggle={onToggle} />
                <ConditionValuesBlock
                    variable={variable}
                    item={item}
                    readOnly={readOnly}
                    error={error}
                    onChange={onChange}
                    onToggle={onToggleValues}
                />
            </div>
        </div>
    );
};

const ConditionValuesBlock: React.FC<{
    variable: FilterVariable;
    item: FilterVariablesItem;
    readOnly: boolean;
    error?: string;
    onChange: (item: FilterVariablesItem) => void;
    onToggle?: (values: string[]) => void;
}> = ({ variable, item, readOnly, error, onChange, onToggle }) => {
    if (item.condition === ApplicationDetailsConditions.EntryPointIs) {
        return (
            <RadiosBlock
                items={Object.values(EntryPoints).map((v) => ({ label: v, isChecked: item.values.includes(v), id: v }))}
                readOnly={readOnly || !isNil(onToggle)}
                error={error}
                onChange={(values) => onChange({ ...item, values })}
            />
        );
    }

    if (([ApplicationDetailsConditions.PrimaryStateIs, ApplicationDetailsConditions.PremiseStateIs] as string[]).includes(item.condition)) {
        return <StatesBlock item={item} readOnly={readOnly} error={error} onChange={onChange} onToggle={onToggle} />;
    }

    if (
        (
            [
                ApplicationDetailsConditions.SubmissionDateIsAfter,
                ApplicationDetailsConditions.SubmissionDateIsBefore,
                ApplicationDetailsConditions.LastUpdateIsAfter,
                ApplicationDetailsConditions.LastUpdateIsBefore,
            ] as string[]
        ).includes(item.condition)
    ) {
        return <DateBlock item={item} readOnly={readOnly || !isNil(onToggle)} error={error} onChange={onChange} />;
    }

    if (
        ([ApplicationDetailsConditions.SubmissionDateIsBetween, ApplicationDetailsConditions.LastUpdateIsBetween] as string[]).includes(
            item.condition
        )
    ) {
        return <DateRangeBlock item={item} readOnly={readOnly || !isNil(onToggle)} error={error} onChange={onChange} />;
    }

    if (item.condition === ApplicationDetailsConditions.EventIs) {
        return <EventsBlock variable={variable} item={item} readOnly={readOnly} error={error} onChange={onChange} onToggle={onToggle} />;
    }

    return null;
};

const EventsBlock: React.FC<{
    variable: FilterVariable;
    item: FilterVariablesItem;
    readOnly?: boolean;
    error?: string;
    onChange: (item: FilterVariablesItem) => void;
    onToggle?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onToggle }) => {
    const [events, isLoading] = useProgramEvents({ programNumber: variable.programNumber });

    const items = (events ?? []).map((event) => ({
        label: event.name,
        id: event.programEventNumber,
        isChecked: item.values.includes(event.programEventNumber),
    }));

    return (
        <CheckboxesBlock
            items={items}
            readOnly={readOnly}
            isLoading={isLoading}
            error={error}
            emptyStateMessage="No events available."
            onChange={(values) => onChange({ ...item, values })}
            onToggle={onToggle}
        />
    );
};
