import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import DropdownListWrapper from "components/ui/DropdownList/DropdownListWrapper";
import DropdownList from "components/ui/DropdownList";
import usePopper from "components/utils/usePopper";
import cn from "classnames";
import WaitIcon from "components/ui/WaitIcon";
import { FilterColumn } from "../utils";
import useOnClickOutside from "components/utils/useOnClickOutside";
import { isEmpty } from "lodash";
import SearchInput from "components/ui/Input/SearchInput";

export const FilterSelectDropdown: React.FC<{
    columnTitle: string;
    withSearch?: boolean;
    onSelect: (item: { label: string; value: string }) => void;
    items?: { label: string; value: string }[];
    isLoadingItems?: boolean;
    noFilters?: boolean;
    isDropdownOpen: boolean;
    setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveColumn: React.Dispatch<React.SetStateAction<FilterColumn | null>>;
    disabled?: boolean;
}> = ({
    withSearch,
    columnTitle,
    onSelect,
    items,
    isLoadingItems,
    noFilters,
    setActiveColumn,
    isDropdownOpen,
    setIsDropdownOpen,
    disabled,
}) => {
    const triggerRef = useRef<any>(null);
    const popupRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<any>(null);
    const listRef = useRef<any>(null);

    const [searchValue, setSearchValue] = useState<string>("");

    const filteredItems = items?.filter((item) => (searchValue ? item.label.toLowerCase().includes(searchValue.toLowerCase()) : true));

    useLayoutEffect(() => {
        const input = triggerRef.current.querySelector("input");
        if (input) {
            input.focus();
        }
    }, [isDropdownOpen]);

    const onAdd = useCallback(() => {
        if (!disabled) {
            // Wait for the column width to expanded first
            setTimeout(() => {
                setIsDropdownOpen(!isDropdownOpen);
                setActiveColumn(columnTitle as FilterColumn);
            }, 200);
        }
    }, [columnTitle, disabled, isDropdownOpen, setActiveColumn, setIsDropdownOpen]);

    const close = (event: any) => {
        const onboardingShown = noFilters && !localStorage.getItem("workcenter-onboarding-complete");
        if (isDropdownOpen && (!event || event.target !== triggerRef.current) && !onboardingShown) {
            setIsDropdownOpen(false);
        }
    };

    const selectFilterValue = useCallback(
        (value: any) => {
            if (listRef.current) {
                listRef.current?.scrollTo(0, 0);
            }
            setIsDropdownOpen(false);
            setSearchValue("");
            onSelect(value);
        },
        [onSelect, setIsDropdownOpen]
    );

    usePopper({
        triggerRef,
        popperRef,
        popupRef,
        placement: "bottom",
        enabled: true,
        modifiers: {},
    });

    useOnClickOutside(popupRef, (event: any) => {
        if (
            event?.target !== triggerRef?.current &&
            !(event?.target instanceof HTMLInputElement) &&
            !(event?.target instanceof SVGPathElement) &&
            !(event?.target instanceof SVGElement)
        ) {
            setSearchValue("");
            close(event);
            popperRef?.current?.forceUpdate();
        }
    });

    return (
        <>
            <div ref={triggerRef}>
                <button
                    className={cn("filter-add-button rounded fill-width bg-white flex-row align-center py-2", {
                        active: isDropdownOpen,
                        disabled,
                    })}
                    hidden={isDropdownOpen && withSearch}
                    onClick={() => onAdd()}
                    disabled={disabled}
                    title={`Add ${columnTitle}`}
                >
                    <FontAwesomeIcon className="px-2" icon={["fal", "circle-plus"]} fixedWidth size="1x" />
                    <span className="fs-14 fw-600">Add</span>
                </button>
                {withSearch && (
                    <div className="dropdown-search flex-row gap-2 align-center" hidden={!isDropdownOpen}>
                        <SearchInput
                            className="w-full"
                            ariaLabel={`Search ${columnTitle}`}
                            value={searchValue}
                            autoFocus
                            onChange={(event: any) => setSearchValue(event.target.value)}
                            onSubmit={() => {
                                if (filteredItems?.length === 1) {
                                    selectFilterValue(filteredItems[0]);
                                }
                            }}
                        />
                    </div>
                )}
            </div>
            <DropdownListWrapper
                triggerRef={triggerRef}
                popupRef={popupRef}
                popperRef={popperRef}
                enabled={true}
                visible={isDropdownOpen}
                className="workcenter-filter-dropdown"
                filled={undefined}
                multipleChoice={undefined}
                zIndexOverride={undefined}
                zIndexOffset={undefined}
            >
                <DropdownList className="dropdown-menu border" alone elementRef={popupRef} visible={isDropdownOpen} onClose={close}>
                    {isLoadingItems ? (
                        <WaitIcon className="p-4" />
                    ) : (
                        <div className="flex-column">
                            {isEmpty(items) ? (
                                <span className="menu-title px-4">No results found</span>
                            ) : (
                                <>
                                    <ul ref={listRef} className="dropdown-filter-list flex-column">
                                        <span className="menu-title px-4">Select {columnTitle}</span>
                                        {filteredItems?.map((item, index) => {
                                            return (
                                                <li key={item.value} className="dropdown-item px-4" onClick={() => selectFilterValue(item)}>
                                                    <span className="fw-500 truncate">{item.label}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    {isEmpty(filteredItems) && <span className="menu-title px-4">No results found</span>}
                                </>
                            )}
                        </div>
                    )}
                </DropdownList>
            </DropdownListWrapper>
        </>
    );
};
