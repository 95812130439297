import {
    FilterVariable,
    FilterVariableForFormField,
    FilterVariableForMeasureAttribute,
    FilterVariablesItem,
    isFormVariable,
    isMeasureVariable,
    Rule,
    VariableCondition,
} from "../../utils";
import { ChangeEvent, useMemo, useState } from "react";
import { VariableBlockItem } from "./VariableBlockItem";
import { VariableHeader } from "./VariableHeader";
import { ConditionAddButton, ConditionAddLine, ConditionSelectLine } from "./ConditionBlock";
import { cloneDeep, isNil, isString } from "lodash";
import { useProgramCatalogs } from "store/resources/useResource";
import { VariablePath } from "./VariablePath";
import DropDownInput from "components/ui/Input/DropDownInput";
import Button from "components/ui/Button";
import useFormPageFields from "components/ui/Workflow/utils/hooks/useFormPageFields";
import useFormPages from "components/ui/Workflow/utils/hooks/useFormPages";
import { ErrorMessage } from "./ErrorMessage";

export const VariableBlock: React.FC<{
    variable: FilterVariable;
    readOnly?: boolean;
    errors?: string | string[];
    scrollContainerId?: string;
    showConditionSelect?: boolean;
    showCatalogSelect?: boolean;
    showFormFieldSelect?: boolean;
    onItemChange?: (itemIndex: number, item: FilterVariablesItem) => void;
    onItemDelete?: (itemIndex: number) => void;
    onItemsClear?: () => void;
    onItemToggle?: (itemIndex: number) => void;
    onItemToggleValues?: (itemIndex: number, values: string[]) => void;
    onVariableChange?: (variable: FilterVariable) => void;
    onVariableDelete?: () => void;
    onVariableToggle?: (enabled: boolean) => void;
    onVariableEdit?: () => void;
    setShowConditionSelect?: (showConditionSelect: boolean) => void;
    onVariableConditionAdd?: (condition: VariableCondition, rule?: Rule) => void;
    onConditionSelect?: () => void;
}> = ({
    variable,
    readOnly = false,
    showConditionSelect,
    showCatalogSelect,
    showFormFieldSelect,
    errors,
    scrollContainerId,
    setShowConditionSelect,
    onItemChange,
    onItemDelete,
    onItemsClear,
    onItemToggle,
    onItemToggleValues,
    onVariableChange,
    onVariableDelete,
    onVariableToggle,
    onVariableEdit,
    onVariableConditionAdd,
    onConditionSelect,
}) => {
    const configurable = !isNil(onVariableToggle);

    const showConditionAddLine = !showCatalogSelect && !showFormFieldSelect;

    const showConditionAddButton = !readOnly && !configurable && !showConditionSelect && !showCatalogSelect && !showFormFieldSelect;

    const variableError = isString(errors) ? errors : undefined;
    const itemErrors = isString(errors) ? [] : errors;

    const onConditionCancel = () => {
        setShowConditionSelect?.(false);
    };

    const onClearAllItems = () => {
        setShowConditionSelect?.(true);
        onItemsClear?.();
    };

    if (readOnly && showConditionSelect) {
        setShowConditionSelect?.(false);
    }

    return (
        <section aria-label="Variable configuration">
            <VariableHeader
                title={variable.type}
                readOnly={readOnly}
                enabled={variable._enabled}
                onItemsClear={onClearAllItems}
                onVariableDelete={onVariableDelete}
                onVariableToggle={onVariableToggle}
                onVariableEdit={onVariableEdit}
            />
            {showConditionAddLine && (
                <ConditionAddLine
                    variable={variable}
                    configurable={configurable}
                    onClick={
                        readOnly || configurable || showCatalogSelect || showFormFieldSelect || showConditionSelect
                            ? undefined
                            : onConditionSelect
                    }
                />
            )}
            {showCatalogSelect && !showConditionAddButton && isMeasureVariable(variable) && (
                <CatalogSelectBlock
                    variable={variable}
                    error={variableError}
                    onVariableChange={(variable) => onVariableChange?.(variable)}
                />
            )}
            {showFormFieldSelect && isFormVariable(variable) && (
                <FormFieldSelectBlock
                    variable={variable}
                    error={variableError}
                    onVariableChange={(variable) => onVariableChange?.(variable)}
                />
            )}
            {variable.items.map((item, index) => (
                <VariableBlockItem
                    key={item._id}
                    variable={variable}
                    error={itemErrors?.[index]}
                    item={item}
                    itemIndex={index}
                    readOnly={readOnly}
                    scrollContainerId={scrollContainerId}
                    onChange={onItemChange}
                    onDelete={onItemDelete}
                    onToggle={onItemToggle}
                    onToggleItemValues={onItemToggleValues}
                />
            ))}
            {showConditionSelect && onVariableConditionAdd && (
                <ConditionSelectLine
                    key={variable.type}
                    type={variable.type}
                    error={variableError}
                    showRule={variable.items.length > 0}
                    scrollContainerId={scrollContainerId}
                    onSelect={onVariableConditionAdd}
                    onCancel={onConditionCancel}
                />
            )}
            {showConditionAddButton && onConditionSelect && <ConditionAddButton onClick={onConditionSelect} />}
        </section>
    );
};

const CatalogSelectBlock: React.FC<{
    variable: FilterVariableForMeasureAttribute;
    error?: string;
    onVariableChange: (variable: FilterVariableForMeasureAttribute) => void;
}> = ({ variable, error, onVariableChange }) => {
    const [selectedCatalog, setSelectedCatalog] = useState<string>();

    const [catalogs, isLoading] = useProgramCatalogs({ programNumber: variable.programNumber });

    const dropdownList = useMemo(
        () => (catalogs ?? []).map((catalog) => ({ label: catalog.name, value: catalog.catalogNumber })),
        [catalogs]
    );

    const onSave = () => {
        if (selectedCatalog) {
            const updatedVariable = cloneDeep(variable);
            updatedVariable.catalogNumber = selectedCatalog;
            onVariableChange(updatedVariable);
        }
    };

    return (
        <div className="flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 bg-opacity-50 px-3 gap-2 relative">
            <VariablePath icon={["far", "square-dashed"]} />
            <div className="flex-column flex-one-in-row py-3 gap-2">
                <div className="flex-row align-center gap-2">
                    <DropDownInput
                        // @ts-ignore
                        defaultOpen
                        data={dropdownList}
                        value={selectedCatalog}
                        placeholder={isLoading ? "Loading..." : "Select catalog"}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedCatalog(e.target.value)}
                    />
                    <Button small primary onClick={onSave} disabled={!selectedCatalog}>
                        Apply
                    </Button>
                </div>
                {error && <ErrorMessage error={error} />}
            </div>
        </div>
    );
};

const FormFieldSelectBlock: React.FC<{
    variable: FilterVariableForFormField;
    error?: string;
    onVariableChange: (variable: FilterVariableForFormField) => void;
}> = ({ variable, error, onVariableChange }) => {
    const [selectedForm, setSelectedForm] = useState<string>();
    const [selectedField, setSelectedField] = useState<string>();

    const [forms, isLoadingForms] = useFormPages({ entityId: variable.programNumber });
    const [fields, isLoadingFields] = useFormPageFields({
        entityId: variable.programNumber,
        pageNumber: selectedForm,
    });

    const formsDropdownList = useMemo(() => (forms ?? []).map((page) => ({ label: page.name, value: page.number })), [forms]);

    const fieldDropdownList = useMemo(() => {
        return (fields ?? []).map((field) => ({
            label: field.friendlyName,
            value: field.fieldId,
        }));
    }, [fields]);

    const onSelectForm = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedForm(e.target.value);
        setSelectedField(undefined);
    };

    const onSelectField = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedField(e.target.value);
    };

    const onSave = () => {
        if (selectedForm && selectedField) {
            const updatedVariable = cloneDeep(variable);
            updatedVariable.pageNumber = selectedForm;
            updatedVariable.fieldNumber = selectedField;
            onVariableChange(updatedVariable);
        }
    };

    return (
        <div className="flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 bg-opacity-50 px-3 gap-2 relative">
            <VariablePath icon={["far", "square-dashed"]} />
            <div className="flex-column flex-one-in-row py-3 gap-2">
                <div className="flex-row align-center gap-2">
                    <DropDownInput
                        // @ts-ignore
                        defaultOpen
                        data={formsDropdownList}
                        value={selectedForm}
                        placeholder={isLoadingForms ? "Loading..." : "Please select"}
                        onChange={onSelectForm}
                    />
                    <DropDownInput
                        // @ts-ignore
                        data={fieldDropdownList}
                        value={selectedField}
                        placeholder={isLoadingFields ? "Loading..." : "Please select"}
                        disabled={!selectedForm}
                        onChange={onSelectField}
                    />
                    <Button small primary onClick={onSave} disabled={!selectedForm || !selectedField}>
                        Apply
                    </Button>
                </div>
                {error && <ErrorMessage error={error} />}
            </div>
        </div>
    );
};
