export const IconSquare: React.FC<{
    style?: React.CSSProperties;
    children: React.ReactNode;
}> = ({ style, children }) => {
    return (
        <div className="flex-column align-center justify-center" style={{ width: 32, height: 32, ...style }}>
            {children}
        </div>
    );
};
