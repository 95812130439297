import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterColumnPlaceholder } from "./FilterColumnPlaceholder";
import { FilterList } from "./FilterList";
import { FilterSelectDropdown } from "./FilterSelectDropdown";
import cn from "classnames";
import { useUtilities } from "store/resources/useResource";
import { FilterItem, UtilityItem, FilterColumn, FilterErrors } from "../utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { openPlainModal } from "components/ui/Modal/utils";
import { OnboardingModal } from "./Onboarding";
import { isEmpty } from "lodash";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import SearchInput from "components/ui/Input/SearchInput";
import IconWrap from "components/ui/Icons";

export const UtilityFilter: React.FC<{
    filter: UtilityItem[];
    selectedUtility: FilterItem | null;
    isActiveColumn: boolean;
    setActiveColumn: React.Dispatch<React.SetStateAction<FilterColumn | null>>;
    isDropdownOpen: boolean;
    setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    showOnboarding: React.MutableRefObject<any>;
    noFilters?: boolean;
    onAdd: (utility: { label: string; value: string }) => void;
    onAddProgramToUtility: () => void;
    onClear: (index: number) => void;
    onSelect: (index: number) => void;
    onRemove: (index: number) => void;
    readOnly?: boolean;
    errors?: FilterErrors;
}> = ({
    filter,
    selectedUtility,
    isActiveColumn,
    isDropdownOpen,
    setIsDropdownOpen,
    showOnboarding,
    errors,
    noFilters,
    readOnly,
    onClear,
    onAdd,
    onSelect,
    onRemove,
    setActiveColumn,
    onAddProgramToUtility,
}) => {
    const [utilities = [], isLoadingUtilities] = useUtilities({
        forced: false,
    });
    const [showListFilter, setShowListFilter] = useState(false);
    const [listFilterValue, setListFilterValue] = useState("");

    const dropdownItems = useMemo(
        () =>
            (utilities ?? [])
                .map((item) => ({ label: item.utility, value: item.utilityNumber }))
                .filter((v: any) => !filter.map((v) => v.utilityNumber).includes(v.value)),
        [utilities, filter]
    );

    const filterItems = useMemo(
        () =>
            filter
                .map((item) => ({ label: item.utility, value: item.utilityNumber, icon: ["fas", "building"] as IconProp }))
                .filter((item) => item.label.toLowerCase().includes(listFilterValue.toLowerCase())),
        [filter, listFilterValue]
    );

    const selectedIndex = useMemo(
        () => filterItems.findIndex((v) => v.value === selectedUtility?.utilityNumber) ?? filter.findIndex((v) => v === selectedUtility),
        [selectedUtility, filter, filterItems]
    );

    const onCloseOnboarding = useCallback(() => {
        localStorage.setItem("workcenter-onboarding-complete", "true");
        if (!selectedUtility && dropdownItems.length === 1 && isActiveColumn && !isLoadingUtilities) {
            onAdd(dropdownItems[0]);
            setIsDropdownOpen(false);
        } else {
            setActiveColumn("Utility");
            setIsDropdownOpen(true);
        }
    }, [dropdownItems, isActiveColumn, isLoadingUtilities, onAdd, selectedUtility, setActiveColumn, setIsDropdownOpen]);

    const onSelectItem = useCallback(
        (item: { label: string; value: string }) => {
            const index = filter.findIndex((v) => v.utilityNumber === item.value);
            onSelect(index);
        },
        [onSelect, filter]
    );

    // use ref to prevent stale state issues related to the modal
    const onCloseOnboardingRef = useRef(onCloseOnboarding);
    useEffect(() => {
        onCloseOnboardingRef.current = onCloseOnboarding;
    }, [onCloseOnboarding]);

    useEffect(() => {
        if (
            dropdownItems.length === 1 &&
            !selectedUtility &&
            isActiveColumn &&
            !isLoadingUtilities &&
            !(noFilters && !localStorage.getItem("workcenter-onboarding-complete"))
        ) {
            onAdd(dropdownItems[0]);
            setIsDropdownOpen(false);
        }
    }, [isActiveColumn, isLoadingUtilities, noFilters, onAdd, selectedUtility, dropdownItems, setIsDropdownOpen]);

    if (showOnboarding.current) {
        showOnboarding.current = false;
        openPlainModal({
            className: "onboarding-modal-workcenter",
            content: <OnboardingModal onClose={() => onCloseOnboardingRef.current()} />,
        });
    }

    return (
        <div
            role="region"
            aria-label="Utility list"
            className={cn("filter-column utility-filter fill-height flex-column p-2 gap-2 transition-all group", { isDropdownOpen })}
        >
            <div
                className={cn("filter-header flex-row align-center justify-space-between bg-white p-1", {
                    isActiveColumn,
                    "not-empty": !isEmpty(filter),
                })}
            >
                {showListFilter ? (
                    <div className="flex-row align-center gap-2">
                        <SearchInput
                            ariaLabel={`Search selected utilities`}
                            value={listFilterValue}
                            autoFocus
                            onChange={(event: any) => setListFilterValue(event.target.value)}
                            onSubmit={() => {
                                if (filterItems?.length === 1) {
                                    onSelect(filter.findIndex((item) => item.utility === filterItems[0].label));
                                }
                            }}
                        />
                        <IconWrap
                            icon="clear-close"
                            onClick={() => {
                                setShowListFilter(false);
                                setListFilterValue("");
                            }}
                            title="Close"
                        />
                    </div>
                ) : (
                    <>
                        <h2 className={cn("column-title group-hover:text-lg fs-16", isActiveColumn)}>Utility</h2>
                        <button className="rounded" title="Search Utility" onClick={() => setShowListFilter(true)}>
                            <div className="p-1">
                                <FontAwesomeIcon
                                    icon={["far", "magnifying-glass"]}
                                    color={!isActiveColumn ? "gray" : "black"}
                                    fixedWidth
                                    size="sm"
                                />
                            </div>
                        </button>
                    </>
                )}
            </div>
            {!readOnly && (
                <FilterSelectDropdown
                    columnTitle="Utility"
                    withSearch
                    onSelect={onAdd}
                    items={dropdownItems}
                    isLoadingItems={isLoadingUtilities}
                    noFilters={noFilters}
                    isDropdownOpen={isDropdownOpen}
                    setIsDropdownOpen={setIsDropdownOpen}
                    setActiveColumn={setActiveColumn}
                />
            )}
            {filter.length > 0 ? (
                <FilterList
                    items={filterItems}
                    selectedIndex={selectedIndex}
                    readOnly={readOnly}
                    addItemLabel="Add Program"
                    onAddItem={onAddProgramToUtility}
                    onClearItem={onClear}
                    onSelectItem={onSelectItem}
                    onRemoveItem={onRemove}
                />
            ) : (
                <FilterColumnPlaceholder>
                    <div className="flex-column align-center px-4 gap-2">
                        <FontAwesomeIcon icon={["fas", "building"]} color="gray" fixedWidth size="2x" />
                        <h4>No Utilities</h4>
                        <span className="fs-14">
                            Please add a Utility <br /> to start your filter
                        </span>
                    </div>
                </FilterColumnPlaceholder>
            )}
        </div>
    );
};
