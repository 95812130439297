import uuidv1 from "uuid/v1";
import { get, isNil, orderBy } from "lodash";

import { createResource, updateResource, optimisticUpdateItem, deleteResource } from "../resources/actions";
import { getSubmitConfiguration, dashboardTypes, setDashboardState } from "../../components/utils/dashboard";
import * as actionTypes from "../actionTypes";
import { log } from "components/utils/logger";

export const saveDashboard =
    ({ dashboard, showSuccessNotification = false, onSuccess, onError, onComplete }) =>
    (dispatch, getState) => {
        let dashboards = get(getState(), `resources.dashboards.itemsById[${dashboard.type}]`, []);

        log("action saveDashboard:", JSON.stringify({ dashboard, dashboards }, null, 4));

        if (dashboard.isGlobal) {
            dispatch(
                cloneDashboard({
                    dashboard,
                    showSuccessNotification,
                    onSuccess,
                    onError,
                    onComplete,
                })
            );
        } else {
            // If this will be the default dashboard, update existing default dashboard.
            if (dashboard.isDefault) {
                const currentDefaultDashboard = dashboards.filter((d) => d.isDefault && !d.isGlobal && d.id !== dashboard.id)[0];
                if (currentDefaultDashboard) {
                    dispatch(
                        saveDashboard({
                            dashboard: {
                                ...currentDefaultDashboard,
                                isDefault: false,
                            },
                        })
                    );

                    dashboards = get(getState(), `resources.dashboards.itemsById[${dashboard.type}]`, []);
                }
            }

            if (dashboard.id) {
                dispatch(
                    optimisticUpdateItem({
                        resourceName: "dashboards",
                        resourceId: dashboard.type,
                        value: dashboards.map((d) => ({
                            ...(d.id === dashboard.id ? dashboard : d),
                        })),
                    })
                );

                dispatch(
                    updateResource({
                        resourceName: "dashboards",
                        resourceId: dashboard.id,
                        body: getSubmitParams(dashboard),
                        showSuccessNotification,
                        onSuccess: onSuccess,
                        onError,
                        onComplete,
                    })
                );
            } else {
                const tempNumber = uuidv1().toUpperCase();
                const needToCloneGlobal =
                    dashboard.type === dashboardTypes.LANDING_PAGE && dashboards.length > 0 && dashboards.every((d) => d.isGlobal);

                if (needToCloneGlobal) {
                    dispatch(
                        cloneDashboard({
                            dashboard: dashboards[0],
                            onComplete: () => {
                                dispatch(
                                    saveDashboard({
                                        dashboard,
                                        showSuccessNotification,
                                        onSuccess,
                                        onError,
                                        onComplete,
                                    })
                                );
                            },
                        })
                    );
                } else {
                    dispatch(
                        optimisticUpdateItem({
                            resourceName: "dashboards",
                            resourceId: dashboard.type,
                            value: dashboards.concat([
                                {
                                    ...dashboard,
                                    id: tempNumber,
                                },
                            ]),
                        })
                    );

                    dispatch(
                        createResource({
                            resourceName: "dashboards",
                            body: getSubmitParams(dashboard),
                            showSuccessNotification,
                            onSuccess: (action) => {
                                // Update dashboard Id
                                const dashboardNumber = action.data.number;
                                const updatedDashboards = get(getState(), `resources.dashboards.itemsById[${dashboard.type}]`, [])
                                    .slice()
                                    .map((d) => ({
                                        ...d,
                                        id: d.id === tempNumber ? dashboardNumber : d.id,
                                    }));

                                dispatch(
                                    optimisticUpdateItem({
                                        resourceName: "dashboards",
                                        resourceId: dashboard.type,
                                        value: orderBy(updatedDashboards, ["name"]),
                                    })
                                );

                                onSuccess && onSuccess(action);
                            },
                            onError: (action) => {
                                // Remove Dashboard
                                dispatch(
                                    optimisticUpdateItem({
                                        resourceName: "dashboards",
                                        resourceId: dashboard.type,
                                        value: dashboards.filter((d) => d.id !== tempNumber),
                                    })
                                );

                                onError && onError(action);
                            },
                            onComplete,
                        })
                    );
                }
            }
        }
    };

export const deleteDashboard =
    ({ dashboard, onSuccess, onError, onComplete }) =>
    (dispatch, getState) => {
        const dashboards = get(getState(), `resources.dashboards.itemsById[${dashboard.type}]`, []);

        dispatch(
            optimisticUpdateItem({
                resourceName: "dashboards",
                resourceId: dashboard.type,
                value: dashboards.filter((d) => d.id !== dashboard.id),
            })
        );

        dispatch(
            deleteResource({
                resourceName: "dashboards",
                resourceId: dashboard.id,
                onSuccess,
                onError,
                onComplete,
            })
        );
    };

export const updateLandingPagePanel =
    ({ dashboardId, panelId, panelSettings }) =>
    (dispatch, getState) => {
        const dashboards = get(getState(), `resources.dashboards.itemsById[${dashboardTypes.LANDING_PAGE}]`, []);
        const dashboard = dashboards.find((i) => i.id === dashboardId);

        if (dashboard) {
            dispatch(
                saveDashboard({
                    dashboard: {
                        ...dashboard,
                        panels: dashboard.panels.map((p) => (p.id === panelId ? { ...p, ...panelSettings } : { ...p })),
                    },
                })
            );
        }
    };

export const cloneDashboard =
    ({ dashboard, showSuccessNotification = false, onSuccess, onError, onComplete }) =>
    (dispatch, getState) => {
        const tempNumber = uuidv1().toUpperCase();
        const dashboards = get(getState(), `resources.dashboards.itemsById[${dashboard.type}]`, []);

        // Add cloned Dashboard
        dispatch(
            optimisticUpdateItem({
                resourceName: "dashboards",
                resourceId: dashboard.type,
                value: dashboards.concat([
                    {
                        ...dashboard,
                        id: tempNumber,
                        isDefault: dashboard.isGlobal,
                        isGlobal: false,
                    },
                ]),
            })
        );

        // Update Active Dashboard ids.
        updateActiveDashboardIds({
            activeDashboardIds: get(getState(), `dashboards.activeDashboardId`, []),
            oldId: dashboard.id,
            newId: tempNumber,
            dispatch,
        });

        // Save Cloned dashboard
        dispatch(
            createResource({
                resourceName: "dashboards",
                body: {
                    ...getSubmitParams(dashboard),
                    isDefault: dashboard.isGlobal,
                },
                showSuccessNotification,
                onSuccess: (action) => {
                    // Update dashboard Id
                    const dashboardNumber = action.data.number;
                    const updatedDashboards = get(getState(), `resources.dashboards.itemsById[${dashboard.type}]`, [])
                        .slice()
                        .map((d) => ({
                            ...d,
                            id: d.id === tempNumber ? dashboardNumber : d.id,
                        }));

                    dispatch(
                        optimisticUpdateItem({
                            resourceName: "dashboards",
                            resourceId: dashboard.type,
                            value: updatedDashboards,
                        })
                    );

                    // Update Active Dashboard ids.
                    updateActiveDashboardIds({
                        activeDashboardIds: get(getState(), `dashboards.activeDashboardId`, []),
                        oldId: tempNumber,
                        newId: dashboardNumber,
                        dispatch,
                    });

                    onSuccess && onSuccess(action);
                },
                onError: (action) => {
                    // Remove cloned Dashboard
                    dispatch(
                        optimisticUpdateItem({
                            resourceName: "dashboards",
                            resourceId: dashboard.type,
                            value: dashboards.filter((d) => d.id !== tempNumber),
                        })
                    );

                    // Update Active Dashboard ids.
                    updateActiveDashboardIds({
                        activeDashboardIds: get(getState(), `dashboards.activeDashboardId`, []),
                        oldId: tempNumber,
                        newId: dashboard.id,
                        dispatch,
                    });

                    onError && onError(action);
                },
                onComplete,
            })
        );
    };

export const saveLayout =
    ({ dashboard, layout }) =>
    (dispatch, getState) => {
        let dashboardChanged = false;
        const activeDashboard = {
            ...dashboard,
        };

        activeDashboard.panels = activeDashboard.panels.map((p) => {
            const changedPanel = layout.filter((item) => item.i === p.id);

            if (
                changedPanel.length &&
                (changedPanel[0].w !== p.w || changedPanel[0].h !== p.h || changedPanel[0].x !== p.x || changedPanel[0].y !== p.y)
            ) {
                dashboardChanged = true;

                return {
                    ...p,
                    w: changedPanel[0].w,
                    h: changedPanel[0].h,
                    x: changedPanel[0].x,
                    y: changedPanel[0].y,
                };
            }

            return p;
        });

        if (dashboardChanged) {
            dispatch(
                saveDashboard({
                    dashboard: activeDashboard,
                })
            );
        }
    };

export const setActiveDashboard =
    ({ entityNumber, dashboardNumber }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_ACTIVE_DASHBOARD,
            entityNumber,
            dashboardNumber,
        });

        setDashboardState(getState().dashboards);
    };

export const setActiveTempPanel =
    ({ entityNumber, panel, activeToolType }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_ACTIVE_TEMP_PANEL,
            activeToolType,
            entityNumber,
            panel,
        });

        setDashboardState(getState().dashboards);
    };

export const setActiveDashboardTool =
    ({ entityNumber, tool }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_ACTIVE_DASHBOARD_TOOL,
            entityNumber,
            tool,
        });

        setDashboardState(getState().dashboards);
    };

export const setCatalogAutoOpen =
    ({ programNumber, activePanel, catalogNumber, utilityNumber, autoOpen }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_AUTO_CATALOG_EDIT_OPEN,
            programNumber,
            activePanel,
            catalogNumber,
            utilityNumber,
            autoOpen,
        });
    };

export const setAttributeAutoOpen =
    ({ programNumber, activePanel, attributeNumber, attributeType, attributeValue, utilityNumber, autoOpen }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_AUTO_ATTRIBUTE_EDIT_OPEN,
            programNumber,
            activePanel,
            attributeNumber,
            attributeType,
            attributeValue,
            utilityNumber,
            autoOpen,
        });
    };

export const toggleSidebar =
    ({ dashboardKey, state }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.TOGGLE_DASHBOARD_SIDEBAR,
            dashboardKey,
            state,
        });

        setDashboardState(getState().dashboards);
    };

export const toggleWidgetConfiguration =
    ({ entityNumber }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.TOGGLE_WIDGET_CONFIGURATION,
            entityNumber,
        });

        setDashboardState(getState().dashboards);
    };

export const setActiveGlobalDashboard =
    ({ dashboardType, activeDashboardId }) =>
    (dispatch, getState) => {
        const dashboards = get(getState(), `resources.dashboards.itemsById[${dashboardType}]`, []);
        const globalDashboard = getGlobalDashboard({ dashboards });
        const activeDashboardKey = getActiveDashboardKey({
            activeDashboardId,
            activeDashboardIds: get(getState(), `dashboards.activeDashboardId`, []),
        });

        if (globalDashboard) {
            dispatch(
                setActiveDashboard({
                    entityNumber: activeDashboardKey,
                    dashboardNumber: globalDashboard.id,
                })
            );
        }
    };

export const setActiveEntityDashboard =
    ({ dashboardType, activeDashboardId, entityNumber }) =>
    (dispatch, getState) => {
        const dashboards = get(getState(), `resources.dashboards.itemsById[${dashboardType}]`, []);
        const entityDashboards = dashboards.filter((d) => !isNil(d.entityNumber));
        const activeDashboardKey = getActiveDashboardKey({
            activeDashboardId,
            activeDashboardIds: get(getState(), `dashboards.activeDashboardId`, []),
        });

        if (entityDashboards.length) {
            const entityDashboard = entityDashboards.filter((d) => d.entityNumber === entityNumber)[0];

            if (entityDashboard) {
                dispatch(
                    setActiveDashboard({
                        entityNumber: activeDashboardKey,
                        dashboardNumber: entityDashboard.id,
                    })
                );

                return;
            }
        }

        // Create Entity Dashboard
        const globalDashboard = getGlobalDashboard({ dashboards });

        if (globalDashboard) {
            dispatch(
                setActiveDashboard({
                    entityNumber: activeDashboardKey,
                    dashboardNumber: globalDashboard.id,
                })
            );

            dispatch(
                cloneDashboard({
                    dashboard: {
                        ...globalDashboard,
                        entityNumber,
                    },
                })
            );
        }
    };

const updateActiveDashboardIds = ({ oldId, newId, activeDashboardIds, dispatch }) => {
    Object.keys(activeDashboardIds).forEach((key) => {
        if (activeDashboardIds[key] === oldId) {
            dispatch(
                setActiveDashboard({
                    entityNumber: key,
                    dashboardNumber: newId,
                })
            );
        }
    });
};

const getActiveDashboardKey = ({ activeDashboardId, activeDashboardIds }) => {
    return Object.keys(activeDashboardIds).filter((key) => activeDashboardIds[key] === activeDashboardId)[0];
};

const getSubmitParams = (dashboard) => {
    return {
        dashboardName: dashboard.name,
        dashboardType: dashboard.type,
        entityNumber: dashboard.entityNumber,
        isDefault: dashboard.isDefault,
        configuration: JSON.stringify(getSubmitConfiguration(dashboard)),
    };
};

const getGlobalDashboard = ({ dashboards }) => {
    const globalDashboards = dashboards.filter((d) => isNil(d.entityNumber));

    const userGlobalDashboard = globalDashboards.filter((d) => !d.isGlobal)[0];
    if (userGlobalDashboard) {
        return userGlobalDashboard;
    }

    const globalDashboard = globalDashboards.filter((d) => d.isGlobal)[0];
    if (globalDashboard) {
        return globalDashboard;
    }

    return null;
};
