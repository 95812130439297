import { decodeHtml } from "components/utils/string";
import { isNil, isString } from "lodash";
import React from "react";
import uuidv1 from "uuid/v1";

import FieldGroupCheckbox from "../FieldGroupCheckbox";

/**
 * Checkbox component.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} [props.id] - The ID of the checkbox.
 * @param {string} [props.className] - The CSS class name for the checkbox.
 * @param {string|ReactNode} [props.label] - The label for the checkbox.
 * @param {boolean|null} props.checked - The checked state of the checkbox.
 * @param {function} [props.onChange] - The event handler for the checkbox's change event.
 * @param {boolean} [props.labelIconBig] - Whether to display a big label icon.
 * @param {boolean} [props.checkedPartly] - Whether the checkbox is in a partially checked state.
 * @param {boolean} [props.iconLabelEmpty] - The icon to display when the checkbox is unchecked.
 * @param {boolean} [props.disabled] - Whether the checkbox is disabled.
 * @param {string} [props.title] - The title attribute for the checkbox.
 * @param {Object} [props.labelStyle] - The inline style for the checkbox label.
 * @returns {ReactNode} The rendered Checkbox component.
 */
const Checkbox = ({
    id,
    className,
    label,
    checked,
    onChange,
    labelIconBig,
    checkedPartly,
    iconLabelEmpty,
    disabled,
    title,
    labelStyle,
}) => {
    const checkboxId = id || uuidv1().toUpperCase();
    const checkboxLabel = !isNil(label) ? isString(label) ? decodeHtml(label) : label : <>&nbsp;</>;

    return (
        <FieldGroupCheckbox
            labelStyle={labelStyle}
            className={className}
            label={checkboxLabel}
            id={checkboxId}
            checked={checked === null ? true : checked}
            checkedPartly={checkedPartly}
            labelIcon={
                checked === null || (checked && checkedPartly)
                    ? "indeterminate-checkbox-filled"
                    : checked
                    ? "checkbox-checked-filled"
                    : "checkbox-unchecked-empty"
            }
            labelIconBig={labelIconBig}
            iconLabelEmpty={iconLabelEmpty}
            onChange={onChange}
            disabled={disabled}
            title={title}
        ></FieldGroupCheckbox>
    );
};

export default Checkbox;
