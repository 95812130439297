import { FilterVariablesItem, useStates } from "components/views/ApplicationProcessingWorkCenterV2/utils";
import { CheckboxesBlock } from "./CheckboxesBlock";

export const StatesBlock: React.FC<{
    item: FilterVariablesItem;
    readOnly?: boolean;
    error?: string;
    onChange: (item: FilterVariablesItem) => void;
    onToggle?: (values: string[]) => void;
}> = ({ item, readOnly = false, error, onChange, onToggle }) => {
    const [states = [], isLoading] = useStates();

    return (
        <CheckboxesBlock
            items={states.map((state) => ({
                label: state.state,
                isChecked: item.values.some((v) => v === state.abbreviation),
                id: state.abbreviation,
            }))}
            emptyStateMessage="No states available."
            readOnly={readOnly}
            error={error}
            isLoading={isLoading}
            onChange={(values) => onChange({ ...item, values })}
            onToggle={onToggle}
        />
    );
};
