import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterColumnPlaceholder } from "./FilterColumnPlaceholder";
import { FilterList } from "./FilterList";
import { FilterSelectDropdown } from "./FilterSelectDropdown";
import cn from "classnames";
import { usePrograms } from "store/resources/useResource";
import { FilterColumn, FilterErrors, ProgramItem, UtilityItem } from "../utils";
import { useCallback, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import SearchInput from "components/ui/Input/SearchInput";
import IconWrap from "components/ui/Icons";

export const ProgramFilter: React.FC<{
    filter: ProgramItem[];
    selectedProgram: ProgramItem | null;
    isActiveColumn: boolean;
    setActiveColumn: React.Dispatch<React.SetStateAction<FilterColumn | null>>;
    isDropdownOpen: boolean;
    setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onAddVariableToProgram: () => void;
    selectedUtility: UtilityItem | null;
    onAdd: (program: { label: string; value: string }) => void;
    onSelect: (index: number) => void;
    onClear: (index: number) => void;
    onRemove: (index: number) => void;
    readOnly?: boolean;
    errors?: FilterErrors;
}> = ({
    filter,
    selectedProgram,
    selectedUtility,
    isActiveColumn,
    isDropdownOpen,
    readOnly,
    errors,
    setIsDropdownOpen,
    onAddVariableToProgram,
    setActiveColumn,
    onAdd,
    onClear,
    onSelect,
    onRemove,
}) => {
    const [programs = [], isLoadingPrograms] = usePrograms({
        utilityNumber: selectedUtility?.utilityNumber,
    });
    const [listFilterValue, setListFilterValue] = useState<string>("");
    const [showListFilter, setShowListFilter] = useState(false);

    const dropdownItems = useMemo(
        () =>
            (programs ?? [])
                .map((p) => ({ label: p.program, value: p.programNumber }))
                .filter((v: any) => !filter.map((v) => v.programNumber).includes(v.value)),
        [programs, filter]
    );

    const filterItems = useMemo(
        () =>
            filter
                .map((item) => ({ label: item.program, value: item.programNumber, icon: ["fas", "grid-2-plus"] as IconProp }))
                .filter((item) => item.label.toLowerCase().includes(listFilterValue.toLowerCase())),
        [filter, listFilterValue]
    );

    const onSelectItem = useCallback(
        (item: { label: string; value: string }) => {
            const index = filter.findIndex((v) => v.programNumber === item.value);
            onSelect(index);
        },
        [onSelect, filter]
    );

    const selectedIndex = useMemo(() => filter.findIndex((v) => v === selectedProgram), [selectedProgram, filter]);

    return (
        <div
            role="region"
            aria-label="Program list"
            className={cn("filter-column program-filter  fill-height flex-column p-2 gap-2 transition-all group", { isDropdownOpen })}
        >
            <div
                className={cn("filter-header flex-row align-center justify-space-between bg-white p-1", {
                    isActiveColumn,
                    "not-empty": !isEmpty(filter),
                })}
            >
                {showListFilter ? (
                    <div className="flex-row align-center gap-2">
                        <SearchInput
                            ariaLabel={`Search selected programs`}
                            value={listFilterValue}
                            autoFocus
                            onChange={(event: any) => setListFilterValue(event.target.value)}
                            onSubmit={() => {
                                if (filterItems?.length === 1) {
                                    onSelect(filter.findIndex((item) => item.program === filterItems[0].label));
                                }
                            }}
                        />
                        <IconWrap
                            icon="clear-close"
                            onClick={() => {
                                setShowListFilter(false);
                                setListFilterValue("");
                            }}
                            title="Close"
                        />
                    </div>
                ) : (
                    <>
                        <h2 className={cn("column-title group-hover:text-lg fs-16", isActiveColumn)}>Program</h2>
                        <button className="rounded" title="Search Program" onClick={() => setShowListFilter(true)}>
                            <div className="p-1">
                                <FontAwesomeIcon
                                    icon={["far", "magnifying-glass"]}
                                    color={!isActiveColumn ? "gray" : "black"}
                                    fixedWidth
                                    size="sm"
                                />
                            </div>
                        </button>
                    </>
                )}
            </div>
            {!readOnly && (
                <FilterSelectDropdown
                    columnTitle="Program"
                    withSearch
                    onSelect={onAdd}
                    items={dropdownItems}
                    isLoadingItems={isLoadingPrograms}
                    isDropdownOpen={isDropdownOpen}
                    setIsDropdownOpen={setIsDropdownOpen}
                    setActiveColumn={setActiveColumn}
                    disabled={!selectedUtility}
                />
            )}
            {filter.length > 0 ? (
                <FilterList
                    items={filterItems}
                    selectedIndex={selectedIndex}
                    readOnly={readOnly}
                    addItemLabel="Add Variable"
                    onAddItem={onAddVariableToProgram}
                    onSelectItem={onSelectItem}
                    onClearItem={onClear}
                    onRemoveItem={onRemove}
                />
            ) : (
                <FilterColumnPlaceholder>
                    <div className="flex-column align-center px-4 gap-2">
                        <FontAwesomeIcon icon={["fas", "grid-2-plus"]} color="gray" fixedWidth size="2x" />
                        <h4>No Programs</h4>
                        <span className="fs-14">
                            Please add a Program <br /> to proceed
                        </span>
                    </div>
                </FilterColumnPlaceholder>
            )}
        </div>
    );
};
