import React, { useState, useMemo, ChangeEvent } from "react";
import { isEmpty } from "lodash";
import { mapGridRowToObject } from "components/utils/datagrid";
import { getColumnKeys } from "components/views/configureGrids";
import { openPlainModal } from "components/ui/Modal/utils";
import { useUserList, WorkcenterUser } from "../utils";
import WaitIcon from "components/ui/WaitIcon";
import { AssignmentActions, TaskOrApplicationRow } from "./types";
import { AssignmentModal } from "./AssignmentModal";
import { SelectedItemsBlock } from "./SelectedItemsBlock";

const ApplicationsFilterControls: React.FC<{
    selectedRows: TaskOrApplicationRow[];
    resetSelectedRows: () => void;
    dataGridId: string;
    instanceId: string;
}> = ({ selectedRows, resetSelectedRows, dataGridId, instanceId }) => {
    const selectedRowsAsObject = useMemo(() => {
        return selectedRows.map((selectedRow: TaskOrApplicationRow) => {
            const selectedRowObject = mapGridRowToObject(getColumnKeys(dataGridId), selectedRow) as TaskOrApplicationRow;
            selectedRowObject.assignedUserNumbersArray = selectedRowObject.assignedUserNumbers?.split(";").map((i) => i.trim());
            return selectedRowObject;
        });
    }, [dataGridId, selectedRows]);

    const openAssignmentDialog = (assignment: AssignmentActions) => {
        openPlainModal({
            className: "bulk-actions-modal-workcenter",
            content: (
                <ModalWrapper
                    instanceId={instanceId}
                    dataGridId={instanceId}
                    assignment={assignment}
                    resetSelectedRows={resetSelectedRows}
                    selectedRowsAsObject={selectedRowsAsObject}
                />
            ),
        });
    };

    if (!selectedRows.length) {
        return null;
    }

    return (
        <SelectedItemsBlock
            leftIcon={"user-plus"}
            rightIcon={"file-check"}
            leftText="Assign to"
            rightText="Clear assignment"
            leftAction={() => openAssignmentDialog(AssignmentActions.addAssignment)}
            rightAction={() => openAssignmentDialog(AssignmentActions.removeAssignment)}
            resetSelectedRows={resetSelectedRows}
            selectedRowsCount={selectedRows.length}
        />
    );
};

export default ApplicationsFilterControls;

const ModalWrapper: React.FC<{
    instanceId: string;
    dataGridId: string;
    selectedRowsAsObject: TaskOrApplicationRow[];
    resetSelectedRows: () => void;
    assignment: AssignmentActions;
}> = ({ instanceId, dataGridId, resetSelectedRows, selectedRowsAsObject, assignment }) => {
    const [users = [], isLoadingUsers] = useUserList();
    const [searchTerm, setSearchTerm] = useState("");

    const assignedUserList = useMemo(() => {
        return selectedRowsAsObject.flatMap((r: TaskOrApplicationRow) =>
            (r.assignedUserNumbers || "").split(";").map((i: string) => i.trim())
        );
    }, [selectedRowsAsObject]);
    const assignableUserList = useMemo(
        (): WorkcenterUser[] =>
            users
                // Only show user in the list if there is at least one row selected where the user isn't assigned yet
                .filter(
                    (activeUser) =>
                        !!selectedRowsAsObject.some(
                            (selectedRow: TaskOrApplicationRow) =>
                                !selectedRow.assignedUserNumbersArray?.some(
                                    (assignedUserNumber: string) => activeUser.userNumber === assignedUserNumber
                                )
                        )
                )
                .filter((user) => isEmpty(searchTerm?.trim()) || user.userName.toLowerCase().includes(searchTerm.toLowerCase())) ?? [],
        [users, selectedRowsAsObject, searchTerm]
    );
    const removeUserList = useMemo(() => {
        // Pick only users available in the users list
        const items = users.filter((u) => assignedUserList.some((aul) => u.userNumber === aul));
        return items;
    }, [users, assignedUserList]);
    const usersAccordingToAction = assignment === AssignmentActions.addAssignment ? assignableUserList : removeUserList;

    const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredUsers = useMemo(() => {
        const users = usersAccordingToAction;
        return users?.filter((user) => isEmpty(searchTerm?.trim()) || user.userName.toLowerCase().includes(searchTerm.toLowerCase())) ?? [];
    }, [usersAccordingToAction, searchTerm]);
    if (isLoadingUsers) {
        return (
            <div className="p-5 mx-auto">
                <WaitIcon />
            </div>
        );
    }

    return (
        <AssignmentModal
            dataGridId={dataGridId}
            instanceId={instanceId}
            selectedRowsAsObject={selectedRowsAsObject}
            assignment={assignment}
            searchTerm={searchTerm}
            addUserList={filteredUsers}
            resetSelectedRows={resetSelectedRows}
            onSearchTermChange={onSearchTermChange}
        />
    );
};
