import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownList from "components/ui/DropdownList";
import DropdownListItem from "components/ui/DropdownList/DropdownListItem";
import PopperTriggerWrapper from "components/ui/PopperTriggerWrapper";
import { useId, useRef, useState } from "react";

export const SplitButton: React.FC<{
    type?: "button" | "submit";
    name?: string;
    text: string;
    onClick?: () => void;
    listItems: SplitButtonItem[];
}> = ({ type = "button", name, text, listItems, onClick }) => {
    const [expanded, setExpanded] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const listId = useId();

    return (
        <div ref={containerRef} className="flex-row align-center relative rounded-6 shadow-md hover:shadow-none">
            <button
                type={type}
                name={name}
                className="rounded-start-6 border-0 px-2 py-1 fw-700 fs-15 lh-1.5 bg-secondary text-primary hover:bg-grey-200 focus:bg-grey-200 active:bg-grey-200 cursor-pointer"
                onClick={onClick}
                style={{ height: 32 }}
            >
                {text}
            </button>
            <PopperTriggerWrapper
                TriggerComponent={(props: { triggerRef: React.RefObject<HTMLButtonElement> }) => (
                    <button
                        title="Options"
                        ref={props.triggerRef}
                        className="rounded-end-6 border-0 bg-secondary text-primary hover:bg-grey-200 focus:bg-grey-200 active:bg-grey-200 cursor-pointer"
                        onClick={() => setExpanded((prev) => !prev)}
                        style={{ height: 32 }}
                        aria-haspopup="true"
                        aria-expanded={expanded}
                        aria-controls={listId}
                    >
                        <FontAwesomeIcon className="py-2 px-1" icon={["fas", "caret-down"]} fixedWidth size="lg" />
                    </button>
                )}
                PopupComponent={(props: {
                    popupRef: React.MutableRefObject<HTMLDivElement>;
                    triggerRef: React.RefObject<HTMLButtonElement>;
                }) => <PopupComponent id={listId} popupRef={props.popupRef} containerRef={containerRef} items={listItems} />}
                placement="bottom-end"
                enabled={expanded}
                closePopperCallback={() => setExpanded(false)}
            />
        </div>
    );
};

const PopupComponent: React.FC<{
    id: string;
    popupRef: React.MutableRefObject<HTMLDivElement>;
    containerRef: React.RefObject<HTMLDivElement>;
    items: SplitButtonItem[];
}> = ({ id, popupRef, containerRef, items }) => {
    const isInitialized = useRef(false);

    const onRefSet = (element: HTMLDivElement) => {
        if (element) {
            popupRef.current = element;

            if (!isInitialized.current) {
                if (containerRef.current && popupRef.current) {
                    isInitialized.current = true;
                    popupRef.current.style.width = containerRef.current.clientWidth + "px";
                }
            }
        }
    };

    return (
        <div id={id} role="menu" aria-label="Action items">
            <DropdownList elementRef={onRefSet} alone visible>
                {items.map((item) => (
                    <DropdownListItem
                        key={item.label}
                        renderItem={() => (
                            <button
                                type={item.type}
                                name={item.name}
                                onClick={item.onClick}
                                className="fill-width fill-height fs-16 fw-400 border border-0 bg-transparent text-start cursor-pointer"
                            >
                                {item.label}
                            </button>
                        )}
                    />
                ))}
            </DropdownList>
        </div>
    );
};

export type SplitButtonItem = {
    label: string;
    type?: "button" | "submit";
    name?: string;
    onClick?: () => void;
};
