import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import SideNav from "components/ui/SideNav";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import { useId, useRef, useState } from "react";
import { FilterListItem } from "../utils";
import { isNil } from "lodash";
import { useIsDesktop } from "components/utils/useIsDesktop";

export const Sidebar: React.FC<{
    show: boolean;
    isDashboardActive: boolean;
    filters: FilterListItem[];
    selectedFilterNumber?: string;
    className?: string;
    onDashboardOpen: () => void;
    onNewFilterOpen: () => void;
    onFilterSelect: (filterNumber: string) => void;
    onFilterShare: (filterNumber: string) => void;
    onFilterManage: (filterNumber: string) => void;
    onFilterFavorite: (filterNumber: string) => void;
    onBackdropClick: () => void;
}> = ({
    show,
    filters,
    isDashboardActive,
    selectedFilterNumber,
    onDashboardOpen,
    onNewFilterOpen,
    onFilterSelect,
    onFilterShare,
    onFilterManage,
    onFilterFavorite,
    onBackdropClick,
}) => {
    const isDesktop = useIsDesktop();

    const favoriteFilters = filters.filter((f) => f.isFavorite);
    const otherFilters = filters.filter((f) => !f.isFavorite);

    const filterListId = useId();
    const favoriteFiltersId = "fav-" + filterListId;
    const allFiltersId = "all-" + filterListId;

    return (
        <SideNav
            className={cn("workcenter-menu flex-column z-[90]", show ? "w-80 min-w-80" : "w-0 min-w-0")}
            mode={isDesktop ? "push" : "over"}
            backdrop={!isDesktop && show}
            onBackdropClick={onBackdropClick}
        >
            <SideNavContent>
                <SideNavBody noPadding rowLayout className="no-scroll fill-height">
                    <nav className="flex-column fill-width gap-2 py-2 no-scroll" aria-label="Filter list">
                        <div className="px-2">
                            <DashboardButton isActive={isDashboardActive} onClick={() => onDashboardOpen()} />
                        </div>
                        {favoriteFilters.length > 0 && (
                            <>
                                <div className="border-bottom mx-2" />
                                <FilterExpandable
                                    title="Favorites"
                                    targetId={favoriteFiltersId}
                                    searchButtonTitle="Search favorite filters"
                                    onSearch={() => alert("Not implemented")}
                                >
                                    <ul id={favoriteFiltersId} className="flex-column with-scroll" style={{ maxHeight: "30%" }}>
                                        {favoriteFilters.map((filter) => (
                                            <FilterItem
                                                key={filter.datasourceNumber}
                                                title={filter.datasourceName}
                                                isActive={filter.datasourceNumber === selectedFilterNumber}
                                                isFavorite
                                                canShare={isNil(filter.sharedBy)}
                                                onSelect={() => onFilterSelect(filter.datasourceNumber)}
                                                onShare={() => onFilterShare(filter.datasourceNumber)}
                                                onManage={() => onFilterManage(filter.datasourceNumber)}
                                                onFavorite={() => onFilterFavorite(filter.datasourceNumber)}
                                            />
                                        ))}
                                    </ul>
                                </FilterExpandable>
                            </>
                        )}
                        <div className="border-bottom mx-2" />
                        <FilterExpandable
                            title="All Filters"
                            targetId={allFiltersId}
                            searchButtonTitle="Search all filters"
                            onSearch={() => alert("Not implemented")}
                            onAdd={onNewFilterOpen}
                        >
                            <ul id={allFiltersId} className="flex-column flex-one-in-column with-scroll">
                                {otherFilters.map((filter) => (
                                    <FilterItem
                                        key={filter.datasourceNumber}
                                        title={filter.datasourceName}
                                        isActive={filter.datasourceNumber === selectedFilterNumber}
                                        canShare={isNil(filter.sharedBy)}
                                        onSelect={() => onFilterSelect(filter.datasourceNumber)}
                                        onShare={() => onFilterShare(filter.datasourceNumber)}
                                        onManage={() => onFilterManage(filter.datasourceNumber)}
                                        onFavorite={() => onFilterFavorite(filter.datasourceNumber)}
                                    />
                                ))}
                            </ul>
                        </FilterExpandable>
                    </nav>
                </SideNavBody>
            </SideNavContent>
        </SideNav>
    );
};

const FilterExpandable: React.FC<{
    title: string;
    targetId: string;
    children: React.ReactNode;
    searchButtonTitle: string;
    onSearch: () => void;
    onAdd?: () => void;
}> = ({ title, targetId, children, searchButtonTitle, onSearch, onAdd }) => {
    const [expanded, setExpanded] = useState(true);
    const filterItemRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();

    return (
        <>
            <div
                ref={filterItemRef}
                className="filter-item filter-expandable flex-row gap-2 align-center px-2 justify-space-between"
                onFocusCapture={(e) => {
                    filterItemRef.current?.classList.add("focused");
                    clearTimeout(timeoutRef.current);
                }}
                onBlurCapture={(e) => {
                    timeoutRef.current = setTimeout(() => {
                        filterItemRef.current?.classList.remove("focused");
                    }, 100);
                }}
            >
                <button
                    className="wc-btn rounded flex-row align-center px-2 gap-2"
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-controls={targetId}
                >
                    <div className="py-1">
                        <FontAwesomeIcon
                            className={cn("expand-icon", { rotated: expanded })}
                            icon={["far", "chevron-right"]}
                            fixedWidth
                            size="sm"
                        />
                    </div>
                    <span>{title}</span>
                </button>
                <div className="filter-actions">
                    <button className="wc-btn rounded p-1" onClick={onSearch} title={searchButtonTitle}>
                        <FontAwesomeIcon icon={["far", "magnifying-glass"]} fixedWidth size="sm" />
                    </button>
                    {onAdd && (
                        <button className="wc-btn rounded p-1" onClick={onAdd} title="Add new filter">
                            <FontAwesomeIcon icon={["far", "circle-plus"]} fixedWidth size="sm" />
                        </button>
                    )}
                </div>
            </div>
            {expanded && children}
        </>
    );
};

const FilterItem: React.FC<{
    title: string;
    isActive: boolean;
    isFavorite?: boolean;
    canShare: boolean;
    onSelect: () => void;
    onShare: () => void;
    onManage: () => void;
    onFavorite: () => void;
}> = ({ title, isActive, isFavorite, canShare, onSelect, onShare, onManage, onFavorite }) => {
    const filterItemRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();

    return (
        <li
            className={cn("filter-item px-2", {
                "filter-favorite": isFavorite,
            })}
        >
            <div
                ref={filterItemRef}
                className={cn("fill-width flex-row gap-2 align-center wc-btn rounded pe-1", {
                    active: isActive,
                })}
                onFocusCapture={(e) => {
                    filterItemRef.current?.classList.add("focused");
                    clearTimeout(timeoutRef.current);
                }}
                onBlurCapture={(e) => {
                    timeoutRef.current = setTimeout(() => {
                        filterItemRef.current?.classList.remove("focused");
                    }, 100);
                }}
            >
                <button
                    className={cn("wc-btn flex-row flex-one align-center truncate", {
                        active: isActive,
                    })}
                    onClick={onSelect}
                >
                    <div className="filter-name-icon p-2">
                        <FontAwesomeIcon icon={["fas", "square-small"]} fixedWidth />
                    </div>
                    <span className="truncate">{title}</span>
                </button>
                <div className="filter-actions">
                    {canShare && (
                        <button className="wc-btn rounded p-1" onClick={onShare} title="Share filter">
                            <FontAwesomeIcon icon={["far", "share-nodes"]} fixedWidth size="sm" />
                        </button>
                    )}
                    <button className="wc-btn rounded p-1" onClick={onManage} title="Manage filter">
                        <FontAwesomeIcon icon={["far", "sliders"]} fixedWidth size="sm" />
                    </button>
                    <button
                        className={cn("wc-btn rounded p-1", {
                            "!text-amber-400": isFavorite,
                        })}
                        onClick={onFavorite}
                        title="Favorite filter"
                        aria-pressed={isFavorite}
                    >
                        <FontAwesomeIcon icon={[isFavorite ? "fas" : "far", "star"]} fixedWidth size="sm" />
                    </button>
                </div>
            </div>
        </li>
    );
};

const DashboardButton: React.FC<{
    isActive: boolean;
    onClick: () => void;
}> = ({ isActive, onClick }) => {
    return (
        <button
            className={cn("wc-btn flex-row align-center fill-width rounded", {
                active: isActive,
            })}
            onClick={onClick}
        >
            <div className="p-2">
                <FontAwesomeIcon icon={[isActive ? "fas" : "far", "grid"]} fixedWidth />
            </div>
            <span>Dashboard</span>
        </button>
    );
};
