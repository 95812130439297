import React, { useState, useCallback, useRef, memo } from "react";
import { isEmpty } from "lodash";

import { onContractorLookup } from "../../../ui/Contractor/actions";
import { isEmailAddress } from "../../../utils/form";
import { useBackEndRequireContractor } from "../../ProgramView/utils";

import Button from "../../../ui/Button";
import ButtonGroup from "../../../ui/Button/ButtonGroup";
import JsonSchemaForm from "../../../ui/Form/JsonSchema/JsonSchemaForm";
import { ErrorMessage } from "../../../ui/Message";
import { Localize } from "components/utils/text";

import "./Contractor.scss";

const Contractor = memo(({ formRef, programNumber, contractor, onChange }) => {
    const sectionRef = useRef();
    const [showError, setShowError] = useState(false);

    const requireContractorOption = useBackEndRequireContractor({
        programNumber,
    });

    // * Require Contractor Contact
    // "r"  Read Only
    // "y"  Required                 - show only form with required fields
    // "n"  Not Required             - show only form with not required fields
    // "l"  Reqired From list        - can select from list and check if readonly form is filled
    // "ln" Not Required From list   - can select from list without check if readonly form is filled
    // "h" Not Required, Hidden      - do not show contractor section

    const showContractorSearch = ["l", "ln"].includes(requireContractorOption);
    const isContractorRequired = ["y", "l"].includes(requireContractorOption);
    const showForm =
        ["y", "n", "r"].includes(requireContractorOption) ||
        (showContractorSearch && Object.keys(contractor || {}).some((key) => !isEmpty(contractor[key])));
    const isFormReadOnly = ["r"].includes(requireContractorOption) || showContractorSearch;

    const required = isContractorRequired ? ["company"] : [];

    const schema = {
        type: "object",
        required,
        properties: {
            company: {
                type: ["string", "null"],
                title: "Company",
            },
            firstName: {
                type: ["string", "null"],
                title: "First Name",
            },
            lastName: {
                type: ["string", "null"],
                title: "Last Name",
            },
            address: {
                type: ["string", "null"],
                title: "Address",
            },
            city: {
                type: ["string", "null"],
                title: "City",
            },
            state: {
                type: ["string", "null"],
                title: Localize.STATE,
            },
            zip: {
                type: ["string", "null"],
                title: "Postal Code",
            },
            phone: {
                type: ["string", "null"],
                title: "Phone",
            },
            email: {
                type: ["string", "null"],
                title: "Email",
            },
            technologies: {
                type: ["string", "null"],
                title: "Technologies",
            },
            services: {
                type: ["string", "null"],
                title: "Services",
            },
        },
    };

    const uiSchema = {
        classNames: "inline-form form-columns-4",
        technologies: {
            "ui:widget": "textarea",
        },
        services: {
            "ui:widget": "textarea",
        },
    };

    const handleContractorSelected = useCallback(
        (data) => {
            setShowError(false);

            onChange({
                ...data,
            });
        },
        [onChange]
    );

    const handleSearch = useCallback(() => {
        onContractorLookup({
            programNumber,
            onSelect: handleContractorSelected,
        });
    }, [programNumber, handleContractorSelected]);

    const handleChange = useCallback(
        ({ formData }) => {
            onChange({
                ...formData,
            });
        },
        [onChange]
    );

    const handleClear = useCallback(() => {
        onChange({});
    }, [onChange]);

    const validate = useCallback((formData, errors) => {
        if (formData.email && !isEmailAddress(formData.email)) {
            errors.email.addError("Email address must be valid email address");
        }

        return errors;
    }, []);

    const onError = (form) => {
        if (showContractorSearch) {
            setShowError(true);
            sectionRef.current && sectionRef.current.scrollIntoView();
        }
    };

    return (
        <div ref={sectionRef} className="new-app-contractor">
            {showContractorSearch && (
                <ButtonGroup transparent>
                    <Button icon="search" onClick={handleSearch}>
                        {isContractorRequired ? <span className="required">Search for a Contractor</span> : <>Search for a Contractor</>}
                    </Button>
                </ButtonGroup>
            )}
            {showError && <ErrorMessage>Contractor is Required</ErrorMessage>}
            <JsonSchemaForm
                hidden={!showForm}
                formRef={formRef}
                initialValues={contractor}
                schema={schema}
                uiSchema={uiSchema}
                onChange={handleChange}
                onError={onError}
                withReset
                noCancel
                noSubmit
                noReset={isFormReadOnly}
                resetText={"Clear"}
                onReset={handleClear}
                validate={validate}
                readOnly={isFormReadOnly}
            />
        </div>
    );
});

export default Contractor;
