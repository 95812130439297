import React from "react";
import { getAccessibilityErrors, getAccessibilityNotices, getAccessibilityWarnings } from "components/ui/PortalBuilder/utils/validation";
import cn from "classnames";
import StatusMark from "components/ui/StatusMark";
import ActionLabel from "components/ui/Label/ActionLabel";
import { PageAccessibilityInfo } from "./index";
import IconWrap from "components/ui/Icons";
import WaitIcon from "components/ui/WaitIcon";

export const PagesListItem = ({ title, messages, isValidating, validationDisabled, onClick, onValidate }: PageAccessibilityInfo) => {
    const errors = getAccessibilityErrors(messages);
    const warnings = getAccessibilityWarnings(messages);
    const notices = getAccessibilityNotices(messages);
    const validationActionTitle = validationDisabled ? "Validation in progress..." : "Validate Page";

    return (
        <div className={cn("list-item-row pages-list-item flex-row align-center")}>
            <ActionLabel className="text-label" onClick={onClick} title="Open Page Details">
                {title}
            </ActionLabel>
            <span className="flex-one" />
            {isValidating ? (
                <WaitIcon />
            ) : (
                <div
                    className="pages-list-item__counts flex-row align-center"
                    title={`${errors.length} errors\n${warnings.length} warnings\n${notices.length} notices`}
                >
                    {errors.length > 0 && <StatusMark statusMarkError>{errors.length}</StatusMark>}
                    {warnings.length > 0 && <StatusMark statusMarkWarning>{warnings.length}</StatusMark>}
                    {notices.length > 0 && <StatusMark statusMarkNeutral>{notices.length}</StatusMark>}
                    <IconWrap
                        icon="update-refresh"
                        title={validationActionTitle}
                        disabled={validationDisabled}
                        onClick={() => onValidate?.()}
                    />
                </div>
            )}
        </div>
    );
};
