export const FilterGroups = {
    Applications: "Applications",
    Tasks: "Tasks",
} as const;

export const ApplicationFilters = {
    Any: "Any",
    AssignedTo: "Assigned to",
    CreatedBy: "Created by",
    CountsByStatus: "Counts by Status",
    ReadyForStatusChange: "Ready for Status Change",
    Unassigned: "Unassigned",
    WithNotes: "With Notes",
    WithTags: "With Tags",
} as const;

export const TaskFilters = {
    Any: "Any",
    OnMyAssignedApps: "On my assigned apps",
    OnMyGroupTasks: "On my group tasks",
    OwnedBy: "Owned by",
    WithNoOwner: "With no owner",
} as const;

export type ApplicationFilter = typeof ApplicationFilters[keyof typeof ApplicationFilters];
export type TaskFilter = typeof TaskFilters[keyof typeof TaskFilters];
export type FilterGroup = typeof FilterGroups[keyof typeof FilterGroups];
export type FilterType = ApplicationFilter | TaskFilter;

export type FilterExploreOptionalParams = {
    filterGroup?: FilterGroup;
    filterType?: FilterType;
    showResults?: boolean;
};

export enum AssignmentActions {
    assignOwner = "ASSIGNOWNER",
    removeOwner = "REMOVEOWNER",
    addAssignment = "ADDASSIGNMENT",
    removeAssignment = "REMOVEASSIGNMENT",
}

export interface TaskOrApplicationRow {
    assignedUserNumbersArray: string[];
    assignedUserNumbers: string;
    appId: string;
    taskNumber: string;
}
