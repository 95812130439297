import React, { ChangeEvent, useState } from "react";
import { HistoryItem } from "../utils";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import IconWrap from "components/ui/Icons";
import SearchInput from "components/ui/Input/SearchInput";
import { HistoryListItem } from "./HistoryListItem";

export const HistorySidePanel: React.FC<{
    historyItems?: HistoryItem[];
    onClose?: () => void;
}> = ({ historyItems, onClose }) => {
    const [searchString, setSearchString] = useState("");

    return (
        <SideNavContent>
            <SideNavBody className="flex-one-in-column p-0">
                <div>
                    <div className="flex flex-column align-center p-3">
                        <div className="flex justify-space-between align-center fill-width">
                            <h2 className="fs-20 fw-500">History</h2> <IconWrap icon="clear-close" title="Close" onClick={onClose} />
                        </div>
                        <div className="d-flex flex-column fill-width">
                            <div className="dropdown-search flex-row pb-2 pt-4 align-center">
                                <div className="fill-width">
                                    <SearchInput
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchString(event.target.value)}
                                        autoFocus
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {historyItems
                        ?.filter((h) => h.filterName.toLowerCase().includes(searchString.toLowerCase()))
                        .map((historyItem, index) => {
                            return <HistoryListItem key={index} historyItem={historyItem} />;
                        })}
                </div>
            </SideNavBody>
        </SideNavContent>
    );
};
