import React, { memo, useRef, useState, useEffect, useCallback } from "react";
import cn from "classnames";

import { sideNavSize } from "./SideNavRoot";
import SideNavBackdrop from "./SideNavBackdrop";
import { SideNavContext } from "./SideNavContext";

import "./style.scss";

/**
 * @param {object} props
 * @param {string} props.id - The id of the side nav.
 * @param {string} [props.className] - The class name of the side nav.
 * @param {"left" | "right"} [props.position] - The position of the side nav.
 * @param {"push" | "over"} [props.mode] - The mode of the side nav.
 * @param {"small" | "medium"} [props.size] - The size of the side nav.
 * @param {boolean} [props.backdrop] - Whether to show the backdrop.
 * @param {boolean} [props.disableClose] - Whether to disable the close button.
 * @param {React.ReactNode} props.children - The children of the side nav.
 * @param {() => void} [props.onBackdropClick] - The callback function to be called when the backdrop is clicked.
 */
const SideNav = memo(
    ({ id, className, position, mode, size = sideNavSize.small, backdrop, disableClose, children, onBackdropClick }: SideNavProps) => {
        const sideNavClassName = cn("sidenav", `sidenav--${position}`, `sidenav--${mode}`, `sidenav--${size}`, className);
        const onCloseCallback = useRef<() => void | null>(null);

        const registerOnClose = useCallback((onCloseCb: () => void) => {
            (onCloseCallback as React.MutableRefObject<() => void | null>).current = onCloseCb;
        }, []);

        const [contextState] = useState({
            registerOnClose,
        });

        useEffect(() => {
            const callback = onCloseCallback.current;

            return () => {
                if (callback) {
                    callback();
                }
            };
        }, []);

        return (
            <SideNavContext.Provider value={contextState}>
                <SideNavBackdrop id={id} show={backdrop} disableClose={disableClose} onClick={onBackdropClick} />
                <div id={`${id}-sidenav`} className={sideNavClassName}>
                    {children}
                </div>
            </SideNavContext.Provider>
        );
    }
);

export interface SideNavProps {
    id?: string;
    className?: string;
    position?: string;
    mode?: string;
    size?: string;
    backdrop?: boolean;
    disableClose?: boolean;
    children?: React.ReactNode;
    onBackdropClick?: () => void;
}

export default SideNav;
