import React, { useState, useRef, useEffect } from "react";
import { isNil } from "lodash";
import { submitResource, listToAnyOf, submitByRef, referenceToAnyOf } from "../../utils/form";
import { getResourceName, getUserGroupList } from "./utils";

import JsonSchemaForm from "../../ui/Form/JsonSchema/JsonSchemaFormV2";
import IconWrap from "../../ui/Icons";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import { WarningMessage } from "../../ui/Message";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import Button from "components/ui/Button";
import { useIsMobile } from "components/utils/useIsMobile";
import { useReference } from "components/ui/Reference/useReference";
import { referenceTypes } from "components/ui/Reference/referenceTypes";
import { getReferenceValueByName } from "components/ui/Reference/utils";
import WaitIcon from "components/ui/WaitIcon";
import { STATUS_ACTIVE } from "components/utils/constants";

const BulkEditForm = (props) => {
    const formRef = useRef();

    const { utilityNumber, userName, selectedGroups, onClose, gridRefresh, sidePanel } = props;

    const title = "Edit Selected User Groups";

    const firstGroup = selectedGroups.length > 0 ? selectedGroups[0] : {};

    const isMobile = useIsMobile();

    const [isSubmitting, setSubmitting] = useState(false);
    const [userGroupTypes = [], isLoadingGroupTypes] = useReference(referenceTypes.groupType);
    const [userGroupStatus = [], isLoadingGroupStatus] = useReference(referenceTypes.genericStatus);
    const isLoading = isLoadingGroupTypes || isLoadingGroupStatus;

    const [showSelectedGroups, setShowSelectedGroups] = useState(false);
    const iconArrow = showSelectedGroups ? "shevron-in-circle-up-filled--before" : "shevron-in-circle-down-drop-down-empty";

    const toggleSelectedGroups = () => {
        setShowSelectedGroups(!showSelectedGroups);
    };

    const groupName = selectedGroups.every((g) => g.groupName === firstGroup.groupName) ? firstGroup.groupName : undefined;

    const groupType = selectedGroups.every((g) => g.groupType === firstGroup.groupType)
        ? Number(
              getReferenceValueByName({
                  reference: userGroupTypes,
                  displayName: firstGroup.groupType,
              })
          )
        : undefined;

    const status = selectedGroups.every((g) => g.status === firstGroup.status)
        ? Number(
              getReferenceValueByName({
                  reference: userGroupStatus,
                  displayName: firstGroup.status,
              })
          )
        : undefined;

    const parentGroupNumber = selectedGroups.every((g) => g.parentGroupNumber === firstGroup.parentGroupNumber)
        ? firstGroup.parentGroupNumber
        : undefined;

    const resource = {
        groupName,
        groupType,
        status,
        ...(isNil(parentGroupNumber) ? {} : { parentGroupNumber }),
    };

    const userGroupList = getUserGroupList({ utilityNumber, userName })?.filter(
        (g) => !selectedGroups?.map((g) => g.groupNumber).includes(g.groupNumber)
    );

    const schema = {
        type: "object",
        properties: {
            groupName: {
                type: "string",
                title: "Group Name",
            },
            groupType: {
                type: "integer",
                title: "Group Type",
                anyOf: referenceToAnyOf({
                    list: userGroupTypes,
                }),
            },
            status: {
                type: "integer",
                title: "Status",
                anyOf: referenceToAnyOf({
                    list: userGroupStatus,
                }),
                default: Number(
                    getReferenceValueByName({
                        reference: userGroupStatus,
                        displayName: STATUS_ACTIVE,
                    })
                ),
            },
            parentGroupNumber: {
                type: "string",
                title: "Parent Group",
                anyOf: listToAnyOf({
                    list: [].concat(
                        [
                            {
                                groupName: " -- NO PARENT GROUP --",
                                groupNumber: "0",
                            },
                        ],
                        userGroupList
                    ),
                    map: (item) => ({
                        title: item.groupName,
                        enum: [item.groupNumber],
                    }),
                }),
            },
        },
    };

    const uiSchema = {
        classNames: isMobile ? undefined : "inline-form form-columns-4",
        groupName: {
            "ui:disabled": true,
        },
        groupType: {
            "ui:placeholder": "Select Group Type to Override",
        },
        status: {
            "ui:placeholder": "Select Status to Override",
        },
        parentGroupNumber: {
            "ui:placeholder": "Select Parent Group to Override",
            "ui:disabled": userGroupList.length === 0,
        },
    };

    const initialValues = resource;

    const submitText = isSubmitting ? "Saving..." : "Save";

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: getResourceName({ utilityNumber }),
            path: isNil(utilityNumber)
                ? undefined
                : {
                      utilityNumber,
                  },
        };

        setSubmitting(true);

        const promises = selectedGroups.map((group) => {
            return new Promise((resolve) => {
                const resourceId = group.groupNumber;

                const body = {
                    groupName: group.groupName,
                    groupDescription: group.groupDescription,
                    groupType: isNil(formData.groupType)
                        ? Number(
                              getReferenceValueByName({
                                  reference: userGroupTypes,
                                  displayName: group.groupType,
                              })
                          )
                        : formData.groupType,
                    status: isNil(formData.status)
                        ? Number(
                              getReferenceValueByName({
                                  reference: userGroupStatus,
                                  displayName: group.status,
                              })
                          )
                        : formData.status,
                    parentGroupNumber: isNil(formData.parentGroupNumber)
                        ? group.parentGroupNumber
                        : formData.parentGroupNumber === "0"
                        ? null
                        : formData.parentGroupNumber,
                };

                submitResource({
                    resourceParams,
                    resourceId,
                    body,
                    onComplete: resolve,
                });
            });
        });

        Promise.all(promises).then((result) => {
            gridRefresh();
            sidePanel.close();
        });
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent className="system-user-groups-panel__form">
            <SideNavHeader title={title} leadBlockIcon={"edit-empty"} smallHeader onClose={onClose} />
            <SideNavBody noPadding>
                <div className="bulk-edit-form fill-height flex-column">
                    {selectedGroups.length === 0 ? (
                        <WarningMessage>Select User Groups to Edit</WarningMessage>
                    ) : (
                        <>
                            <div className="json-form-title">
                                Override Values for <b>{selectedGroups.length}</b> Selected User Groups
                                <IconWithLabel icon={iconArrow} iconWithLabelRight onClick={toggleSelectedGroups}>
                                    {showSelectedGroups ? "Hide Selected Groups" : "Show Selected Groups"}
                                </IconWithLabel>
                            </div>
                            <JsonSchemaForm
                                formRef={formRef}
                                schema={schema}
                                uiSchema={uiSchema}
                                initialValues={initialValues}
                                disabled={isSubmitting}
                                onSubmit={onSubmit}
                                noSubmit
                                noCancel
                                noReset
                            />
                            {showSelectedGroups && <SelectedUserGroups userGroups={selectedGroups} />}
                        </>
                    )}
                </div>
            </SideNavBody>
            <SideNavFooter justifyCenter>
                <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};

const SelectedUserGroups = ({ userGroups = [] }) => {
    return (
        <div className="selected-user-groups flex-column no-scroll">
            <div className="selected-user-groups--expandable-block flex-column no-scroll">
                <div className="system-user-groups--column-names flex-row">
                    <div className="system-user-groups__column-name flex-one">Group Name</div>
                    <div className="system-user-groups__column-name flex-one">Group Type</div>
                    <div className="system-user-groups__column-name flex-one">Status</div>
                    <div className="system-user-groups__column-name flex-one">Parent Group Name</div>
                </div>
                <div className="system-user-groups--selected flex-column fill-width with-scroll">
                    {userGroups.map((group) => (
                        <div key={group.groupNumber} className="flex-row align-center">
                            <div className="system-user-groups__item flex-one flex-row align-center">
                                <IconWrap icon="group-people-empty" />
                                <span className="system-user-groups__item-label">Group Name</span>
                                {group.groupName}
                            </div>
                            <div className="system-user-groups__item flex-one">
                                <span className="system-user-groups__item-label">Group Type</span>
                                {group.groupType}
                            </div>
                            <div className="system-user-groups__item flex-one">
                                <span className="system-user-groups__item-label">Status</span>
                                {group.status}
                            </div>
                            <div className="system-user-groups__item flex-one">
                                <span className="system-user-groups__item-label">Parent Group Name</span>
                                {group.parentGroupName ? group.parentGroupName : "-"}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BulkEditForm;
