import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as loginActions from "store/login/actions";
import { openConfirmModal } from "components/ui/Modal/utils";
import { isAuthenticated } from "components/utils/user";

const MODAL_SHOW_TIMEOUT = 90; // seconds
const MODAL_MESSAGE_TIMEOUT = 60; // seconds

function useSessionTimeout() {
    const dispatch = useDispatch();
    const sessionTimeoutRef = useRef<NodeJS.Timeout>();
    const user = useSelector((state: any) => state.user);
    const isUserAuthenticated: boolean = isAuthenticated(user);
    const refreshToken = useSelector((state: any) => state.user?.refreshToken);

    useEffect(() => {
        const delay = user?.userSessionTimeout * 60000 - MODAL_SHOW_TIMEOUT * 1000;

        clearTimeout(sessionTimeoutRef.current);

        if (!isUserAuthenticated || !user?.userSessionTimeout || !refreshToken) {
            return;
        }

        sessionTimeoutRef.current = setTimeout(() => {
            openConfirmModal({
                onConfirm: () => dispatch(loginActions.refresh()),
                onClose: () => dispatch(loginActions.logout()),
                title: "User session",
                message: <SessionTimeoutMessage onExpire={() => dispatch(loginActions.logout())} />,
                showCloseButton: false,
            });
        }, delay);

        return () => {
            clearTimeout(sessionTimeoutRef.current);
        };
    }, [dispatch, isUserAuthenticated, user?.userSessionTimeout, refreshToken]);
}

const SessionTimeoutMessage: React.FC<{ onExpire(): void }> = ({ onExpire }) => {
    const [counter, setCounter] = useState(MODAL_MESSAGE_TIMEOUT);

    useEffect(() => {
        let startTime = Date.now();
        const targetTime = startTime + MODAL_MESSAGE_TIMEOUT * 1000;
        let cancelationToken = 0;

        const updateCounter = () => {
            const currentTime = Date.now();
            const remainingTime = Math.round((targetTime - currentTime) / 1000);

            if (remainingTime >= 0) {
                setCounter(remainingTime);
                cancelationToken = requestAnimationFrame(updateCounter);
            } else {
                cancelAnimationFrame(cancelationToken);
                onExpire();
            }
        };

        cancelationToken = requestAnimationFrame(updateCounter);

        return () => {
            cancelAnimationFrame(cancelationToken);
        };
    }, [onExpire]);

    return (
        <div className="text-center">
            <p>The session is about to expire in {counter} seconds.</p>
            <br />
            <p>Continue current session?</p>
        </div>
    );
};

export default useSessionTimeout;
