import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VariablePath } from "./VariablePath";
import PopperTriggerWrapper from "components/ui/PopperTriggerWrapper";
import { useState } from "react";
import DropdownList from "components/ui/DropdownList";
import DropdownListItem from "components/ui/DropdownList/DropdownListItem";
import { IconButton } from "../../IconButton";
import { Switch } from "../../Switch";
import cn from "classnames";
import { isNil } from "lodash";

export const VariableHeader: React.FC<{
    title: string;
    readOnly?: boolean;
    enabled?: boolean;
    onItemsClear?: () => void;
    onVariableDelete?: () => void;
    onVariableToggle?: (enabled: boolean) => void;
    onVariableEdit?: () => void;
}> = ({ title, readOnly, enabled, onItemsClear, onVariableDelete, onVariableToggle, onVariableEdit }) => {
    const [expanded, setExpanded] = useState(false);

    const onClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        onItemsClear?.();
        setExpanded(false);
    };

    const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        onVariableDelete?.();
        setExpanded(false);
    };

    const editable = !readOnly && isNil(onVariableToggle);

    return (
        <div className={cn("flex-row gap-2 no-shrink align-center relative", isNil(onVariableToggle) ? "px-3" : "px-1")}>
            <VariablePath
                className="text-primary"
                icon={["fas", "square"]}
                size="lg"
                showTopLine={false}
                bottomLineStyle={{ position: "absolute", top: 26, height: 10 }}
            />
            <h2 className="fs-16 fw-500 text-dark">{title}</h2>
            {readOnly && (
                <div className="flex-row fs-14 fw-400 gap-1 align-center me-auto border rounded px-2 py-1 bg-grey-200 text-secondary">
                    <FontAwesomeIcon icon={["far", "pen-slash"]} size="xs" fixedWidth />
                    View only
                </div>
            )}
            {onVariableEdit && <IconButton icon={["far", "sliders"]} title="Edit variable" onClick={onVariableEdit} />}
            {editable && (
                <div className="flex-row fs-14 fw-400 gap-1 align-center me-auto border rounded px-2 py-1 bg-primary-light border-primary-light text-primary">
                    <FontAwesomeIcon icon={["far", "pen"]} size="xs" fixedWidth />
                    Editing
                </div>
            )}
            {onVariableToggle && (
                <div className="flex-row align-center gap-2 ms-auto text-secondary">
                    <FontAwesomeIcon icon={["far", "eye"]} fixedWidth />
                    <Switch title="Toggle variable visibility" checked={enabled} onChange={onVariableToggle} />
                </div>
            )}
            {editable && (
                <div className="relative">
                    <PopperTriggerWrapper
                        TriggerComponent={(props: { triggerRef: React.RefObject<HTMLButtonElement> }) => (
                            <button
                                title="Options"
                                ref={props.triggerRef}
                                aria-label="Options"
                                className="border rounded hover:bg-grey-200 focus:bg-grey-200 active:bg-grey-200"
                                onClick={() => setExpanded((prev) => !prev)}
                            >
                                <FontAwesomeIcon className="py-2 px-1" icon={["fal", "ellipsis"]} fixedWidth size="lg" />
                            </button>
                        )}
                        PopupComponent={(props: { popupRef: React.RefObject<HTMLDivElement> }) => (
                            <PopupComponent popupRef={props.popupRef} onClear={onClear} onDelete={onDelete} />
                        )}
                        placement="bottom-end"
                        enabled={expanded}
                        closePopperCallback={() => setExpanded(false)}
                    />
                </div>
            )}
        </div>
    );
};

const PopupComponent: React.FC<{
    popupRef: React.RefObject<HTMLDivElement>;
    onClear: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ popupRef, onClear, onDelete }) => {
    return (
        <DropdownList className="dropdown-menu" elementRef={popupRef} alone visible style={{ width: 160, minWidth: 160 }}>
            <DropdownListItem
                renderItem={() => (
                    <button onClick={onClear} className="flex-row align-center justify-start gap-2 fill-width fill-height fs-16 fw-400">
                        <FontAwesomeIcon icon={["fal", "broom-wide"]} fixedWidth size="sm" />
                        <span>Clear All</span>
                    </button>
                )}
            />
            <DropdownListItem
                renderItem={() => (
                    <button
                        onClick={onDelete}
                        style={{ color: "red" }}
                        className="flex-row align-center justify-start gap-2 fill-width fill-height fs-16 fw-400"
                    >
                        <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth size="sm" />
                        <span>Delete</span>
                    </button>
                )}
            />
        </DropdownList>
    );
};
