import { useId, useMemo, useState } from "react";
import { isEmpty, isNil } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import SearchInput from "components/ui/Input/SearchInput";
import { ConfigurableCheckboxList, ReadOnlyCheckboxList } from "./CheckboxesBlock";
import { IconButton } from "../../IconButton";
import { ErrorMessage } from "./ErrorMessage";

export const ValuesBlock: React.FC<{
    items: string[];
    error?: string;
    readOnly: boolean;
    onChange: (values: string[]) => void;
    onToggle?: (values: string[]) => void;
}> = ({ items, readOnly, error, onChange, onToggle }) => {
    const [expanded, setExpanded] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const listId = useId();

    const [inputError, setInputError] = useState<string | undefined>();
    const currentError = inputError ?? error;

    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredItems = useMemo(() => {
        if (isEmpty(searchTerm)) {
            return items;
        }

        return items.filter((item) => item.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [items, searchTerm]);

    const onAdd = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const inputField = e.currentTarget.querySelector<HTMLInputElement>("[name='attribute-value']");

        const value = inputField?.value;
        setInputError(undefined);

        // check if value empty
        if (isEmpty((value ?? "").trim())) {
            setInputError("Value cannot be empty");
            inputField?.focus();
            return;
        }

        // check if value already exists
        if (items.includes(value!)) {
            setInputError("Value already exists");
            inputField?.focus();
            return;
        }

        if (inputField && value) {
            onChange([...items, value]);
            inputField.value = "";
            inputField.focus();
        }
    };

    const onRemove = (value: string) => {
        onChange(items.filter((i) => i !== value));
    };

    return (
        <div
            className={cn("p-2 border rounded-2 bg-white", {
                "border-danger": !isEmpty(error),
            })}
        >
            <div className="flex-column fill-width gap-2">
                <div className="flex-row align-center justify-space-between">
                    <button
                        className="wc-btn rounded flex-row align-center px-2 gap-2 text-secondary"
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-controls={listId}
                    >
                        <div className="py-1">
                            <FontAwesomeIcon
                                className={cn("expand-icon", { rotated: expanded })}
                                icon={["far", "chevron-right"]}
                                fixedWidth
                                size="sm"
                            />
                        </div>
                        <span>
                            {items.length} {items.length === 1 ? "Item" : "Items"}
                        </span>
                    </button>
                    <SearchInput className="w-40" value={searchTerm} onChange={onSearchTermChange} expandable={!isNil(onToggle)} />
                </div>
                {expanded && (
                    <>
                        {currentError && <ErrorMessage error={currentError} />}
                        {readOnly && (
                            <ReadOnlyCheckboxList
                                listId={listId}
                                items={filteredItems.map((item) => ({ label: item, id: item, isChecked: true }))}
                            />
                        )}
                        {onToggle && (
                            <ConfigurableCheckboxList
                                listId={listId}
                                items={filteredItems.map((item) => ({
                                    label: item,
                                    isChecked: true,
                                    id: item,
                                }))}
                                onChange={onToggle}
                            />
                        )}
                        {!onToggle && !readOnly && (
                            <>
                                <form className="flex-row align-center gap-2 px-2" onSubmit={onAdd}>
                                    <input
                                        className={cn("p-2", {
                                            "border-danger": !isEmpty(error),
                                        })}
                                        name="attribute-value"
                                        maxLength={100}
                                        placeholder="Enter value"
                                        aria-label="Enter value"
                                        style={{ maxWidth: 320 }}
                                    />
                                    <button
                                        type="submit"
                                        className="rounded px-2 py-1 fw-600 fs-14 lh-1.5 bg-secondary text-primary hover:bg-grey-200 focus:bg-grey-200 active:bg-grey-200"
                                    >
                                        Add
                                    </button>
                                </form>
                                {filteredItems.length > 0 && (
                                    <div id={listId} className="ps-2 py-1 relative" style={{ left: -1 }}>
                                        <ul className="flex-column fill-width gap-2 with-scroll" style={{ maxHeight: 200 }}>
                                            {filteredItems.map((item) => (
                                                <li key={item} className="flex-row align-center gap-1">
                                                    <IconButton
                                                        className="border-0 text-secondary"
                                                        icon={["far", "xmark"]}
                                                        onClick={() => onRemove(item)}
                                                        title="Remove value"
                                                    />
                                                    <span className="border border-grey-400 rounded px-2 py-1 bg-theme-white text-dark fs-14 fw-400">
                                                        {item}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
