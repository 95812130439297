import { IconName } from "@fortawesome/fontawesome-svg-core";
import { AssignmentActions } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Separator from "components/ui/Separator";

export const SelectedItemsBlock: React.FC<{
    leftIcon: IconName;
    rightIcon: IconName;
    leftText: string;
    rightText: string;
    leftAction: (action?: AssignmentActions) => void;
    rightAction: (action?: AssignmentActions) => void;
    resetSelectedRows: () => void;
    selectedRowsCount: number;
}> = ({ selectedRowsCount, resetSelectedRows, leftText, rightText, leftAction, rightAction, leftIcon, rightIcon }) => {
    return (
        <div className="flex items-center bg-theme-white h-12 rounded-lg gap-2">
            <div className="flex-row align-center text-truncate ps-1">
                <div className="selected-decorator" />
                <div
                    role="button"
                    tabIndex={0}
                    aria-label={`Unselect ${selectedRowsCount} rows`}
                    className="flex-row align-center ps-1 text-truncate cursor-pointer"
                    onClick={() => resetSelectedRows()}
                >
                    <FontAwesomeIcon className="item-icon" icon={["fas", "xmark"]} fixedWidth size="sm" />
                    <span className="item-label text-truncate px-2 fw-600 fs-14">{selectedRowsCount} Selected</span>
                </div>
            </div>
            <Separator />
            <div className="flex-row align-center text-truncate ps-1 text-primary">
                <div className="selected-decorator" />
                <div
                    role="button"
                    tabIndex={0}
                    className="flex-row align-center ps-1 text-truncate cursor-pointer"
                    aria-haspopup="dialog"
                    onClick={() => leftAction()}
                >
                    <FontAwesomeIcon className="item-icon" icon={["far", leftIcon]} fixedWidth size="sm" />
                    <span className="item-label text-truncate px-2 fw-600 fs-14">{leftText}</span>
                </div>
            </div>
            <Separator />

            <div className="flex-row align-center text-truncate ps-1 text-primary">
                <div className="selected-decorator" />
                <div
                    role="button"
                    tabIndex={0}
                    className="flex-row align-center ps-1 text-truncate cursor-pointer"
                    aria-haspopup="dialog"
                    onClick={() => rightAction(AssignmentActions.assignOwner)}
                >
                    <FontAwesomeIcon className="item-icon" icon={["far", rightIcon]} fixedWidth size="sm" />
                    <span className="item-label text-truncate px-2 fw-600 fs-14">{rightText}</span>
                </div>
            </div>
        </div>
    );
};
