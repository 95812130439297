import { FilterVariable, FilterVariablesItem } from "components/views/ApplicationProcessingWorkCenterV2/utils";
import { useProgramWorkflowStatuses } from "store/resources/useResource";
import { VariablePath } from "../VariablePath";
import { CheckboxesBlock } from "../CheckboxesBlock";
import { ConditionBlock } from "../ConditionBlock";
import cn from "classnames";
import { isNil } from "lodash";

export const VariableBlockItemProjectStatuses: React.FC<{
    variable: FilterVariable;
    error?: string;
    item: FilterVariablesItem;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
    onEdit: () => void;
    onDelete: () => void;
    onToggle?: () => void;
    onToggleValues?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onEdit, onDelete, onToggle, onToggleValues }) => {
    const [projectStatuses = [], isLoading] = useProgramWorkflowStatuses({ programNumber: variable.programNumber });

    return (
        <div
            className={cn(
                "flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 focus-within:bg-grey-200 bg-opacity-50 gap-2",
                isNil(onToggle) ? "px-3" : "px-1"
            )}
            data-testid={`variable-block-item-${variable.items.indexOf(item)}`}
        >
            <VariablePath icon={["far", "wave-pulse"]} />
            <div className={cn("flex-column fill-width bg-opacity-100", isNil(onToggle) ? "pb-3" : "pb-1")}>
                <ConditionBlock item={item} readOnly={readOnly} onEdit={onEdit} onDelete={onDelete} onToggle={onToggle} />
                <CheckboxesBlock
                    items={projectStatuses.map((status) => ({
                        label: status.status,
                        isChecked: item.values.includes(status.workflowNumber),
                        id: status.workflowNumber,
                    }))}
                    emptyStateMessage="No statuses available."
                    error={error}
                    readOnly={readOnly}
                    isLoading={isLoading}
                    onChange={(values) => onChange({ ...item, values })}
                    onToggle={onToggleValues}
                />
            </div>
        </div>
    );
};
