import cn from "classnames";
import { store } from "../../../store/configureStore";
import { modalClose, modalOpen } from "../../../store/modal/actions";

export const closeModal = () => store.dispatch(modalClose());

export const openRevertChangesModal = ({ message, onConfirm }) => {
    openConfirmModal({
        title: "Revert Changes",
        modalIcon: "undo",
        message,
        onConfirm,
    });
};

/**
 * Opens a confirm modal with the specified title, icon, message, and callbacks.
 * @param {Object} options - The options for the confirm modal.
 * @param {string} options.title - The title of the confirm modal.
 * @param {string} [options.modalIcon] - The icon to display in the confirm modal.
 * @param {string | React.ReactNode} options.message - The message to display in the confirm modal.
 * @param {Function} options.onConfirm - The callback to execute when the confirm button is clicked.
 * @param {Function} [options.onClose] - The callback to execute when the modal is closed.
 * @param {Function} [options.onAfterOpen] - The callback to execute after the modal is opened.
 * @param {Boolean} [options.showCloseButton] - The options for toggle close modal button.
 */
export const openConfirmModal = ({ title, modalIcon, message, onConfirm, onClose, onAfterOpen, showCloseButton }) => {
    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title,
                modalIcon,
                overlayClassName: "modal-styled",
                className: "modal-sm",
                footerContentCenter: true,
                content: message,
                onConfirm,
                onClose,
                onAfterOpen,
                showCloseButton,
            },
        })
    );
};

/**
 *
 * @param {object} params
 * @param {React.ReactNode} params.content - The content to display in the plain modal.
 * @param {string} [params.className] - The class name of the plain modal.
 */
export const openPlainModal = ({ content, className }) => {
    store.dispatch(
        modalOpen({
            modalType: "PLAIN_DIALOG",
            modalProps: {
                overlayClassName: "modal-styled",
                className: cn("modal-sm", className),
                content,
            },
        })
    );
};

export const openHelpModal = ({ content, title, buttonText }) => {
    store.dispatch(
        modalOpen({
            modalType: "SIMPLE_DIALOG",
            modalProps: {
                title,
                text: content,
                buttonText,
                className: "help-modal",
                noOverlayIcon: true,
                overlayClassName: "modal-styled",
                textAlignLeft: true,
            },
        })
    );
};

/**
 * Open a simple modal with a title and text
 * @param {object} params
 * @param {string} [params.title] - Modal title
 * @param {string} params.text - Modal content
 * @param {string} [params.modalIcon] - Modal icon shown in the header
 * @param {string} [params.buttonText] - Custom button text
 * @param {boolean} [params.noOverlayIcon] - Whether to show the overlay icon
 */
export const openInfoModal = ({ text, title, modalIcon, buttonText, noOverlayIcon }) => {
    store.dispatch(
        modalOpen({
            modalType: "SIMPLE_DIALOG",
            modalProps: {
                title,
                text,
                modalIcon,
                buttonText,
                noOverlayIcon: noOverlayIcon ?? false,
                overlayClassName: "modal-styled",
                className: "modal-sm",
            },
        })
    );
};

export const openFormModal = ({ title, modalIcon, form }) => {
    store.dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title,
                modalIcon,
                overlayClassName: "modal-styled",
                className: "modal-sm",
                children: form,
                noFooter: true,
            },
        })
    );
};

/**
 * Opens a waiting modal.
 *
 * @param {Object} options - The options for the waiting modal.
 * @param {string} options.title - The title of the waiting modal.
 * @param {string} [options.modalIcon] - The icon of the waiting modal.
 * @param {string} [options.className] - The class name of the waiting modal.
 */
export const openWaitingModal = ({ title, modalIcon, className }) => {
    store.dispatch(
        modalOpen({
            modalType: "WAITING_MODAL",
            modalProps: {
                title,
                modalIcon,
                className,
            },
        })
    );
};

export const openErrorModal = ({ text }) => {
    store.dispatch(
        modalOpen({
            modalType: "SIMPLE_DIALOG",
            modalProps: {
                text,
                errorModal: true,
            },
        })
    );
};
