import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HistoryItem } from "../utils";

export const HistoryListItem = ({ historyItem }: { historyItem: HistoryItem }) => {
    return (
        <div className="flex justify-space-between border-bottom align-center p-3">
            <div className="flex align-center justify-center">
                <FontAwesomeIcon className="text-secondary" icon={["fal", "clock-rotate-left"]} />
                <div className="flex flex-column ps-2 gap-1">
                    <h4 className="fs-14 fw-400">{historyItem.filterName}</h4>
                    <span className="text-secondary">{historyItem.searchType}</span>
                </div>
            </div>
            <div className="text-secondary">{historyItem.dateString}</div>
        </div>
    );
};
