import React from "react";
import RawHtml from "components/ui/RawHtml";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openHelpModal } from "components/ui/Modal/utils";
import "./HelpModalIconWidget.scss";

const HelpModalIconWidget = (props) => {
    const openModal = (event, props) => {
        event.preventDefault();

        openHelpModal({
            title: props.helpModalTitle,
            content: <RawHtml>{props.helpModalContent}</RawHtml>,
        });
    };

    if (!props.helpModalIcon || !props.helpModalContent) {
        return;
    }

    return (
        <span className="help__modal">
            <button
                title="Click to view help information"
                className="bg-transparent border border-0"
                onClick={(e) => openModal(e, { ...props })}
            >
                <FontAwesomeIcon icon={["far", props.helpModalIcon]} fixedWidth size="sm" />
            </button>
        </span>
    );
};

export default HelpModalIconWidget;
