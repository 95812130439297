import React, { useState, useMemo, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { mapGridRowToObject } from "components/utils/datagrid";
import { getColumnKeys } from "components/views/configureGrids";
import { updateResource } from "store/resources/actions";
import { getData } from "store/dataGrid/actions";
import { openPlainModal } from "components/ui/Modal/utils";
import { useUserList, WorkcenterUser } from "../utils";
import { AssignmentActions, TaskOrApplicationRow } from "./types";
import { AssignmentModal } from "./AssignmentModal";
import { SelectedItemsBlock } from "./SelectedItemsBlock";
import WaitIcon from "components/ui/WaitIcon";

const TasksFilterControls: React.FC<{
    selectedRows: any;
    resetSelectedRows: () => void;
    dataGridId: string;
    instanceId: string;
}> = ({ selectedRows, resetSelectedRows, dataGridId, instanceId }) => {
    const dispatch = useDispatch();

    const selectedRowsAsObject = useMemo(() => {
        return selectedRows.map((selectedRow: TaskOrApplicationRow) => {
            const selectedRowObject = mapGridRowToObject(getColumnKeys(dataGridId), selectedRow) as TaskOrApplicationRow;
            selectedRowObject.assignedUserNumbersArray = selectedRowObject.assignedUserNumbers?.split(";").map((i) => i.trim());
            return selectedRowObject;
        });
    }, [dataGridId, selectedRows]);

    const onRemoveAssignment = () => {
        dispatch(
            // @ts-ignore
            updateResource({
                resourceName: "workcenterItemUsers",
                body: {
                    tgtUserNumbers: [],
                    action: AssignmentActions.removeOwner,
                    items: selectedRowsAsObject.map((r: TaskOrApplicationRow) => r.taskNumber),
                },
                onSuccess: () => {
                    resetSelectedRows();
                    dispatch(
                        // @ts-ignore
                        getData({
                            dataGridId: instanceId,
                        })
                    );
                },
            })
        );
    };

    const openAssignmentDialog = (assignment: AssignmentActions) => {
        openPlainModal({
            className: "bulk-actions-modal-workcenter",
            content: (
                <ModalWrapper
                    instanceId={instanceId}
                    dataGridId={dataGridId}
                    assignment={assignment}
                    resetSelectedRows={resetSelectedRows}
                    selectedRowsAsObject={selectedRowsAsObject}
                />
            ),
        });
    };

    if (!selectedRows.length) {
        return null;
    }

    return (
        <SelectedItemsBlock
            leftIcon={"user-xmark"}
            rightIcon={"user-check"}
            leftText="Remove Owner"
            rightText="Assign Owner"
            leftAction={onRemoveAssignment}
            rightAction={() => openAssignmentDialog(AssignmentActions.assignOwner)}
            resetSelectedRows={resetSelectedRows}
            selectedRowsCount={selectedRows.length}
        />
    );
};

export default TasksFilterControls;

const ModalWrapper: React.FC<{
    instanceId: string;
    dataGridId: string;
    selectedRowsAsObject: TaskOrApplicationRow[];
    resetSelectedRows: () => void;
    assignment: AssignmentActions;
}> = ({ instanceId, dataGridId, resetSelectedRows, selectedRowsAsObject, assignment }) => {
    const [users = [], isLoadingUsers] = useUserList();

    const [searchTerm, setSearchTerm] = useState("");
    const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const assignableUserList = useMemo(
        (): WorkcenterUser[] =>
            users
                // Only show user in the list if there is at least one row selected where the user isn't assigned yet
                .filter(
                    (activeUser) =>
                        !!selectedRowsAsObject.some(
                            (selectedRow: TaskOrApplicationRow) =>
                                !selectedRow.assignedUserNumbersArray?.some(
                                    (assignedUserNumber: string) => activeUser.userNumber === assignedUserNumber
                                )
                        )
                )
                .filter((user) => isEmpty(searchTerm?.trim()) || user.userName.toLowerCase().includes(searchTerm.toLowerCase())) ?? [],
        [users, selectedRowsAsObject, searchTerm]
    );

    if (isLoadingUsers) {
        return (
            <div className="p-5 mx-auto">
                <WaitIcon />
            </div>
        );
    }

    return (
        <AssignmentModal
            dataGridId={dataGridId}
            instanceId={instanceId}
            selectedRowsAsObject={selectedRowsAsObject}
            assignment={assignment}
            searchTerm={searchTerm}
            addUserList={assignableUserList}
            resetSelectedRows={resetSelectedRows}
            onSearchTermChange={onSearchTermChange}
        />
    );
};
