import * as actionType from "../actionTypes";
import { isEqual, isNil, cloneDeep } from "lodash";
import {
    copyTreeParams,
    areFiltersEqual,
    filterObjToString,
    getPageSize,
    getTotalRecords,
    getSortType,
    getSelectedRows,
    SortType,
    searchAttrToFilter,
} from "../../components/utils/datagrid";
import { getRelatedGrids, getColumnKeys, programsApplicationsGridColumnKeys } from "../../components/views/configureGrids";
import { clientGridGetDataActions } from "./getDataActions";
import { getBrowserTimezoneOffset } from "components/utils/date";
import { batch } from "react-redux";
import { getParentsInTreeList } from "components/utils/tree";
import { isAdjustTimeForTimezoneEnabled } from "../../components/utils/settings";
import { setApplicationStatus } from "store/programs/actions";

export const init =
    ({ name, config, pageSize }) =>
    async (dispatch, getState) => {
        const existingConfig = getState().dataGrid[name];

        batch(() => {
            if (!existingConfig) {
                const dataGrid = {
                    sort: [],

                    // Default paging and filtering options for client grids. Applicable for client grid only.
                    isServerSideFiltering: false,
                    serverSidePaging: false,

                    ...config,
                    isConstructing: false,
                    isReadingColumns: false,
                    isUpdatingColumns: false,
                    isReading: false,
                    isError: false,
                    isFilterChanged: false,
                    isSettingsOpen: {},
                    // true if data is requested at least once. Set requested if client grid has source rows set up.
                    isDataRequested: !isNil(config.sourceRows),

                    message: null,
                    managedColumns: [],
                    filter: {
                        filters: [],
                    },
                    rows: [],
                    paging: {
                        skip: 0,
                        take: pageSize ?? getPageSize(config),
                        total: getTotalRecords(config),
                        limited: !isNil(pageSize),
                    },
                };

                dispatch({
                    type: actionType.DATA_GRID_INIT,
                    name,
                    dataGrid,
                });

                // Load initial data in grid
                if ((config.sourceRows?.length > 0 || dataGrid.getDataAction) && !dataGrid.noInitialFetch) {
                    dispatch(getData({ dataGridId: name }));
                }

                // Set limited pageSize if existing config does not have it set up.
            } else if (!isNil(pageSize) && !existingConfig.paging?.limited) {
                const dataGrid = {
                    ...existingConfig,
                    paging: {
                        ...existingConfig.paging,
                        take: pageSize,
                        limited: true,
                    },
                };

                dispatch({
                    type: actionType.DATA_GRID_INIT,
                    name,
                    dataGrid,
                });
            }

            dispatch({
                type: actionType.DATA_GRID_MOUNT,
                name,
            });
        });
    };

export const destroy =
    ({ name }) =>
    async (dispatch, getState) => {
        const dataGrid = getState().dataGrid[name];

        if (dataGrid) {
            dispatch({
                type: actionType.DATA_GRID_DESTROY,
                name,
            });
        }
    };

/**
 * Unmounts a data grid.
 * @param {Object} options - The options for unmounting the data grid.
 * @param {string} options.dataGridId - The ID of the data grid to unmount.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @param {Function} getState - The getState function from Redux.
 */
export const unmount =
    ({ dataGridId }) =>
    (dispatch, getState) => {
        const dataGrid = getState().dataGrid[dataGridId];

        if (dataGrid) {
            dispatch({
                type: actionType.DATA_GRID_UNMOUNT,
                name: dataGridId,
            });
        }
    };
/**
 *
 * @param {Object} options - The options for constructing a data grid.
 * @param {Object} [options.initialFilter] - The initial filter to apply to the data grid.
 * @param {Object} [options.filterRequired] - Whether the data grid requires a filter.
 * @param {string} options.dataGridId - The ID of the data grid to construct.
 * @param {string} [options.dataGridInstanceId] - The instance ID of the data grid to construct. If not provided, a unique ID will be generated.
 * @param {string} [options.returnType] - The return type of the data grid. Default is "JSON".
 * @param {Object} [options.filter] - The filter to apply to the data grid.
 * @param {Object} [options.sort] - The sort to apply to the data grid.
 * @param {number} [options.page] - The page to apply to the data grid.
 * @param {boolean} [options.data] - Whether to fetch data from the server.
 * @param {boolean} [options.onSuccess] - The callback to call when the data grid is constructed.
 * @param {boolean} [options.onError] - The callback to call when an error occurs while constructing the data grid.
 * @param {boolean} [options.showSuccessNotification] - Whether to show a success notification when the data grid is constructed.
 * @param {boolean} [options.showErrorNotification] - Whether to show an error notification when an error occurs while constructing the data grid.
 * @param {boolean} [options.successMessage] - The success message to show when the data grid is constructed.
 * @param {boolean} [options.errorMessage] - The error message to show when an error occurs while constructing the data grid.
 * @returns
 */
export const construct =
    ({
        initialFilter,
        filterRequired,
        dataGridId,
        dataGridInstanceId = null,
        returnType = "JSON",
        filter = null,
        sort = null,
        page = null,
        data = false,
        onSuccess,
        onError,
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
    }) =>
    async (dispatch, getState) => {
        let name = dataGridInstanceId;
        if (!name) {
            name = dataGridId;
        }

        const url = `${process.env.REACT_APP_SYSTEM_API_BASE_URL}grid/${dataGridId}/Construct`;
        const grid = getState().dataGrid[name];

        if (!grid) {
            dispatch(
                init({
                    name,
                    config: {
                        dataGridId,
                        ...(initialFilter ? { filter } : { defaultFilter: filter }),
                    },
                })
            );
        }

        if (!grid || isEqual(grid, {}) || grid.isConstructing) {
            dispatch({
                type: actionType.DATA_GRID_CONSTRUCT,
                url,
                query: { returnType },
                actionTypes: {
                    pending: actionType.DATA_GRID_CONSTRUCT_REQUEST,
                    response: actionType.DATA_GRID_CONSTRUCT_SUCCESS,
                    error: actionType.DATA_GRID_CONSTRUCT_ERROR,
                },
                passThroughData: {
                    dataGridId: name,
                    returnType,
                    filter,
                    sort,
                    page,
                    data,
                    onSuccess,
                    onError,
                    showSuccessNotification,
                    showErrorNotification,
                    successMessage,
                    errorMessage,
                    filterRequired,
                    initialFilter,
                },
            });
        } else {
            if (!areFiltersEqual(filter, grid.filter)) {
                dispatch(
                    setFilter({
                        dataGridId: name,
                        filter,
                        setAsDefault: !initialFilter,
                    })
                );
            }

            if (sort) {
                dispatch(setSort({ dataGridId: name, sort }));
            }

            if (page) {
                dispatch(setPage({ dataGridId: name, page }));
            } else {
                if (grid.paging.skip > 0) {
                    dispatch(
                        setPage({
                            dataGridId: name,
                            page: {
                                ...grid.paging,
                                skip: 0,
                            },
                        })
                    );
                }
            }

            if (data && (!filterRequired || filter)) {
                dispatch(getData({ dataGridId: name, onSuccess, onError }));
            }
        }
    };

export const getColumns =
    ({ dataGridId }) =>
    async (dispatch, getState) => {
        const dataGrid = getState().dataGrid[dataGridId];
        const url = `${process.env.REACT_APP_SYSTEM_API_BASE_URL}grid/${dataGridId}/Columns`;

        if (dataGrid) {
            dispatch({
                type: actionType.API_GET_AUTHORIZED,
                url,
                actionTypes: {
                    pending: actionType.DATA_GRID_GET_COLUMNS_REQUEST,
                    response: actionType.DATA_GRID_GET_COLUMNS_SUCCESS,
                    error: actionType.DATA_GRID_GET_COLUMNS_ERROR,
                },
                passThroughData: { dataGridId },
            });
        }
    };

export const setColumns =
    ({ dataGridId, columns }) =>
    async (dispatch, getState) => {
        const dataGrid = getState().dataGrid[dataGridId];

        if (dataGrid) {
            columns.forEach((element, index) => {
                element.order = index;
            });

            updateColumnsRequest({
                dataGridId: dataGrid.dataGridId,
                dataGridInstanceId: dataGridId,
                columns,
                dispatch,
            });

            // Update related Grid columns

            const currentGridColumnKeys = getColumnKeys(dataGrid.dataGridId);

            getRelatedGrids(dataGrid.dataGridId).forEach((grid, index) => {
                const relatedGridInstanceId = "related-grid" + index;

                dispatch(
                    construct({
                        dataGridId: grid.id,
                        dataGridInstanceId: relatedGridInstanceId,
                        onSuccess: () => {
                            const currentGridConfig = getState().dataGrid[dataGridId];
                            const relatedGridConfig = getState().dataGrid[relatedGridInstanceId];

                            if (relatedGridConfig) {
                                const currentGridColumns = currentGridConfig.columns;
                                const relatedGridColumns = relatedGridConfig.columns;
                                const relatedGridColumnKeys = getColumnKeys(grid.id);

                                const relatdeGridColumns = currentGridColumns.map((currentGridColumn) => {
                                    const columnKey = Object.keys(currentGridColumnKeys).filter(
                                        (k) => currentGridColumnKeys[k] === currentGridColumn.key
                                    )[0];
                                    const key = relatedGridColumnKeys[columnKey];
                                    const relatedGridColumn = relatedGridColumns.filter((c) => c.key === key)[0];

                                    if (!relatedGridColumn) {
                                        return null;
                                    }

                                    return {
                                        key: relatedGridColumn.key,
                                        name: relatedGridColumn.name,
                                        order: currentGridColumn.order,
                                        active: currentGridColumn.active,
                                    };
                                });

                                // Ensure all columns has proper data and update
                                if (relatdeGridColumns.every((c) => !isNil(c))) {
                                    updateColumnsRequest({
                                        dataGridId: grid.id,
                                        dataGridInstanceId: grid.id,
                                        columns: relatdeGridColumns,
                                        dispatch,
                                    });
                                }
                            }

                            dispatch(
                                destroy({
                                    name: relatedGridInstanceId,
                                })
                            );
                        },
                    })
                );
            });
        }
    };

export const setSourceRows =
    ({ dataGridId, rows = [] }) =>
    async (dispatch, getState) => {
        const dataGrid = getState().dataGrid[dataGridId];

        if (dataGrid && dataGrid.rows) {
            const oldRows = dataGrid.rows || [];

            copyTreeParams({
                newRows: rows,
                oldRows,
            });

            dispatch({
                type: actionType.DATA_GRID_SET_SOURCE_ROWS,
                name: dataGridId,
                rows,
            });
        }
    };

export const addSourceRows =
    ({ dataGridId, rows = [], position = "bottom" }) =>
    (dispatch, getState) => {
        const dataGrid = getState().dataGrid[dataGridId];

        if (dataGrid) {
            const oldRows = dataGrid?.rows || [];
            const newRows = position === "bottom" ? [...oldRows, ...rows] : [...rows, ...oldRows];

            dispatch({
                type: actionType.DATA_GRID_SET_SOURCE_ROWS,
                name: dataGridId,
                rows: newRows,
            });
        }
    };

export const resetRows =
    ({ dataGridId }) =>
    (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        const rows = grid.rows.map((row) => ({
            ...row,
            _selected: false,
        }));
        dispatch({
            type: actionType.DATA_GRID_SELECT_ROWS,
            name: dataGridId,
            rows,
        });
    };

export const selectRow =
    ({ dataGridId, index, isSelected }) =>
    (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid?.rows?.length > 0) {
            let rows = grid.rows.slice();
            // If Select All is clicked
            if (isNil(index)) {
                const selectedRowCount = getSelectedRows(dataGridId).length;
                const nextState = selectedRowCount < rows.length;

                rows = rows.map((row) => ({
                    ...row,
                    _selected: nextState,
                }));
            } else {
                if (index < rows.length) {
                    rows[index] = {
                        ...rows[index],
                        _selected: isSelected,
                    };
                }
            }

            dispatch({
                type: actionType.DATA_GRID_SELECT_ROWS,
                name: dataGridId,
                rows,
            });
        }
    };

export const selectRows =
    ({ dataGridId, selectedRows = [] }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid && grid.rows && grid.rows.length > 0) {
            let rows = [];

            if (selectedRows.length > 0) {
                const excludedKeys = ["totRecords", "MoreRecords", "_selected"];
                const compareKeys = Object.keys(selectedRows[0]).filter((key) => !excludedKeys.includes(key));

                const isSelectedRow = (row) => {
                    return selectedRows.reduce((isSelected, selectedRow) => {
                        if (!isSelected) {
                            isSelected =
                                compareKeys
                                    .map((key) => {
                                        return selectedRow[key] === row[key];
                                    })
                                    .filter((r) => r === false).length === 0;
                        }

                        return isSelected;
                    }, false);
                };

                rows = grid.rows.map((r) => {
                    return {
                        ...r,
                        _selected: isSelectedRow(r),
                    };
                });
            } else {
                rows = grid.rows.map((r) => {
                    return {
                        ...r,
                        _selected: false,
                    };
                });
            }

            dispatch({
                type: actionType.DATA_GRID_SELECT_ROWS,
                name: dataGridId,
                rows,
            });
        }
    };

/**
 * Gets datagrid data.
 *
 * @param {object} params
 * @param {string} params.dataGridId
 * @param {boolean} [params.filterRequired]
 * @param {function} [params.onSuccess]
 * @param {function} [params.onError]
 * @param {function} [params.onComplete]
 * @param {boolean} [params.exportCSV]
 * @param {string} [params.fileName]
 * @param {string} [params.fileNamePostfix]
 * @param {number} [params.exportRowsTotal]
 * @param {import("./types").DataGridRow[]} [params.sourceRows]
 * @param {boolean} [params.showSuccessNotification]
 * @param {boolean} [params.showErrorNotification]
 * @param {string} [params.successMessage]
 * @param {string} [params.errorMessage]
 * @param {Array<string>} [params.columnsKeys]
 * @returns
 */
export const getData =
    ({
        filterRequired,
        dataGridId,
        onSuccess,
        onError,
        onComplete,
        exportCSV = false,
        fileName,
        fileNamePostfix,
        exportRowsTotal,
        sourceRows,
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        columnsKeys = undefined,
    }) =>
    (dispatch, getState) => {
        const dataGrid = getState().dataGrid[dataGridId];

        if (!dataGrid) {
            return;
        }

        if (sourceRows) {
            dispatch(
                setSourceRows({
                    dataGridId,
                    rows: sourceRows,
                })
            );
        }

        if (dataGrid.getDataAction) {
            return clientGridGetDataActions[dataGrid.getDataAction.type]({
                ...dataGrid.getDataAction.props,
                exportCSV,
                fileName,
                fileNamePostfix,
                onComplete,
            });
        }

        const url = `${process.env.REACT_APP_SYSTEM_API_BASE_URL}grid/${dataGrid.dataGridId}`;
        let searchAttr = "";

        // Filter
        if (dataGrid.filter) {
            searchAttr = filterObjToString(dataGrid.filter, {
                transform: [
                    { search: "|", replace: "_" },
                    { search: "=", replace: "_" },
                ],
            });

            // Add additional filter if user tries to open applications grid filtered by status.
            if (dataGridId.endsWith("-applications")) {
                const programNumber = dataGridId.replace("-applications", "");
                const applicationStatus = getState().programs.applicationStatus[programNumber];
                if (applicationStatus) {
                    searchAttr = `${searchAttr}|${programsApplicationsGridColumnKeys.status}=${applicationStatus}`;
                    dispatch(setApplicationStatus({ programNumber, applicationStatus: undefined }));
                    dispatch(setFilter({ dataGridId, filter: searchAttrToFilter(searchAttr) }));
                }
            }
        }

        // Paging
        let { skip, take } = dataGrid.paging;

        // If filter changed, take first page.
        if (dataGrid.isFilterChanged && skip > 0) {
            skip = 0;

            dispatch(
                setPage({
                    dataGridId,
                    page: {
                        skip,
                        take,
                    },
                })
            );
        }

        const pageNum = exportCSV ? 1 : skip / take + 1;
        const recsPerPage = exportCSV ? exportRowsTotal : take;

        // Sorting
        let sortBy = "";
        let sortAsc = "";

        if (dataGrid.sort && dataGrid.sort.length > 0) {
            sortBy = dataGrid.sort[0].field;
            sortAsc = dataGrid.sort[0].dir === "asc" ? "1" : "0";
        }

        // Time offset
        //debugger
        const timeOffset = getBrowserTimezoneOffset();

        if (!dataGrid.filter?.filters?.length && filterRequired) {
            dispatch({
                type: actionType.DATA_GRID_GET_DATA_SUCCESS,
                data: {
                    grid: {
                        rows: [],
                        needFilter: true,
                    },
                },
                passThroughData: {
                    dataGridId,
                },
            });
        } else if (dataGrid && pageNum > 0 && recsPerPage > 0) {
            const adjustTime = isAdjustTimeForTimezoneEnabled();

            dispatch({
                type: actionType.DATA_GRID_GET_DATA,
                url,
                query: {
                    searchAttr,
                    pageNum,
                    recsPerPage,
                    sortBy,
                    sortAsc,
                    timeOffset: adjustTime ? timeOffset : undefined,
                },
                actionTypes: {
                    pending: actionType.DATA_GRID_GET_DATA_REQUEST,
                    response: exportCSV ? actionType.DATA_GRID_GET_DATA_EXPORT : actionType.DATA_GRID_GET_DATA_SUCCESS,
                    error: actionType.DATA_GRID_GET_DATA_ERROR,
                },
                passThroughData: {
                    dataGridId,
                    searchAttr,
                    pageNum,
                    recsPerPage,
                    sortBy,
                    sortAsc,
                    onSuccess,
                    onError,
                    showSuccessNotification,
                    showErrorNotification,
                    successMessage,
                    errorMessage,
                    fileName,
                    fileNamePostfix,
                    columnsKeys,
                },
            });
        }
    };

export const clearData =
    ({ dataGridId }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid && grid.rows && grid.rows.length > 0) {
            dispatch({
                type: actionType.DATA_GRID_CLEAR_DATA,
                name: dataGridId,
            });
        }
    };

export const setFilter =
    ({ dataGridId, filter, setAsDefault = false }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionType.DATA_GRID_FILTER,
            name: dataGridId,
            setAsDefault,
            filter,
        });
    };

export const setSort =
    ({ dataGridId, sort }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid) {
            const { columns = [] } = grid;

            sort.forEach((s) => {
                columns.filter((c) => c.name === s.field).forEach((c) => (c.sort = s.dir));
            });

            const sortType = getSortType(grid);

            dispatch({
                type: actionType.DATA_GRID_SORT,
                name: dataGridId,
                columns,
                sort,
                sortType,
            });
        }
    };

export const setPage =
    ({ dataGridId, page }) =>
    async (dispatch, getState) => {
        const { skip, take } = page;

        dispatch({
            type: actionType.DATA_GRID_SET_PAGE,
            name: dataGridId,
            skip,
            take,
        });
    };

/**
 * Filters the data grid based on the provided parameters.
 * @param {Object} options - The options for filtering.
 * @param {string} options.dataGridId - The ID of the data grid.
 * @param {boolean} [options.filterRequired] - Indicates if filtering is required.
 * @returns {Function} - The async action function.
 */
export const filter =
    ({ dataGridId, filterRequired }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid.getDataAction) {
            if (!grid.isServerSideFiltering) {
                dispatch({
                    type: actionType.DATA_GRID_CLIENT_FILTER,
                    name: dataGridId,
                });
            }

            if (grid.serverSidePaging || grid.isServerSideFiltering) {
                dispatch(
                    getData({
                        dataGridId,
                    })
                );
            }
        } else {
            dispatch(
                getData({
                    dataGridId,
                    filterRequired,
                })
            );
        }
    };

export const sort =
    ({ dataGridId, sort }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid) {
            dispatch(setSort({ dataGridId, sort }));

            const sortType = getSortType(grid);

            switch (sortType) {
                case SortType.SERVER:
                    dispatch(getData({ dataGridId }));
                    break;
                case SortType.CLIENT:
                    dispatch({
                        type: actionType.DATA_GRID_CLIENT_SORT,
                        name: dataGridId,
                    });
                    break;
                default:
                    break;
            }
        }
    };

export const paginate =
    ({ dataGridId, page, onSuccess, onError }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        dispatch(setPage({ dataGridId, page }));

        if (grid.getDataAction && !grid.serverSidePaging) {
            dispatch({
                type: actionType.DATA_GRID_CLIENT_PAGINATE,
                name: dataGridId,
            });
        } else {
            dispatch(
                getData({
                    dataGridId,
                    onSuccess,
                    onError,
                })
            );
        }
    };

export const setDataActionProps =
    ({ dataGridId, getDataActionProps }) =>
    (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid && grid.getDataAction && !isEqual(grid.getDataAction.props, getDataActionProps)) {
            dispatch({
                type: actionType.DATA_GRID_SET_DATA_ACTION_PROPS,
                name: dataGridId,
                getDataActionProps,
            });
        }
    };

export const onExpandChange =
    ({ dataGridId, row, index, expandedContent }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid) {
            // Update rows
            const rows = (getState().dataGrid[dataGridId]?.rows ?? []).map((r, i) => ({
                ...r,
                _expanded: i === index ? !r._expanded : r._expanded,
                _expandedContent: i === index ? expandedContent : r._expandedContent,
            }));

            dispatch({
                type: actionType.DATA_GRID_ROW_EXPAND_CHANGE,
                name: dataGridId,
                rows,
            });
        }
    };

export const onRowGroupExpandChange =
    ({ dataGridId, groupName, expanded }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid) {
            // Update rows group
            const rowGroups = cloneDeep(grid.rowGroups) ?? {};
            rowGroups[groupName] = expanded;

            dispatch({
                type: actionType.DATA_GRID_ROW_GROUP_EXPAND_CHANGE,
                name: dataGridId,
                rowGroups,
            });
        }
    };

export const onTreeExpandChange =
    ({ dataGridId, row, index }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        if (grid) {
            const nextExpandedState = !row._treeExpanded;
            const rowId = row[grid.tree.idColumn];

            // Update source rows to preserve tree state across pages
            const sourceRows = cloneDeep(getState().dataGrid[dataGridId]?.sourceRows ?? []);

            // Use forEach to get updated parents for every row.
            sourceRows.forEach((r, index) => {
                const parents = getParentsInTreeList({
                    index,
                    list: sourceRows,
                });

                // Change expanded state for clicked row
                r._treeExpanded = r[grid.tree.idColumn] === rowId ? nextExpandedState : r._treeExpanded;

                // Change visibility for children
                r._treeHidden = parents.some((p) => !p._treeExpanded);
            });

            dispatch({
                type: actionType.DATA_GRID_TREE_EXPAND_CHANGE,
                name: dataGridId,
                sourceRows,
            });
        }
    };

export const clearError =
    ({ dataGridId }) =>
    async (dispatch, getState) => {
        dispatch({
            type: actionType.DATA_GRID_CLEAR_ERROR,
            name: dataGridId,
        });
    };

/**
 * Exports data from a data grid to a CSV file.
 *
 * @param {Object} options - The options for exporting the data.
 * @param {string} options.dataGridId - The ID of the data grid to export.
 * @param {string} [options.fileName] - The name of the CSV file to export.
 * @param {string} [options.fileNamePostfix] - The postfix to add to the CSV file name.
 * @param {string} [options.formatColumnType] - The data type to format the column as.
 * @param {string} [options.formatColumnKey] - The key of the column to format.
 * @param {Array<string>} [options.columnsKeys] - The keys of the columns to export.
 * @returns {Function} - The async function that exports the data.
 */
export const exportCSV =
    ({ dataGridId, fileName, fileNamePostfix, formatColumnType, formatColumnKey, columnsKeys }) =>
    async (dispatch, getState) => {
        const grid = getState().dataGrid[dataGridId];

        let total = 1000;

        if (grid && grid.rows && grid.rows.length !== 0) {
            total = grid.rows[0].totRecords;
        }

        if (formatColumnType && formatColumnKey) {
            const column = grid.columns.find((column) => column.key === formatColumnKey);
            if (column) {
                column.datatype = formatColumnType;
            }
            dispatch(setColumns(dataGridId, grid.columns));
        }

        dispatch(
            getData({
                dataGridId,
                exportCSV: true,
                exportRowsTotal: total,
                fileName,
                fileNamePostfix,
                columnsKeys,
            })
        );
    };

export const optimisticUpdateRow =
    ({ gridId, rowSelector, row }) =>
    (dispatch, getState) => {
        const dataGrid = getState().dataGrid[gridId];
        const rows = cloneDeep(dataGrid.rows).map((oldRow, index) => {
            if (rowSelector(oldRow, index)) {
                return { ...oldRow, ...row };
            }

            return oldRow;
        });

        dispatch({
            type: actionType.DATA_GRID_UPDATE_ROWS,
            name: gridId,
            rows,
        });
    };

export const changeSettingsOpen =
    ({ dataGridId, isOpen, containerName }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionType.DATA_GRID_CHANGE_SETTINGS_OPEN,
            name: dataGridId,
            isOpen,
            containerName,
        });
    };

const updateColumnsRequest = ({ dataGridId, dataGridInstanceId, columns, dispatch }) => {
    const url = `${process.env.REACT_APP_SYSTEM_API_BASE_URL}grid/${dataGridId}/Columns`;

    const data = {
        columns: columns.map((c) => {
            return {
                key: c.key,
                name: c.name,
                order: c.order,
                active: c.active,
            };
        }),
    };

    dispatch({
        type: actionType.API_PUT_AUTHORIZED,
        url,
        body: JSON.stringify(data),
        actionTypes: {
            pending: actionType.DATA_GRID_SET_COLUMNS_REQUEST,
            response: actionType.DATA_GRID_SET_COLUMNS_SUCCESS,
            error: actionType.DATA_GRID_SET_COLUMNS_ERROR,
        },
        passThroughData: {
            name: dataGridInstanceId,
            columns,
        },
    });
};
