import React, { useCallback, useMemo, memo, useState } from "react";
import WaitIcon from "../../../../../../WaitIcon";
import JsonSchemaForm from "../../../../../../Form/JsonSchema/JsonSchemaForm";
import { isNil } from "lodash";
import useFormPages from "components/ui/Workflow/utils/hooks/useFormPages";
import { useSelector } from "react-redux";
import useFormPageSections from "components/ui/Workflow/utils/hooks/useFormPageSections";
import { getAttribute, getInitialPageFromAttributes, getProperties } from "components/ui/Workflow/utils/utils";

export const FormSectionEntryForm = memo(({ entityId, attributes, content, fields, onChange, onCloseModal }) => {
    const workflowStepAttributeTypes = useSelector((state) => state.resources.programWorkflowStepAttributeTypes.itemsById[entityId]);

    const initialPageNumber = useMemo(
        () =>
            getInitialPageFromAttributes({
                attributes,
                workflowStepAttributeTypes,
            }),
        [attributes, workflowStepAttributeTypes]
    );

    const initialSection = useMemo(
        () =>
            getFormSectionFromAttributes({
                attributes,
                workflowStepAttributeTypes,
            }),
        [attributes, workflowStepAttributeTypes]
    );

    const [formData, setFormData] = useState({
        formpgnum: initialPageNumber,
        formsecnum: initialSection,
        content,
    });

    const [pages, isLoadingFormPages] = useFormPages({ entityId });
    const [pageSections, isLoadingPageSections] = useFormPageSections({
        entityId,
        pageNumber: formData.formpgnum,
    });

    const schema = useMemo(
        () => ({
            type: "object",
            required: ["formpgnum", "formsecnum"],
            properties: {
                ...getProperties({
                    fields,
                    pages,
                    pageSections,
                    workflowStepAttributeTypes,
                }),
                content: {
                    title: "Content",
                    type: "string",
                },
            },
        }),
        [fields, pages, pageSections, workflowStepAttributeTypes]
    );

    const uiSchema = useMemo(
        () => ({
            classNames: "inline-form form-columns-2",
            formsecnum: {
                "ui:disabled": isNil(formData.formpgnum),
                "ui:placeholder": isNil(formData.formpgnum) ? undefined : isLoadingPageSections ? "Loading..." : "-- SELECT --",
            },
            content: {
                classNames: "workflow-step-types-widget__form-section workflow-step-types-widget__form-section-textarea fill-width",
                "ui:widget": "HtmlEditorWidget",
            },
        }),
        [formData.formpgnum, isLoadingPageSections]
    );

    const handleClickSave = useCallback(
        (formData) => {
            const { content } = formData;

            const data = {
                content,
                attributes: [],
            };

            for (const key in formData) {
                if (formData.hasOwnProperty(key) && key !== "content") {
                    const attribute = getAttribute({
                        code: key,
                        workflowStepAttributeTypes,
                    });

                    data.attributes.push({
                        typeNumber: attribute.number,
                        name: attribute.name,
                        value: formData[key],
                    });
                }
            }
            onChange(data);
            onCloseModal();
        },
        [workflowStepAttributeTypes, onChange, onCloseModal]
    );

    const onFormChange = useCallback(({ formData }) => {
        setFormData((prevValue) => {
            if (formData.formpgnum !== prevValue.formpgnum) {
                formData.formsecnum = undefined;

                return formData;
            }

            return formData;
        });
    }, []);

    if (isLoadingFormPages) {
        return <WaitIcon />;
    }

    return (
        <JsonSchemaForm
            className="workflow-step-types-widget__form"
            schema={schema}
            uiSchema={uiSchema}
            initialValues={formData}
            centeredFooter
            submitText="Save"
            onCancel={onCloseModal}
            onSubmit={handleClickSave}
            onChange={onFormChange}
            noReset
        />
    );
});

const getFormSectionFromAttributes = ({ attributes, workflowStepAttributeTypes }) => {
    const attribute = getAttribute({
        code: "formsecnum",
        workflowStepAttributeTypes,
    });
    return attributes.find((a) => a.typeNumber === attribute?.number)?.value;
};
