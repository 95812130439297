import React, { memo } from "react";
import { useSelector } from "react-redux";

import GridDetailsFooter from "../DataGrid/GridDetailsFooter";
import InlinePanelHeader from "../Dashboard/Panel/InlinePanelHeader";
import JsonSchemaForm from "../Form/JsonSchema/JsonSchemaForm";
import { Localize } from "components/utils/text";

import "../DataGrid/GridDetailsPanel.scss";

const Form = memo((props) => {
    const { dataItem, onClose } = props;
    const isMobile = useSelector((state) => state.window?.isMobile);

    const schema = {
        type: "object",
        properties: {
            contactTitle: {
                type: ["string", "null"],
                title: "Contact Title",
            },
            company: {
                type: ["string", "null"],
                title: "Company",
            },
            firstName: {
                type: ["string", "null"],
                title: "First Name",
            },
            lastName: {
                type: ["string", "null"],
                title: "Last Name",
            },
            address: {
                type: ["string", "null"],
                title: "Address",
            },
            city: {
                type: ["string", "null"],
                title: "City",
            },
            state: {
                type: ["string", "null"],
                title: Localize.STATE,
            },
            zip: {
                type: ["string", "null"],
                title: "Postal Code",
            },
            phone: {
                type: ["string", "null"],
                title: "Phone",
            },
            email: {
                type: ["string", "null"],
                title: "Email",
            },
            technologies: {
                type: ["string", "null"],
                title: "Technologies",
            },
            services: {
                type: ["string", "null"],
                title: "Services",
            },
        },
    };

    const uiSchema = {
        classNames: isMobile ? "" : "inline-form form-columns-3",
        technologies: {
            "ui:widget": "textarea",
        },
        services: {
            "ui:widget": "textarea",
        },
    };

    return (
        <div className="grid-details-panel contractor-details fill-width flex-column fill-height">
            <InlinePanelHeader title={"Contractor Details"} onClose={onClose} />
            <div className="flex-one with-scroll">
                <JsonSchemaForm initialValues={dataItem} schema={schema} uiSchema={uiSchema} noReset noCancel noSubmit readOnly noActions />
            </div>
            <GridDetailsFooter {...props} />
        </div>
    );
});

export default Form;
