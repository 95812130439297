import Button from "components/ui/Button";
import { FilterGroup, FilterGroups, ApplicationFilters, TaskFilters, FilterType } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataGrid from "components/ui/DataGrid";
import { useId, useRef, useState } from "react";
import { availableGrids, getColumnKeys } from "components/views/configureGrids";
import ActionLabel from "components/ui/Label/ActionLabel";
import { openProjectTab } from "components/utils/window";
import { DataGridColumn, DataGridRow } from "store/dataGrid/types";
import { openProgramTab } from "store/window/openTabActions";
import { useDispatch, useSelector } from "react-redux";
import { construct, exportCSV, getData, setFilter } from "store/dataGrid/actions";
import { searchAttrToFilter } from "components/utils/datagrid";
import { FilterTypeSelector } from "./FilterTypeSelector";
import { VariablesSelector } from "./VariablesSelector";
import ApplicationsFilterControls from "./ApplicationsFilterControls";
import TasksFilterControls from "./TasksFilterControls";
import { GridLoadingSkeleton } from "./GridLoadingSkeleton";
import * as dataGridActions from "store/dataGrid/actions";
import { FilterConfig, FilterVariable, sanitizeFilterConfig } from "../utils";

export const FilterExploreResults: React.FC<{
    filterNumber: string;
    filterGroup: FilterGroup;
    filterType: FilterType;
    userNumber?: string;
    onVariableEdit: (variable: FilterVariable) => void;
    selectedUserNumber: string | undefined;
    onSelectFilterType: (filterGroup: FilterGroup, filterType: FilterType) => void;
    onSelectUser: (userNumber: string | undefined) => void;
}> = ({ filterNumber, filterGroup, filterType, userNumber, onVariableEdit, selectedUserNumber, onSelectFilterType, onSelectUser }) => {
    const dispatch = useDispatch();

    const [updatedFilterConfig, setUpdatedFilterConfig] = useState<FilterConfig>();
    const dataGridInstanceId = `workcenter-${useId()}`;
    const dataGridId = getGridId(filterGroup, filterType);
    const dataGridFilter = getGridFilter(filterNumber, userNumber, filterGroup, filterType);
    const columnKeys = getColumnKeys(dataGridId);
    const [selectedRows, setSelectedRows] = useState([]);

    // @ts-ignore
    const gridConfig = useSelector((state) => state.dataGrid[dataGridInstanceId]);

    const isGridInitialized = useRef(false);
    if (!isGridInitialized.current) {
        isGridInitialized.current = true;
        dispatch(
            construct({
                dataGridId,
                dataGridInstanceId,
                filter: searchAttrToFilter(dataGridFilter)!,
                data: true,
            })
        );
    }

    const onExport = () => {
        dispatch(
            exportCSV({
                dataGridId: dataGridInstanceId,
                fileName: "workcenter",
                fileNamePostfix: filterGroup,
            })
        );
    };

    const onFilterTypeChange = (filterGroup: FilterGroup, filterType: FilterType, userNumber: string | undefined) => {
        onSelectUser(userNumber);
        onSelectFilterType(filterGroup, filterType);
    };

    const onApplyFilter = (filerConfig: FilterConfig) => {
        setSelectedRows([]);
        setUpdatedFilterConfig(filerConfig);

        const dataGridFilter = getGridFilter(filterNumber, userNumber, filterGroup, filterType, filerConfig);

        dispatch(
            setFilter({
                dataGridId: dataGridInstanceId,
                filter: searchAttrToFilter(dataGridFilter),
            })
        );

        dispatch(
            getData({
                dataGridId: dataGridInstanceId,
            })
        );
    };

    const onResetFilter = () => {
        setSelectedRows([]);
        setUpdatedFilterConfig(undefined);

        const dataGridFilter = getGridFilter(filterNumber, userNumber, filterGroup, filterType);

        dispatch(
            setFilter({
                dataGridId: dataGridInstanceId,
                filter: searchAttrToFilter(dataGridFilter),
            })
        );

        dispatch(
            getData({
                dataGridId: dataGridInstanceId,
            })
        );
    };

    if (!gridConfig || gridConfig.isConstructing || (gridConfig.isReading && !gridConfig.rows?.length)) {
        return <GridLoadingSkeleton />;
    }

    return (
        <div className="flex-column fill-width no-scroll p-8 pt-6 gap-4 bg-white">
            <div className="flex-row align-center gap-3">
                <FilterTypeSelector
                    filterGroup={filterGroup}
                    filterType={filterType}
                    userNumber={selectedUserNumber}
                    onChange={onFilterTypeChange}
                />
                <VariablesSelector
                    filterNumber={filterNumber}
                    updatedFilterConfig={updatedFilterConfig}
                    onEdit={onVariableEdit}
                    onSubmit={onApplyFilter}
                />
                {updatedFilterConfig && <Button onClick={onResetFilter}>Reset all</Button>}
                <Button className="ms-auto" onClick={onExport}>
                    <div className="flex-row align-center gap-2">
                        <FontAwesomeIcon icon={["far", "file-csv"]} size="sm" /> Export CSV
                    </div>
                </Button>
            </div>
            {filterGroup === FilterGroups.Applications && (
                <ApplicationsFilterControls
                    instanceId={dataGridInstanceId}
                    resetSelectedRows={() => {
                        setSelectedRows([]);
                        dispatch(dataGridActions.resetRows({ dataGridId: dataGridInstanceId }));
                    }}
                    selectedRows={selectedRows}
                    dataGridId={dataGridId}
                />
            )}
            {filterGroup === FilterGroups.Tasks && (
                <TasksFilterControls
                    instanceId={dataGridInstanceId}
                    resetSelectedRows={() => {
                        setSelectedRows([]);
                        dispatch(dataGridActions.resetRows({ dataGridId: dataGridInstanceId }));
                    }}
                    selectedRows={selectedRows}
                    dataGridId={dataGridId}
                />
            )}
            <div className="flex-one-in-column no-scroll">
                <DataGrid
                    canSelectRow //={showAssignments}
                    onRowSelectChange={setSelectedRows}
                    // onPageChanged={onPageChanged}
                    // onFilterChanged={onFilterChanged}
                    name={dataGridInstanceId}
                    config={gridConfig}
                    columnCellContent={{
                        [columnKeys.appId]: (column: DataGridColumn, row: DataGridRow) => (
                            <ActionLabel onClick={() => openProjectTab({ applicationNumber: row[column.key] })}>
                                {row[column.key]}
                            </ActionLabel>
                        ),
                        [columnKeys.program]: (column: DataGridColumn, row: DataGridRow) => {
                            if (row[columnKeys.progId]) {
                                return (
                                    <ActionLabel onClick={() => dispatch(openProgramTab({ programNumber: row[columnKeys.progId] }))}>
                                        {row[column.key]}
                                    </ActionLabel>
                                );
                            }

                            return row[column.key];
                        },
                        [columnKeys.project]: (column: DataGridColumn, row: DataGridRow) => {
                            if (row[columnKeys.appId]) {
                                return (
                                    <ActionLabel onClick={() => openProjectTab({ applicationNumber: row[columnKeys.appId] })}>
                                        {row[column.key]}
                                    </ActionLabel>
                                );
                            }

                            return row[column.key];
                        },
                        [columnKeys.status]: (column: DataGridColumn, row: DataGridRow) => {
                            if (row[columnKeys.progId]) {
                                return (
                                    <ActionLabel
                                        onClick={() => {
                                            dispatch(
                                                openProgramTab({
                                                    programNumber: row[columnKeys.progId],
                                                    activePanel: "program-applications",
                                                    applicationStatus: row[column.key],
                                                })
                                            );
                                        }}
                                    >
                                        {row[column.key]}
                                    </ActionLabel>
                                );
                            }

                            return row[column.key];
                        },
                    }}
                />
            </div>
        </div>
    );
};

const getGridId = (filterGroup: FilterGroup, filterType: FilterType) => {
    switch (`${filterGroup}-${filterType}`) {
        case `${FilterGroups.Applications}-${ApplicationFilters.Any}`:
            return availableGrids.workqueueApps;
        case `${FilterGroups.Applications}-${ApplicationFilters.Unassigned}`:
            return availableGrids.workqueueApps;
        case `${FilterGroups.Applications}-${ApplicationFilters.WithNotes}`:
            return availableGrids.workqueueAppsNotes;
        case `${FilterGroups.Applications}-${ApplicationFilters.ReadyForStatusChange}`:
            return availableGrids.workqueueAppsStatusChange;
        case `${FilterGroups.Applications}-${ApplicationFilters.AssignedTo}`:
            return availableGrids.workqueueAppsAssigned;
        case `${FilterGroups.Applications}-${ApplicationFilters.CreatedBy}`:
            return availableGrids.workqueueApps;
        case `${FilterGroups.Applications}-${ApplicationFilters.WithTags}`:
            return availableGrids.workqueueAppsWithTags;
        case `${FilterGroups.Applications}-${ApplicationFilters.CountsByStatus}`:
            return availableGrids.workqueueAppsCountsByStatus;
        case `${FilterGroups.Tasks}-${TaskFilters.Any}`:
            return availableGrids.workqueueTasks;
        case `${FilterGroups.Tasks}-${TaskFilters.WithNoOwner}`:
            return availableGrids.workqueueTasks;
        case `${FilterGroups.Tasks}-${TaskFilters.OwnedBy}`:
            return availableGrids.workqueueTasks;
        case `${FilterGroups.Tasks}-${TaskFilters.OnMyGroupTasks}`:
            return availableGrids.workqueueTasksMyAssignedGroups;
        case `${FilterGroups.Tasks}-${TaskFilters.OnMyAssignedApps}`:
            return availableGrids.workqueueTasksAssignedApps;
        default:
            return "";
    }
};

const getGridFilter = (
    filterNumber: string,
    userNumber: string | undefined,
    filterGroup: FilterGroup,
    filterType: FilterType,
    filterConfig: FilterConfig | undefined = undefined
) => {
    // hardcoded until backend issues in V50-8493 are fixed
    let defaultFilterNumber = "37F3A8E86A68461FBE8F8A4439D7C590";

    if (filterConfig) {
        const config = sanitizeFilterConfig(filterConfig.filter((v) => v._enabled).filter((v) => v.items.some((i) => i._enabled)));
        defaultFilterNumber = JSON.stringify(config);
    }

    const defaultFilter = `datasourcenumber=${defaultFilterNumber}`;

    switch (`${filterGroup}-${filterType}`) {
        case `${FilterGroups.Applications}-${ApplicationFilters.Any}`:
            return defaultFilter;
        case `${FilterGroups.Applications}-${ApplicationFilters.Unassigned}`:
            return `${defaultFilter}|assigned=null`;
        case `${FilterGroups.Applications}-${ApplicationFilters.WithNotes}`:
            return `${defaultFilter}|notes=true`;
        case `${FilterGroups.Applications}-${ApplicationFilters.ReadyForStatusChange}`:
            return defaultFilter;
        case `${FilterGroups.Applications}-${ApplicationFilters.AssignedTo}`: {
            const assignedUserFilter = userNumber ? `|assignedUserNumber=${userNumber}` : "";
            return `${defaultFilter}${assignedUserFilter}`;
        }
        case `${FilterGroups.Applications}-${ApplicationFilters.CreatedBy}`: {
            const createdByUserFilter = userNumber ? `|usernumber=${userNumber}` : "";
            return `${defaultFilter}${createdByUserFilter}`;
        }
        case `${FilterGroups.Applications}-${ApplicationFilters.WithTags}`:
            return defaultFilter;
        case `${FilterGroups.Applications}-${ApplicationFilters.CountsByStatus}`:
            return defaultFilter;
        case `${FilterGroups.Tasks}-${TaskFilters.Any}`:
            return defaultFilter;
        case `${FilterGroups.Tasks}-${TaskFilters.WithNoOwner}`:
            return `${defaultFilter}|usernumber=null`;
        case `${FilterGroups.Tasks}-${TaskFilters.OwnedBy}`: {
            const ownedByUserFilter = userNumber ? `|usernumber=${userNumber}` : "";
            return `${defaultFilter}${ownedByUserFilter}`;
        }
        case `${FilterGroups.Tasks}-${TaskFilters.OnMyGroupTasks}`: {
            const assignedGroupsFilter = userNumber ? `|groupUserNumber=${userNumber}` : "";
            return `${defaultFilter}${assignedGroupsFilter}`;
        }
        case `${FilterGroups.Tasks}-${TaskFilters.OnMyAssignedApps}`: {
            const assignedAppsFilter = userNumber ? `|usernumber=${userNumber}` : "";
            return `${defaultFilter}${assignedAppsFilter}`;
        }
        default:
            return defaultFilter;
    }
};
