import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterColumnPlaceholder } from "./FilterColumnPlaceholder";
import { FilterSelectDropdown } from "./FilterSelectDropdown";
import { FilterList } from "./FilterList";
import cn from "classnames";
import { FilterVariable, ProgramItem, VariableTypes, FilterColumn, getVariableIcon } from "../utils";
import { useCallback, useMemo } from "react";
import { isEmpty } from "lodash";

export const VariableFilter: React.FC<{
    variableFilter: FilterVariable[];
    selectedVariable: FilterVariable | null;
    isActiveColumn: boolean;
    selectedProgram: ProgramItem | null;
    isDropdownOpen: boolean;
    readOnly?: boolean;
    onConditionSelectDisabled?: boolean;
    setActiveColumn: React.Dispatch<React.SetStateAction<FilterColumn | null>>;
    setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onAdd: (variable: { label: string; value: string }) => void;
    onAddCondition: () => void;
    onSelect: (item: FilterVariable, index: number) => void;
    onRemove: (index: number) => void;
    onClear: (index: number) => void;
}> = ({
    variableFilter,
    selectedVariable,
    isActiveColumn,
    isDropdownOpen,
    onConditionSelectDisabled,
    selectedProgram,
    readOnly,
    setIsDropdownOpen,
    setActiveColumn,
    onAdd,
    onAddCondition,
    onSelect,
    onRemove,
    onClear,
}) => {
    const dropdownItems = useMemo(
        () =>
            Object.values(VariableTypes)
                .map((type) => ({
                    label: type,
                    value: type,
                }))
                .filter((v) => !variableFilter.map((v) => v.type).includes(v.value)),
        [variableFilter]
    );

    const filterItems = useMemo(
        () =>
            variableFilter.map((v) => ({
                label: v.type,
                value: v.type,
                icon: getVariableIcon(v.type),
            })),
        [variableFilter]
    );

    const onSelectItem = useCallback(
        (item: { label: string; value: string }) => {
            const index = variableFilter.findIndex((v) => v.type === item.value);
            const filterItem = variableFilter[index];
            onSelect(filterItem, index);
        },
        [onSelect, variableFilter]
    );

    const selectedIndex = useMemo(() => variableFilter.findIndex((v) => v === selectedVariable), [selectedVariable, variableFilter]);

    return (
        <div
            role="region"
            aria-label="Variable list"
            className={cn("variable-filter filter-column fill-height flex-column py-2.5 px-2 gap-2 transition-all group", {
                isDropdownOpen,
            })}
        >
            <div
                className={cn("filter-header flex-row align-center justify-space-between bg-white p-1", {
                    isActiveColumn,
                    "not-empty": !isEmpty(variableFilter),
                })}
            >
                <h2 className="column-title group-hover:text-lg fs-16 pb-1">Variable</h2>
            </div>
            {!readOnly && (
                <FilterSelectDropdown
                    columnTitle="Variable"
                    onSelect={onAdd}
                    items={dropdownItems}
                    isDropdownOpen={isDropdownOpen}
                    setIsDropdownOpen={setIsDropdownOpen}
                    setActiveColumn={setActiveColumn}
                    disabled={!selectedProgram || readOnly}
                />
            )}
            {variableFilter.length > 0 ? (
                <FilterList
                    readOnly={readOnly}
                    items={filterItems}
                    selectedIndex={selectedIndex}
                    addItemLabel="Add Condition"
                    onSelectItem={onSelectItem}
                    onAddItem={onConditionSelectDisabled ? undefined : onAddCondition}
                    onRemoveItem={onRemove}
                    onClearItem={onClear}
                />
            ) : (
                <FilterColumnPlaceholder>
                    <div className="flex-column align-center px-8 gap-2">
                        <FontAwesomeIcon icon={["fas", "rectangle-terminal"]} color="gray" fixedWidth size="2x" />
                        <h4>No Variables</h4>
                        <span className="fs-14">
                            Please add a Variable <br /> to proceed
                        </span>
                    </div>
                </FilterColumnPlaceholder>
            )}
        </div>
    );
};
