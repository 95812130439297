import React from "react";
import { isEmpty } from "lodash";
import TextInput from "../../../Input/TextInput";
import NumericInput from "../../../Input/NumericInput";

/**
 * BaseInput component
 *
 * @param {Object} props - Component props
 * @param {string} props.id - The unique ID for the input field (required).
 * @param {string} [props.type=text] - Input type.
 * @param {string} [props.placeholder] - Placeholder text for the input field.
 * @param {*} [props.value] - The current value of the input.
 * @param {boolean} [props.required=false] - Specifies whether the input is required.
 * @param {boolean} [props.disabled=false] - Specifies whether the input is disabled.
 * @param {boolean} [props.readonly=false] - Specifies whether the input is read-only.
 * @param {boolean} [props.autofocus=false] - Specifies whether the input should automatically focus when the page loads.
 * @param {Function} [props.onChange] - Function to call when the input value changes.
 * @param {Function} [props.onBlur] - Function to call when the input loses focus.
 * @param {Function} [props.onFocus] - Function to call when the input gains focus.
 * @param {React.CSSProperties} [props.style] - custom styles
 *
 * @returns {JSX.Element} The rendered input element.
 */
function BaseInput(props) {
    if (!props.id) {
        throw new Error(`no id for props ${JSON.stringify(props)}`);
    }

    const { value, readonly, disabled, autofocus, onBlur, onFocus, options, schema, formContext, registry, rawErrors, ...inputProps } =
        props;

    inputProps.type = options.inputType || inputProps.type || "text";
    const _onChange = ({ target: { value } }) => {
        return props.onChange(value === "" ? options.emptyValue : value);
    };

    const isError = !isEmpty(props.rawErrors);

    let component = (
        <TextInput
            id={inputProps.id}
            type={inputProps.type}
            name={inputProps.id}
            label={undefined}
            placeholder={inputProps.placeholder}
            readOnly={readonly}
            disabled={disabled}
            autoFocus={autofocus}
            minLength={schema.minLength}
            maxLength={schema.maxLength}
            value={value === null || value === undefined ? "" : value}
            onChange={_onChange}
            onBlur={onBlur && ((event) => onBlur(inputProps.id, event.target.value))}
            onFocus={onFocus && ((event) => onFocus(inputProps.id, event.target.value))}
            error={isError}
            style={props.style}
        />
    );

    if (["number", "integer"].indexOf(schema.type) > -1) {
        const min = schema.exclusiveMinimum ? schema.exclusiveMinimum + 1 : schema.minimum;
        const max = schema.exclusiveMaximum ? schema.exclusiveMaximum - 1 : schema.maximum;

        component = (
            <NumericInput
                id={inputProps.id}
                className={schema.type === "number" ? "fill-width" : undefined}
                label={undefined}
                placeholder={inputProps.placeholder}
                readOnly={readonly}
                disabled={disabled}
                autoFocus={autofocus}
                min={min}
                max={max}
                decimals={options.decimals}
                name={inputProps.id}
                value={value === null || value === undefined ? null : value}
                onChange={_onChange}
                onBlur={onBlur && ((event) => onBlur(inputProps.id, event.target.value))}
                onFocus={onFocus && ((event) => onFocus(inputProps.id, event.target.value))}
                error={isError}
            />
        );
    }

    return component;
}

export default BaseInput;
