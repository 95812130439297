import React, { useCallback, useEffect } from "react";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";
import { PropertyListItem } from "../Property/PropertyListItem";
import sassVariables from "../../../../../assets/sass/_export.module.scss";
import { debounce, isEmpty } from "lodash";
import { BACKGROUND_COLOR_OPACITY_PROPERTY, BACKGROUND_COLOR_PROPERTY, BACKGROUND_WIDTH_PROPERTY } from "./propertyConfig";
import { ColorType, PropertyType } from "../../types";
import { ColorGradientProperty } from "./ColorGradientSlider";
import { SelectBoxProperty } from "./SelectBoxProperty";

import "./BackgroundProperty.scss";

export const BackgroundProperty = (props) => {
    const {
        title,
        titleSwitch,
        value = [],
        nestingLevel,
        onChange,
        borderBottom,
        defaultValue,
        selectableWidth,
        withGradient,
        selectPanelSubTitle,
    } = props;
    const backGroundOff = props.titleSwitchValue ?? titleSwitch.defaultValue;

    const onPropertyChange = useCallback(
        (id, value, additionalValues) => {
            debouncedOnChange(id, value, additionalValues, onChange);
        },
        [onChange]
    );

    const turnOffBackground = () => {
        let extraProperties = [
            {
                id: "backgroundColor",
                value: undefined,
            },
            {
                id: "colorType",
                value: undefined,
            },
        ];
        if (backGroundOff) {
            extraProperties = [
                {
                    id: "backgroundColor",
                    value: defaultValue?.defaultBackgroundColor ?? sassVariables.colorLightGreyMedium,
                },
                {
                    id: "colorType",
                    value: defaultValue?.colorType ?? "solid",
                },
            ];
        }
        onChange(titleSwitch?.id, !backGroundOff, extraProperties);
    };

    const colorTypes = [
        {
            text: "Gradient",
            value: ColorType.LinearGradient,
        },
        {
            text: "Solid",
            value: ColorType.Solid,
        },
    ];

    useEffect(() => {
        if (!isEmpty(titleSwitch?.defaultValue) && isEmpty(props.titleSwitchValue)) {
            onChange(titleSwitch?.id, titleSwitch?.defaultValue);
        }
    }, [onChange, props.titleSwitchValue, titleSwitch?.defaultValue, titleSwitch?.id]);

    if (props.hidden) {
        return null;
    }

    const onChangeType = (id, type) => {
        let extraProperties = [];
        if (type !== "solid") {
            extraProperties = [
                {
                    id: "firstGradientValue",
                    value: value.firstGradientValue,
                },
                {
                    id: "secondGradientValue",
                    value: value.secondGradientValue,
                },
                {
                    id: "firstGradientColor",
                    value: value.firstGradientColor ?? "#000000",
                },
                {
                    id: "secondGradientColor",
                    value: value?.secondGradientColor ?? "#ffffff",
                },
                {
                    id: "gradientDirection",
                    value: value?.gradientDirection ?? "to bottom",
                },
            ];
        }
        onChange(id, type, extraProperties);
    };

    const properties = withGradient
        ? value.colorType === ColorType.Solid
            ? selectableWidth
                ? [{ ...BACKGROUND_COLOR_PROPERTY, defaultValue: "#fffff" }, BACKGROUND_COLOR_OPACITY_PROPERTY, BACKGROUND_WIDTH_PROPERTY]
                : [{ ...BACKGROUND_COLOR_PROPERTY, defaultValue: "#fffff" }, BACKGROUND_COLOR_OPACITY_PROPERTY]
            : selectableWidth
            ? [LINEAR_GRADIENT_PROPERTY, BACKGROUND_COLOR_OPACITY_PROPERTY, BACKGROUND_WIDTH_PROPERTY]
            : [LINEAR_GRADIENT_PROPERTY, BACKGROUND_COLOR_OPACITY_PROPERTY]
        : [
              { ...BACKGROUND_COLOR_PROPERTY, defaultValue: "#fffff", selectPanelSubTitle: selectPanelSubTitle },
              BACKGROUND_COLOR_OPACITY_PROPERTY,
          ];

    return (
        <PropertyListItem className="property-list-item--prop-group property-list-item--background" borderBottom={borderBottom}>
            <PropertyListItemTitle
                title={title ?? "Background"}
                toggleTooltip={backGroundOff ? "Switch ON" : "Switch OFF"}
                onToggle={turnOffBackground}
                toggleValue={backGroundOff}
            />
            {withGradient && (
                <SelectBoxProperty
                    hidden={backGroundOff}
                    id="colorType"
                    items={colorTypes}
                    value={value.colorType}
                    onChange={onChangeType}
                    fullWidth={true}
                    borderBottom={false}
                />
            )}
            <PropertyList
                items={properties}
                nestingLevel={nestingLevel + 1}
                config={value}
                onChange={onPropertyChange}
                isExpanded={!backGroundOff}
            />
        </PropertyListItem>
    );
};

const debouncedOnChange = debounce((id, value, additionalValues, onChange) => {
    onChange(id, value, additionalValues);
}, 50);

const LINEAR_GRADIENT_PROPERTY = {
    id: "linearGradient",
    type: PropertyType.CustomComponent,
    component: ColorGradientProperty,
    propertiesGroup: [
        {
            id: "gradientDirection",
        },
        {
            id: "firstGradientColor",
        },
        {
            id: "secondGradientColor",
        },
        {
            id: "firstGradientValue",
        },
        {
            id: "secondGradientValue",
        },
    ],
};
