import { Skeleton } from "../Skeleton";

export const GridLoadingSkeleton = () => {
    return (
        <div className="p-8 pt-6 h-full flex flex-col bg-white">
            <div className="flex flex-col w-full h-full">
                <div className="flex w-full justify-between items h-[4%] mb-4">
                    <div className="flex justify-between w-[55%] h-full gap-4">
                        <Skeleton className="w-2/6 h-full" />
                        <Skeleton className="w-3/6 h-full" />
                        <Skeleton className="w-1/6 h-full" />
                    </div>
                    <div className="flex justify-end w-[45%] h-full">
                        <Skeleton className="w-[12%] h-full" />
                    </div>
                </div>

                <div className="flex items-center space-x-4 flex-1 p-4 bg-theme-white">
                    <div className="h-full w-full justify-between flex flex-col">
                        <div className="flex justify-between h-[4%]">
                            <Skeleton className="w-[12%]" />
                            <Skeleton className="w-[12%]" />
                            <Skeleton className="w-[12%]" />
                            <Skeleton className="w-[12%]" />
                            <Skeleton className="w-[12%]" />
                            <Skeleton className="w-[12%]" />
                            <Skeleton className="w-[12%]" />
                            <Skeleton className="w-[12%]" />
                        </div>
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                        <Skeleton className="h-[2%] w-full" />
                    </div>
                    <div className="h-full justify-between flex flex-col">
                        <Skeleton className="h-[4%] w-7 rounded" />
                        <Skeleton className="h-[4%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                        <Skeleton className="h-[2%] w-7 rounded" />
                    </div>
                </div>
            </div>
        </div>
    );
};
