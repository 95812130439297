import React, { memo, useCallback, useMemo, useRef } from "react";
import { transformApplicationFormErrors } from "components/utils/form";

import ContentTitle from "../../ui/Title/ContentTitle";
import JsonSchemaFormWithConditionals from "components/ui/Form/JsonSchema/JsonSchemaFormWithConditionals";
import IconWrap from "../../ui/Icons";
import StatusMsg from "../../ui/StatusMsg";

const ApplicationForm = memo(({ formRef, programNumber, applicationNumber, schema, uiSchema, rules, initialValues, onChange }) => {
    const encryptState = useRef(new Map()); // Keys of encrypted fields with editing in progress

    const formContext = useMemo(
        () => ({
            programNumber,
            applicationNumber,
            localizeDateValues: true,
            encryptState,
            isNewApplication: true,
        }),
        [programNumber, applicationNumber]
    );

    const handleChange = useCallback(
        ({ formData }) => {
            onChange(formData);
        },
        [onChange]
    );

    const transformErrors = useCallback(
        (errors) => {
            return transformApplicationFormErrors(errors, schema, uiSchema, formContext);
        },
        [schema, uiSchema, formContext]
    );

    return (
        <div className="new-app-form">
            <div className="new-app-section">
                <div className="section-name">
                    <IconWrap iconWrapBig iconWrapTheme iconWrap="applications"></IconWrap>
                    <ContentTitle>{schema.title}</ContentTitle>
                </div>
                <div className="new-app-section__form">
                    <div className="application-form-info">
                        <StatusMsg msgInfo>
                            Highlighted fields marked with{" "}
                            <IconWrap iconWrapCombined icon="administrative-field-icon eye-visibility-empty" /> are administrative and are
                            not shown to all users.
                        </StatusMsg>
                    </div>
                    <div className="application-form">
                        {schema && uiSchema && (
                            <JsonSchemaFormWithConditionals
                                formRef={formRef}
                                schema={schema}
                                uiSchema={uiSchema}
                                rules={rules}
                                initialValues={initialValues}
                                fieldKey="ui:fieldNumber"
                                onChange={handleChange}
                                transformErrors={transformErrors}
                                noActions
                                defaultValueOnInitialize
                                emptyItemInSelectLists
                                formContext={formContext}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ApplicationForm;
