import React, { useEffect, memo, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { windowContainerTypes, processAuthenticatedRoutes } from "../../utils/window";
import { setSettingsTheme } from "../../utils/settings";
import { isAuthenticated, isQrCode, isStatusExpired } from "../../utils/user";
import { windowContainerReset, windowAdd } from "../../../store/window/actions";
import { clientRoute } from "../../utils/constants";
import useSessionTimeout from "./utils/hooks/useSessionTimeout";

import Windows from "../../ui/Windows";
import WaitIcon from "../../ui/WaitIcon";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import { loadThemes } from "store/login/actions";

const WindowManager = memo(() => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const isUserAuthenticated = isAuthenticated(user);
    const isUserQrCode = isQrCode(user);
    const isExpired = isStatusExpired(user);
    const isResetPassword = window.location.pathname.split("/")[1] === clientRoute.resetPassword;
    const isSaml = window.location.pathname.split("/")[1] === clientRoute.saml;
    const containerName = isUserAuthenticated ? windowContainerTypes.Root : windowContainerTypes.Login;

    const isLoadingThemes = useThemes();

    useSessionTimeout();

    const views = useMemo(
        () =>
            isUserAuthenticated
                ? [
                      {
                          containerName,
                          name: "Home",
                          component: "HomeView",
                          close: false,
                          showHeader: false,
                      },
                  ]
                : [
                      {
                          containerName,
                          name: "Login",
                          component: "Login",
                          showTab: false,
                          showHeader: false,
                          activate: true,
                          persist: false,
                      },
                  ],
        [isUserAuthenticated, containerName]
    );

    useEffect(() => {
        if (!isUserAuthenticated && !isUserQrCode && !isExpired && !isResetPassword && !isSaml) {
            dispatch(windowContainerReset(containerName));
            dispatch(windowAdd(views[0]));
        }
    }, [containerName, isUserAuthenticated, isUserQrCode, isExpired, isResetPassword, isSaml, views, dispatch]);

    useEffect(() => {
        if (isUserAuthenticated) {
            processAuthenticatedRoutes();
        }
    }, [isUserAuthenticated]);

    // Ensure that the reset password view is opened.
    useEffect(() => {
        if (isResetPassword) {
            dispatch(windowContainerReset(windowContainerTypes.Login));
            dispatch(
                windowAdd({
                    containerName: windowContainerTypes.Login,
                    name: "Reset Password",
                    component: "ResetPassword",
                    showTab: false,
                    showHeader: false,
                    activate: true,
                    persist: false,
                })
            );
        }
    }, [isResetPassword, dispatch]);

    if (isLoadingThemes) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    return <Windows containerName={containerName} initialViews={views} persist={isUserAuthenticated} />;
});

const useThemes = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const isLoading = useSelector((state) => state.resources.userThemes.itemsById[`${user?.userNumber}-is-loading`] ?? false);
    const themesLoadedRef = useRef(false);

    if (!themesLoadedRef.current && isAuthenticated(user)) {
        themesLoadedRef.current = true;
        dispatch(loadThemes());
    }

    if (!isAuthenticated(user)) {
        // Set default theme to show on login screen
        setSettingsTheme();
    }

    return isLoading;
};

export default WindowManager;
