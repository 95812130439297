import { useCallback, useState } from "react";
import BaseInput from "../BaseInput";
import { createResource } from "store/resources/actions";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openErrorModal, openInfoModal, openWaitingModal } from "components/ui/Modal/utils";
import { isEmpty } from "lodash";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { mask } from "components/utils/string";
import { useApplication } from "store/resources/useResource";

export const EncryptedTextWidget: React.FC<{
    id: string;
    value: string;
    onChange: (value: string | undefined) => void;
    onFocus: (id: string, value: string) => {};
    onBlur: (id: string, value: string) => {};
    disabled: boolean;
    readonly: boolean;
    required: boolean;
    formContext: { applicationNumber?: string; encryptState?: { current: Map<string, boolean> }; isNewApplication?: boolean };
    options: { fieldNumber?: string };
}> = (props) => {
    const dispatch = useDispatch();

    const { applicationNumber, encryptState, isNewApplication } = props.formContext ?? {};
    const fieldNumber = props.options?.fieldNumber;

    const encrypted = isEncrypted(props.value);

    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState<string | undefined>(props.value);
    const [isMasked, setIsMasked] = useState<boolean>(encrypted);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const [application] = useApplication({ applicationNumber });

    const hasRightsToDecrypt = application?.hasDR;

    const showDecryptButton = encrypted && applicationNumber && fieldNumber && props.readonly && !isLoading && hasRightsToDecrypt;
    const canEdit = isNewApplication || hasRightsToDecrypt;

    if (encryptState && fieldNumber) {
        const isBusy = !isEmpty(inputValue) && (isLoading || !encrypted || inputValue !== props.value);

        if (isBusy) {
            encryptState.current.set(fieldNumber, true);
        } else {
            encryptState.current.delete(fieldNumber);
        }
    }

    const onChange = (value: string | undefined) => {
        if (!canEdit) {
            return;
        }

        if (isFocused && isMasked) {
            setIsMasked(false);
        }

        setInputValue(value);
    };

    const onBlur = (id: string, value: string) => {
        setIsFocused(false);

        if (props.readonly || props.disabled || isMaskedValue(value)) {
            return;
        }

        if ((value ?? "").trim().length === 0) {
            setIsMasked(true);
            props.onChange(undefined);

            return;
        }

        setIsLoading(true);

        dispatch(
            createResource({
                resourceName: "encryptFormField",
                query: {
                    stringValue: value,
                },
                onSuccess: (action: { data: string }) => {
                    setInputValue(action.data);
                    setIsMasked(true);
                    props.onChange(action.data);
                },
                onError: () => {
                    setInputValue(undefined);
                    props.onChange(undefined);
                },
                onComplete: () => setIsLoading(false),
            })
        );
    };

    const onFocus = (id: string, value: string) => {
        setIsFocused(true);

        if (props.readonly || props.disabled) {
            return;
        }

        /* Do a onChange with the existing value to update the form save button state
         * to be disabled while editing the encrypted field.
         */
        props.onChange(props.value);
    };

    const onShowValue = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        openWaitingModal({
            title: "Decrypting...",
        });

        dispatch(
            createResource({
                resourceName: "decryptFormField",
                query: {
                    appId: applicationNumber,
                    fieldNumber,
                },
                onSuccess: (action: { data: string }) => {
                    openInfoModal({
                        title: "Decrypted Data",
                        text: action.data,
                        noOverlayIcon: true,
                    });
                },
                onError: () => {
                    openErrorModal({
                        text: "Failed to decrypt data.",
                    });
                },
            })
        );
    };

    const onRef = useCallback(
        (ref: HTMLDivElement) => {
            const onInputClick = (e: MouseEvent) => {
                // select all on every click within the input if the value is only the mask
                if (e.target instanceof HTMLInputElement && e.target.id === props.id && isMaskedValue(e.target.value)) {
                    e.preventDefault();
                    e.target.select();
                }
            };

            const onInputMouseDown = (e: MouseEvent) => {
                if (e.target instanceof HTMLInputElement && e.target.id === props.id && isMaskedValue(e.target.value)) {
                    e.preventDefault();
                }
            };

            if (ref) {
                document.addEventListener("click", onInputClick);
                document.addEventListener("mousedown", onInputMouseDown);
            } else {
                document.removeEventListener("click", onInputClick);
                document.addEventListener("mousedown", onInputMouseDown);
            }
        },
        [props.id]
    );

    return (
        <div ref={onRef} className="flex-column">
            <BaseInput
                {...props}
                disabled={isLoading}
                readonly={!canEdit || props.readonly}
                type="text"
                value={isMasked ? mask(inputValue ?? "") : inputValue}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            {(encrypted || isLoading) && (
                <p id={`encrypted-value-${props.id}`} className="help-block" role="status" aria-atomic="true">
                    {isFocused && canEdit && !isEncrypted(inputValue ?? "") ? (
                        <span>
                            <FontAwesomeIcon icon={["far", "unlock"]} fixedWidth /> Enter value to encrypt
                        </span>
                    ) : (
                        <>
                            {isLoading ? (
                                <span>
                                    <FontAwesomeIcon icon={["fal", "arrows-rotate"]} spin fixedWidth /> Encrypting...
                                </span>
                            ) : (
                                <span className="flex-row align-center gap-2">
                                    <span>
                                        <FontAwesomeIcon icon={["far", "lock"]} fixedWidth /> Value is encrypted
                                    </span>
                                    {showDecryptButton && (
                                        <IconWithLabel icon="eye-visibility-empty" onClick={onShowValue} title="Decrypt Form Data">
                                            see value
                                        </IconWithLabel>
                                    )}
                                </span>
                            )}
                        </>
                    )}
                </p>
            )}
        </div>
    );
};

const isEncrypted = (value: string) => {
    return (value ?? "").trim().startsWith("===");
};

const isMaskedValue = (value: string) => {
    return value.length > 0 && value.split("•").join("") === "";
};
