import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { distinct } from "components/utils/array";
import { entityType } from "components/utils/constants";
import { isEmpty, isEqual, isNil, pickBy, set, sortBy, uniqBy } from "lodash";
import { useEffect, useId, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { AnyAction, Store } from "redux";
import { modalClose, modalOpen } from "store/modal/actions";
import { createResource, deleteResource, getResource, updateResource } from "store/resources/actions";
import { useProgramCatalog, usePrograms, useResource, useUtilities } from "store/resources/useResource";
import FilterCreateModal from "./FilterCreateModal";
import { openConfirmModal } from "components/ui/Modal/utils";
import { FilterGroup, FilterType } from "./FilterExplore/types";
import useFormPages from "components/ui/Workflow/utils/hooks/useFormPages";
import useFormPageFields from "components/ui/Workflow/utils/hooks/useFormPageFields";
import { compareDateWithoutTime } from "components/utils/date";

export const useGetFilters = (forced: boolean = true) => {
    const [filters, isLoadingFilters] = useResource({
        resourceName: "workcenterV2",
        key: "all-filters",
        forced,
    });

    return [filters, isLoadingFilters] as [FilterListItem[] | undefined, boolean];
};

export const useFilterConfig = (filterNumber?: string, forced = true) => {
    const [filterConfig, isLoading] = useResource({
        resourceName: "workcenterFilters",
        resourceId: filterNumber,
        forced,
    });

    const config = useMemo(() => {
        try {
            return filterConfig ? JSON.parse(filterConfig) : undefined;
        } catch (e) {
            return undefined;
        }
    }, [filterConfig]);

    return [config, isLoading] as [FilterConfig | undefined, boolean];
};

export const useGetHistory = () => {
    const [history, isLoadingHistory] = useResource({
        resourceName: "workcenterHistory",
        key: "all-filters34534",
        forced: true,
    });

    return [history, isLoadingHistory] as [HistoryItem[] | undefined, boolean];
};

export const refreshFilters = (dispatch: any) => {
    dispatch(
        // @ts-ignore
        getResource({
            resourceName: "workcenterV2",
            key: "all-filters",
        })
    );
};

export const useUserList = () => {
    return useResource({
        resourceName: "workcenterUserList",
        key: "workcenter-user-list",
    }) as [WorkcenterUser[] | undefined, boolean];
};

export const useUserAssignments = (filterNumber: string) => {
    return useResource({
        resourceName: "workcenterUserAssignments",
        key: filterNumber,
        path: {
            datasourceNumber: filterNumber,
        },
    }) as [UserAssignment[] | undefined, boolean];
};

export const refreshUserAssignments = (filterNumber: string, dispatch: any) => {
    dispatch(
        // @ts-ignore
        getResource({
            resourceName: "workcenterUserAssignments",
            key: filterNumber,
            path: {
                datasourceNumber: filterNumber,
            },
        })
    );
};

export const useStates = () => {
    return useResource({
        resourceName: "states",
        key: "states",
    }) as [data: { state: string; abbreviation: string }[] | undefined, isLoading: boolean];
};

export const favoriteFilter = (filterNumber: string, dispatch: any) => {
    dispatch(
        // @ts-ignore
        updateResource({
            resourceName: "userFavorites",
            query: {
                entityType: entityType.WorkcenterFilter,
                entityId: filterNumber,
            },
            onSuccess: () => {
                refreshFilters(dispatch);
            },
        })
    );
};

export const deleteFilter = (filterNumber: string, filterName: string, dispatch: any, onSuccess?: () => void) => {
    const onConfirm = () => {
        dispatch(
            // @ts-ignore
            deleteResource({
                resourceName: "workcenter",
                resourceId: filterNumber,
                onSuccess: () => {
                    refreshFilters(dispatch);
                    onSuccess?.();
                },
            })
        );
    };

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Filter",
                modalIcon: "delete-trash-empty",
                overlayClassName: "modal-styled",
                className: "delete-filter-confirmation-modal modal-sm",
                footerContentCenter: true,
                content: (
                    <p>
                        Are you sure you want to delete Filter <b>{filterName}</b>?
                    </p>
                ),
                onConfirm,
            },
        })
    );
};

export const confirmFilterCancel = (onConfirm: Function) => {
    openConfirmModal({
        title: "Cancel Filter",
        message: "By canceling, all configuration made will be discarded. Are you sure you want to discard all configurations?",
        onConfirm,
    });
};

export const createFilter = async (
    variables: FilterConfig,
    dispatch: any,
    onSuccess?: (filterNumber: string, filterGroup?: FilterGroup, filterType?: FilterType) => void
) => {
    const close = () => {
        dispatch(modalClose());
    };

    const submit = (filterName: string, filterGroup?: FilterGroup, filterType?: FilterType) => {
        const data: FilterUpdateModel = {
            datasourceName: filterName,
            isDefault: false,
            allowshare: true,
            statusItems: [],
            datasourceConfig: JSON.stringify(sanitizeFilterConfig(variables)),
        };

        dispatch(
            createResource({
                resourceName: "workcenterFilters",
                body: data,
                onSuccess: (action: { data: { number: string } }) => {
                    refreshFilters(dispatch);
                    close();
                    onSuccess?.(action.data.number, filterGroup, filterType);
                },
            })
        );
    };

    dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "New Filter",
                overlayClassName: "modal-styled",
                className: "modal-auto",
                children: <FilterCreateModal onSubmit={submit} onCancel={close} />,
                noFooter: true,
            },
        })
    );
};

export const updateFilter = (filterNumber: string, data: FilterUpdateModel, dispatch: any) => {
    dispatch(
        // @ts-ignore
        updateResource({
            resourceName: "workcenterFilters",
            resourceId: filterNumber,
            body: data,
            onSuccess: () => {
                refreshFilters(dispatch);
            },
        })
    );
};

export const shareFilter = (filterNumber: string, users: string[], dispatch: any) => {
    return new Promise((resolve, reject) => {
        dispatch(
            // @ts-ignore
            updateResource({
                resourceName: "workcenterUserAssignments",
                path: {
                    datasourceNumber: filterNumber,
                },
                body: users,
                onSuccess: () => {
                    refreshUserAssignments(filterNumber, dispatch);
                    resolve(1);
                },
                onError: (error: any) => {
                    reject(error);
                },
            })
        );
    });
};

/**
 * Removed temp properties with prefix "_".
 * @param config - Filter config
 * @returns
 */
export const sanitizeFilterConfig = (config: FilterConfig | FilterConfigForSave) => {
    const removeDeletedItems = (item: FilterVariablesItem | FilterVariablesItemForSave) =>
        !("_deletedAt" in item && !isNil(item._deletedAt));

    const removeTempKeys = <T extends object>(obj: T): Partial<T> => pickBy(obj, (_, key) => !key.startsWith("_"));

    const sanitizeItems = (items: FilterVariablesItem[] | FilterVariablesItemForSave[]) =>
        items.filter(removeDeletedItems).map((item) => removeTempKeys(item) as FilterVariablesItemForSave);

    return config.map((variable) => {
        if (isFormVariable(variable)) {
            return {
                ...(removeTempKeys(variable) as Omit<FilterVariableForFormField, "items">),
                items: sanitizeItems(variable.items),
            };
        } else if (isMeasureVariable(variable)) {
            return {
                ...(removeTempKeys(variable) as Omit<FilterVariableForMeasureAttribute, "items">),
                items: sanitizeItems(variable.items),
            };
        } else {
            return {
                ...(removeTempKeys(variable) as Omit<FilterVariableForProgram, "items">),
                items: sanitizeItems(variable.items),
            };
        }
    });
};

export type WorkcenterUser = {
    userNumber: string;
    userName: string;
};

export type UserAssignment = {
    userName: string;
    userNumber: string;
    assigned: "yes" | "no";
};

export type FilterUpdateModel = {
    datasourceName: string;
    isDefault: boolean;
    allowshare: boolean;
    statusItems: string[];
    datasourceConfig: string;
};

export type FilterListItem = {
    datasourceName: string;
    datasourceNumber: string;
    isDefault: boolean;
    isFavorite: boolean;
    isShared: boolean;
    isSharedTo: boolean;
    sharedBy?: string;
};

export type HistoryItem = {
    filterName: string;
    searchType: string;
    dateString: string;
};

export type FilterTab = "Explore" | "Manage" | "Share";

export type UtilityItem = {
    utilityNumber: string;
    utility: string;
};

export type ProgramItem = {
    programNumber: string;
    program: string;
    utilityNumber: string;
};

export type FilterColumn = "Utility" | "Program" | "Variable";
export type FilterItem = UtilityItem | ProgramItem;

export const VariableTypes = {
    ApplicationDetails: "Application details",
    FormFields: "Form fields",
    MeasureAttribute: "Measure attribute",
    ProjectStatuses: "Project statuses",
} as const;

export const ProjectStatusConditions = {
    StatusIs: "Status is",
    StatusIsNot: "Status is not",
} as const;

export const ApplicationDetailsConditions = {
    EntryPointIs: "Entry point is",
    PrimaryStateIs: "Primary state is",
    PremiseStateIs: "Premise state is",
    SubmissionDateIsAfter: "Submission date is after",
    SubmissionDateIsBefore: "Submission date is before",
    SubmissionDateIsBetween: "Submission date is between",
    LastUpdateIsAfter: "Last update is after",
    LastUpdateIsBefore: "Last update is before",
    LastUpdateIsBetween: "Last update is between",
    EventIs: "Event is",
} as const;

export const FormFieldsConditions = {
    AnswerIs: "Answer is",
    AnswerIsNot: "Answer is not",
    AnswerIsGreaterThan: "Answer is greater than",
    AnswerIsGreaterThanOrEqualTo: "Answer is greater than or equal to",
    AnswerIsLowerThan: "Answer is lower than",
    AnswerIsLowerThanOrEqualTo: "Answer is lower than or equal to",
    AnswerIsBetween: "Answer is between",
    RequirementIs: "Requirement is",
} as const;

export const MeasureAttributeConditions = {
    CategoryIs: "Category is",
    CategoryIsNot: "Category is not",
    MeasureStatusIs: "Measure status is",
    MeasureStatusIsNot: "Measure status is not",
    AttributeIs: "Attribute is",
    AttributeIsNot: "Attribute is not",
    AttributeValueIs: "Attribute Value is",
    AttributeValueIsNot: "Attribute Value is not",
} as const;

export const EntryPoints = {
    Backend: "Backend",
    Portal: "Portal",
} as const;

export const Rules = {
    And: "And",
    Or: "Or",
} as const;

export const MeasureStatuses = {
    Approved: "Approved",
    NotApproved: "Not approved",
} as const;

export const FormFieldRequirements = {
    Yes: "Yes",
    No: "No",
} as const;

export type VariableType = typeof VariableTypes[keyof typeof VariableTypes];
export type ProjectStatusCondition = typeof ProjectStatusConditions[keyof typeof ProjectStatusConditions];
export type ApplicationDetailsCondition = typeof ApplicationDetailsConditions[keyof typeof ApplicationDetailsConditions];
export type FormFieldsCondition = typeof FormFieldsConditions[keyof typeof FormFieldsConditions];
export type MeasureAttributeCondition = typeof MeasureAttributeConditions[keyof typeof MeasureAttributeConditions];
export type VariableCondition = ProjectStatusCondition | ApplicationDetailsCondition | MeasureAttributeCondition | FormFieldsCondition;
export type EntryPoint = typeof EntryPoints[keyof typeof EntryPoints];
export type Rule = typeof Rules[keyof typeof Rules];
export type MeasureStatus = typeof MeasureStatuses[keyof typeof MeasureStatuses];
export type FormFieldRequirement = typeof FormFieldRequirements[keyof typeof FormFieldRequirements];

export type FilterVariableForProgram = {
    type: VariableType;
    utilityNumber: string;
    programNumber: string;
    items: FilterVariablesItem[];
};

export type FilterVariableForMeasureAttribute = {
    type: "Measure attribute";
    utilityNumber: string;
    programNumber: string;
    catalogNumber: string; // selected measure
    items: FilterVariablesItem[];
};

export type FilterVariableForFormField = {
    type: "Form fields";
    utilityNumber: string;
    programNumber: string;
    pageNumber: string; // selected form page
    fieldNumber: string; // selected form field
    fieldType: string; // selected form field type
    items: FilterVariablesItem[];
};

type FilterVariableTempProps = {
    _enabled?: boolean;
};

export type FilterVariable = (FilterVariableForProgram | FilterVariableForMeasureAttribute | FilterVariableForFormField) &
    FilterVariableTempProps;

export type FilterVariablesItem = {
    condition: VariableCondition;
    values: string[]; // array of values for the condition
    rule?: Rule; // "And", "Or" rule for the condition
} & FilterVariablesItemTempProps;

type FilterVariablesItemTempProps = {
    _id: string;
    _enabled?: boolean;
    _deletedAt?: number; // timestamp of deletion
};

export type FilterVariablesItemForSave = Omit<FilterVariablesItem, keyof FilterVariablesItemTempProps>;

export type FilterVariableForSave =
    | (Omit<FilterVariableForProgram, "items"> & {
          items: FilterVariablesItemForSave[];
      })
    | (Omit<FilterVariableForMeasureAttribute, "items"> & {
          items: FilterVariablesItemForSave[];
      })
    | (Omit<FilterVariableForFormField, "items"> & {
          items: FilterVariablesItemForSave[];
      });

export type FilterConfig = FilterVariable[];
export type FilterConfigForSave = FilterVariableForSave[];

export type FilterErrors = string[][];

export const getVariableIcon = (variableType: VariableType): IconProp => {
    switch (variableType) {
        case VariableTypes.ApplicationDetails:
            return ["fas", "file-circle-info"];
        case VariableTypes.FormFields:
            return ["far", "message-lines"];
        case VariableTypes.MeasureAttribute:
            return ["fas", "book"];
        case VariableTypes.ProjectStatuses:
            return ["fas", "wave-pulse"];
        default:
            return ["fas", "terminal"];
    }
};

export const useVariableDescription = (variable: FilterVariable) => {
    const { formName, fieldName } = useFieldVariableInfo(variable as FilterVariableForFormField);

    const [catalog] = useProgramCatalog({
        programNumber: variable.programNumber,
        catalogNumber: "catalogNumber" in variable ? variable.catalogNumber : undefined,
    });

    const itemCount = variable.items.filter((i) => i._enabled).length;
    const itemsLabel = itemCount === 1 ? `${itemCount} item` : `${itemCount} items`;
    const emptyValue = <>&nbsp;</>; // empty value with preserving the line height

    const getFormFieldDescription = () => {
        if ("fieldNumber" in variable && isNil(variable.fieldNumber)) {
            return itemsLabel;
        }

        return formName && fieldName ? `${formName.trim()}・${fieldName.trim()}` : emptyValue;
    };

    const getMeasureFieldDescription = () => {
        if ("catalogNumber" in variable && isNil(variable.catalogNumber)) {
            return itemsLabel;
        }

        return catalog?.name ? `${catalog?.name}` : emptyValue;
    };

    switch (variable.type) {
        case VariableTypes.ApplicationDetails:
            return itemsLabel;
        case VariableTypes.FormFields:
            return getFormFieldDescription();
        case VariableTypes.MeasureAttribute:
            return getMeasureFieldDescription();
        case VariableTypes.ProjectStatuses:
            return itemsLabel;
        default:
            return emptyValue;
    }
};

export const useFieldVariableInfo = (variable?: FilterVariable) => {
    let programNumber: string | undefined = undefined;
    let pageNumber: string | undefined = undefined;
    let fieldNumber: string | undefined = undefined;

    if (variable && isFormVariable(variable)) {
        programNumber = variable.programNumber;
        pageNumber = variable.pageNumber;
        fieldNumber = variable.fieldNumber;
    }

    const [forms, isLoadingForms] = useFormPages({ entityId: programNumber });
    const [fields, isLoadingFields] = useFormPageFields({
        entityId: programNumber,
        pageNumber: pageNumber,
    });

    const isLoading = isLoadingForms || isLoadingFields;

    const formName = useMemo(() => forms?.find((form) => form.number === pageNumber)?.name, [forms, pageNumber]);

    const fieldName = useMemo(() => fields?.find((field) => field.fieldId === fieldNumber)?.friendlyName, [fields, fieldNumber]);

    return { formName, fieldName, isLoading };
};

export const getVariableConditionsDropdownList = (variableType: VariableType) => {
    let conditions;

    switch (variableType) {
        case VariableTypes.ApplicationDetails:
            conditions = ApplicationDetailsConditions;
            break;
        case VariableTypes.FormFields:
            conditions = FormFieldsConditions;
            break;
        case VariableTypes.MeasureAttribute:
            conditions = MeasureAttributeConditions;
            break;
        case VariableTypes.ProjectStatuses:
            conditions = ProjectStatusConditions;
            break;
        default:
            conditions = {};
            break;
    }

    return Object.values(conditions).map((c) => ({
        label: c,
        value: c,
    }));
};

export const useProgramName = (utilityNumber: string, programNumber: string) => {
    const [programs] = usePrograms({ utilityNumber });

    return programs?.find((program) => program.programNumber === programNumber)?.program;
};

export const useFilterUtilities = (filterConfig?: FilterConfig): [UtilityItem[], boolean] => {
    const [allUtilities, isLoading] = useUtilities({ forced: false });

    const utilities = useMemo(
        () =>
            uniqBy(
                (filterConfig ?? [])
                    .map((item) => ({
                        utilityNumber: item.utilityNumber,
                        utility: allUtilities?.find((utility) => utility.utilityNumber === item.utilityNumber)?.utility ?? "",
                    }))
                    .filter(distinct),
                "utilityNumber"
            ),
        [filterConfig, allUtilities]
    );

    return [utilities, isLoading];
};

export const useFilterPrograms = (filterConfig?: FilterConfig): [ProgramItem[], boolean] => {
    const store = useStore();
    const [programs, setPrograms] = useState<ProgramItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (filterConfig) {
            setIsLoading(true);
            getFilterPrograms(filterConfig, store)
                .then((programs) => {
                    setPrograms(programs);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [filterConfig, store]);

    return [programs, isLoading];
};

const getFilterPrograms = async (filterConfig: FilterConfig, store: Store<any, AnyAction>) => {
    const getProgramName = async (programNumber: string, utilityNumber: string) => {
        // check in store.
        const programsFromStore = store.getState().resources.programs.itemsById[utilityNumber] as
            | { programList: { programNumber: string; program: string }[] }
            | undefined;

        if (!isEmpty(programsFromStore?.programList)) {
            return programsFromStore?.programList.find((p) => p.programNumber === programNumber)?.program ?? "";
        }

        type ProgramsResponse = { programList: { programNumber: string; program: string; isActive: boolean }[] };

        // check in API.
        try {
            const programsFromAPI = await new Promise<ProgramsResponse>((resolve, reject) => {
                store.dispatch(
                    // @ts-ignore
                    getResource({
                        resourceName: "programs",
                        key: utilityNumber,
                        query: {
                            utilityNumber,
                        },
                        onSuccess: (action: { data: ProgramsResponse }) => resolve(action.data),
                        onError: (action: any) => reject(action),
                    })
                );
            });

            return programsFromAPI?.programList?.find((p) => p.programNumber === programNumber)?.program ?? "";
        } catch (error) {
            console.log(error);
        }

        return "";
    };

    const programs = uniqBy(
        (filterConfig ?? [])
            .map((item) => ({
                programNumber: item.programNumber,
                utilityNumber: item.utilityNumber,
                program: "",
            }))
            .filter(distinct),
        "programNumber"
    );

    for (const program of programs) {
        program.program = await getProgramName(program.programNumber, program.utilityNumber);
    }

    return programs;
};

export const useFilterVariables = (filterConfig?: FilterConfig | FilterConfigForSave) => {
    const newId = useId();

    return useMemo(
        () =>
            filterConfig?.map((v) => ({
                ...v,
                items: v.items.map((i, index) => ({
                    ...i,
                    _id: newId + index,
                })),
            })),
        [filterConfig, newId]
    );
};

export const validateConfig = (config: FilterConfig) => {
    const errors: FilterErrors = [];

    config.forEach((variable, variableIndex) => {
        if (variable.items.length === 0) {
            set(errors, `[${variableIndex}]`, "Select a condition to continue");
        }

        if (isFormVariable(variable) && (!variable.fieldNumber || !variable.pageNumber)) {
            set(errors, `[${variableIndex}]`, "Select a form field to continue");
        }

        if (isMeasureVariable(variable) && !variable.catalogNumber) {
            set(errors, `[${variableIndex}]`, "Select a catalog to continue");
        }

        variable.items.forEach((item, itemIndex) => {
            if (
                (
                    [
                        ApplicationDetailsConditions.PrimaryStateIs,
                        ApplicationDetailsConditions.PremiseStateIs,
                        ApplicationDetailsConditions.EventIs,
                        ProjectStatusConditions.StatusIs,
                        ProjectStatusConditions.StatusIsNot,
                        MeasureAttributeConditions.CategoryIs,
                        MeasureAttributeConditions.CategoryIsNot,
                        MeasureAttributeConditions.AttributeIs,
                        MeasureAttributeConditions.AttributeIsNot,
                    ] as string[]
                ).includes(item.condition)
            ) {
                if (isEmpty(item.values)) {
                    set(errors, `[${variableIndex}][${itemIndex}]`, "Select one to continue");
                }
            } else if (
                (
                    [
                        MeasureAttributeConditions.AttributeValueIs,
                        MeasureAttributeConditions.AttributeValueIsNot,
                        FormFieldsConditions.AnswerIs,
                        FormFieldsConditions.AnswerIsNot,
                        FormFieldsConditions.AnswerIsGreaterThan,
                        FormFieldsConditions.AnswerIsGreaterThanOrEqualTo,
                        FormFieldsConditions.AnswerIsLowerThan,
                        FormFieldsConditions.AnswerIsLowerThanOrEqualTo,
                    ] as string[]
                ).includes(item.condition)
            ) {
                if (isEmpty(item.values)) {
                    set(errors, `[${variableIndex}][${itemIndex}]`, "Add a value to continue");
                }
            } else if (item.condition === FormFieldsConditions.AnswerIsBetween) {
                if (item.values.length !== 2 || isEmpty(item.values[0]) || isEmpty(item.values[1])) {
                    set(errors, `[${variableIndex}][${itemIndex}]`, "Enter values to continue");
                }
            } else if (
                (
                    [
                        ApplicationDetailsConditions.LastUpdateIsBefore,
                        ApplicationDetailsConditions.LastUpdateIsAfter,
                        ApplicationDetailsConditions.SubmissionDateIsBefore,
                        ApplicationDetailsConditions.SubmissionDateIsAfter,
                    ] as string[]
                ).includes(item.condition)
            ) {
                if (isEmpty(item.values)) {
                    set(errors, `[${variableIndex}][${itemIndex}]`, "Select a date to continue");
                }
            } else if (
                (
                    [ApplicationDetailsConditions.LastUpdateIsBetween, ApplicationDetailsConditions.SubmissionDateIsBetween] as string[]
                ).includes(item.condition)
            ) {
                if (isEmpty(item.values[0]) || isEmpty(item.values[1])) {
                    set(errors, `[${variableIndex}][${itemIndex}]`, "Select date range to continue");
                } else if (compareDateWithoutTime(item.values[0], item.values[1]) > 0) {
                    set(errors, `[${variableIndex}][${itemIndex}]`, "End date can not be before start date");
                }
            }
        });
    });

    return errors;
};

export const isConfigChanged = (config: FilterConfig | undefined, initialConfig: FilterConfig | undefined) => {
    if (!config || !initialConfig) {
        return false;
    }

    if (config.length !== initialConfig.length) {
        return true;
    }

    // Check if any variable or condition "enabled" state is different
    if (
        config.some(
            (v, valueIndex) =>
                v._enabled !== initialConfig[valueIndex]._enabled ||
                v.items.some((i, itemIndex) => i._enabled !== initialConfig[valueIndex].items[itemIndex]._enabled)
        )
    ) {
        return true;
    }

    // Check if any condition values are different
    if (
        config.some((v, valueIndex) =>
            v.items.some((i, itemIndex) => !isEqual(sortBy(i.values), sortBy(initialConfig[valueIndex].items[itemIndex].values)))
        )
    ) {
        return true;
    }

    return false;
};

export const isMeasureVariable = (variable: FilterVariable | FilterVariableForSave) => {
    return "catalogNumber" in variable && variable.type === VariableTypes.MeasureAttribute;
};

export const isFormVariable = (variable: FilterVariable | FilterVariableForSave) => {
    return "pageNumber" in variable && variable.type === VariableTypes.FormFields;
};

export const getConditionIcon = (variableType: VariableType, condition: VariableCondition) => {
    switch (variableType) {
        case VariableTypes.ApplicationDetails:
            return getApplicationDetailsConditionIcon(condition);
        case VariableTypes.FormFields:
            return getFormFieldsConditionIcon(condition);
        case VariableTypes.MeasureAttribute:
            return getMeasureAttributeConditionIcon(condition);
        case VariableTypes.ProjectStatuses:
            return getProjectStatusesConditionIcon(condition);
        default:
            return null;
    }
};

export const getConditionInitialValues = (condition: VariableCondition) => {
    const values: string[] = [];
    if (condition === ApplicationDetailsConditions.EntryPointIs) {
        values.push(EntryPoints.Backend);
    } else if (condition === MeasureAttributeConditions.MeasureStatusIs || condition === MeasureAttributeConditions.MeasureStatusIsNot) {
        values.push(MeasureStatuses.Approved);
    } else if (condition === FormFieldsConditions.RequirementIs) {
        values.push(FormFieldRequirements.Yes);
    }

    return values;
};

const getApplicationDetailsConditionIcon = (condition: VariableCondition): IconProp | null => {
    switch (condition) {
        case ApplicationDetailsConditions.EntryPointIs:
            return ["far", "arrow-up-to-arc"];
        case ApplicationDetailsConditions.PrimaryStateIs:
            return ["far", "location-dot"];
        case ApplicationDetailsConditions.PremiseStateIs:
            return ["far", "location-dot"];
        case ApplicationDetailsConditions.SubmissionDateIsBetween:
            return ["fal", "calendar-days"];
        case ApplicationDetailsConditions.SubmissionDateIsAfter:
            return ["fal", "calendar-days"];
        case ApplicationDetailsConditions.SubmissionDateIsBefore:
            return ["fal", "calendar-days"];
        case ApplicationDetailsConditions.LastUpdateIsBetween:
            return ["fal", "calendar-days"];
        case ApplicationDetailsConditions.LastUpdateIsAfter:
            return ["fal", "calendar-days"];
        case ApplicationDetailsConditions.LastUpdateIsBefore:
            return ["fal", "calendar-days"];
        case ApplicationDetailsConditions.EventIs:
            return ["far", "layer-group"];
        default:
            return null;
    }
};

const getFormFieldsConditionIcon = (condition: VariableCondition): IconProp | null => {
    switch (condition) {
        case FormFieldsConditions.AnswerIs:
            return ["far", "message-lines"];
        case FormFieldsConditions.AnswerIsNot:
            return ["far", "message-lines"];
        case FormFieldsConditions.AnswerIsGreaterThan:
            return ["far", "message-lines"];
        case FormFieldsConditions.AnswerIsGreaterThanOrEqualTo:
            return ["far", "message-lines"];
        case FormFieldsConditions.AnswerIsLowerThan:
            return ["far", "message-lines"];
        case FormFieldsConditions.AnswerIsLowerThanOrEqualTo:
            return ["far", "message-lines"];
        case FormFieldsConditions.AnswerIsBetween:
            return ["far", "message-lines"];
        case FormFieldsConditions.RequirementIs:
            return ["far", "message-lines"];
        default:
            return null;
    }
};

const getMeasureAttributeConditionIcon = (condition: VariableCondition): IconProp | null => {
    switch (condition) {
        case MeasureAttributeConditions.CategoryIs:
            return ["fas", "book"];
        case MeasureAttributeConditions.CategoryIsNot:
            return ["fas", "book"];
        case MeasureAttributeConditions.MeasureStatusIs:
            return ["fas", "book"];
        case MeasureAttributeConditions.MeasureStatusIsNot:
            return ["fas", "book"];
        case MeasureAttributeConditions.AttributeIs:
            return ["fas", "book"];
        case MeasureAttributeConditions.AttributeIsNot:
            return ["fas", "book"];
        case MeasureAttributeConditions.AttributeValueIs:
            return ["fas", "book"];
        case MeasureAttributeConditions.AttributeValueIsNot:
            return ["fas", "book"];
        default:
            return null;
    }
};

const getProjectStatusesConditionIcon = (condition: VariableCondition): IconProp | null => {
    switch (condition) {
        case ProjectStatusConditions.StatusIs:
            return ["far", "wave-pulse"];
        case ProjectStatusConditions.StatusIsNot:
            return ["far", "wave-pulse"];
        default:
            return null;
    }
};
