import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterVariable, getVariableIcon, useVariableDescription } from "../../utils";
import cn from "classnames";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/Popover";

export const VariablesListItem: React.FC<{
    variable: FilterVariable;
    isActive: boolean;
    onClick: () => void;
    onToggle: () => void;
}> = ({ variable, isActive, onClick, onToggle }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const icon = variable._enabled ? getVariableIcon(variable.type) : "eye-slash";
    const description = useVariableDescription(variable);

    const menuIcon = variable._enabled ? "eye-slash" : "eye";
    const menuLabel = variable._enabled ? "Disable all" : "Enable all";

    const onToggleVariable = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onToggle();
        setMenuOpen(false);
    };

    return (
        <li className="px-1">
            <div
                className={cn(
                    "border-0 rounded cursor-pointer group focus-within:bg-blue flex-row align-center pe-3 py-2 w-full overflow-hidden",
                    {
                        "bg-blue item-active": isActive || menuOpen,
                        "bg-transparent hover:bg-blue focus:bg-blue active:bg-blue": !isActive,
                    }
                )}
            >
                <button
                    className={cn("flex-row align-center flex-grow w-full hover:bg-blue focus:bg-blue active:bg-blue", {
                        truncate: menuOpen,
                        "group-hover:truncate group-focus-within:truncate group-[item-active]:truncate": !menuOpen,
                    })}
                    onClick={onClick}
                >
                    <div className={cn("px-1", { invisible: !isActive })}>
                        <div className="bg-primary" style={{ width: 3, height: 16, borderRadius: 1 }}></div>
                    </div>
                    <div
                        className={cn("rounded flex-column align-center justify-center no-shrink", {
                            "bg-theme-middle": variable._enabled,
                            "bg-grey-600": !variable._enabled,
                        })}
                        style={{ width: "24px", height: "24px" }}
                    >
                        <FontAwesomeIcon icon={icon} color="white" fixedWidth size="sm" />
                    </div>
                    <div className="flex-column gap-1 truncate ps-2 text-start">
                        <span className="fs-14 fw-400 text-theme-dark">{variable.type}</span>
                        <span className="text-secondary truncate">{description ?? <>&nbsp;</>}</span>
                    </div>
                </button>
                <Popover open={menuOpen} onOpenChange={setMenuOpen}>
                    <PopoverTrigger asChild>
                        <button
                            aria-label="Options"
                            className={cn("rounded hover:bg-grey-400 focus:bg-grey-400 active:bg-grey-400 data-[state=open]:bg-grey-400", {
                                "hidden hover:block active:block focus:block group-focus-within:block group-hover:block group-[item-active]:block data-[state=open]:block":
                                    !menuOpen,
                            })}
                        >
                            <FontAwesomeIcon className="py-2 px-1" icon={["fal", "ellipsis"]} fixedWidth size="lg" />
                        </button>
                    </PopoverTrigger>
                    <PopoverContent align="start" asChild hideWhenDetached>
                        <ul className="bg-white w-40 rounded border">
                            <li>
                                <button
                                    onClick={onToggleVariable}
                                    className="flex-row align-center justify-start gap-2 p-4 w-full h-full fs-16 fw-400 hover:bg-grey-200 focus:bg-grey-200 active:bg-grey-200"
                                >
                                    <FontAwesomeIcon icon={["fal", menuIcon]} fixedWidth size="sm" />
                                    <span>{menuLabel}</span>
                                </button>
                            </li>
                        </ul>
                    </PopoverContent>
                </Popover>
            </div>
        </li>
    );
};
