import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";

import { getData } from "../../../../../../store/dataGrid/actions";
import { listToAnyOf, submitResource, submitByRef } from "../../../../../utils/form";
import { useResource, useProgramFormPages } from "../../../../../../store/resources/useResource";
import { programsContentGridColumnKeys, programsContentRevisionsGridColumnKeys } from "../../../../../views/configureGrids";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import Button from "../../../../Button";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { deepEquals } from "react-jsonschema-form/lib/utils";
import { isChildProgram } from "components/views/ProgramView/utils";

const ContentForm = memo(({ dataItem, programNumber, formNumber, revisionNumber, gridId, onClose, sidePanel }) => {
    const dispatch = useDispatch();

    const isNew = dataItem == null;
    const contentNumber = isNew
        ? undefined
        : revisionNumber
        ? dataItem[programsContentRevisionsGridColumnKeys.contentNumber]
        : dataItem[programsContentGridColumnKeys.contentNumber];

    const [contentTypes = []] = useReference(referenceTypes.contentType);
    const contentTypeList =
        (contentTypes &&
            contentTypes.map((i) => ({
                title: i.display,
                enum: [i.val],
            }))) ||
        [];

    const [formPages = []] = useProgramFormPages({
        programNumber,
        formNumber,
    });

    const formPageList = listToAnyOf({
        list: formPages,
        map: (page) => ({ title: page.name, enum: [page.number] }),
    });

    const [isSubmitting, setSubmitting] = useState(false);

    const formRef = useRef();

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const [contentData, isLoadingContentData] = useResource({
        resourceName: "programContent",
        resourceId: contentNumber,
        path: {
            programNumber,
        },
        forced: true,
    });
    const [initialValues, setInitialValues] = useState();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    const submitText = isSubmitting ? "Saving..." : "Save";

    useEffect(() => {
        if (!isLoadingContentData) {
            let temp = { ...contentData };

            if (contentData && contentData.itemList) {
                const activeItem = revisionNumber
                    ? contentData.itemList.find((i) => i.itemNumber === revisionNumber)
                    : contentData.itemList.find((i) => i.itemNumber === dataItem[programsContentGridColumnKeys.itemNumber]);

                if (activeItem) {
                    temp.content = activeItem.content;
                    temp.extId = isNil(activeItem.extId) ? undefined : activeItem.extId;
                }

                delete temp.itemList;
            }

            temp.menuTitle = temp.menuTitle || undefined;
            temp.menuOrder = temp.menuOrder || undefined;

            setInitialValues(temp);
        }
    }, [contentData, isLoadingContentData, revisionNumber, dataItem]);

    const schema = {
        type: "object",
        required: ["contentType", "title", "friendlyTitle", "menu"],
        properties: {
            contentType: {
                type: "string",
                title: "Type",
                anyOf: contentTypeList,
            },
            title: {
                type: "string",
                title: "Title",
            },
            friendlyTitle: {
                type: "string",
                title: "Friendly Title",
            },
            menu: {
                type: "string",
                title: "Menu",
                anyOf: [
                    {
                        title: "Yes",
                        enum: ["Y"],
                    },
                    {
                        title: "No",
                        enum: ["N"],
                    },
                ],
            },
            menuTitle: {
                type: "string",
                title: "Menu Title",
            },
            menuOrder: {
                type: "integer",
                title: "Menu Order",
            },
        },
        dependencies: {
            menu: {
                oneOf: [
                    {
                        properties: {
                            menu: {
                                enum: ["Y"],
                            },
                        },
                        required: ["menuTitle", "menuOrder"],
                    },
                    {
                        properties: {
                            menu: {
                                enum: ["N"],
                            },
                        },
                    },
                ],
            },
            contentType: {
                oneOf: [
                    {
                        properties: {
                            contentType: {
                                enum: ["78"],
                            },
                        },
                    },
                    {
                        properties: {
                            contentType: {
                                enum: ["142", "66"],
                            },
                            title: {
                                title: "URL",
                            },
                        },
                    },
                    {
                        properties: {
                            contentType: {
                                enum: ["373", "76", "228", "748", "64", "65", "485", "427"],
                            },
                            content: {
                                type: "string",
                                title: "Content",
                            },
                        },
                    },
                    {
                        properties: {
                            contentType: {
                                enum: ["548"],
                            },
                            extId: {
                                type: "string",
                                title: "Additional Detail",
                            },
                            content: {
                                title: "Content",
                                type: "string",
                            },
                        },
                    },
                    {
                        properties: {
                            contentType: {
                                enum: ["77"],
                            },
                            extId: {
                                type: "string",
                                title: "Application Form",
                                anyOf: formPageList,
                            },
                        },
                    },
                    {
                        properties: {
                            contentType: {
                                enum: ["742", "126", "484"],
                            },
                            extId: {
                                type: "string",
                                title: "Application Form",
                                anyOf: formPageList,
                            },
                            content: {
                                title: "Content",
                                type: "string",
                                default: "",
                            },
                        },
                    },
                ],
            },
        },
    };

    const [uiSchema, setUiSchema] = useState({
        classNames: "inline-form form-columns-2",
        contentType: {
            classNames: "program-content-dropdown",
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
        menu: {
            classNames: "program-content-dropdown",
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
        content: {
            classNames: "fill-width",
            "ui:widget": "HtmlEditorWidget",
        },
    });

    const onSubmit = useCallback(
        (formData) => {
            const resourceParams = {
                resourceName: "programContent",
                path: {
                    programNumber,
                },
            };

            const body = {
                ...formData,
                status: isNew ? "active" : "pending",
            };

            submitResource({
                resourceParams,
                resourceId: contentNumber,
                body,
                onRefresh: () => dispatch(getData({ dataGridId: gridId })),
                onSuccess: sidePanel ? sidePanel.close : onClose,
                setSubmitting,
            });
        },
        [programNumber, contentNumber, isNew, gridId, onClose, sidePanel, dispatch]
    );

    const onChange = useCallback(
        (e) => {
            if (e && e.formData && ["77", "742", "126", "484"].includes(e.formData.contentType)) {
                let extId = {
                    classNames: "program-content-dropdown",
                    "ui:options": {
                        placeholder: "-- SELECT --",
                    },
                };

                setUiSchema({ ...uiSchema, extId });
            } else {
                let uiSchemaTemp = { ...uiSchema };
                delete uiSchemaTemp.extId;

                setUiSchema(uiSchemaTemp);
            }

            if (e.formData.contentType) {
                setIsSaveDisabled(deepEquals(initialValues, e.formData));
            }
        },
        [initialValues, uiSchema]
    );

    useEffect(() => {
        if (sidePanel) {
            sidePanel.setForm(formRef);
        }
    }, [sidePanel]);

    const isLocked = isChildProgram({ programNumber });

    return (
        <SideNavContent>
            <SideNavHeader
                title={isNew ? "Add New Content" : isLocked ? "View Content" : "Edit Content"}
                leadBlockIcon={isNew ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty"}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody className="flex-one-in-column">
                {isLoadingContentData && <WaitIcon />}
                {!isLoadingContentData && (
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        submitText={submitText}
                        disabled={isSubmitting || isLocked}
                        onChange={onChange}
                        noActions
                    />
                )}
            </SideNavBody>
            {!isLoadingContentData && (
                <SideNavFooter justifyEnd={isLocked} justifyCenter={!isLocked}>
                    {!isLocked && (
                        <Button primary onClick={handleSave} disabled={isSaveDisabled || isSubmitting}>
                            {submitText}
                        </Button>
                    )}
                    <Button onClick={onClose}>{isLocked ? "Close" : "Cancel"}</Button>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
});

export default ContentForm;
