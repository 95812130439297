import { useResource } from "store/resources/useResource";
import useProgramFormNumber from "./useProgramFormNumber";

/**
 * Get list of form pages for a program.
 *
 * @param {object} params
 * @param {string} [params.entityId] - programNumber
 * @returns {[data: { number: string, name: string }[] | undefined, isLoading: boolean]} - form pages and loading state.
 */
const useFormPages = ({ entityId }) => {
    const [formNumber, isLoadingForm] = useProgramFormNumber({ entityId });
    const key = formNumber ? `${entityId}-${formNumber}` : undefined;

    const [pages = [], isLoadingPages] = useResource({
        resourceName: "programFormPages",
        key,
        path: {
            programNumber: entityId,
            formNumber,
        },
        forced: true,
        transform: (data) => {
            return data ?? [];
        },
    });

    return [pages, isLoadingForm || isLoadingPages];
};

export default useFormPages;
