export const availableGrids = {
    utilities: "44DCF57F-73D8-4050-B114-D2A79F2E14A3",
    utilitiesAttributes: "55ABA081-E4E2-4C1A-B6A4-1EE2ACCEADE2",
    utilitiesCalculations: "BF29AA0F-3DC8-4834-AC1A-4AFD46ED4A16",
    utilitiesCalculationsRevisions: "A69A22C6-EB6A-4D21-A0F2-51BE27886180",
    utilitiesCatalogCalculationAssociations: "77B4DDEB-45C9-4FF0-9980-FF197A85B722",
    utilitiesEventCalculationAssociations: "A07C5E7A-D122-4514-B416-2D02E34E4629",
    utilitiesCampaigns: "86423432-F186-495D-BCFD-B1E5A8AF05EE",
    utilitiesContracts: "338EBDC2-AA83-49AC-BCE6-34E1607575CA",
    utilitiesFastTags: "71E4D26F-EC29-45EE-8429-2F3458D243D7",
    utilitiesFiles: "807B5A7A-3642-4708-8C93-3FC7350CD0EB",
    utilitiesHostnames: "373FBF5E-F23F-45E4-B238-0CEDCFFC0DC2",

    revisions: "303E1585-A387-43D1-905D-037D62B80A1C",
    programs: "A50277C1-CDC4-424A-B74B-146BB383BAED",
    programBookmarks: "7809C6C1-1C8F-4331-A918-3672016D68E6",
    programsApplications: "ED045AD4-7AE6-46D2-B521-F0E64F9D6311",
    programsApprovedMeasures: "57797ECD-D132-4735-99ED-A15EBE056DE8",
    programsAttributes: "C355D1FC-5664-496D-A6BF-AF29C038349A",
    programsAudits: "BF6BF0F1-6BDF-4003-A52A-FF9DF334289B",
    programsBudgetLines: "26207A5B-8948-44F6-8C33-43FBEE28EC59",
    programsCalculations: "D8CF73CD-D9F8-4003-8092-345CACA02FB5",
    programsCalculationsRevisions: "DFB6B841-7F45-4103-935A-71404FD7C503",
    programsCatalog: "CC2BDEAE-1825-4D6E-8189-09A4778C915C",
    programsContent: "9EE47A51-27EF-4136-AA58-9C464FD3FA1B",
    programsContentRevisions: "520237B6-67A5-4153-B8BB-18AE001E4076",
    programsDocuments: "99BCE6ED-67E7-42EA-960F-91E4B47EAF5C",
    programsDocumentsRevisions: "80B0D98E-6A21-448B-A258-B31D9C7478E7",
    programsCorrespondenceWorkflowGrid: "f498f0b9-4de1-428c-9b5e-1d5d58f730cf",
    programsEvents: "CDD3EE9C-A8CB-4BEE-AADE-A9F16FEA8472",
    programsFastTags: "71E4D26F-EC29-45EE-8429-2F3458D243D7",
    programsFiles: "FCB21226-54A2-4913-9922-52DD23C273D4",
    programsForms: "E9361032-57AB-4708-945B-A9DA392FFBC3",
    programsFormsPages: "95C4C6CE-375C-4903-AEE7-1C63A4CF98B0",
    programsFormsHistory: "5CB5B290-1212-4D1F-BE3B-AB0AAC1A4230",
    programsGoals: "1D6AF1C4-CE82-4B18-A678-3701490C8CCB",
    programsRebates: "223DCD2D-8DF1-42B9-8220-236B86A91057",
    programsWorkflowAudit: "5B3FB7EE-3A6E-49DC-8F71-6430DAC2F445",
    programsWorkflowItems: "701AF7DE-9BAA-4050-A52A-962983A181F7",
    programsDashboardCriteria: "483910DE-0000-43BF-8F15-D422DAADE45A",

    applicationsSummaryStatus: "D5DD51F0-1FBA-48F1-A50B-9DDE91C6F107",
    applicationsAssigned: "6C4CD24F-4B6E-4D35-AB6F-C6219B3DB0F1",
    applicationsBookmarks: "9739A6CF-3660-44E8-B9B3-95873A3D325D",
    applicationsRecentApplications: "81FF7797-F0C7-4FFC-9869-3AF880EB2519",

    locationApplications: "2090610F-76CA-4670-94F9-A2C16D74CFEC",
    locationEquipment: "B6892114-6A8B-41FA-9DBA-99A450A785CC",
    locationPortals: "A3A80027-312C-4514-8208-9772B8DDD198",
    locationParticipation: "03B408C4-8E2D-4CE9-BA8C-70CC85640E86",

    contractorApplications: "dd157596-7ee1-491a-8af8-c311495aa1e6",
    contractorParticipation: "d89b35e4-be10-4c53-bf78-3c73f8372769",
    contractorEquipment: "fb797cc7-8323-4651-8892-38bc5b609124",

    audit: "3FE1B77E-0EB9-491B-9921-7160B052B6C8",
    auditFastTags: "0EF9E8AB-A4C9-471E-A654-8ACC73F79DBA",
    events: "DA02112F-9613-4C66-95B6-CFDD250447B1",
    contacts: "623CDB26-BE54-4A7A-95B7-77BE8245D007",
    documents: "1B0FD498-53A1-495E-A380-5AE98BE3A83F",
    equipment: "649C3527-C1C7-4CF1-A923-7B8E0CDC9F5F",
    files: "1CC9D0E3-38D1-4D78-8F00-2D7AFC04F26C",
    notes: "41B3F26C-4B8A-4575-9225-7A8D8F02D601",
    qualityControl: "B7B49A86-0ABC-4C9D-9734-A6E91413CBBE",
    rebates: "8713DC5C-5A97-45CF-9770-34FDBB87B3C9",
    rebatesEquipment: "2FE7BD25-6E39-44DA-B6C9-352DFECCF40A",
    users: "41305B97-D68F-443E-895F-3BAA77DFC24E",

    qcNotes: "B2F8AAE9-521B-45AB-8B77-346604354E89",

    workqueueTasksMyAssignedGroups: "3942AA66-D3D4-4E72-A1BE-0D574EE156B8",
    workqueueTasks: "8E6ADBE3-8505-4356-BEE5-911F6C7A50BC",
    workqueueTasksAssignedApps: "8D41FFD4-03F8-4D8C-8318-76A26B1591E8",
    workqueueApps: "84CE69FC-30A5-4017-8FB8-795AEB4690CB",
    workqueueAppsStatusChange: "4B2388A4-C678-4A42-ABB4-502F4CE53E11",
    workqueueAppsNotes: "4C5EDFB0-24BF-430F-9EA6-BC1B14CF6286",
    workqueueAppsAssigned: "EDB0F2C5-33A2-4229-B170-09FAAF166CC4",
    workqueueAppsWithTags: "A9E2BAAB-A09C-475B-B180-75807454D980",
    workqueueAppsCountsByStatus: "D0BFE8C1-2408-4343-8341-686882F0BEC3",

    lookupTable: "A854DA5C-49B3-4639-B216-06ABC83F25A6",

    invoiceFiles: "6316ABB5-ABDE-428F-A095-29A789F347A8",
    invoiceCorrespondence: "1B0FD498-53A1-495E-A380-5AE98BE3A87F",
    invoiceDocumentAudit: "E4AC7506-CBF0-489E-B073-9CE405129591",
    invoiceItemAudit: "1B4C70E0-305E-4EEC-B57F-7F654FC979A4",

    contractsAudit: "A99835B5-7328-4648-94A9-41B5DF515672",
    contractVendors: "60196F92-8B5B-45CC-ADC8-D020026C42E8",

    rebateVendors: "68E3C974-78E8-42B2-8D4D-F3AD9C8477E1",

    applicationDocumentQueue: "fdf3fc30-146d-49e7-a784-a77fdb7b2aac",

    logsSystemErrors: "6A8DAF82-A603-48E7-A6BC-E8563255595A",
    logsSystemEntry: "72FACD6B-3C54-4298-B9E3-8CA0C6A3BBBE",
    logsSystemProcessQueue: "C6FAE614-4B4E-40E7-8294-A5461C937FEE",
    logsSystemFTP: "25FD1F38-0E08-4FB9-8326-D6EB080E66E2",
    logsSystemJob: "A6D7946B-6D59-429A-8FAF-B3BD73C4E9DB",
    logsSystemXmlProcess: "409EF360-959D-45B5-8CF5-E5FC21DE0412",
    logsSystemXmlProcessDetails: "6784A078-0F98-4749-81B8-283ED1692664",

    logsSecurityErrors: "27538449-D5E0-40B1-9D82-33CF1E4C0ABA",
    logsSecurityUsersLoggedIn: "0E0C2B36-82C3-4F75-8E6D-297FFB750158",
    logsSecurityCustomersLoggedIn: "2BBBBFD5-2CAF-4BB5-BB1B-B92B41993102",

    eventAttributes: "C5A723E4-A259-4008-8850-7D4EB27A7C2E",
    productAttributes: "F6B1735B-E6AC-4C36-839A-B0273F9DEDFD",
    budgetAttributes: "512f60ae-0a06-42ae-b620-1dfab88de200",
    allCatalogAttributes: "E28C9892-D1BB-4EA2-B958-496A5330A0FC",
    allHostnames: "44B0EF69-5DB1-49C6-90C5-54AFB839973B",
    allProgramAttributes: "6C5E67C4-5D4E-4E93-B437-328B000CE770",

    catalog: "8C511385-30B5-45F4-8C04-4E66372DA04C",

    programsCatalogHistory: "10D3DF0C-B9EB-4A8B-949F-E9474AC93D7D",
    programCatalogAttributeLookups: "9C9A468E-51BB-4E7E-B677-2A54726BA9F3",

    widgetUtilities: "BEFC83A8-217D-4EEC-B4C8-EDB8BA19A473",
    widgetPrograms: "F794F6F0-7A06-46A5-A82A-0066797A72AE",
    widgetApplicationsAssigned: "8FA7AE56-A6C5-461E-888E-4466E415B0B7",
    widgetApplicationsBookmarks: "599F0DFB-7D70-4411-8F9D-3DC044063352",
    widgetApplicationsRecent: "47A0DECF-0F7B-47EF-B967-70079228B1D5",
    widgetContractBookmarks: "27DDCA9B-8D77-4F13-B72A-47810E0B6398",
    widgetInvoiceDocumentBookmarks: "669F741C-7110-45C0-8652-A7FE446D6DDC",

    userSuccessfulLogins: "0D02CC1B-DD51-4492-903A-1F7628CE16D5",
    userPasswordChanges: "AE47200C-AD91-44C8-A186-3092C2917A2E",
    userPasswordResetRequests: "91C3EF64-068E-40CB-AEB3-DFC70980C787",
    userFailedLoginAttempts: "8C9138BC-6D2A-48F4-B484-270636156332",
    userAccountAudit: "2DDCD552-6F6E-4CC3-A900-9B89E7DF89E3",
    fileTransferProtocolConfigs: "C92C5A13-1607-4194-9FAE-6355B4171CD8",
    fileTransferProtocolSchedules: "2694C23C-BA61-47EC-B2F1-42873D5E3C81",

    cisLookup: "74C3CBA4-0FAE-4F4F-8D6D-BE01E9422F40",
    budgetLineAmounts: "9C1ECEA3-AD16-412D-BD8F-74BFF25FBB1D",
    budgetLineInvoices: "CF4FEA6E-36A2-45B1-8CFC-505FD832C040",
    calculationLookups: "476CC54B-2200-4EEE-852C-CE55810F600C",
    contractAttributes: "D8C33077-F552-4ED5-974A-191594513811",
    budgetLineAttributes: "061DE184-1C45-4A48-A6E4-316C514B8ABB",
    budgetLines: "8DBCBA03-2848-44BF-9B3B-659D1C37C50A",
    budgetLookups: "95496810-FF7F-46FD-8DD8-D4D6A8ED4D62",
    invoiceDocuments: "EDC82EBE-D80D-47D9-8A54-298BB7230152",
    invoiceItems: "C416AA0B-EE78-46D0-AB92-261B1E0303B3",
    invoiceNotes: "78057456-56BD-4499-B8B7-F99A234ED2ED",
    programCatalogCategories: "8BBB9844-AB64-412D-B442-CD084092B3FB",
    programEventCategories: "8BB19B87-C635-470B-8EF0-26627334E73D",
    attributeLookups: "AECAED27-DF00-4D8D-BCCD-5825C5D69DE3",
    scanQueue: "17955271-30D4-47AD-AA38-FE8DE201CB03",
    scanQueueUserGroups: "CA196997-2D1A-4137-9A37-9BE2FB174F4A",
    userGroups: "4B2EF5BC-0AAD-4943-93F9-95492F7CA1FA",
    friendlyNames: "63BFD561-DA46-4AF4-8B13-3627111A41AB",
    contractors: "BB48A91E-ECD2-4177-8636-F08CF5A3D1FC",
    customers: "C93EE63D-3008-4659-933C-4B5FE1D8DDAA",
    applicationFiles: "59CB9E58-1FE6-4D88-972D-66E0C7A08BF0",
    analyticsEvents: "109B9A10-78CE-4D77-8B22-6F3BD3016EE1",
    analyticsCampaigns: "4E1A9A4A-0621-4735-BEC1-94C0F15356DF",
    analyticsAccounts: "2B162CA6-6F09-4844-A1A4-8C2399AA8222",

    relatedApplications: "BA3A3DB1-660E-44AA-BDD1-34CFAE0AE9B9",
};

export const analyticsAccountsGridColumnKeys = {
    programNumber: "c_9f67f3db-8445-4975-a95a-6873f60bc522",
    dateCreated: "c_f09d22bd-289c-40e6-92cb-4d3f3c795c06",
};

export const applicationsSummaryStatusGridColumnKeys = {
    utilityNumber: "c_484e7057-3262-46aa-b602-0f21707b0776",
    programNumber: "c_6a6335b4-7bbb-46c5-aae2-22bd715dceca",
    statusName: "c_8714a9f2-8cb1-4273-a747-a49105f0bf8d",
};

export const applicationsAssignedGridColumnKeys = {
    userNumber: "c_8d1a9a87-f537-4419-a025-4dae1ce5ba36",
    applicationNumber: "c_d719f25f-6307-42b4-9f73-98fd57e22a54",
    projectNumber: "c_ba0a74ce-6a12-4672-b665-d7f706f83424",
};

export const applicationsBookmarksGridColumnKeys = {
    userNumber: "c_3966b78c-77c9-4a6a-a376-046a83acebd5",
    applicationNumber: "c_0126fcca-8ecd-4970-915f-01aa9afb67df",
    projectName: "c_aeb19fa5-6074-464b-acf2-8b8c35e0ffa5",
    projectNumber: "c_dc608941-b485-4791-863e-52e991aff72b",
};

export const applicationsRecentApplicationsGridColumnKeys = {
    applicationNumber: "c_93f8bac7-3d2e-42e0-8d0c-38b739d4477c",
    projectName: "c_197ee854-bc06-4e8a-8d68-79c3b10a906b",
    projectNumber: "c_df005557-aeec-44ed-a129-7b1e9e4d6f3d",
    programID: "c_87fb7887-fb94-4d7c-8773-ac94aae694b1",
    programName: "c_2802bbee-45e7-41f9-b6bf-fb60f2a83ba9",
    programNumber: "c_e4a02684-88c5-4fed-b0f7-21437709eaee",
    utilityName: "c_7e30dfbc-c5ff-4387-b35e-1d1cd858ede9",
    utilityNumber: "c_0f727b3d-d5db-4f66-bd40-a542a170bb16",
};

export const auditGridColumnKeys = {
    entityNumber: "c_6cf454c0-3756-4b83-bc91-9257ccf68d09",
    date: "c_91475145-2490-417d-887c-a98f42d9e9e0",
    user: "c_a400915d-bd89-4e53-80b9-c94c837d5ad3",
    action: "c_9bad7c43-3d60-45ec-9d15-77f14e842ca0",
    audittype: "c_778f5615-17d8-4da2-955e-4d2349ca574c",
    newValue: "c_ba75bd8e-ea81-4cdd-96ea-48975cddeb20",
    oldValue: "c_81ee3379-405b-4f9d-9934-b7ed5412584c",
};

export const auditFastTagsGridColumnKeys = {
    auditid: "c_2e1be1d8-cf95-4eda-87e0-be6ae705eece",
    userId: "c_8cd00ff6-28a0-4f4e-a02f-a696c8bcd20a",
    user: "c_2b274cc2-7de8-4897-afe4-368e33f6eab0",
    auditType: "c_7c21b579-af7c-489b-b72d-171f4b701c11",
    id: "c_989fc6dd-40a3-4bb4-be7e-0c891129ae00",
    action: "c_9ed4e789-fd4b-4dd0-805b-563f45ef2679",
    oldValue: "c_54cfd553-63bd-4ce3-b6be-9b6790bce3a4",
    newValue: "c_3d49aceb-2355-4c59-ba6f-4f4a1033cf7d",
    date: "c_ae7e64a9-60ea-4c14-ac04-a42b4e673557",
    fastTagNumber: "c_4eff29a1-ce34-4c38-ad79-22c7b59b438f",
    fastTagName: "c_eb623a3e-788d-435e-ab86-64f6be1e5e70",
};

export const qualityControlGridColumnKeys = {
    id: "c_cc22d918-8a01-42b1-a425-e71398e822f4",
    type: "c_e0384c1d-6f8c-43a6-bc1f-8baaf0958807",
    status: "c_8c291ac3-adf6-4c98-a273-c354fb8ac60e",
    dateEntered: "c_e00ec4fd-fe47-4aae-a75e-137ccec7ab8c",
    entityNumber: "c_d9cb0d01-96b5-41d5-bd49-d6350de2aa61",
    qcNumber: "c_d105c191-079a-4e13-947a-18df1a165549",
    description: "c_e331d754-4704-4bd6-97d0-ba3f50970ed5",
};

export const rebatesGridColumnKeys = {
    applicationNumber: "c_950deeac-b535-40e0-acfa-4c1054336d78",
    rebateid: "c_731452fd-864c-44c8-8242-734e56d2740e",
    payee: "c_663e36a5-44d1-4594-830d-1d04f78f19dc",
    amount: "c_96d3efd0-71e2-410d-9ad6-8ff94f46f5f1",
    status: "c_96d88bfb-b9e1-4320-a4b9-fd679fd90267",
    entered: "c_cdf5d570-8387-42de-8212-b24d562f4939",
    approved: "c_a1a3d88d-e716-4b27-8419-3c0c79ef872f",
    committed: "c_9bc9c8c7-7abc-4962-b66e-46ab02e843ec",
    transferred: "c_365a6642-62e1-4bdb-8c9f-ed2e88f8d65e",
    checkInfo: "c_9620f528-419e-4263-b3e4-440834370d6b",
    invoiceDate: "c_9f0902cb-d1ee-43ff-bbaf-cf77655f6808",
};

export const rebatesEquipmentGridColumnKeys = {
    equipmentId: "c_d96acabd-8860-495a-a2d5-2c14a542cd8d",
    appid: "c_32bb8be4-5ce1-4e65-b9ae-59e5f093922d",
    name: "c_045530d8-b8cf-4903-9070-13ddf4ef11ff",
    quantity: "c_7aacbd7c-00b9-4d55-868f-5b319612271c",
    remainingRebateAvailable: "c_11132905-a8ae-413f-9378-1d16e480e370",
};

export const locationApplicationsGridColumnKeys = {
    applicationNumber: "c_5c41bd37-0e70-4f97-bd7a-daf0418f64a6",
    projectName: "c_d5db02b9-f196-440b-be6a-66e1baefd7c2",
    projectNumber: "c_1ce7e8bb-9aaa-4d08-8824-25e70690aa96",
    address: "c_ea98e27d-3f61-42c1-b445-571fe1ce2168",
    zip: "c_d12de6a2-3e78-4826-a54d-fbb366dd5820",
    utility: "c_dd593c56-1b8d-4e18-a274-ca8db9574c20",
    program: "c_2d15af0a-6ab6-4d7e-9635-66f5dbc5b1cb",
    dateSubmitted: "c_95a8bffb-e803-4084-a72b-305ed2b2c8fd",
    status: "c_84a8f366-a1fe-40e0-a2e8-f8dfc3a71ad5",
    statusDate: "c_8faad61c-a019-41bc-8556-c1aa67e63eaa",
    rebate: "c_68a54dd4-a249-4a39-a5ad-0b91fe8dffb1",
};

export const contractorParticipationGridColumnKeys = {
    contactnumber: "c_f966d1bd-45b5-48f2-bb1b-172c30fe5378",
    program: "c_937075c6-ffbc-4f19-a78c-50ecbe173db8",
    "total apps": "c_9252cfb7-a6c3-4809-8823-704598057fe0",
    "total rebate": "c_f328ba22-b7b6-4498-b515-33a3adc399af",
    programnumber: "c_16e4b3f8-8203-4578-bd2f-fbbe58c6f80f",
};

export const contractorEquipmentGridColumnKeys = {
    contactnumber: "c_03cf1d2b-b1ad-4b54-86fe-6e75dffbd2c5",
    category: "c_6793d2f8-360c-48e1-9be1-52de5b0b340b",
    quantity: "c_49efbbd0-28f5-430b-ab20-cdd17d1138c9",
};

export const contractorApplicationsGridColumnKeys = {
    contactnumber: "c_7fe66219-27f3-4fc5-ad49-f46ec47975d5",
    numericrebate: "c_e54938bc-b009-4166-a1c6-5b7251b3cea8",
    utility: "c_3b1f1c8c-9392-44a7-96e8-7be99a98d99f",
    program: "c_f3b63704-b17e-4368-809f-c96ed97d805b",
    "date submitted": "c_6f8fb65c-57d6-4a6a-88a2-c7068a1b4397",
    status: "c_27818827-c6a8-412a-a8a2-de3ae32c189d",
    "status date": "c_3e3b98cf-beda-42cf-997c-b698ae338c93",
    rebate: "c_758e3391-524b-4ba0-b905-41abe10b322b",
    "date created": "c_8bf29f9d-d6fb-4ea9-99e4-29329e8240f8",
    appid: "c_72e88923-8327-4c8a-927b-96fee2090020",
    "project name": "c_9bdbfaf4-131b-4ae8-99c3-85464ebee639",
    "project number": "c_2599d6a3-ee75-431c-ac61-b8ac2aad3d3d",
};

export const locationEquipmentGridColumnKeys = {
    address: "c_0626cd63-122e-4dfa-8e91-5c0961857094",
    zip: "c_e00c22cb-59b7-4165-a77f-b89e49a95a8f",
    category: "c_1a254f8f-2750-4040-9602-c7e13b75a371",
    quantity: "c_80b4400d-0c56-49b7-b4ee-a4aa63d88403",
};

export const locationPortalsGridColumnKeys = {
    utilityNumber: "c_b85f50d0-1121-43ce-aae7-9e519047401d",
    program: "c_aed5542c-97a8-4467-864e-7733fa7e154b",
    url: "c_1a661c3e-7d3a-4fb8-b490-ba49e0b13ef0",
};

export const locationParticipationGridColumnKeys = {
    programName: "c_6db745b5-bfae-4001-8001-cb8ccfde54a6",
    programNumber: "c_59b0dcc4-3d5f-4f9a-8c05-fd180bc69803",
    address: "c_eff09aa1-2e9d-42dd-8602-d10b09934fe1",
    zip: "c_f17076bb-1b81-468e-8aa0-5036de03e373",
    rebate: "c_209507a3-9890-4657-bb2f-fe4fac1662af",
};

export const utilitiesGridColumnKeys = {
    utilityName: "c_3c0e0034-5425-43a6-bbf3-f22cd87a619b",
    utilityNumber: "c_bc9df3f9-4427-436a-8448-a6c1b85622a4",
    utilityId: "c_8797ea5e-b1b9-4de9-b408-5ced79e24509",
    isFavorite: "c_b1c13ec8-9ee3-409f-9fa1-a75d1244f5cb",
};

export const utilitiesAttributesGridColumnKeys = {
    utilityNumber: "c_d837d7a7-0ba9-4d3b-a1fc-9e54bdc5026d",
    attributeType: "c_860d5266-03f7-45e3-b84a-e5c03db96dbf",
    attributeValue: "c_163dcb2c-017c-4d2a-bce4-b0b8069655a8",
    attributeNumber: "c_32747d96-503e-43a3-a51f-84b7c426d218",
};

export const utilitiesCalculationsGridColumnKeys = {
    utilityNumber: "c_361e0f61-043e-4bad-b10c-e148842b00ec",
    calculationNumber: "c_ab825c55-e846-44f5-8ab6-ed92eb3a9ed2",
    calculationName: "c_87ff9274-ff99-4ec9-8eee-6e996be23f69",
    calculationType: "c_4c67f617-357a-4f4c-8dcf-f238e22c1f9c",
    target: "c_347e9218-7d65-44e2-8333-dd62c75ddff9",
    revisionDate: "c_0e6887e6-2aaa-4a71-b185-af4cf6dd857d",
    userName: "c_0a614a90-de03-417c-95b9-4c142603714a",
};

export const utilitiesCalculationsRevisionsColumnKeys = {
    utilityNumber: "c_45d958b8-5a02-4c04-86b9-77dc925ee8b5",
    calculationNumber: "c_84feb474-d52a-496e-a561-68255f9960fb",
    calculationRevisionNumber: "c_8f0f3ab2-76c3-47bf-ab65-cdb28b466d9e",
    calculationName: "c_fe509d22-b26c-4dc7-9662-29ef69eaf1cf",
    calculationType: "c_ec521ad8-e608-4ec6-8476-ab3442ad44c8",
    target: "?", //TODO: need this column if show readonly details in revisions grid.
    revisionDate: "c_9b952c23-3e5e-4833-a6b0-8e3b9de3f149",
    userName: "c_715f4acc-a676-4651-b1e6-dbf3b51efe02",
};

export const programsCalculationsRevisionsGridColumnKeys = {
    programNumber: "c_f7fce0a5-4ebb-4ae7-a738-fd7d26dfb340",
    calculationNumber: "c_35d834ff-3d55-45a1-9dcf-671e29b67605",
    calculationRevisionNumber: "c_e3864da9-e354-472e-a2c0-e79477f21f36",
    calculationName: "c_38df53d4-5498-46d8-a98f-aa3b8a90635f",
    calculationType: "c_cb6506c8-b02c-47dd-aca6-2e3541b8cb3e",
    target: "c_3e129730-dc0e-417f-a2fc-c9e03881c10d",
    revisionDate: "c_a0e8a5f4-6d9e-44c0-98e4-f2aec93f14e9",
    userName: "c_b4c17d11-99b2-469a-a475-3dd678f9abdc",
};

export const utilitiesCatalogCalculationAssociationsColumnKeys = {
    calculationNumber: "c_2674d941-daea-4835-8c8b-ddb2b7babc97",
    catalogNumber: "c_97f1ab93-72b7-4c4a-bf4e-7d6091d1434e",
    catalogName: "c_84f7128a-311d-4420-85aa-8a6b35bc488e",
};

export const utilitiesEventCalculationAssociationsColumnKeys = {
    calculationNumber: "c_92262ec1-f1fd-45bf-9891-6cf4fd55a009",
    eventNumber: "c_60c74fec-601b-4b7f-83a6-1fe5485bd4c8",
};

export const utilitiesCampaignsGridColumnKeys = {
    utilityNumber: "c_58bd80f9-cf12-417f-bd23-c6240514cb5c",
};

export const utilitiesContractsGridColumnKeys = {
    utilityNumber: "c_2b09caca-3c25-41e9-a318-e18f8c2eb1e4",
};

export const utilitiesFastTagsGridColumnKeys = {
    utilityNumber: "c_0a282a15-8adc-40d6-8a47-5ab6eec8b66c",
    itemId: "c_8c7c7ede-5676-4b67-961b-200648a9f574",
    createdDate: "c_0d750b5b-9231-49f9-bf24-55ee563e61bd",
    tagName: "c_eb623a3e-788d-435e-ab86-64f6be1e5e70",
    tagValue: "c_ed6dc33f-4f37-4986-a15f-eb2a541798e1",
    number: "c_8de0b34b-343d-442c-8190-e6c339d76fcf",
};

export const utilitiesFilesGridColumnKeys = {
    fileId: "c_02b5f7c3-8885-48b3-8969-440dac3839ae",
    utilityNumber: "c_08b5ef00-45d8-4196-a30e-7466f380129b",
    date: "c_bfa6c59b-da66-4ecc-82cb-99e79da26d3a",
    user: "c_d0ed734e-431f-4191-ad9a-9ee53d83dc5e",
    fileType: "c_28ef4233-56d2-40b4-9ae4-d807b5b4973b",
    fileName: "c_ab07be57-ca24-4a6e-b57b-d85926e3968b",
    tag: "c_5b780d43-2575-4dc5-85a1-6bb0b54e4cd3",
};

export const utilitiesHostnamesGridColumnKeys = {
    programId: "c_c4015aed-1a18-4f77-adec-0183db8ac3a9",
    utilityNumber: "c_a08056fb-7535-4501-9b2c-66730fd70e24",
    hostname: "c_ca8a6313-53e8-49fb-9af0-c8febaa19d53",
};

export const programsGridColumnKeys = {
    utilityNumber: "c_bbea44d5-5134-4cf2-83c3-b3ba4b8f4b34",
    programId: "c_59361e82-2b8d-405c-b1e8-318860b73907",
    programName: "c_1ec0fc5f-a9e4-4fd9-9aac-e7909ae9eaf7",
    programNumber: "c_3b4a4498-512f-4b3d-8203-c3f9c3979a11",
    status: "c_38b01673-17c9-4d6a-b63f-37138f04bf6a",
    isFavorite: "c_fcf4782f-cb89-4608-a454-02bafba2d345",
    hostName: "c_bb6a0ac6-f888-463a-a042-cf253e93207e",
};

export const ftpConfigColumnKeys = {
    clientName: "c_caa74589-494e-4cf4-ad2b-6c21a887ae7d",
    serverHostName: "c_3fcfbc3c-0fb8-4ba5-b160-183ca3e20d21",
    localPath: "c_69ec64e7-980a-41a8-b9ae-b22716ce79a1",
    hasSchedule: "c_c7ae9147-ee4c-4520-99c7-9586d0336aa0",
    username: "c_fc616c52-f063-4ca9-b51b-45e0a30f7c5b",
    remotePath: "c_4822061c-b17d-46ac-b309-082f3ff2c1df",
    usesftp: "c_60818bff-c704-4e97-9e57-b61f45a03151",
    winScpPath: "c_f6e38ce0-a888-40c7-a669-6d2b70030017",
    fileExtension: "c_b21b4acc-7fe1-4751-a3d4-1c9d7923a331",
    decryptionNeeded: "c_9ec9d8f5-0cab-42d9-bfe0-df34e1cc68cd",
    isActive: "c_18e58b6e-e585-46d8-9ea2-4ef9835d6d21",
    emailToAddy: "c_4f723845-6e21-496f-a6d3-04cb76a32dfe",
    emailCCAddy: "c_181efc84-4ad3-46d1-b6fc-c692c1904669",
    emailSubject: "c_936903f0-061f-4c0c-b30f-3ecf08eea76c",
    deleteFilePostDL: "c_e3abfcd9-4324-4771-b6b6-009293a966b1",
    filePrefix: "c_e10d6470-c420-45b6-8a80-34f288bf26b2",
    decryptExtension: "c_5344b7c8-0da3-446f-9855-c562960121d9",
    clientDownloadConfigNumber: "c_458f493e-5657-46f2-a23f-869535059b76",
};

export const ftpScheduleColumnKeys = {
    dayOfWeek: "c_aba334fe-898a-4470-9a34-53875c99f081",
    timeOfDay: "c_b7293423-852b-4c29-a62a-9f25905fa637",
    clientDownloadConfigNumber: "c_4a988bde-ec1c-41c5-b9ff-88c418e0e5ad",
    isActive: "c_481c409c-304e-4947-958a-207d9311bd3d",
    clientDownloadConfigScheduleNumber: "c_bd944d33-ea81-45ad-9b68-52f66a75f7d3",
};

export const revisionsGridColumnKeys = {
    portaltemplatenumber: "c_ad1fe90e-b946-46b2-826e-04b79881f67b",
    revisiondate: "c_6cb1ba41-fc75-4386-834f-3b7f23f0db26",
    name: "c_d8b93a7b-26b9-4ad9-a433-8d3b9d1f47db",
    portaltemplaterevisionnumber: "c_43ae7057-5c9c-46e2-86d7-b4a88501f05f",
    entityid: "c_b9a9d3f8-4ade-43b3-bb5a-466a2ed888a2",
    entitytype: "c_bcafdc9c-e731-4408-b8d6-f396b9917a27",
    username: "c_90c10c93-e686-408a-8d34-b5dbc100b1a5",
    originalcreator: "c_6b9965a0-e8be-493b-936d-1f5414871fdc",
};

export const programBookmarksGridColumnKeys = {
    utilityNumber: "c_138251af-6a8e-461f-89ec-3a9d8db0fe3b",
    programId: "c_35257d04-c9a3-4c0a-9334-367418cfd03b",
    programName: "c_b205e0ee-2dc3-4de4-a363-e93a80541dec",
    programNumber: "c_8dedcf55-f80f-4890-b72d-1e8bd133b619",
    status: "c_0cdd6165-809d-4c26-825e-76c142ff5022",
    isFavorite: "c_c84d8892-df93-4402-8e78-592c9352c07b",
};

export const programsApplicationsGridColumnKeys = {
    programNumber: "c_e1bdf89a-0803-4370-acf1-976cd287882a",
    applicationNumber: "c_eef25aab-ba0a-4181-842e-8851a6671003",
    projectName: "c_1aa81997-5abd-47ae-a132-6b7bd62039a6",
    projectNumber: "c_fb179236-bb41-45a8-87a0-b24292961dce",
    customerNumber: "c_9529f0f5-d4d8-45a1-9352-289a4995a189",
    status: "c_03f931e3-e7dc-4019-baef-627b6225c551",
    dateCreated: "c_794ae75d-8d0f-4565-af8c-c43ecd00bfa7",
};

export const programsApprovedMeasuresGridColumnKeys = {
    programNumber: "c_9d0eff6f-e74f-423b-b1d8-2a53b360f055",
};

export const programsBudgetLinesGridColumnKeys = {
    programNumber: "c_a97a1a0b-bcf9-4141-92f4-3f4cabf5a628",
    budgetLineNumber: "c_74324dff-388e-4049-8b13-0e95b8b9d261",
    utilityNumber: "c_d6060689-5931-41d1-b142-43fdcdeea5f1",
    contractNumber: "c_8a050824-3e52-42c5-9445-f70087260e97",
    budgetName: "c_2e924b34-6af1-4dd2-b4c1-3a43520f9682",
};

export const programsCalculationsGridColumnKeys = {
    programNumber: "c_be06d1a4-4d62-4414-b6e9-e15d76832087",
    calculationNumber: "c_bb0850eb-e072-4d08-b8ab-ca6c8a070a97",
    calculationName: "c_c5d50527-4ea5-414a-96b6-ba4bc28a0aae",
    calculationType: "c_881bb60d-8e36-4489-b3f7-e0a4d8751b3f",
    revisionDate: "c_2281241d-18af-4162-86c9-a071e284a5a7",
    target: "c_d8c693ee-e10f-448a-9237-9acd3e1a59b9",
    userName: "c_39ae9902-0016-4967-9db0-7b4c75ab7f06",
};

export const programsCatalogGridColumnKeys = {
    programID: "c_f43acdd6-f1b3-42f1-ad28-4811b82e9b73",
    programNumber: "c_2dc89ac7-a195-4edd-9405-0d2e66608f45",
    utilityNumber: "c_9185e2cc-c49a-424b-a087-99d95ea112bf",
    catalogID: "c_e1ceb93e-866c-4925-95ef-e32c9479f9f2",
    catalogNumber: "c_80d7bdf5-1249-4be9-b337-2c2e143bc094",
    equipmentName: "c_7fe26221-f975-419d-899c-dbe119b103f6",
    programName: "c_74d5df44-f335-4368-a4a5-9fca279bc49c",
    calculations: "c_550769c7-31ef-452b-87ec-55d64aee7f11",
};

export const programsAttributesGridColumnKeys = {
    programId: "c_ebff51da-b0c2-4aba-982a-db0a4d953518",
    attributeNumber: "c_de507191-2dd2-4e90-918c-2c9c0509ff07",
    programNumber: "c_2dac67dd-e1fe-4a43-9e0a-b39dfb04ccca",
    attributeType: "c_006ac812-5b0c-43c8-9fd1-f5a2d7d9af02",
    attributeValue: "c_62522813-4f9d-4eb7-8a40-21e59e0a4437",
};

export const programsAuditsGridColumnKeys = {
    programNumber: "c_a9115fac-18c6-4ee7-9867-74d68ad64f86",
};

export const programsDashboardCriteriaGridColumnKeys = {
    programNumber: "c_c65e7cf3-4d2f-489e-bf3f-bb632de2ddb0",
};

export const programsFastTagsGridColumnKeys = {
    programNumber: "c_0a282a15-8adc-40d6-8a47-5ab6eec8b66c",
    dateCreated: "c_0d750b5b-9231-49f9-bf24-55ee563e61bd",
    tagName: "c_eb623a3e-788d-435e-ab86-64f6be1e5e70",
    tagValue: "c_ed6dc33f-4f37-4986-a15f-eb2a541798e1",
    number: "c_8de0b34b-343d-442c-8190-e6c339d76fcf",
};

export const programsContentGridColumnKeys = {
    programNumber: "c_79c6c8bd-b37f-4c16-8690-626075b362b1",
    type: "c_3191172f-4bb7-457c-a75e-70e5c9c59841",
    title: "c_445530ac-3677-4655-b168-7f28049fcf53",
    urlTitle: "c_dda2f757-e662-4d40-aea6-8532cff5b7e3",
    menu: "c_4d2543bd-e743-45b9-a74d-b3076463eea4",
    currentVersion: "c_08571fb9-fa7d-49da-bac4-c6d1730ce7bd",
    menuTitle: "c_faa37510-5d78-411f-80c5-ebff0a2330c5",
    menuOrder: "c_3d7620d6-94b6-4785-925a-f68f800298f0",
    contentNumber: "c_f7e251c3-b171-4939-b29f-4ef275d7e205",
    itemNumber: "c_5438e245-6f77-42ca-a874-52f9ee034f3b",
};

export const programsContentRevisionsGridColumnKeys = {
    contentNumber: "c_6dca6f1a-5f7d-42eb-9eb5-44a4f39528ff",
    itemNumber: "c_eb2eeb3e-f88e-4657-b293-d659a67e63c9",
    title: "c_fddf0c09-b176-4bd6-8d01-c82664f19fe6",
    date: "c_623c7336-2890-4228-8112-048b3c6fb226",
    user: "c_56b51b67-5266-412d-8b9f-612f8d676330",
    status: "c_f5e5b29a-6710-4856-a620-fb746408037d",
    dateEntered: "c_623c7336-2890-4228-8112-048b3c6fb226",
};

export const programsDocumentsGridColumnKeys = {
    programNumber: "c_65e7f2e5-5ff8-4ae9-86ba-14f20f57e08b",
    itemType: "c_2dc9dfa6-3e31-4365-82ed-4e290c4cf654",
    type: "c_5645a5bb-5496-476a-a981-8f38641f8d2a",
    name: "c_6ee72537-472e-4324-8be0-a016c4f0a6ea",
    createdBy: "c_c20bd5b3-0b96-4d3b-ae20-bd32a823ebbd",
    documentNumber: "c_f26aca66-c838-41aa-a6d1-e049a073fd4f",
    stepsUsed: "c_2baed9ae-2901-462e-bc84-e09d6c82111e",
};

export const programsDocumentsRevisionsGridColumnKeys = {
    correspondenceVersionNumber: "c_7f44b409-89dc-4fb2-aa2f-1df07931b111",
    documentNumber: "c_9f6693f8-d5c9-4372-982d-13e8c4f94dab",
    type: "c_46768fda-b151-403d-b92f-9641035a3db0",
    name: "c_a96c3737-02ef-4ecd-9e73-4a5c8341878a",
    date: "c_aba3b46c-830c-4f22-9bd4-f5a2e352e500",
    user: "c_18b16cba-fac8-4979-a70d-daaaa04e9f73",
};

export const programsCorrespondenceWorkflowGridColumnKeys = {
    stepNumber: "c_719f3437-13c7-432d-a56b-66c941616d25",
    wfNumber: "c_fdd9a735-c788-4191-99fe-86ee1ae5f26d",
    entityId: "c_fd594ac6-e76f-44e5-921f-1738cd75cb0a",
    status: "c_222ebfc8-5ac0-4557-88d1-a78621badb48",
    stepType: "c_a7a4884f-cbc4-47a2-ac87-0cd574c45578",
    step: "c_27b974ed-8826-436e-b7e9-611869840234",
    documentNumber: "c_a059dbf6-b65a-45ff-b2b4-fd1322347008",
};

export const programsEventsGridColumnKeys = {
    utilityNumber: "c_f933a905-2d5f-4afe-95da-6288c4d32ad1",
    programNumber: "c_144bbb0a-5e31-45e8-ba4a-a7f9a5c714aa",
    programName: "c_3101e990-eaea-4284-ad8e-7d8ed32000fd",
    eventNumber: "c_14219684-ac41-4ecd-bff3-5d913bdef7ae",
    calculations: "c_e14b6ebd-6d9e-48f5-9e77-ce4430bfab31",
    eventName: "c_346e1054-95d0-4fea-a4b4-0e579814b42d",
};

export const programsFilesGridColumnKeys = {
    programNumber: "c_d2c9ae58-8973-419a-8358-332643a0929f",
    fileId: "c_85cd1035-d0be-4bd5-b524-087f980f867c",
    fileName: "c_aa58c93d-1fe9-44f8-b830-da9d76018c86",
};

export const programsFormsGridColumnKeys = {
    programNumber: "c_92adef9a-7e91-4a08-920c-418f42f09420",
    formName: "c_90fde758-8445-4e9b-b483-aecd560b2ead",
    formNumber: "c_841952e1-7978-408e-8d5a-0ddcec3f94e5",
};

export const programsFormsPagesGridColumnKeys = {
    programNumber: "c_60a428cf-defe-4454-9af0-4481ed834e28",
    pageID: "c_76d29455-435c-408e-a0a8-0c066364bd9e",
    pageNumber: "c_468068fb-ef26-4fa5-80f4-41ceea32f971",
    pageName: "c_1243ff4f-1f10-4257-a130-f7886ebfc3a0",
};

export const programsFormsHistoryGridColumnKeys = {
    programNumber: "c_670a7f7d-2ddf-4d00-9567-bcef795ad3ea",
};

export const programsGoalsGridColumnKeys = {
    programNumber: "c_47281389-6fca-48a3-8ee7-d21f0033a4d2",
    effectivedate: "c_145da00a-c5b3-4ed8-a252-2acdb3433f1f",
    goaltype: "c_243b9e34-af6c-4762-b6b7-1da2e3be16e8",
    goalname: "c_473e20aa-1358-4a9f-97ea-67162b435d92",
    goal: "c_708cd9d0-5be8-405a-a352-e9712b727b4c",
    goalnumber: "c_a7f6f91a-4db1-45ae-85c1-95cfa1d7df1e",
    groupname: "c_add4e531-4915-4b2c-92ea-3d0c9fae8006",
    expiredate: "c_f7fd1216-d212-46ab-ae2f-d691fb04a82a",
};

export const programsRebatesGridColumnKeys = {
    programNumber: "c_e10912e3-fce4-41c6-8d1e-f52a56a5c203",
    projectNumber: "c_91c1af1c-0e06-4f41-9117-a40cac8823d8",
    applicationNumber: "c_deb462bf-2d0d-4736-9133-75a2b90d52a8",
    checkInfo: "c_a9e78004-a1d1-4bc3-93ec-c484412f7a90",
};

export const programsWorkflowAuditGridColumnKeys = {
    programNumber: "c_82d25ea4-ced0-4ef6-bbc4-7be47317220d",
};

export const programsWorkflowItemsGridColumnKeys = {
    programNumber: "c_7f4fc3e2-ee44-40fb-a4cc-cc4fa1a5e057",
};

export const applicationsGridColumnKeys = {
    applicationNumber: "c_eef25aab-ba0a-4181-842e-8851a6671003",
};

export const usersGridColumnKeys = {
    userNumber: "c_7ebcc85c-08f4-496c-878d-f8709cc49986",
    firstName: "c_4b1212c5-36b4-4f9b-838d-18fbdcdc2c17",
    lastName: "c_e5cf6f55-8bb6-46ad-b644-23d686e6debb",
    name: "c_6b6418bd-3ad0-477c-aa5a-3f336c76d6e8",
    company: "c_3ad5bdb4-3ea1-48bd-a637-7dfd8db93bd0",
    email: "c_f46f09dd-c8db-46d5-acb6-ab7ea04753db",
    status: "c_0f03a8f8-bde0-4033-813c-ea3b7ef1fb22",
    client: "c_108b22fa-1995-4584-a39b-0794f48664aa",
    clientNumber: "c_5c0e2953-c4df-40a8-a47a-a4b3923c86f9",
};

export const notesGridColumnKeys = {
    userNumber: "c_1f5c618a-5899-4505-9983-a964221396c5",
    userName: "c_e5e53785-041a-4111-8a77-2fdc6d277d71",
    refId: "c_c8e2c2d4-aa26-472d-bfb9-3a16df079f81",
    noteId: "c_62b71048-7c6f-4371-ac1a-c97942c63b8d",
    note: "c_4499e993-6b2c-49c5-b9e6-3daf7c339ce2",
    noteType: "c_e70ca3aa-4223-408a-8ff1-5831ae7d585e",
    refType: "c_2847d34e-a23c-4a33-a835-32724750dfa5",
    date: "c_3ebf13c2-15dc-4076-a015-9067dc34a509",
    security: "c_7ba80bcc-823a-4828-8c6b-791adb293340",
};

export const qcNotesGridColumnKeys = {
    refType: "c_1777fce4-f1ae-4f0f-a9b4-74b99e96d2ec",
    security: "c_9644ba6d-f8b2-4aa4-9dfa-462d9acb9bed",
    noteId: "c_75e78f15-456a-419a-ab49-618c28ad432e",
    note: "c_76baff0a-6f05-4444-9555-60aa9060f91f",
    noteType: "c_38e23f35-f9f2-469b-8e5f-5c8b9d0d8506",
    refId: "c_da760045-e1fd-4d83-bbea-5ef15d660343",
    date: "c_f4ab814f-a1f6-4dbc-a762-2c2b1d1c96a7",
    dateEntered: "c_baf8c307-395c-43bc-9136-562f8a5ccfb0",
};

export const filesGridColumnKeys = {
    fileId: "c_68f72ad2-6485-467d-bbba-159ca4830081",
    entityId: "c_204786c1-ec6b-4471-89de-3672ce7506d0", // or appId
    entityTypeId: "c_06377e47-970a-43a9-8bc5-e3ec9b090a51",
    date: "c_6915fbbc-eb60-4aef-9254-93b15d078eac",
    folder: "c_74e5be8c-3d4e-41ec-aa0f-544476f78ba1",
    security: "c_c68ea78a-1a4c-4a67-8322-81e3670cdd91",
    tag: "c_661711ef-8231-417e-bc9f-d61521a9a73d",
    type: "c_06377e47-970a-43a9-8bc5-e3ec9b090a51",
    name: "c_6181b376-fdef-434c-ae39-0357a2cfaadd",
    intrefid: "c_ba7826f2-525c-4d96-a42f-1dd531bd4e90",
    fileName: "c_4d0cfcd2-2a27-445e-af8c-d2bcfd628e78",
};

export const documentsGridColumnKeys = {
    appId: "c_011bc42c-5161-43a5-ba0d-2700ba6513a6",
    item: "c_c88d4499-ef25-4892-a00c-0dbba52661f1",
    status: "c_dacab7fc-3ccd-4e94-a087-19414b9e1875",
    content: "c_1246cdcd-d43e-499e-bdc0-7b3ffd04dc5a",
    documentNumber: "c_4ff8f543-24a4-4866-9733-d5d3bebe382f",
    type: "c_3200016e-789f-4060-a865-bc2bf203433e",
    date: "c_3a26daa4-68f6-4b7e-9b11-2671ca778f88",
};

export const contactsGridColumnKeys = {
    applicationNumber: "c_5185cd0b-71b0-4e95-8ddb-9f6284433692",
    acct_number: "c_b1c7c621-cb6a-4cc7-ad12-a9cae9d1e8f1",
    address: "c_b7453f8c-7f09-4fc9-839b-52d04dd69c66",
    address_cont: "c_b0adf2a0-e8a7-4774-9ab2-3c3473156ac6",
    cell: "c_5ce5bc44-86f0-4906-b933-da31b33827d4",
    city: "c_3c27f9e9-1bc9-4e76-8721-95b1700eeff1",
    company: "c_151f331d-0a06-4686-b73d-5a658ded062f",
    contacttitle: "c_141ccd3e-8a40-4ea1-996f-ffbb92cd7b68",
    contactid: "c_d8b663da-4aed-42ab-9a14-7209e8db08dc",
    contactnumber: "c_56df5457-822f-4f1d-92db-b8ca18f383f3",
    contacttype: "c_e21f5a25-896d-496d-a8f9-2d74c55b19c8",
    email: "c_f4a671c0-35f3-4407-a8c1-aec777b5632e",
    fax: "c_d59639e3-c84a-4aa5-be5f-133667592e48",
    firstname: "c_5c7ba3a6-adc1-4e4a-90b1-76efc6025010",
    lastname: "c_a70bee2d-b706-4a51-a36c-18cf7ca642e2",
    phone: "c_d0b4a5d5-ccc8-4ca8-bf24-abb41b0d0afa",
    premiseid: "c_0e283d3a-ca10-41c7-86f9-5e19e2af172a",
    refappid: "c_9eba64d1-ca23-42d0-b4a1-40a562d503ab",
    state: "c_d299d297-e935-4c3c-8bf4-cd059a7186b9",
    taxid: "c_8dc9ffb6-161f-4166-8b0e-6df9ced31dab",
    workflowgroup: "c_fb0548f1-d9ad-4898-a369-7f2572acc5dc",
    zip: "c_86aacc78-546f-4a7c-ad25-90b954852bc5",
    meterid: "c_444cc52f-c7d2-4ce7-93a2-9d38d552d267",
};

export const workqueueAppsColumnKeys = {
    appId: "c_37ee5dc5-90fa-423b-b118-0cab422b7613",
    progId: "c_345fbf2d-e49b-494a-8a34-1ddbde0802cf",
    program: "c_810292c9-5b98-4931-a78c-752a3a2bc8ee",
    project: "c_c0da5bad-9bdf-45e6-98d9-a75291312922",
    utility: "c_7f332105-f683-4fd1-90c1-4b9453d893d0",
    opco: "c_3b8895c4-e00a-47ef-bd4d-4cec614b6884",
    tags: "c_967353b5-3ef2-4d9d-85b3-930a91925910",
    dateCreated: "c_00286854-a1e2-4c73-b27d-a5e35d56896c",
    premiseName: "c_167b8b71-4f29-47a1-9fe3-b1faaf3f5a66",
    dateEdited: "c_ce1482fb-d201-4e16-8df5-bad2f5c70334",
    status: "c_b013e8fb-51e3-444f-935f-c72aea0d27cf",
    assigned: "c_002c6653-74bf-42d6-92a5-e1ef196a8326",
    companyOrLastName: "c_00f87708-d4b9-440a-a13a-ada033288c02",
    importDate: "c_da3d93d0-59e1-4122-a09a-aa2bc27bec4c",
    assignedUserNumbers: "c_da942bd3-0203-4b11-bb7f-0b14a8ddefbd",
};

export const rebateVendorColumnKeys = {
    contactid: "c_7a89d867-d3ef-4af7-94f4-37752011b481",
    "first name": "c_a22561d2-c92d-4a8c-a58b-11ebefad737a",
    "last name": "c_016e2cb8-690f-4303-b61b-a700b11325f1",
    "company name": "c_24c41db8-0ffa-4b19-9a2b-a2714378ff1d",
    "account #": "c_14800a99-d294-4d24-a372-52d3df65f7d1",
    "tax id": "c_83d4fcb7-e81c-4e4c-be4b-ff5e36af7642",
    address: "c_6bed3763-99ee-4c72-b3be-56b4e8ae218c",
    "vendor id": "c_0e57f67d-086e-44db-82b9-c7f5261d340e",
    city: "c_5db7f0e6-1f46-40af-b047-e076c4c6f817",
    state: "c_89ec523a-ff7e-45d0-a0a9-bb5ca6fc0b0e",
    programid: "c_b5e2e245-90c9-478f-95cf-21a93026c630",
    zip: "c_8f72cd34-46cc-4be1-84b0-2804e760198f",
    phone: "c_12f05732-f815-48db-bc56-bf9a7a76d20e",
    email: "c_325debdd-6170-49e5-af1b-cd9071f643f9",
    address_cont: "c_63881b8e-e3c1-4a05-a235-8730f43f2994",
    programnumber: "c_83e01f0f-94e7-45e9-aa79-8c8629951f12",
    utilitynumber: "c_fe7d20a1-7fb8-4863-8a3d-85dcbb4d8720",
    fax: "c_5f042194-2022-4fa7-9f07-f75836cb80cf",
};

export const workqueueAppsStatusChangeColumnKeys = {
    appId: "c_3218e4c5-67ac-4cea-8630-ef6fb5281077",
    progId: "c_318e5dd0-fdf8-4240-965c-da0303bc03d6",
    program: "c_b0338c58-bbb2-458a-9ee7-8418fcbefea9",
    project: "c_cb2eb241-9faa-46e3-86fb-cb0a3e751d94",
    utility: "c_8c40401b-7607-406a-818b-152503cb9cd5",
    opco: "c_5a0a5e6f-c866-46c6-8940-5cacc59f375e",
    tags: "c_2f1a6efd-8ef3-4a57-a7c1-18236c51a2fb",
    dateCreated: "c_24c892a1-18a7-4b70-8ec0-34d25afb6755",
    premiseName: "c_a5db950d-8cbf-4814-844d-323eeb11d0c6",
    dateEdited: "c_8c4f1c7b-229f-4eca-95b7-e20349119abf",
    status: "c_e51b0a58-70d9-458f-976a-dcba1f9449b0",
    assigned: "c_75eaf825-4eff-4519-a365-785fa20f3d39",
    companyOrLastName: "c_5bc5818b-4f6b-484d-9da7-5e478d7cfacf",
    importDate: "c_5ac70db2-6da6-4a9a-a84f-4c308dce9958",
    assignedUserNumbers: "c_aab5cdcf-fe45-4dd7-80ba-24c5ac529fb3",
};

export const workqueueAppsNotesColumnKeys = {
    appId: "c_addbc9a6-7d07-4590-8c83-e02502bbfb75",
    progId: "c_c5eeadab-f211-48c4-9b0f-068927284e16",
    program: "c_787f600b-dfd2-47d5-bf6a-9b5ced463366",
    project: "c_c4ddb234-67e2-4e23-8d23-db63b4e09a34",
    utility: "c_4179916e-0e13-41d0-936d-167fa0953740",
    opco: "c_08d1dfbe-4ba9-496c-8180-212eb5a2528a",
    tags: "c_2b50b885-ce78-42ad-8299-bad7bb79dec3",
    dateCreated: "c_cf672b7e-5873-46fa-ae78-02d338e8b6dd",
    premiseName: "c_2e33679b-69e3-4283-9804-28042e9e4ad5",
    dateEdited: "c_c1bb420e-0087-4d5b-b9d7-1f437f5fac36",
    status: "c_4ae7ea1a-0d63-44ec-8c7b-ac478a73eed4",
    assigned: "c_51287455-17ab-40bd-9ea4-b1520532ed2a",
    companyOrLastName: "c_31b24b28-2b7c-4535-891c-c12be3749b11",
    importDate: "c_2715a275-dedd-4ffb-bdb4-a6eb0bd08cb0",
    assignedUserNumbers: "c_2c0b2e81-6cef-4aea-b049-c594325558ce",
};

export const workqueueAppsAssignedColumnKeys = {
    appId: "c_98d7e2b7-f13e-40e4-a173-815fc32e71d7",
    progId: "c_b58e27b9-babb-4a6f-93ab-9af1609176ae",
    program: "c_64177a78-353c-4726-8afa-9416910cf293",
    project: "c_2fe54fba-906a-4fa0-820b-241323636a0e",
    utility: "c_4e3b0ac8-3265-4ba5-bde2-dc04fa032702",
    opco: "c_60a89dfb-c88b-45c1-b315-d58ce5212053",
    tags: "c_878faba8-d28b-4ec5-a7ce-1d94cd485c05",
    dateCreated: "c_17b7fa72-d956-4376-a49b-3fbe5676c207",
    premiseName: "c_dcb3f65f-9c1b-4d65-9f9c-d2cfbcb06c74",
    dateEdited: "c_0bfed6ba-620e-4e11-aa0a-4ecb83f1dd7a",
    status: "c_3673fdad-6bc4-4aa3-af1f-ae80ee2565f6",
    assigned: "c_fa34d3fc-7840-4582-b973-3ab51d07f375",
    companyOrLastName: "c_5d71a651-9ff1-47b2-99b8-db692f975961",
    importDate: "c_3d2061bb-5dd0-443f-8c63-567950130145",
    assignedUserNumbers: "c_1c6c3696-8cf3-459c-9214-a0c22f3f72bc",
};

export const workqueueAppsWithTagsColumnKeys = {
    appId: "c_7b692496-48fb-4a9c-ab2b-162dead40e1c",
    progId: "c_3bc64d2a-be89-4c52-8637-a48fe69f4ca7",
    program: "c_e3d5e0e0-a2c3-4a6c-91ca-012d0c293c4e",
    project: "c_fae85584-3934-451d-8fcb-c0a533e17c88",
    utility: "c_4e5bc519-1f62-47c0-98cb-438cddc7ea8e",
    opco: "c_e332f5ba-e1ab-4551-b373-caff93f38eec",
    tags: "c_dcd22d23-06fe-48b2-92da-719a7f38426f",
    dateCreated: "c_a4b470f4-150d-4dfb-8c99-c55d7cec4fc0",
    premiseName: "c_0945c138-543c-49c2-ad45-3b78ec91c0cb",
    dateEdited: "c_0792ff65-b51d-4e50-ad9c-a77c86347e90",
    status: "c_20fd7498-0e93-4a93-9c0d-6d6bc7e4648b",
    assigned: "c_dacf6194-0e11-423d-8628-5a35a77f2b7f",
    companyOrLastName: "c_8d8eeae2-7975-45fd-bba1-940c2e547db8",
    importDate: "c_9667d672-5919-44b8-b5b9-b25efd21ece8",
    assignedUserNumbers: "c_b4aa45fb-60b4-4e78-9dce-b526e74687a4",
};

export const assignedGroupsColumnKeys = {
    appId: "c_7f9d8964-5d9e-4175-9d63-f8a66a1fdf50",
    progId: "c_1f66e5c1-ac70-48ee-9d0c-a18d855c999f",
    program: "c_3123f3e9-eeb0-4a05-a1f6-5fc64a7d2e5f",
    taskNumber: "c_da5549a4-4de4-4a31-8d8d-8dd37e9b8d99",
    opco: "c_fba75f02-78f3-4ee7-adab-b0093081f936",
    step: "c_9d9efbe7-006d-4853-9219-9ba810fee9bc",
    type: "c_b7fe81cd-d998-42a5-a8b9-83770ada3538",
    owner: "c_ae833f3f-d95c-4c6a-ad81-4dd6fbe41d5c",
    due: "c_45abac0c-4bcd-40c4-bedd-89e100370bca",
    status: "c_e1250fbc-fc3d-4842-8940-e9c101d9a89c",
    project: "c_d9ee7f0a-b199-432f-a0bf-6a67f6817143",
    assignedTo: "c_9ddce6e1-38bf-482a-9e67-0271219e6d14",
    userNumber: "c_ab5e0655-d3ab-4fa8-9cd6-cd16d564109d",
    refid: "c_75b722d5-b322-4e99-a03f-447def1b81cc",
    datasourcenumber: "c_0bafd442-a864-4c04-a961-7612db04c003",
    groupusernumber: "c_58c82de1-ec3a-4827-8f61-950dde304c76",
};

export const workqueueTasksColumnKeys = {
    appId: "c_38999519-73fd-4c1b-9d7f-2677e2f18d51",
    progId: "c_81d6b272-0fac-4448-bfa2-07b2e0fd88a1",
    program: "c_80e1e44c-1ee6-4a9e-853e-275528d3c372",
    taskNumber: "c_c21fae8e-52c4-47b9-8786-19c26e78ce73",
    opco: "c_b5949b38-6f04-4590-8eaa-2a03585b8567",
    step: "c_cd273ed3-25c8-448d-927a-b966eb9b20fd",
    type: "c_22dbd28f-338e-44c9-9b26-c300f9003279",
    owner: "c_1383e490-c3e5-411b-8b8e-cb131bcd3a08",
    due: "c_57020732-a8cf-4deb-ae81-cf48adca0e40",
    status: "c_6876a556-392c-4b3f-9d93-f9a574e68909",
    project: "c_faeb033a-bbec-4a6c-8a82-971cf28bca62",
    assignedTo: "c_c3e474c9-a2a5-4377-a4cd-6af9aaba428d",
    userNumber: "c_135bd68d-47e2-4c2a-88e3-0a3e10a45e61",
};

export const workqueueTasksAssignedAppsColumnKeys = {
    appId: "c_5d7c9929-26f5-4721-9013-0a2e8c75ee2c",
    progId: "c_167016b8-cdad-4b8c-b130-2c9aa0516bcf",
    program: "c_9571ef36-689d-432f-ab98-6a392e6435cf",
    taskNumber: "c_db5bc7a7-1021-436e-9bfa-a9ee7164c550",
    opco: "c_b3e2c82c-3355-4b28-bdb2-e2711dfeea22",
    step: "c_64f21629-90f7-4225-b1ab-8d65cc1f3947",
    type: "c_421bf338-5773-4bd1-9864-9e3130bb0a92",
    owner: "c_ed4cb147-5424-4975-9ee1-a436541a1bcd",
    due: "c_0090dd3f-abf6-4bc3-bfb8-ee77e50684ec",
    status: "c_779b0ea1-2744-44e0-939b-f99a728af5bc",
    project: "c_767a2d1e-0dce-4b60-a92c-83891e9bd03b",
    assignedTo: "c_f5fbfa8b-4e07-4fc1-9dff-bf6835b65cc9",
    userNumber: "c_a629a018-e211-47d7-8f93-27f88c50b914",
};

export const workqueueAppsCountsByStatusColumnKeys = {
    programid: "c_50933e5c-fd03-4cb2-88f3-7a7d983c3f12",
    progId: "c_e5205226-2f83-4a87-b5fb-1ebe98c7fc09",
    program: "c_968fe6c9-7eb1-4622-b58a-bfd2ed2df5fc",
    utility: "c_3debabf3-d875-4847-8b7d-36f65b842b30",
    status: "c_63261544-ad1c-4e3d-be08-1b0b2f84e9ce",
};

export const equipmentGridColumnKeys = {
    appid: "c_fccf287d-b617-410a-b7b1-035294cb2a64",
    equipmentId: "c_17c29980-5878-4527-b3f2-89c35c1e67b0",
    equipmentQuantity: "c_727c637e-b242-49cd-aa64-b83a1527cbcc",
    equipmentName: "c_73262807-e041-40bc-ac9b-e21c6a4b138c",
    catalogId: "c_b6eed9c1-1b7f-46f2-8e82-af5996a382ca",
    refId: "c_d729e0f2-9c0d-428c-a0a9-9d3f005eee3a",
    dateEntered: "c_f4db7fca-81da-428c-854f-94c50415826b",
};

export const eventsGridColumnKeys = {
    appid: "c_d67a722e-e61c-4d34-93a6-368e63f271a2",
    number: "c_9c7a7881-82bc-4ab8-83a9-5045735d6805",
    name: "c_2b2ec510-807a-49ea-a8e7-b4b0814c9ce2",
    category: "c_96678ffb-0ab0-42ef-920d-82dbf0588b45",
    type: "c_c1fcd1ad-a8de-49f8-8683-d35be6c407ad",
    dateEntered: "c_5eb26e1e-48e0-4ee7-b3c0-40c6e3ec6331",
    startDate: "c_7fda4431-6eba-427b-a39b-77d79fb8cca7",
    endDate: "c_44a434d7-c2bc-42d8-b0e3-3f5dce98b165",
    duration: "c_96705639-fd1e-4cbc-afd5-95a7e59eaa72",
    categorynumber: "c_e0027367-cde9-4378-89ac-3c89ffe5621e",
};

export const lookupTableColumnKeys = {
    lookupTableNumber: "c_8306fc11-ec8e-4073-baf3-26b27da6306a",
    attrLookupValueNumber: "c_6559e0e9-d48b-48d7-9dba-b22e5fc7ef7d",
    lowerLimit: "c_2c69519e-9d8d-4efe-bcf8-4dc065b8c51a",
    upperLimit: "c_af44d646-a3dc-4e20-a6dd-588e95745108",
    value: "c_cc941bdc-6851-4ceb-a651-35296ce12942",
};

export const invoiceCorrespondenceGridColumnKeys = {
    documentNumber: "c_062b3431-74a6-4a3f-b407-88018a2ccddb",
    type: "c_cecf5f99-bb26-4100-becc-d863a3f62a2d",
    content: "c_aebeb8dd-4104-466a-ac2b-56877eba51ec",
};

export const invoiceDocumentAuditGridColumnKeys = {
    id: "c_068a558d-8602-41f6-96a4-916c33e9aa5d",
    date: "c_1f8fdd87-3a79-4c1e-aa0d-9a706ac4f1e5",
};

export const invoiceItemAuditGridColumnKeys = {
    id: "c_f0bb7267-ebdf-4784-9148-5aba29e679fd",
};

export const contractVendorsGridColumnKeys = {
    vendorContactId: "c_c9d75005-9fe4-405b-8875-da58ca9a40cf",
    vendorNumber: "c_9d87c74d-d1ee-4f04-a052-68b052cebe6f",
};

export const catalogGridColumnKeys = {
    utilityNumber: "c_fcd61bda-dba4-4cfc-b567-af30fd7b8e81",
    utilityName: "c_6f2cc980-d770-4c6b-a194-7086b9f17a49",
    programNumber: "c_825cd68a-03c7-4f21-90a9-06499a81279e",
    programName: "c_c57bfc22-1352-4730-93e3-050db0f3b71d",
    catalogNumber: "c_c9c29e72-f04c-4628-a59d-5b1f150c59bb",
    calculations: "c_c6d89f43-2f8d-465a-b76a-56e45d973481",
    catalogId: "c_a9b87084-c088-4db5-9264-129823d3b996",
    name: "c_9555f439-0f31-4cff-9ea1-2f25c72086ae",
};

export const programsCatalogHistoryGridColumnKeys = {
    id: "c_712ab12d-d323-409d-a0c1-43e3d162ef51",
};

export const programCatalogAttributeLookupsGridColumnKeys = {
    programNumber: "c_0250569a-a1e2-4790-bee6-833916bfc74b",
    catalogNumber: "c_661160f6-cb26-450a-8492-f6564462a03c",
    catalogAttrNumber: "c_12250dbe-6072-438e-b6f9-8edf93611975",
    lookupNumber: "c_e6f4b164-73d6-4ee9-8e51-4aafbc0285da",
    lookupValue: "c_90ee39b9-888f-42aa-aabd-2b0f3b120272",
    timesUsed: "c_3f321132-7c48-4d4c-9815-c7e32ad6152a",
};

export const logsSystemXmlProcessGridColumnKeys = {
    processRunID: "c_9bb2e19b-579c-4076-abbe-2d0ea01da630",
    startDate: "c_3378492a-c2f2-41c6-afff-1a86da3f68da",
};

export const logsSystemXmlProcessDetailsGridColumnKeys = {
    processRunID: "c_e96ddcfc-aabe-4be0-9dd3-ab9bc0c95ae4",
    context: "c_82cf0f67-f261-4925-b3be-3a800d008947",
};

export const logsSystemErrorsGridColumnKeys = {
    date: "c_39dc96dc-230a-4f8a-8176-075a95472b2d",
    errorId: "c_ab4961c7-039a-4a3f-9137-20b98663b5c5",
    errorNumber: "c_7bb8a6b9-18fb-46b9-b15e-e6c77ceb0391",
    errorName: "c_b23050f7-d774-403c-950c-78ce01fab710",
    errorMessage: "c_c73dcbf3-6227-4024-a5c6-6b5bf01b1e72",
    userId: "c_1956e06a-3911-4c55-a5af-3c6c645838e0",
    user: "c_151545fe-6075-4d07-b7ce-af522b93c202",
    userAgent: "c_7506ff9e-9f94-432a-ae38-79b77881071c",
    url: "c_ee9f2c82-f70a-4766-b9ab-dda385a6a37e",
    ipAddress: "c_47e41471-68c3-4047-888c-b5e7ceff9c36",
    state: "c_119218d7-cf13-4d39-8a8f-555996d49652",
};

export const widgetUtilitiesGridColumnKeys = {
    utilityName: "c_694408e2-c91f-49b6-a364-275520308ce1",
    utilityNumber: "c_fc564390-2a92-4c5e-ac35-705d19e1de8a",
    isFavorite: "c_89392d12-a1c1-40a6-8874-72ac5109a08c",
};

export const widgetProgramsGridColumnKeys = {
    programName: "c_0c9ad04a-a19f-4839-807a-cfbb8fee88b2",
    programNumber: "c_49a58176-7947-4c4e-a87b-8fad0aebabf3",
    isFavorite: "c_8a6a5eb6-af48-40f6-a1cf-c48f873ce878",
};

export const widgetApplicationsAssignedGridColumnKeys = {
    applicationNumber: "c_7cfd744c-5b84-47ac-b1c3-5631e3f03fa4",
    projectNumber: "c_2a0e236b-2b96-459d-aad7-b15d805c42c8",
    userNumber: "c_30ae6f5c-3073-4344-b53b-9cb998416c37",
};

export const widgetApplicationsBookmarksGridColumnKeys = {
    applicationNumber: "c_5b20b17f-27b2-4e8a-b638-a6e421001307",
    projectName: "c_75500358-550c-4f93-a635-9f0cf8766fcb",
    projectNumber: "c_cbbdc73c-969f-45bc-8882-b5375757fc18",
    userNumber: "c_15f8ff53-9b5e-4904-933f-e677a9e04253",
};

export const widgetApplicationsRecentGridColumnKeys = {
    applicationNumber: "c_ee5a869b-4d5d-4f20-a7f0-287122b0c200",
    projectName: "c_49d77a42-ffb1-489e-8b02-8420c14d9414",
    projectNumber: "c_8f516b88-1fcd-4846-b469-bb0dc4bbaea0",
    programID: "c_ccbcc21a-a930-4147-a882-beb0654e22ec",
    programName: "c_86dcc27a-b43c-422c-be47-0c478c6bd56e",
    programNumber: "c_edee17ec-3914-49a7-a66a-b63f969f92d2",
    utilityName: "c_d6a80557-b86a-4d62-8882-23aede2c8e06",
    utilityNumber: "c_756e1fcb-d9c0-45e7-b4a6-af1a283fa17f",
};

export const widgetContractBookmarksGridColumnKeys = {
    contractNumber: "c_e90bdd47-c879-4c31-b533-9e794a14ec15",
    utilityNumber: "c_3c69851a-5848-48d6-8b1c-26089d9c0197",
    utility: "c_63262125-1250-4fa3-9f99-3cb53e270cd0",
    contractDescription: "c_e27c5562-bb0c-48e9-8a14-43ddbd71e8c2",
    isFavorite: "c_a41023ea-d4c9-4e25-ada6-374a69456151",
};

export const widgetInvoiceDocumentBookmarksGridColumnKeys = {
    invoiceDocumentNumber: "c_cdaac978-1635-4eb6-ad4a-93498e20b2d5",
    vendor: "c_2de458f1-15d1-4caa-9a6e-ece09e580789",
    invoiceDescription: "c_c5d2dc99-4642-48a4-9001-415a0b66de4f",
    contractNumber: "c_513e4ff6-e68d-4c5f-b741-9784bfcedd03",
    contractDescription: "c_34a1ea59-c5db-43ba-9f39-547a919c62fc",
    utilityNumber: "c_2adf94b2-0ad9-494d-a339-e1bad5eb5c0d",
    utility: "c_1c9a6ff2-a33a-4df9-a542-64b375d18f57",
    isFavorite: "c_1d144667-4cff-464e-8d8a-c07c7a09329e",
};

export const allHostnamesGridColumnKeys = {
    utilityNumber: "c_37e4b198-6ef5-4305-b118-96d415ea2e6c",
    programNumber: "c_a767938b-62b5-4df0-9a4b-cb931f95aa90",
    utility: "c_a6ea0627-1cf6-4581-a9a3-1fccff544cf4",
    program: "c_39db51cf-1211-425c-8df7-8b168cb16986",
    hostname: "c_6d9a42c1-a3b0-425e-86f8-82c42ac80ba0",
};

export const allCatalogAttributesColumnKeys = {
    procIdColumnKey: "c_ba737ed3-4b0b-4fa9-b717-4e5f3bc9b250",
    catNumberColumnKey: "c_32054972-f619-400a-9616-86c6bec209ec",
    utilityColumnKey: "c_c83b9c96-a2b9-4753-bd9e-7689179f81a6",
};

export const allProgramAttributesColumnKeys = {
    programNumber: "c_16df8b37-db75-43aa-b522-206cdfdd6025",
    utilityId: "c_f5699313-7186-45bb-95d7-773d8b80f39f",
    attributeNumber: "c_99abe06e-d43c-46a7-a825-81beb0886f8a",
    attributeType: "c_f30e894d-99c9-4c03-90ad-fab91eb11291",
    attributeValue: "c_c5aed02c-df0b-4f1a-b397-001d1e66a6e5",
};

export const relatedApplicationsColumnKeys = {
    applicationNumber: "c_97395766-b8c7-494f-9cf1-db62437b8055",
    projectNumber: "c_d4809c2b-3d08-4989-8485-55b4a5266a12",
    projectName: "c_b099adc4-daf8-4a54-ab3c-d966f5e1ac2b",
    firstName: "c_3cf46bb7-6300-482b-b27a-0f6af1a7add8",
    lastName: "c_c27d558c-3df0-4584-814e-f8bb32b41c5d",
};

/**
 * Get related DataGrid list with similar columns.
 * Used to update column configuration for all grids at once.
 *
 * @param {string} dataGridId
 * @returns {array}
 */
export const getRelatedGrids = (dataGridId) => {
    let relatedGrids = [];

    switch (dataGridId) {
        case availableGrids.workqueueTasks:
        case availableGrids.workqueueTasksAssignedApps:
            relatedGrids = [
                {
                    id: availableGrids.workqueueTasks,
                    columns: workqueueTasksColumnKeys,
                },
                {
                    id: availableGrids.workqueueTasksAssignedApps,
                    columns: workqueueTasksAssignedAppsColumnKeys,
                },
            ];

            break;
        case availableGrids.workqueueApps:
        case availableGrids.workqueueAppsAssigned:
        case availableGrids.workqueueAppsNotes:
        case availableGrids.workqueueAppsStatusChange:
        case availableGrids.workqueueAppsWithTags:
            relatedGrids = [
                {
                    id: availableGrids.workqueueApps,
                    columns: workqueueAppsColumnKeys,
                },
                {
                    id: availableGrids.workqueueAppsAssigned,
                    columns: workqueueAppsAssignedColumnKeys,
                },
                {
                    id: availableGrids.workqueueAppsNotes,
                    columns: workqueueAppsNotesColumnKeys,
                },
                {
                    id: availableGrids.workqueueAppsStatusChange,
                    columns: workqueueAppsStatusChangeColumnKeys,
                },
                {
                    id: availableGrids.workqueueAppsWithTags,
                    columns: workqueueAppsWithTagsColumnKeys,
                },
            ];

            break;
        default:
            break;
    }

    return relatedGrids.filter((grid) => grid.id !== dataGridId);
};

/**
 * Returns the column keys for the given data grid id
 * @param {string} dataGridId
 * @returns {typeof workqueueAppsColumnKeys} columnKeys
 */
export const getColumnKeys = (dataGridId) => {
    let columnKeys = {};

    switch (dataGridId) {
        case availableGrids.workqueueApps:
            columnKeys = workqueueAppsColumnKeys;
            break;

        case availableGrids.workqueueAppsStatusChange:
            columnKeys = workqueueAppsStatusChangeColumnKeys;
            break;

        case availableGrids.workqueueAppsNotes:
            columnKeys = workqueueAppsNotesColumnKeys;
            break;

        case availableGrids.workqueueAppsAssigned:
            columnKeys = workqueueAppsAssignedColumnKeys;
            break;

        case availableGrids.workqueueAppsWithTags:
            columnKeys = workqueueAppsWithTagsColumnKeys;
            break;

        case availableGrids.workqueueTasks:
            columnKeys = workqueueTasksColumnKeys;
            break;

        case availableGrids.workqueueTasksAssignedApps:
            columnKeys = workqueueTasksAssignedAppsColumnKeys;
            break;

        case availableGrids.workqueueAppsCountsByStatus:
            columnKeys = workqueueAppsCountsByStatusColumnKeys;
            break;

        case availableGrids.workqueueTasksMyAssignedGroups:
            columnKeys = assignedGroupsColumnKeys;
            break;

        default:
            break;
    }

    return columnKeys;
};

export const getFriendlyNamesGridGridId = ({ formNumber }) => {
    return `${formNumber}-friendly-names`;
};

export const invoiceFilesGridColumnKeys = {
    entityId: "c_7ab7e6e2-aa78-4633-95ef-8685ce2a3b91",
    fileId: "c_2b3d4a38-f0b5-417d-b2c8-f350a11218be",
    fileName: "c_759d437b-1851-4d31-8768-7e1e8d4cdc5e",
    folder: "c_132195f6-3810-4dcb-8975-3378ccaf0970",
    security: "c_34775e93-ed40-4c93-81d9-4e16eed4aa5e",
    tag: "c_86fac352-bff5-4369-8d6b-e97cd0e404bc",
    date: "c_91f51f9e-4a42-4134-98d5-440bf1fe200c",
};

export const applicationFilesGridColumnKeys = {
    date: "c_83fd6bf5-688d-4efe-babe-256ba24a4062",
    fileId: "c_8ae507ef-8eed-498e-9955-a629d130b1de",
    fileName: "c_654da4c6-adbe-4ca4-aa6f-f74b93179058",
    security: "c_8ecf35d8-adb6-4a82-8377-068a331826a1",
    fileAuthorizationGroup: "c_75d79cbe-0143-4e1e-94bc-948d0601b6b5",
    type: "c_87a838f6-eaaa-479e-99c7-bffd6b2ff4bc",
    folder: "c_50dcb066-73d8-4181-b04a-67ca042a52db",
    refId: "c_90730b09-7518-4fd7-938b-435b55b305bc",
    tag: "c_33b9574b-7cc6-4065-8d4d-089c9b2328eb",
    user: "c_79e01f24-9525-4ed7-ac0b-581ad375a2cf",
};
