import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

export const IconButton: React.FC<{
    className?: string;
    onClick: () => void;
    icon: IconProp;
    title: string;
}> = ({ className, icon, title, onClick }) => {
    return (
        <button className={cn(className, "wc-btn p-2 border rounded")} onClick={onClick} title={title}>
            <FontAwesomeIcon icon={icon} fixedWidth />
        </button>
    );
};
