import React, { useCallback, memo, useRef, useEffect, useState } from "react";
import cn from "classnames";
import ReactDatePicker from "react-datepicker";
import { isDate, isString } from "lodash";
import { compareDateWithoutTime, DATEPICKER_DATE_FORMAT, getDateValueForInput, jsonDateToDate } from "components/utils/date";
import useFocusClasses from "components/utils/useFocusClasses";
import Label from "../Label";
import { useIsMobile } from "components/utils/useIsMobile";
import IconWrap from "../Icons";
import Portal from "../Portal";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";
import { MAX_DATE } from "components/utils/constants";

const DatePicker = memo((props) => {
    const {
        className,
        wrapperClassName,
        id,
        name,
        placeholder,
        value,
        onChange,
        onKeyDown,
        disabled,
        readOnly,
        minDate,
        maxDate,
        autocomplete = false,
        showTime,
        showTimeOnly,
        error,
        title,
        popperPlacement,
        popperContainer,
        popperProps,
        popperClassName,
        popperModifiers,
        testId,
    } = props;

    const datePickerRef = useRef();
    const dateFormat = showTimeOnly ? "h:mm aa" : showTime ? DATEPICKER_DATE_FORMAT + " h:mm aa" : DATEPICKER_DATE_FORMAT;

    // Set selected value only if value is date.
    // If value is a string it will be shown as is in input field.
    const selectedDate = isString(value) ? undefined : value;

    const parsedMinDate = minDate ? jsonDateToDate(minDate) : undefined;
    const parsedMaxDate = maxDate ? jsonDateToDate(maxDate) : undefined;
    const todayDate = new Date();
    const isTodayEarlierThanMinDate = compareDateWithoutTime(todayDate, parsedMinDate) === -1;
    const isTodayLaterThanMaxDate = compareDateWithoutTime(todayDate, parsedMaxDate) === 1;
    const todayButtonEnabled = !showTimeOnly && !(isTodayEarlierThanMinDate || isTodayLaterThanMaxDate);
    const isMobile = useIsMobile();
    const [openDatePicker, setOpenDatePicker] = useState(true);

    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled,
    });

    const onPickerChange = useCallback(
        (date, event) => {
            onChange && onChange({ value: date, event });
        },
        [onChange]
    );

    const onFocus = useCallback(
        (event) => {
            onFocusClassesFocus(event);
            props.onFocus && props.onFocus(event);
        },
        [onFocusClassesFocus, props]
    );

    const onBlur = useCallback(
        (event) => {
            // Update dates without time part and only if there are date limits
            if (!showTime && (isDate(parsedMinDate) || isDate(parsedMaxDate))) {
                const date = getDateValueForInput({ value: event.target.value, isFormatDate: true });

                // Send date as a string if it is out of range. This prevents datepicker to reset to previous valid date.
                if (
                    !isDate(date) ||
                    (isDate(parsedMinDate) && compareDateWithoutTime(date, parsedMinDate) === -1) ||
                    (isDate(parsedMaxDate) && compareDateWithoutTime(date, parsedMaxDate) === 1)
                ) {
                    onPickerChange(event.target.value, event);
                }
            }

            onFocusClassesBlur(event);
            props.onBlur && props.onBlur(event);
        },
        [onFocusClassesBlur, onPickerChange, parsedMaxDate, parsedMinDate, props, showTime]
    );

    const onPickerKeyDown = useCallback(
        (event) => {
            // Close datepicker on Tab and move to next field
            if (event.key === "Tab") {
                datePickerRef.current.setOpen(false);
            }
            onKeyDown && onKeyDown(event, datePickerRef.current.props.value);
        },
        [onKeyDown]
    );

    useEffect(() => {
        setOpenDatePicker(true);
    }, [openDatePicker]);

    const onOpen = () => {
        // Scroll to selected time on datepicker open.
        if (showTime) {
            // Allow to draw the time list before scrolling to selected time.
            setTimeout(() => {
                const selectedTime = document.querySelector(".react-datepicker__time-list-item--selected");
                if (selectedTime) {
                    selectedTime.scrollIntoView({ block: "center" });
                }
            }, 0);
        }
    };

    const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return (
            <>
                <div className="react-datepicker__mobile-header">
                    {title ?? name}
                    <IconWrap iconWrapClickable icon="clear-close" onClick={() => setOpenDatePicker(false)} />
                </div>
                <div className="react-datepicker__current-month">
                    <button
                        type="button"
                        className="react-datepicker__navigation react-datepicker__navigation--previous"
                        aria-label="Previous month"
                        onClick={decreaseMonth}
                    ></button>
                    {months[date.getMonth()]} {date.getFullYear()}
                    <button
                        className="react-datepicker__navigation react-datepicker__navigation--next react-datepicker__navigation--next--with-time react-datepicker__navigation--next--with-today-button"
                        aria-label="Next Month"
                        onClick={increaseMonth}
                    ></button>
                </div>
            </>
        );
    };

    const modifiers = (popperModifiers ?? []).concat({
        // Custom modifier to write popper attributes. Not sure why this does not happen automatically.
        name: "setAttributes",
        enabled: true,
        fn: ({ state }) => {
            if (state?.attributes.popper["data-popper-reference-hidden"]) {
                state.elements.popper.dataset["popperReferenceHidden"] = undefined;
            } else {
                delete state.elements.popper.dataset["popperReferenceHidden"];
            }
        },
        phase: "write",
        requires: ["computeStyles"],
    });

    return (
        <>
            {openDatePicker && (
                <>
                    {props.label && <Label className="datepicker-label">{props.label}</Label>}
                    <ReactDatePicker
                        {...(isMobile && { renderCustomHeader })}
                        ref={datePickerRef}
                        id={id ?? testId}
                        name={name}
                        wrapperClassName={wrapperClassName}
                        className={cn("datepicker", className, {
                            error: error,
                            "read-only": readOnly,
                            disabled: disabled,
                        })}
                        autoComplete={autocomplete ? "on" : "off"}
                        todayButton={todayButtonEnabled ? "Today" : undefined}
                        dateFormat={dateFormat}
                        timeInputLabel="Time:"
                        showTimeSelect={showTime}
                        showTimeSelectOnly={showTimeOnly}
                        timeIntervals={1}
                        selected={selectedDate}
                        value={value}
                        onChange={onPickerChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onPickerKeyDown}
                        onCalendarOpen={onOpen}
                        disabled={disabled}
                        readOnly={readOnly}
                        title={title}
                        minDate={parsedMinDate}
                        maxDate={parsedMaxDate ?? MAX_DATE}
                        placeholderText={placeholder}
                        popperContainer={popperContainer ?? (showTime ? DatePickerPortal : undefined)}
                        popperClassName={cn(popperClassName, {
                            "react-datepicker-popper--time-only": showTimeOnly,
                        })}
                        popperProps={popperProps}
                        popperPlacement={popperPlacement ?? "bottom"}
                        popperModifiers={modifiers}
                    />
                </>
            )}
        </>
    );
});

export const DatePickerPortal = ({ children }) => {
    return <Portal container={document.body}>{children}</Portal>;
};

export default DatePicker;
