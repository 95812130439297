import { Suspense, lazy } from "react";
import Login from "./Login";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import QrCodeForm from "./Login/QrCodeForm";
import SamlView from "./SamlView";

import ComponentsPage from "./ComponentsPage";
import UserProfile from "./UserProfile";
import ApplicationFormPages from "./ApplicationFormPages";
import UILibrary from "./UILibrary";

import HomeView from "./HomeView";
import HomeDashboardView from "./HomeDashboardView";
import ApplicationProcessing from "./ApplicationProcessing";
import ApplicationProcessingWorkCenter from "./ApplicationProcessingWorkCenter";
import ApplicationProcessingBookmarks from "./ApplicationProcessingBookmarks";
import ApplicationProcessingRecentApplications from "./ApplicationProcessingRecentApplications";
import ApplicationProcessingDocumentQueue from "./ApplicationProcessingDocumentQueue";
import NewApplication from "./NewApplication";
import NewApplicationForm from "./NewApplication/NewApplicationForm";
import ScanQueue from "./ScanQueue";
import NewApplicationFromScan from "./ScanQueue/NewApplicationFromScan";
import ScanQueueManagement from "./ScanQueueManagement";

import UtilitiesAndProgramManagement from "./UtilitiesAndProgramManagement";
import UtilitiesAndProgramManagementCatalog from "./UtilitiesAndProgramManagementCatalog";
import UtilitiesAndProgramManagementAllUtilities from "./UtilitiesAndProgramManagementAllUtilities";
import UtilitiesAndProgramManagementAllPrograms from "./UtilitiesAndProgramManagementAllPrograms";
import UtilitiesAndProgramManagementBookmarkedPrograms from "./UtilitiesAndProgramManagementBookmarkedPrograms";
import UtilitiesAndProgramManagementBookmarkedUtilities from "./UtilitiesAndProgramManagementBookmarkedUtilities";
import UtilitiesAndProgramManagementBookmarkedContracts from "./UtilitiesAndProgramManagementBookmarkedContracts";
import UtilitiesAndProgramManagementBookmarkedInvoices from "./UtilitiesAndProgramManagementBookmarkedInvoices";

import CrmView from "./CrmView";

import SystemManagement from "./SystemManagement";
import SystemManagementUsers from "./SystemManagementUsers";
import SystemManagementUserGroups from "./SystemManagementUserGroups";
import SystemManagementConfiguration from "./SystemManagementConfiguration";
import LogsSystem from "./SystemManagementLogs/LogsSystem";
import LogsSecurity from "./SystemManagementLogs/LogsSecurity";

import SplitView from "./SplitView";
import ApplicationProcessingAssignedApps from "./ApplicationProcessingAssignedApps";
import UtilityContractView from "./UtilityContractView";
import UtilityBudgetView from "./UtilityBudgetView";
import UtilityInvoiceView from "./UtilityInvoiceView";
import Dashboard from "../ui/Dashboard";
import UserGroups from "./UserGroups";
import GlobalSearchView from "./GlobalSearchView";
import ResponsiveGridDashboard from "../ui/Dashboard/ResponsiveGridDashboard";
import CustomerView from "./CustomerView";
import FileView from "./FileView";
import PortalBuilderView from "./PortalBuilderView";
import ReleaseNotes from "./ReleaseNotes";
import WaitIcon from "components/ui/WaitIcon";
import ModuleLoadError from "components/ui/ModuleLoadError";
import { ApplicationProcessingWorkCenterV2 } from "./ApplicationProcessingWorkCenterV2";
import TRMulatorView from "./TRMulatorView";

const ProjectView = lazy(() =>
    import("./ProjectView").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

const ProgramView = lazy(() =>
    import("./ProgramView").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

const UtilityView = lazy(() =>
    import("./UtilityView").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

const PortalAnalytics = lazy(() =>
    import("./PortalAnalytics").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

export const availableViews = {
    Login: Login,
    ChangePassword: ChangePassword,
    ForgotPassword: ForgotPassword,
    ResetPassword: ResetPassword,
    QrCodeForm: QrCodeForm,
    SamlView: SamlView,

    HomeView: HomeView,
    HomeDashboardView: HomeDashboardView,
    ApplicationProcessing: ApplicationProcessing,
    ApplicationProcessingWorkCenter: ApplicationProcessingWorkCenter,

    ApplicationProcessingWorkCenterV2: (props) => (
        <Suspense fallback={<WaitIcon />}>
            <ApplicationProcessingWorkCenterV2 {...props} />
        </Suspense>
    ),

    ApplicationProcessingBookmarks: ApplicationProcessingBookmarks,
    ApplicationProcessingRecentApplications: ApplicationProcessingRecentApplications,
    ApplicationProcessingAssignedApps: ApplicationProcessingAssignedApps,
    ApplicationProcessingDocumentQueue: ApplicationProcessingDocumentQueue,
    NewApplication: NewApplication,
    NewApplicationForm: NewApplicationForm,
    ScanQueue: ScanQueue,
    NewApplicationFromScan: NewApplicationFromScan,
    ScanQueueManagement: ScanQueueManagement,

    UtilitiesAndProgramManagement: UtilitiesAndProgramManagement,
    UtilitiesAndProgramManagementCatalog: UtilitiesAndProgramManagementCatalog,
    UtilitiesAndProgramManagementAllUtilities: UtilitiesAndProgramManagementAllUtilities,
    UtilitiesAndProgramManagementAllPrograms: UtilitiesAndProgramManagementAllPrograms,
    UtilitiesAndProgramManagementBookmarkedPrograms: UtilitiesAndProgramManagementBookmarkedPrograms,
    UtilitiesAndProgramManagementBookmarkedUtilities: UtilitiesAndProgramManagementBookmarkedUtilities,
    UtilitiesAndProgramManagementBookmarkedContracts: UtilitiesAndProgramManagementBookmarkedContracts,
    UtilitiesAndProgramManagementBookmarkedInvoices: UtilitiesAndProgramManagementBookmarkedInvoices,

    CRM: CrmView,
    TRMulatorView,

    SystemManagement: SystemManagement,
    SystemManagementLogsSystem: LogsSystem,
    SystemManagementLogsSecurity: LogsSecurity,
    SystemManagementUsers: SystemManagementUsers,
    SystemManagementUserGroups: SystemManagementUserGroups,
    SystemManagementConfiguration: SystemManagementConfiguration,

    UtilityView: (props) => (
        <Suspense fallback={<WaitIcon />}>
            <UtilityView {...props} />
        </Suspense>
    ),
    ProgramView: (props) => (
        <Suspense fallback={<WaitIcon />}>
            <ProgramView {...props} />
        </Suspense>
    ),
    ProjectView: (props) => (
        <Suspense fallback={<WaitIcon />}>
            <ProjectView {...props} />
        </Suspense>
    ),

    UtilityContractView: UtilityContractView,
    UtilityBudgetView: UtilityBudgetView,
    UtilityInvoiceView: UtilityInvoiceView,

    CustomerView: CustomerView,

    SplitView: SplitView,

    ComponentsPage: ComponentsPage,
    UILibrary: UILibrary,
    UserProfile: UserProfile,
    UserGroups: UserGroups,
    ApplicationFormPages: ApplicationFormPages,
    PortalBuilderView,

    Dashboard,
    ResponsiveGridDashboard: ResponsiveGridDashboard,
    GlobalSearchView: GlobalSearchView,

    FileView: FileView,

    PortalAnalytics: (props) => (
        <Suspense fallback={<WaitIcon />}>
            <PortalAnalytics {...props} />
        </Suspense>
    ),
    ReleaseNotes,
};
