import { isObject } from "lodash";
import * as actionTypes from "../actionTypes";
import { getResourceCallbackKey, getResourceKey, getResourceUrl } from "../configureResources";

export const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

export const getResourceList =
    ({
        resourceName,
        path = {},
        query = {},
        appendData,
        onSuccess,
        onError,
        onComplete,
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
    }) =>
    (dispatch, getState) => {
        // TODO: Do nothing if list is already requested,
        // need to somehow reset isReadingList if it stuck in true state and API call is ended
        // or investigate why isReadingList can stuck in true state.
        //const resource = getState().resources[resourceName];
        // if(resource.isReadingList) {
        //     return;
        // }

        dispatch({
            type: actionTypes.API_GET_AUTHORIZED,
            url: getResourceUrl(resourceName, path),
            query: {
                // Remove query params with empty value
                ...Object.keys(query)
                    .filter((key) => query[key] !== null && query[key] !== undefined)
                    .reduce((obj, key) => (obj = { ...obj, [key]: query[key] }), {}),
            },
            actionTypes: {
                pending: actionTypes.API_CRUD_READ_LIST_REQUEST,
                response: actionTypes.API_CRUD_READ_LIST_SUCCESS,
                error: actionTypes.API_CRUD_READ_LIST_ERROR,
            },
            passThroughData: {
                resourceName,
                path,
                appendData,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onSuccess,
                onError,
                onComplete,
            },
        });
    };

export const getResource =
    ({
        resourceName,
        resourceId,
        key,
        path = {},
        query = {},
        onSuccess,
        onError,
        onComplete,
        transform,
        showSuccessNotification = false,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        skipErrorReport,
    }) =>
    (dispatch, getState) => {
        const resourceKey = getResourceKey({ resourceId, key });
        const resourceState = getState().resources[resourceName];

        if (!isObject(resourceState)) {
            throw Error(`Invalid resource name: ${resourceName}. Make sure it is defined in available resources list.`);
        }

        const isLoading = resourceState.itemsById[`${resourceKey}-is-loading`];

        // Do nothing if resource is already loading.
        if (isLoading) {
            // Register callbacks to be called after response is received.
            dispatch({
                type: actionTypes.API_CRUD_REGISTER_CALLBACKS,
                key: getResourceCallbackKey({ resourceName, resourceId, key }),
                callbacks: { onSuccess, onError, onComplete },
            });

            return;
        }

        dispatch({
            type: actionTypes.API_GET_AUTHORIZED,
            url: getResourceUrl(resourceName, path) + (resourceId ? "/" + resourceId : ""),
            query: {
                // Remove query params with empty value
                ...Object.keys(query)
                    .filter((key) => query[key] !== null && query[key] !== undefined)
                    .reduce((obj, key) => (obj = { ...obj, [key]: query[key] }), {}),
            },
            actionTypes: {
                pending: actionTypes.API_CRUD_READ_REQUEST,
                response: actionTypes.API_CRUD_READ_SUCCESS,
                error: actionTypes.API_CRUD_READ_ERROR,
            },
            skipErrorReport,
            passThroughData: {
                resourceName,
                resourceId,
                key,
                path,
                query,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onSuccess,
                onError,
                onComplete,
                transform,
            },
        });
    };

/**
 *
 * @param {object} params
 * @param {string} params.resourceName - Resource name
 * @param {string} [params.resourceId] - Resource id
 * @param {object} [params.path] - Resource path params
 * @param {object} [params.query] - Resource query params
 * @param {any} [params.body] - Resource body
 * @param {boolean} [params.showSuccessNotification=true] - Show success notification
 * @param {boolean} [params.showErrorNotification=true] - Show error notification
 * @param {string} [params.successMessage] - Custom success message
 * @param {string} [params.errorMessage] - Custom error message
 * @param {object} [params.optimisticUpdate] - Optimistic update params
 * @param {function} [params.onSuccess] - Success callback
 * @param {function} [params.onError] - Error callback
 * @param {function} [params.onComplete] - Complete callback
 * @param {boolean} [params.skipErrorReport] - Skip error report
 * @param {string} [params.recaptchaAction] - Action name for recaptcha
 * @returns
 */
export const createResource =
    ({
        resourceName,
        resourceId = null,
        path = {},
        query,
        body,
        showSuccessNotification = true,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        optimisticUpdate,
        onSuccess,
        onError,
        onComplete,
        skipErrorReport,
        recaptchaAction,
    }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.API_POST_AUTHORIZED,
            url: getResourceUrl(resourceName, path),
            query,
            body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
            recaptchaAction,
            actionTypes: {
                pending: actionTypes.API_CRUD_CREATE_REQUEST,
                response: actionTypes.API_CRUD_CREATE_SUCCESS,
                error: actionTypes.API_CRUD_CREATE_ERROR,
            },
            skipErrorReport,
            passThroughData: {
                resourceName,
                resourceId,
                path,
                query,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onSuccess,
                onError,
                onComplete,
            },
        });

        if (optimisticUpdate) {
            const resource = getState().resources[resourceName];

            dispatch({
                type: actionTypes.API_CRUD_OPTIMISTIC_UPDATE_LIST,
                items: resource.items.concat([optimisticUpdate.value]),
                passThroughData: {
                    resourceName,
                },
            });

            if (resourceId) {
                dispatch({
                    type: actionTypes.API_CRUD_OPTIMISTIC_UPDATE_ITEM,
                    id: resourceId,
                    value: optimisticUpdate.value,
                    passThroughData: {
                        resourceName,
                    },
                });
            }
        }
    };

export const updateResource =
    ({
        resourceName,
        resourceId,
        path = {},
        query = {},
        body,
        showSuccessNotification = true,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        optimisticUpdate,
        onSuccess,
        onError,
        onComplete,
        skipErrorReport,
    }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.API_PUT_AUTHORIZED,
            url: getResourceUrl(resourceName, path) + (resourceId ? "/" + resourceId : ""),
            query: {
                // Remove query params with empty value
                ...Object.keys(query)
                    .filter((key) => query[key] !== null && query[key] !== undefined)
                    .reduce((obj, key) => (obj = { ...obj, [key]: query[key] }), {}),
            },
            body: body || body === "" ? JSON.stringify(body) : undefined,
            actionTypes: {
                pending: actionTypes.API_CRUD_UPDATE_REQUEST,
                response: actionTypes.API_CRUD_UPDATE_SUCCESS,
                error: actionTypes.API_CRUD_UPDATE_ERROR,
            },
            skipErrorReport,
            // These params show what resource to update, and on API error removes item with 'resourceId' from store.
            passThroughData: {
                resourceName,
                resourceId,
                path,
                query,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onSuccess,
                onError,
                onComplete,
            },
        });

        //TODO remove this
        if (optimisticUpdate) {
            const { updateList = true, updateItem = true, clearItem = false, key, value } = optimisticUpdate;

            const resource = getState().resources[resourceName];

            if (updateList && key) {
                dispatch({
                    type: actionTypes.API_CRUD_OPTIMISTIC_UPDATE_LIST,
                    items: resource.items.map((item) => {
                        if (item[key] === resourceId) {
                            return value;
                        }

                        return item;
                    }),
                    passThroughData: {
                        resourceName,
                    },
                });
            }

            if (clearItem) {
                dispatch({
                    type: actionTypes.API_CRUD_OPTIMISTIC_UPDATE_ITEM,
                    id: resourceId,
                    value: null,
                    passThroughData: {
                        resourceName,
                    },
                });
            } else {
                if (updateItem) {
                    dispatch({
                        type: actionTypes.API_CRUD_OPTIMISTIC_UPDATE_ITEM,
                        id: resourceId,
                        value: value,
                        passThroughData: {
                            resourceName,
                        },
                    });
                }
            }
        }
    };

export const deleteResource =
    ({
        resourceName,
        resourceId,
        path = {},
        query = {},
        body,
        showSuccessNotification = true,
        showErrorNotification = true,
        successMessage,
        errorMessage,
        optimisticUpdate,
        onSuccess,
        onError,
        onComplete,
        skipErrorReport,
    }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.API_DELETE_AUTHORIZED,
            url: getResourceUrl(resourceName, path) + (resourceId ? "/" + resourceId : ""),
            query: {
                // Remove query params with empty value
                ...Object.keys(query)
                    .filter((key) => query[key] !== null && query[key] !== undefined)
                    .reduce((obj, key) => (obj = { ...obj, [key]: query[key] }), {}),
            },
            body: body || body === "" ? JSON.stringify(body) : undefined,
            actionTypes: {
                pending: actionTypes.API_CRUD_DELETE_REQUEST,
                response: actionTypes.API_CRUD_DELETE_SUCCESS,
                error: actionTypes.API_CRUD_DELETE_ERROR,
            },
            skipErrorReport,
            passThroughData: {
                resourceName,
                resourceId,
                path,
                query,
                showSuccessNotification,
                showErrorNotification,
                successMessage,
                errorMessage,
                onSuccess,
                onError,
                onComplete,
            },
        });

        if (resourceId) {
            // Clear cached value
            dispatch(
                optimisticUpdateItem({
                    resourceName,
                    resourceId,
                    value: null,
                })
            );
        }

        // TODO: remove this
        if (optimisticUpdate) {
            const resource = getState().resources[resourceName];

            dispatch({
                type: actionTypes.API_CRUD_OPTIMISTIC_UPDATE_LIST,
                items: resource.items.filter((item) => item[optimisticUpdate.key] !== resourceId),
                passThroughData: {
                    resourceName,
                },
            });
        }
    };

export const optimisticUpdateItem =
    ({ value, resourceName, resourceId }) =>
    (dispatch) => {
        dispatch({
            type: actionTypes.API_CRUD_OPTIMISTIC_UPDATE_ITEM,
            id: resourceId,
            value,
            passThroughData: {
                resourceName,
            },
        });
    };
