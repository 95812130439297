import React, { useState, memo } from "react";

import { stripHtml } from "./../../utils/string";
import { useResource } from "../../../store/resources/useResource";

import WaitIcon from "../WaitIcon";
import Button from "../Button";
import IconWrap from "../Icons";
import SideNavFooter from "../SideNav/SideNavFooter";
import SideNavBody from "../SideNav/SideNavBody";
import ListFilter, { useListFilter } from "../AssignmentSelector/ListFilter";
import { WarningMessage } from "../Message";
import { getProgramWorkflowAssignmentsResourceParams } from "../../../store/configureResources";
import { sortBy } from "lodash";
import ViewPlaceholder from "../ViewPlaceholder";

import "./style.scss";

const UserGroupsSelector = memo(
    ({ utilityNumber, programNumber, queryType, groupType, disabled, onSubmit, submitText, icon, onBack, hidden, onClose }) => {
        const [selectedGroups, setSelectedGroups] = useState([]);

        const [groups, isReadingList] = useResource(
            programNumber
                ? {
                      ...getProgramWorkflowAssignmentsResourceParams({
                          programNumber,
                      }),
                      transform: (data) => {
                          return (data || []).map((g) => ({
                              ...g,
                              status: "active",
                          }));
                      },
                  }
                : {
                      resourceName: "utilityGroups",
                      key: utilityNumber,
                      path: {
                          utilityNumber,
                      },
                      query: {
                          queryType,
                          groupType,
                      },
                      transform: (data = { groupList: [] }) => {
                          return data.groupList;
                      },
                  }
        );

        const onAdd = (group) => {
            setSelectedGroups([...selectedGroups, group]);
        };

        const onRemove = (group) => {
            const newGroups = [...selectedGroups];
            const groupIndex = newGroups.findIndex((g) => g.groupNumber === group.groupNumber);
            if (groupIndex > -1) {
                newGroups.splice(groupIndex, 1);
                setSelectedGroups(newGroups);
            }
        };

        let availableGroups = groups.reduce((result, group) => {
            if (selectedGroups.filter((g) => g.groupNumber === group.groupNumber).length === 0 && group.status.toLowerCase() === "active") {
                group.groupName = stripHtml(group.groupName);
                result.push(group);
            }

            return result;
        }, []);

        availableGroups = sortBy(availableGroups, (group) => group.groupName);

        const [filteredAvailableGroups, availableGroupsFilterValue, onAvailableGroupsFilterChange] = useListFilter({
            items: availableGroups,
            filterKey: "groupName",
        });

        const [filteredSelectedGroups, selectedGroupsFilterValue, onSelectedGroupsFilterChange] = useListFilter({
            items: selectedGroups,
            filterKey: "groupName",
        });

        return (
            <div className="flex-column flex-one-in-column" hidden={hidden}>
                <SideNavBody noPadding className="utility-user-groups-selector flex-one-in-column">
                    {isReadingList && (
                        <ViewPlaceholder>
                            <WaitIcon />
                        </ViewPlaceholder>
                    )}
                    {!isReadingList && groups?.length === 0 && (
                        <SideNavBody>
                            <div className="application-form-warning-msg">
                                <WarningMessage>There are no user groups available for the program.</WarningMessage>
                            </div>
                        </SideNavBody>
                    )}
                    {!isReadingList && groups?.length > 0 && (
                        <div className="flex-row fill-height">
                            <div className="flex-column column-left flex-one-in-row">
                                <ListFilter
                                    disabled={disabled}
                                    autoFocus
                                    filterValue={availableGroupsFilterValue}
                                    onFilterChange={onAvailableGroupsFilterChange}
                                />
                                <div className="group-list selectable flex-column flex-one-in-column">
                                    {filteredAvailableGroups.map((g) => (
                                        <div key={g.groupNumber} className="group flex-row" onClick={(e) => onAdd(g)}>
                                            <span>{g.groupName}</span>
                                            <span className="flex-one" />
                                            <IconWrap disabled={disabled} iconWrap="arrow-right" title="Add >" onClick={(e) => onAdd(g)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="flex-column column-right flex-one-in-row">
                                <ListFilter
                                    disabled={disabled}
                                    filterValue={selectedGroupsFilterValue}
                                    onFilterChange={onSelectedGroupsFilterChange}
                                />
                                <div className="group-list selected flex-column flex-one-in-column">
                                    {filteredSelectedGroups.map((g) => (
                                        <div key={g.groupNumber} className="group flex-row" onClick={(e) => onRemove(g)}>
                                            <IconWrap
                                                disabled={disabled}
                                                iconWrap="arrow-left"
                                                title="< Remove"
                                                onClick={(e) => onRemove(g)}
                                            />
                                            <span className="flex-one" />
                                            <span>{g.groupName}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </SideNavBody>
                {!isReadingList && (
                    <SideNavFooter setPrimaryButton>
                        <Button icon="shevron-small-left" disabled={disabled} onClick={onBack}>
                            Back
                        </Button>
                        <div className="flex-row flex-one-in-row justify-end">
                            <Button
                                primary
                                icon={icon}
                                disabled={disabled || (groups && groups.length === 0)}
                                onClick={() => onSubmit(selectedGroups)}
                            >
                                {submitText || "Submit"}
                            </Button>
                            <Button disabled={disabled} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                    </SideNavFooter>
                )}
            </div>
        );
    }
);

export default UserGroupsSelector;
