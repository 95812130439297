import React, { useRef, useEffect, useCallback } from "react";
import { isString } from "lodash";

import FieldGroupNumericInput from "../FieldGroupNumericInput";

/**
 * A numeric input field.
 * @param {Object} props - The properties object.
 * @param {string} [props.id] - The input field ID.
 * @param {string} [props.className] - Additional CSS class names to apply to the outermost div of the component.
 * @param {string} [props.label] - The input field label.
 * @param {string} [props.placeholder] - The input field placeholder.
 * @param {boolean} [props.required] - Whether the input field is required.
 * @param {string} [props.name] - The input field name.
 * @param {number} [props.value] - The input field value.
 * @param {boolean} [props.disabled] - Whether the input field is disabled.
 * @param {boolean} [props.readOnly] - Whether the input field is read-only.
 * @param {boolean} [props.withoutIcon] - Whether to hide the input field icon.
 * @param {boolean} [props.error] - Whether the input field has an error.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} [props.onChange] - Function to handle changes in the input value. Receives the new value as its argument.
 * @param {(event: React.KeyboardEvent<HTMLInputElement>) => void} [props.onKeyDown] - Function to handle key down events in the input field. Receives the keyboard event as its argument.
 * @param {(event: React.FocusEvent<HTMLInputElement>) => void} [props.onFocus] - Function to handle focus events in the input field. Receives the focus event as its argument.
 * @param {(event: React.FocusEvent<HTMLInputElement>) => void} [props.onBlur] - Function to handle blur events in the input field. Receives the blur event as its argument.
 * @param {number} [props.min] - The minimum value of the input field.
 * @param {number} [props.max] - The maximum value of the input field.
 * @param {number} [props.decimals] - The number of decimal places to allow.
 * @param {string} [props.title] - The input field title.
 * @param {boolean} [props.lightUpError] - Whether to light up the input field error.
 * @param {string} [props.testId] - The input field test ID.
 */
const NumericInput = ({
    id,
    className,
    label,
    placeholder,
    required,
    name,
    value,
    disabled,
    readOnly,
    withoutIcon,
    error,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    min = 0,
    max,
    decimals,
    title,
    lightUpError,
    testId,
}) => {
    const inputRef = useRef();

    const getValue = useCallback(() => {
        if ((value === undefined || value === null) && inputRef.current) {
            return inputRef.current.value;
        }

        return "" + value;
    }, [value]);

    useEffect(() => {
        if (decimals) {
            let parts = getValue().split(".");

            if (parts[1] && parts[1].length > decimals) {
                parts[1] = parts[1].slice(0, decimals);

                const newValue = parts.join(".");

                onChange({
                    target: {
                        value: +newValue,
                    },
                });
            }
        }
    }, [decimals, getValue, onChange]);

    const onUp = () => {
        let parts = getValue().split(".");
        parts[0] = +parts[0] + 1;
        const newValue = parts.join(".");

        if (max !== undefined && parseFloat(newValue) > max) {
            return;
        }

        onChange({
            target: {
                value: +newValue,
            },
        });

        if (value === undefined || value === null) {
            inputRef.current.value = newValue;
        }
    };

    const onDown = () => {
        let parts = getValue().split(".");
        parts[0] = +parts[0] - 1;
        const newValue = parts.join(".");

        if (min !== undefined && parseFloat(newValue) < min) {
            return;
        }

        onChange({
            target: {
                value: +newValue,
            },
        });

        if (value === undefined || value === null) {
            inputRef.current.value = newValue;
        }
    };

    return (
        <FieldGroupNumericInput
            id={id}
            inputRef={inputRef}
            className={className}
            label={label}
            placeholder={placeholder}
            labelRequired={required}
            disabled={disabled}
            readOnly={readOnly}
            name={name}
            value={value ?? ""}
            error={error}
            msgText={isString(error) ? error : undefined}
            title={title}
            min={min}
            max={max}
            withIcon
            iconRight
            withoutIcon={withoutIcon}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            onUp={onUp}
            onDown={onDown}
            lightUpError={lightUpError}
            testId={testId}
        />
    );
};

export default NumericInput;
