import React, { useCallback, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isObject, omit } from "lodash";

import { pickInitialValues, pickNumber, pickString } from "../../utils/form";
import { setAttribute } from "../../../store/attributes/actions";
import { attributeLookupsToArray, attributeLookupsToString } from "../Form/JsonSchema/widgets/LookupValuesWidget/utils";
import {
    isValidationTypeNumeric,
    isValidationTypeDate,
    valueToString,
    isLowHighLimitRequired,
    isAttributeWithLookups,
    AttributeFieldType,
} from "../../utils/attributes";
import { LIMIT_VALIDATION_TEXT } from "./utils";
import { useProgramAttributes } from "store/resources/useResource";
import { jsonDateToDate } from "components/utils/date";

import JsonSchemaForm from "../Form/JsonSchema/JsonSchemaForm";
import LookupValuesWidget from "../Form/JsonSchema/widgets/LookupValuesWidget";

const AttributeForm = memo(
    ({
        formRef,
        programNumber,
        item,
        entityKey,
        availableAttributesResourceName,
        availableAttributesResourceKeys,
        isSubmitting,
        hidden,
        onError,
    }) => {
        const dispatch = useDispatch();
        const attributes = useSelector((state) => state.attributes[entityKey]) ?? [];

        const attributeId = item._id;

        const numberKey = availableAttributesResourceKeys.number;
        const descriptionKey = availableAttributesResourceKeys.description;

        const isEvent = availableAttributesResourceName === "programEventAttributes";
        const isCatalog = availableAttributesResourceName === "programCatalogAttributes";

        const maxItemOrder = attributes.length - 1;

        const [availableAttributes = []] = useProgramAttributes({
            programNumber,
            resourceName: availableAttributesResourceName,
        });

        const attributeName =
            (item &&
                item[numberKey] &&
                availableAttributes.filter((a) => a[numberKey] === item[numberKey]).map((a) => a[descriptionKey])[0]) ||
            "";

        const hasLookups = isAttributeWithLookups({ attributeName });

        useEffect(() => {
            if (item && item.itemOrder) {
                if (maxItemOrder === item.itemOrder) {
                    if (formRef && formRef.current && formRef.current.formElement) {
                        formRef.current.formElement.scrollIntoView();
                    }
                }
            }
        }, [formRef, item, maxItemOrder]);

        const onChange = useCallback(
            ({ formData, errorSchema }) => {
                // Reset lower/higher limit values if value type changed
                const resetLimits =
                    isValidationTypeNumeric(formData.validationType) !== isValidationTypeNumeric(item.validationType) ||
                    isValidationTypeDate(formData.validationType) !== isValidationTypeDate(item.validationType);

                const attributeValues = omit(formData, ["lookupValues", "defaultValue", "lowerLimit", "upperLimit"]);

                const hasError = isObject(errorSchema)
                    ? Object.keys(formData).some((key) => errorSchema[key]?.__errors?.length > 0)
                    : !isEmpty(errorSchema);

                dispatch(
                    setAttribute({
                        key: entityKey,
                        attribute: {
                            ...attributeValues,
                            lookupValues: hasLookups ? attributeLookupsToArray(formData.lookupValues) : [],
                            defaultValue: valueToString(item.defaultValue, item), // this value does not come from form
                            lowerLimit: resetLimits ? undefined : formData.lowerLimit,
                            upperLimit: resetLimits ? undefined : formData.upperLimit,
                            _id: attributeId,
                            _error: hasError,
                        },
                    })
                );
            },
            [entityKey, attributeId, hasLookups, item, dispatch]
        );

        const required = [numberKey, "validationType"].concat(isLowHighLimitRequired(item, isEvent) ? ["lowerLimit", "upperLimit"] : []);

        const schema = {
            type: "object",
            required,
            properties: {
                ...(isCatalog
                    ? {
                          friendlyNameToolTip: {
                              type: "string",
                              title: "Friendly Name Tooltip",
                          },
                      }
                    : {}),
                validationOther: {
                    type: "string",
                    title: "Validation Other",
                },
                ...(!isEvent || (isEvent && isValidationTypeNumeric(item.validationType))
                    ? {
                          lowerLimit: {
                              type: isValidationTypeNumeric(item.validationType) ? "number" : "string",
                              title: "Low Limit",
                          },
                      }
                    : {}),
                ...(!isEvent || (isEvent && isValidationTypeNumeric(item.validationType))
                    ? {
                          upperLimit: {
                              type: isValidationTypeNumeric(item.validationType) ? "number" : "string",
                              title: "High Limit",
                          },
                      }
                    : {}),
                itemOrder: {
                    type: "integer",
                    title: "Order",
                    minimum: 0,
                    maximum: maxItemOrder,
                },
                ...(isCatalog
                    ? {
                          externalRefId: {
                              type: "string",
                              title: "External Ref ID",
                          },
                          standardFieldMapping: {
                              type: "string",
                              title: "Standard Field Mapping",
                          },
                          dataDescription: {
                              type: "string",
                              title: "Data Description",
                          },
                      }
                    : {}),
                ...(!isCatalog
                    ? {
                          fieldType: {
                              type: "integer",
                              title: "Field Type",
                              anyOf: [
                                  {
                                      title: "Text",
                                      enum: [Number(AttributeFieldType.TEXT)],
                                  },
                                  {
                                      title: "Text Area",
                                      enum: [Number(AttributeFieldType.TEXTAREA)],
                                  },
                              ],
                          },
                      }
                    : {}),
                ...(hasLookups
                    ? {
                          lookupValues: {
                              type: "string",
                          },
                      }
                    : {}),
            },
        };

        const uiSchema = {
            classNames: "inline-form form-columns-5",
            lowerLimit: {
                classNames: "attribute-lower-limit",
                ...(isValidationTypeDate(item.validationType)
                    ? {
                          "ui:widget": "daterangevalidation",
                          "ui:overlap": true,
                          "ui:showInfo": false,
                      }
                    : {}),
            },
            upperLimit: {
                classNames: "attribute-upper-limit",
                ...(isValidationTypeDate(item.validationType)
                    ? {
                          "ui:widget": "daterangevalidation",
                          "ui:overlap": true,
                          "ui:showInfo": false,
                      }
                    : {}),
            },
            itemOrder: {
                classNames: "item-order",
            },
            ...(isCatalog
                ? {
                      dataDescription: {
                          classNames: "data-description",
                          "ui:widget": "textarea",
                      },
                  }
                : {}),
            ...(!isCatalog
                ? {
                      fieldType: {
                          "ui:emptyItem": true,
                          "ui:withPopper": true,
                          "ui:zIndexOffset": 21,
                          "ui:popperPadding": 45,
                      },
                  }
                : {}),
            ...(hasLookups
                ? {
                      lookupValues: {
                          classNames: "attribute-lookup-values",
                          "ui:widget": (props) => (
                              <LookupValuesWidget {...props} entityKey={entityKey} attributeId={attributeId} isCatalog={isCatalog} />
                          ),
                          "ui:options": {
                              displayLabel: false,
                          },
                      },
                  }
                : {}),
        };

        const initialValues = item
            ? {
                  ...pickInitialValues(omit(item, ["_error"])),
                  lookupValues: attributeLookupsToString(item.lookupValues),
                  lowerLimit: isValidationTypeNumeric(item.validationType) ? pickNumber(item.lowerLimit) : pickString(item.lowerLimit),
                  upperLimit: isValidationTypeNumeric(item.validationType) ? pickNumber(item.upperLimit) : pickString(item.upperLimit),
              }
            : {};

        const onValidate = useCallback((formData, errors) => {
            const low = formData?.lowerLimit;
            const high = formData?.upperLimit;

            if (isValidationTypeDate(formData.validationType)) {
                if (jsonDateToDate(low) >= jsonDateToDate(high)) {
                    errors.lowerLimit.addError(LIMIT_VALIDATION_TEXT);
                }
            } else if (isValidationTypeNumeric(formData.validationType)) {
                if (low >= high) {
                    errors.lowerLimit.addError(LIMIT_VALIDATION_TEXT);
                }
            }

            return errors;
        }, []);

        return (
            <JsonSchemaForm
                key={attributeId}
                formRef={formRef}
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                disabled={isSubmitting}
                hidden={hidden}
                liveValidate
                validate={onValidate}
                onChange={onChange}
                onError={onError}
                noActions
                debounce
            />
        );
    }
);

export default AttributeForm;
