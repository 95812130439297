import React, { useState, memo } from "react";

import { restoreDefaultScanQueueOrder } from "../utils";

import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import IconWrap from "../../../ui/Icons";
import Grid from "./Grid";

const GlobalScansQueue = memo(({ onRefresh, visibility }) => {
    const [isVisibleInfo, setIsVisibleInfo] = useState(false);

    return (
        <div className={"scan-queue-management__global-queue flex-column" + (visibility ? "" : " visible")}>
            <div className={"top-block flex-row" + (isVisibleInfo ? " info-block-visible" : "")}>
                <div className="top-block__title">
                    Global Scans Queue
                    <IconWrap
                        icon={isVisibleInfo ? "cancel-clear-circle-highlight-off-filled" : "info-filled"}
                        onClick={() => !setIsVisibleInfo(!isVisibleInfo)}
                        title={isVisibleInfo ? "Hide Info" : "Show Info"}
                    />
                </div>
                <IconWithLabel iconWithLabelRight iconWithLabel="low-priority" onClick={restoreDefaultScanQueueOrder}>
                    Restore default order
                </IconWithLabel>
            </div>
            <div className={"info-block flex-row justify-center" + (isVisibleInfo ? "" : " collapse-info")}>
                <div>
                    <IconWrap iconWrapError iconWrap="arrow-in-circle-up-empty"></IconWrap>
                    High priority
                </div>
                <div>
                    <IconWrap iconWrapTheme iconWrap="arrow-in-circle-down-empty"></IconWrap>
                    Order has been changed
                </div>
                <div>
                    <IconWrap iconWrap="arrow-in-circle-up-empty"></IconWrap>
                    Set high priority
                </div>
            </div>
            <div className="main-block flex-column fill-height no-scroll">
                <Grid onRefresh={onRefresh} />
            </div>
        </div>
    );
});

export default GlobalScansQueue;
