import React, { memo } from "react";
import ViewWithSubmenu from "../../ui/ViewWithSubmenu";

import { systemTabs } from "../../utils/window";
import { systemUserRights } from "components/utils/user";
import { isDevEnvironment } from "components/utils/constants";

const submenuItems = [
    {
        id: "work-center",
        text: "Work Center",
        icon: "star-empty",
        component: "ApplicationProcessingWorkCenter",
    },
    {
        id: "work-center-v2",
        text: "Work Center V2",
        icon: "star-empty",
        component: "ApplicationProcessingWorkCenterV2",
    },
    {
        id: "assigned-apps",
        text: "Assigned Applications",
        icon: "location-user-empty",
        component: "ApplicationProcessingAssignedApps",
        userRights: [
            systemUserRights.VISIONDSM_ADD_APPLICATION,
            systemUserRights.VISIONDSM_MANAGE_APPLICATIONS,
            systemUserRights.VISIONDSM_VIEW_APPLICATION,
            systemUserRights.VISIONDSM_ONLY_ASSIGNED_APPS,
        ],
    },
    {
        id: systemTabs.ApplicationBookmarks,
        text: "Bookmarks",
        icon: "bookmark-empty",
        component: "ApplicationProcessingBookmarks",
        userRights: [
            systemUserRights.VISIONDSM_ADD_APPLICATION,
            systemUserRights.VISIONDSM_MANAGE_APPLICATIONS,
            systemUserRights.VISIONDSM_VIEW_APPLICATION,
        ],
    },
    {
        id: "recent-apps",
        text: "Recent Applications",
        icon: "restore-update",
        component: "ApplicationProcessingRecentApplications",
        userRights: [
            systemUserRights.VISIONDSM_ADD_APPLICATION,
            systemUserRights.VISIONDSM_MANAGE_APPLICATIONS,
            systemUserRights.VISIONDSM_VIEW_APPLICATION,
        ],
    },
    {
        id: "document-queue",
        text: "Document Queue",
        icon: "copy-empty",
        component: "ApplicationProcessingDocumentQueue",
        userRights: [systemUserRights.VISIONDSM_MANAGE_DOCUMENT_QUEUE],
    },
    {
        id: "new-app",
        text: "New Application",
        icon: "new-empty",
        component: "NewApplication",
        userRights: [systemUserRights.VISIONDSM_ADD_APPLICATION, systemUserRights.VISIONDSM_MANAGE_APPLICATIONS],
    },
    {
        id: "scan-queue",
        text: "Scan Queue",
        icon: "overscan-empty",
        component: "ScanQueue",
        userRights: [systemUserRights.VISIONDSM_MANAGE_SCANGROUPS, systemUserRights.VISIONDSM_ADD_APPLICATION],
    },

    // Show the new work center only in dev environment
].filter((item) => isDevEnvironment || item.id !== "work-center-v2");

const ApplicationProcessing = memo(() => {
    return (
        <ViewWithSubmenu
            className="app-processing-view"
            viewName="ApplicationProcessing"
            menuItems={submenuItems}
            viewPlaceholder="Application Processing"
        />
    );
});

export default ApplicationProcessing;
