import React, { useCallback } from "react";
import ClassNames from "classnames";
import useFocusClasses from "../../utils/useFocusClasses";

import "./style.scss";

const Input = (props) => {
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: props.disabled,
    });

    const onFocus = useCallback(
        (event) => {
            onFocusClassesFocus(event);
            props.onFocus && props.onFocus(event);
        },
        [onFocusClassesFocus, props]
    );

    const onBlur = useCallback(
        (event) => {
            onFocusClassesBlur(event);
            props.onBlur && props.onBlur(event);
        },
        [onFocusClassesBlur, props]
    );

    const classes = ClassNames(props.withIcon, props.type, props.className, {
        error: props.error,
        focus: props.focus,
        frozen: props.frozen,
        right: props.iconRight,
        "with-two-icons": props.twoIcons,
        "with-one-icon": props.withIcon,
        highlighted: props.highlighted,
        numeric: props.numeric,
        "numeric-input-filled": props.numericInputFilled,
        "input-read-only": props.readOnly,
        "input-expanded": props.inputExpanded,
        "input-table-filter": props.inputTableFilter,
        "checked-partly": props.checkedPartly,
        "with-label-inside": props.withLabelInside,
        "light-up-error": props.lightUpError,
    });

    const {
        withIcon,
        className,
        error,
        frozen,
        iconRight,
        twoIcons,
        focus,
        highlighted,
        numeric,
        inputExpanded,
        numericInputFilled,
        inputRef,
        inputTableFilter,
        checkedPartly,
        withLabelInside,
        lightUpError,
        onClick,
        ariaLabel,
        ...rest
    } = props;

    return (
        <input
            ref={props.inputRef}
            className={classes}
            aria-label={ariaLabel}
            {...rest}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
        />
    );
};

export default Input;
