import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconSquare } from "../../IconSquare";
import cn from "classnames";

export const VariablePath: React.FC<{
    className?: string;
    icon: IconProp;
    size?: SizeProp;
    showTopLine?: boolean;
    showBottomLine?: boolean;
    topLineStyle?: React.CSSProperties;
    bottomLineStyle?: React.CSSProperties;
}> = ({ className, icon, size = "sm", showTopLine = true, showBottomLine = true, topLineStyle, bottomLineStyle }) => {
    return (
        <div className={cn("flex-column align-center", className)}>
            {showTopLine && <ItemLine style={{ height: 14, ...topLineStyle }} />}
            <IconSquare>
                <FontAwesomeIcon icon={icon} size={size} fixedWidth />
            </IconSquare>
            {showBottomLine && <ItemLine style={{ flexGrow: 1, ...bottomLineStyle }} />}
        </div>
    );
};

const ItemLine: React.FC<{
    style?: React.CSSProperties;
}> = ({ style }) => <div className="bg-grey-400 bg-opacity-100" style={{ width: 2, ...style }}></div>;
