import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import { Dashboard } from "./Dashboard";
import { NewFilter } from "./Filter";
import { DashboardHeader, FilterHeader, NewFilterHeader } from "./Header";
import { Sidebar } from "./Sidebar";
import {
    confirmFilterCancel,
    createFilter,
    deleteFilter,
    favoriteFilter,
    FilterConfig,
    FilterErrors,
    FilterTab,
    FilterVariable,
    sanitizeFilterConfig,
    updateFilter,
    useFilterConfig,
    useGetFilters,
    validateConfig,
} from "./utils";
import { isEmpty, isNil } from "lodash";
import WaitIcon from "components/ui/WaitIcon";
import { FilterExplore } from "./FilterExplore";
import { FilterManage } from "./FilterManage";
import { FilterShare } from "./FilterShare";
import { FilterExploreOptionalParams, FilterGroup, FilterType } from "./FilterExplore/types";
import { openErrorModal } from "components/ui/Modal/utils";
import { useIsDesktop } from "components/utils/useIsDesktop";

import "./styles.scss";

export const ApplicationProcessingWorkCenterV2 = () => {
    const dispatch = useDispatch();
    const isDesktop = useIsDesktop();

    const [sidebarExpanded, setSidebarExpanded] = useState(isDesktop);
    const [selectedFilterNumber, setSelectedFilterNumber] = useState<string>();
    const [activeView, setActiveView] = useState<ActiveView | null>(null);

    const [isEditingFilter, setIsEditingFilter] = useState(false);

    const [allFilters, isLoadingAllFilters] = useGetFilters();
    const [filterConfig, isLoadingFilter] = useFilterConfig(selectedFilterNumber, activeView === "Manage");
    const [filterErrors, setFilterErrors] = useState<FilterErrors>();
    const [variableToManage, setVariableToManage] = useState<FilterVariable>();

    const [isFilterChanged, setIsFilterChanged] = useState(false);

    const updatedConfigRef = useRef<FilterConfig>();
    const filterExploreParamsRef = useRef<FilterExploreOptionalParams>({});

    const filterInfo = allFilters?.find((f) => f.datasourceNumber === selectedFilterNumber);

    if (isNil(updatedConfigRef.current) && !isNil(filterConfig)) {
        updatedConfigRef.current = filterConfig;
    }

    if (isNil(activeView) && !isNil(allFilters)) {
        setActiveView(
            (allFilters?.length && allFilters?.length > 0) || localStorage.getItem("workcenter-onboarding-complete")
                ? "Dashboard"
                : "NewFilter"
        );
    }

    const onDashboardOpen = () => {
        setActiveView("Dashboard");
        setSelectedFilterNumber(undefined);
        setIsEditingFilter(false);
        setFilterErrors(undefined);
        updatedConfigRef.current = undefined;
    };

    const onNewFilterOpen = () => {
        setActiveView("NewFilter");
        setSelectedFilterNumber(undefined);
        setVariableToManage(undefined);
        setIsEditingFilter(false);
        setFilterErrors(undefined);
        updatedConfigRef.current = undefined;
        setSidebarExpanded(false);
    };

    const onFilterExplore = (filterNumber?: string, filterGroup?: FilterGroup, filterType?: FilterType) => {
        setIsEditingFilter(false);
        setFilterErrors(undefined);
        setSelectedFilterNumber(filterNumber);

        filterExploreParamsRef.current = {};
        if (filterGroup && filterType) {
            filterExploreParamsRef.current = {
                filterGroup,
                filterType,
                showResults: true,
            };
        }

        setActiveView("Explore");
    };

    const onFilterManage = (filterNumber?: string) => {
        setIsEditingFilter(false);
        setFilterErrors(undefined);
        setVariableToManage(undefined);
        setSelectedFilterNumber(filterNumber);
        updatedConfigRef.current = undefined;
        setActiveView("Manage");
    };

    const onFilterShare = (filterNumber?: string) => {
        setIsEditingFilter(false);
        setFilterErrors(undefined);
        setSelectedFilterNumber(filterNumber);
        setActiveView("Share");
    };

    const onFilterTabChange = (tab: FilterTab) => {
        switch (tab) {
            case "Explore":
                onFilterExplore(selectedFilterNumber);
                break;
            case "Manage":
                onFilterManage(selectedFilterNumber);
                break;
            case "Share":
                onFilterShare(selectedFilterNumber);
                break;
            default:
                break;
        }
    };

    const onFilterFavorite = (filterNumber: string) => {
        favoriteFilter(filterNumber, dispatch);
    };

    const onFilterDelete = (filterNumber: string) => {
        if (!isEmpty(filterNumber) && filterInfo) {
            deleteFilter(filterNumber, filterInfo.datasourceName, dispatch, () => setSelectedFilterNumber(undefined));
            setActiveView("Dashboard");

            if (isDesktop) {
                setSidebarExpanded(true);
            }
        }
    };

    const onChangeFilterName = (filterName: string, callback: (error?: string) => void) => {
        if (selectedFilterNumber && filterInfo && filterConfig) {
            if (
                allFilters
                    ?.filter((f) => !f.isShared && f.datasourceNumber !== filterInfo.datasourceNumber)
                    .some((f) => f.datasourceName === filterName)
            ) {
                callback("Filter name must be unique");
                return;
            }

            updateFilter(
                selectedFilterNumber,
                {
                    datasourceName: filterName,
                    isDefault: filterInfo.isDefault,
                    allowshare: filterInfo.isShared,
                    statusItems: [],
                    datasourceConfig: JSON.stringify(sanitizeFilterConfig(filterConfig)),
                },
                dispatch
            );

            callback();
        }
    };

    const onFilterChange = useCallback((variables: FilterVariable[]) => {
        updatedConfigRef.current = variables;
        const errors = validateConfig(updatedConfigRef.current);
        setFilterErrors(errors);
    }, []);

    const onFilterDiscardChanges = () => {
        confirmFilterCancel(() => {
            setIsEditingFilter(false);
            setFilterErrors(undefined);
            setIsFilterChanged((prev) => !prev);
          
            if (isDesktop) {
                setSidebarExpanded(true);
            }
        });
    };

    const onFilterSave = () => {
        const errors = updatedConfigRef.current ? validateConfig(updatedConfigRef.current) : undefined;
        setFilterErrors(errors);

        if (isEmpty(errors)) {
            if (activeView === "NewFilter") {
                if (!updatedConfigRef.current) {
                    openErrorModal({
                        text: "Please add at least one variable to the filter",
                    });

                    return;
                }

                createFilter(
                    updatedConfigRef.current,
                    dispatch,
                    (filterNumber: string, filterGroup?: FilterGroup, filterType?: FilterType) => {
                        if (filterGroup && filterType) {
                            onFilterExplore(filterNumber, filterGroup, filterType);
                        } else {
                            setActiveView("Dashboard");

                            if (isDesktop) {
                                setSidebarExpanded(true);
                            }
                        }
                    }
                );
            } else if (selectedFilterNumber && filterInfo && updatedConfigRef.current) {
                if (isEmpty(updatedConfigRef.current)) {
                    openErrorModal({
                        text: "Please add at least one variable to the filter",
                    });
                    return;
                }

                updateFilter(
                    selectedFilterNumber,
                    {
                        datasourceName: filterInfo.datasourceName,
                        isDefault: filterInfo.isDefault,
                        allowshare: filterInfo.isShared,
                        statusItems: [],
                        datasourceConfig: JSON.stringify(sanitizeFilterConfig(updatedConfigRef.current)),
                    },
                    dispatch
                );

                setIsEditingFilter(false);

                if (isDesktop) {
                    setSidebarExpanded(true);
                }
            }
        }
    };

    const onNewFilterCancel = () => {
        confirmFilterCancel(() => {
            setActiveView("Dashboard");
            setFilterErrors(undefined);

            if (isDesktop) {
                setSidebarExpanded(true);
            }
        });
    };

    const onVariableEdit = (variable: FilterVariable) => {
        setActiveView("Manage");
        setVariableToManage(variable);
        setIsEditingFilter(true);
        setFilterErrors(undefined);
    };

    const showFilter =
        activeView !== "Dashboard" &&
        activeView !== "NewFilter" &&
        !isNil(filterConfig) &&
        !isNil(filterInfo) &&
        !isNil(selectedFilterNumber);
    const showFilterHeader = !isNil(filterInfo) && !isNil(selectedFilterNumber) && !isEditingFilter;
    const showLoading = (isLoadingFilter && isNil(filterConfig)) || (isLoadingAllFilters && isNil(allFilters));

    return (
        <div className="workcenter-v2-view flex-row flex-one-in-column fill-width no-scroll relative">
            <Sidebar
                show={sidebarExpanded}
                isDashboardActive={activeView === "Dashboard"}
                filters={allFilters ?? []}
                selectedFilterNumber={selectedFilterNumber}
                onDashboardOpen={onDashboardOpen}
                onNewFilterOpen={onNewFilterOpen}
                onFilterSelect={onFilterExplore}
                onFilterShare={onFilterShare}
                onFilterManage={onFilterManage}
                onFilterFavorite={onFilterFavorite}
                onBackdropClick={() => setSidebarExpanded(false)}
            />
            <div className="flex-one-in-row flex-column fill-width">
                {activeView === "Dashboard" && (
                    <DashboardHeader isSidebarExpanded={sidebarExpanded} onToggleSidebar={() => setSidebarExpanded(!sidebarExpanded)} />
                )}
                {activeView === "NewFilter" && <NewFilterHeader onSave={onFilterSave} onCancel={onNewFilterCancel} />}
                {showFilterHeader && (
                    <FilterHeader
                        key={filterInfo.datasourceNumber}
                        title={filterInfo.datasourceName}
                        activeTab={activeView as FilterTab}
                        isSidebarExpanded={sidebarExpanded}
                        isFavorite={filterInfo.isFavorite}
                        sharedBy={filterInfo.sharedBy}
                        onToggleSidebar={() => setSidebarExpanded(!sidebarExpanded)}
                        onEdit={() => onFilterTabChange("Manage")}
                        onClone={() => alert("Not implemented")}
                        onDelete={() => onFilterDelete(selectedFilterNumber)}
                        onFavorite={() => onFilterFavorite(selectedFilterNumber)}
                        onSelectTab={onFilterTabChange}
                        onChangeFilterName={onChangeFilterName}
                    />
                )}
                <main className="flex-column flex-one-in-column bg-white w-full with-scroll">
                    {showLoading ? (
                        <ViewPlaceholder>
                            <WaitIcon />
                        </ViewPlaceholder>
                    ) : (
                        <>
                            {activeView === "Dashboard" && <Dashboard onNewFilterOpen={onNewFilterOpen} />}
                            {activeView === "NewFilter" && (
                                <NewFilter noFilters={allFilters?.length === 0} errors={filterErrors} onChange={onFilterChange} />
                            )}
                            {activeView === "Explore" && showFilter && (
                                <FilterExplore
                                    key={selectedFilterNumber}
                                    filterNumber={selectedFilterNumber}
                                    onVariableEdit={onVariableEdit}
                                    {...filterExploreParamsRef.current}
                                />
                            )}
                            {activeView === "Manage" && showFilter && (
                                <FilterManage
                                    key={`${isFilterChanged}-${selectedFilterNumber}`}
                                    filterNumber={selectedFilterNumber}
                                    activeVariable={variableToManage}
                                    readOnly={!isEditingFilter}
                                    errors={filterErrors}
                                    onEdit={() => {
                                        setSidebarExpanded(false);
                                        setIsEditingFilter(true);
                                    }}
                                    onDiscardChanges={onFilterDiscardChanges}
                                    onSaveAndShowResults={(filterGroup, filterType) =>
                                        onFilterExplore(selectedFilterNumber, filterGroup, filterType)
                                    }
                                    onSave={onFilterSave}
                                    onChange={onFilterChange}
                                />
                            )}
                            {activeView === "Share" && showFilter && (
                                <FilterShare key={selectedFilterNumber} filterNumber={selectedFilterNumber} />
                            )}
                        </>
                    )}
                </main>
            </div>
        </div>
    );
};

type ActiveView = "Explore" | "Manage" | "Share" | "Dashboard" | "NewFilter";
