import React, { useCallback, useMemo, memo } from "react";

import { useResource } from "../../../../../../../../store/resources/useResource";
import { useReference } from "../../../../../../Reference/useReference";
import { referenceTypes } from "../../../../../../Reference/referenceTypes";
import { STEP_TYPES_ATTRIBUTES } from "../../../../../constants/step-types";

import WaitIcon from "../../../../../../WaitIcon";
import JsonSchemaForm from "../../../../../../Form/JsonSchema/JsonSchemaForm";

export const QcForm = memo(({ attributes, content, stepAttributeTypesGetResourceOptions, onChange, onCloseModal }) => {
    const [qcTypes = [], isQcTypesLoading] = useReference(referenceTypes.qcType);
    const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

    const getAttribute = useCallback(
        ({ number = null, code = null }) => {
            if (number) {
                return workflowStepAttributeTypes.find((i) => i.number === number);
            } else if (code) {
                return workflowStepAttributeTypes.find((i) => i.code === code);
            }
        },
        [workflowStepAttributeTypes]
    );

    const schema = useMemo(
        () => ({
            type: "object",
            required: ["qctype", "qcpercent"],
            properties: {
                qctype: {
                    type: "string",
                    title: getAttribute({
                        code: STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_TYPE,
                    }).name,
                    anyOf: qcTypes.map((qcType) => ({
                        title: qcType.display,
                        enum: [qcType.val],
                    })),
                },
                qcpercent: {
                    type: "integer",
                    title: getAttribute({
                        code: STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_THRESHOLD_PERCENTAGE,
                    }).name,
                },
                content: {
                    type: "string",
                    title: "Content",
                },
            },
        }),
        [qcTypes, getAttribute]
    );

    const uiSchema = useMemo(
        () => ({
            classNames: "inline-form form-columns-2",
            qctype: {
                "ui:placeholder": isQcTypesLoading ? "Loading..." : "Existing Correspondence",
                "ui:disabled": isQcTypesLoading || !qcTypes.length,
            },
            content: {
                classNames: "workflow-step-types-widget__form-field workflow-step-types-widget__form-field-textarea fill-width",
                "ui:widget": "textarea",
            },
        }),
        [isQcTypesLoading, qcTypes]
    );

    const initialValues = useMemo(() => {
        const { qctype, qcpercent } = attributes.reduce(
            (acc, { typeNumber, value }) => {
                const attribute = getAttribute({ number: typeNumber });

                if (attribute.code === STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_TYPE) {
                    if (!isQcTypesLoading && value) {
                        acc.qctype = value;
                    }
                } else if (attribute.code === STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_THRESHOLD_PERCENTAGE) {
                    acc.qcpercent = Number(value);
                }

                return acc;
            },
            { qctype: undefined, qcpercent: undefined }
        );

        return {
            qctype,
            qcpercent,
            content,
        };
    }, [attributes, content, isQcTypesLoading, getAttribute]);

    const handleClickSave = useCallback(
        (formData) => {
            const { content, qctype, qcpercent } = formData;

            const attributeQC = getAttribute({
                code: STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_TYPE,
            });
            const attributeTP = getAttribute({
                code: STEP_TYPES_ATTRIBUTES.QUALITY_CONTROL_THRESHOLD_PERCENTAGE,
            });

            onChange({
                content,
                attributes: [
                    {
                        typeNumber: attributeQC.number,
                        name: attributeQC.name,
                        value: qctype || "",
                    },
                    {
                        typeNumber: attributeTP.number,
                        name: attributeTP.name,
                        value: qcpercent ? qcpercent.toString() : "",
                    },
                ],
            });

            onCloseModal();
        },
        [getAttribute, onChange, onCloseModal]
    );

    if (isLoadingWorkflowStepAttributeTypes) {
        return <WaitIcon />;
    }

    return (
        <JsonSchemaForm
            className="workflow-step-types-widget__form"
            schema={schema}
            uiSchema={uiSchema}
            initialValues={initialValues}
            withCancel
            noReset
            centeredFooter
            submitText="Save"
            onSubmit={handleClickSave}
            onCancel={onCloseModal}
        />
    );
});
