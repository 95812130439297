import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/ui/Button";
import { FilterConfig, FilterVariable, isConfigChanged, useFilterConfig } from "../../utils";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import WaitIcon from "components/ui/WaitIcon";
import { VariableBlock } from "../../Filter/VariableBlock";
import { VariablesList } from "./VariablesList";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/Popover";

export const VariablesSelector: React.FC<{
    filterNumber: string;
    updatedFilterConfig?: FilterConfig;
    onEdit: (variable: FilterVariable) => void;
    onSubmit: (filterConfig: FilterConfig) => void;
}> = ({ filterNumber, updatedFilterConfig, onEdit, onSubmit }) => {
    const [expanded, setExpanded] = useState(false);
    const [filterConfig] = useFilterConfig(filterNumber);

    const variablesCount = updatedFilterConfig?.filter((v) => v._enabled).length ?? filterConfig?.length ?? 0;

    return (
        <Popover open={expanded} onOpenChange={setExpanded}>
            <PopoverTrigger asChild>
                <button
                    className="wc-btn border rounded px-2 py-1 bg-white flex-row align-center gap-2"
                    onClick={() => setExpanded((prev) => !prev)}
                >
                    <span>Variables</span>
                    <span className="border border-grey-400 rounded px-2 py-1 bg-grey-200">{variablesCount}</span>
                    <FontAwesomeIcon className="expand-icon rotated" icon={["far", "chevron-right"]} fixedWidth size="sm" />
                </button>
            </PopoverTrigger>
            <PopoverContent align="start" hideWhenDetached>
                <PopupComponent
                    filterNumber={filterNumber}
                    updatedFilterConfig={updatedFilterConfig}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    onClose={() => setExpanded(false)}
                />
            </PopoverContent>
            {expanded && <div className="content-wrap-backdrop"></div>}
        </Popover>
    );
};

const PopupComponent: React.FC<{
    filterNumber: string;
    updatedFilterConfig?: FilterConfig;
    onEdit: (variable: FilterVariable) => void;
    onSubmit: (filterConfig: FilterConfig) => void;
    onClose: () => void;
}> = ({ filterNumber, updatedFilterConfig, onEdit, onSubmit, onClose }) => {
    const [selectedVariable, setSelectedVariable] = useState<FilterVariable>();
    const [filterConfig, setFilterConfig] = useState<FilterVariable[]>();
    const [data, isLoading] = useFilterConfig(filterNumber, false);

    const originalConfig = useMemo(
        () =>
            data?.map((variable) => ({
                ...variable,
                items: variable.items.map((item) => ({ ...item, _enabled: true })),
                _enabled: true,
            })),
        [data]
    );

    const initialConfig = useMemo(() => updatedFilterConfig ?? originalConfig, [originalConfig, updatedFilterConfig]);

    if (!selectedVariable && initialConfig && !isEmpty(initialConfig)) {
        setSelectedVariable(initialConfig[0]);
        setFilterConfig(initialConfig);
    }

    const isChanged = isConfigChanged(filterConfig, initialConfig);

    const selectedVariableIndex = useMemo(() => filterConfig?.findIndex((v) => v === selectedVariable), [selectedVariable, filterConfig]);

    const onVariableToggle = (enabled: boolean, variable: FilterVariable | undefined = undefined) => {
        const variableIndex = variable ? filterConfig?.findIndex((v) => v === variable) : selectedVariableIndex;

        if (!isNil(variableIndex) && filterConfig?.[variableIndex]) {
            const updatedVariables = cloneDeep(filterConfig);
            updatedVariables[variableIndex] = {
                ...updatedVariables[variableIndex],
                _enabled: enabled,
            };
            setFilterConfig(updatedVariables);

            // Update the selected variable to have up to date reference
            if (!isNil(selectedVariableIndex)) {
                setSelectedVariable(updatedVariables[selectedVariableIndex]);
            }
        }
    };

    const onVariableEdit = () => {
        if (!isNil(selectedVariableIndex) && filterConfig?.[selectedVariableIndex]) {
            onEdit(filterConfig[selectedVariableIndex]);
        }
    };

    const onItemToggle = (itemIndex: number) => {
        if (!isNil(selectedVariableIndex) && filterConfig?.[selectedVariableIndex]) {
            const updatedVariables = cloneDeep(filterConfig);
            const item = updatedVariables[selectedVariableIndex].items[itemIndex];

            updatedVariables[selectedVariableIndex].items[itemIndex] = {
                ...item,
                _enabled: !item._enabled,
            };

            setFilterConfig(updatedVariables);
            setSelectedVariable(updatedVariables[selectedVariableIndex]);
        }
    };

    const onItemToggleValues = (itemIndex: number, values: string[]) => {
        if (!isNil(selectedVariableIndex) && filterConfig?.[selectedVariableIndex]) {
            const updatedVariables = cloneDeep(filterConfig);
            const item = updatedVariables[selectedVariableIndex].items[itemIndex];

            updatedVariables[selectedVariableIndex].items[itemIndex] = {
                ...item,
                values,
            };

            setFilterConfig(updatedVariables);
            setSelectedVariable(updatedVariables[selectedVariableIndex]);
        }
    };

    const onApplyChanges = () => {
        if (filterConfig) {
            onSubmit(filterConfig);
            onClose();
        }
    };

    return (
        <div className="workcenter-v2-view">
            <div className="flex-column z-10 bg-white rounded border no-scroll" style={{ height: 580 }}>
                <div className="flex-row fill-height no-scroll">
                    {isLoading && !filterConfig && (
                        <ViewPlaceholder>
                            <WaitIcon />
                        </ViewPlaceholder>
                    )}
                    {!isLoading && !filterConfig && (
                        <ViewPlaceholder>
                            <div className="text-secondary">No filter found</div>
                        </ViewPlaceholder>
                    )}
                    {!isLoading && filterConfig && (
                        <VariablesList
                            filterConfig={filterConfig}
                            selectedVariable={selectedVariable}
                            onSelectVariable={setSelectedVariable}
                            onVariableToggle={(variable) => onVariableToggle(!variable._enabled, variable)}
                        />
                    )}
                    <div className="pt-3 overflow-y-auto bg-theme-white px-2" style={{ width: 440 }}>
                        {selectedVariable && (
                            <VariableBlock
                                variable={selectedVariable}
                                onVariableToggle={onVariableToggle}
                                onItemToggle={onItemToggle}
                                onItemToggleValues={onItemToggleValues}
                                onVariableEdit={onVariableEdit}
                            />
                        )}
                    </div>
                </div>
                <div className="flex-row border-top p-4 justify-space-between">
                    <div className="flex-row gap-2 align-center text-secondary">
                        <FontAwesomeIcon icon={["far", "floppy-disk-circle-xmark"]} />
                        <span>Changes will not affect the saved filter</span>
                    </div>
                    <div className="flex-row gap-3 align-center">
                        <Button onClick={onClose}>Cancel</Button>
                        <Button primary onClick={onApplyChanges} disabled={!isChanged}>
                            Apply Changes
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
