import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ErrorMessage: React.FC<{
    error: string;
}> = ({ error }) => {
    return (
        <p className="flex-row align-center gap-2 px-2 fs-14 fw-400 text-danger">
            <FontAwesomeIcon icon={["far", "circle-exclamation"]} fixedWidth size="sm" />
            {error}
        </p>
    );
};
