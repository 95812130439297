import React, { memo, useCallback } from "react";
import { PropertyListItem } from "../Property/PropertyListItem";
import { SelectBoxProperty } from "../Property/SelectBoxProperty";
import { SwitchProperty } from "../Property/SwitchProperty";
import { TextProperty } from "../Property/TextProperty";
import { LinkType, ValidationRule } from "../../types";
import { isNil } from "lodash";

import "./LinkUrlProperty.scss";
import { SelectProperty } from "../Property/SelectProperty";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { isSamePage } from "../../utils/page";

export const LinkUrlProperty = memo((props) => {
    const { value, onChange, title, errors = {}, listContainsActiveError, noSelectFromExistingPages, disabled } = props;

    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);

    const links = updatedConfig.content.pages.map((p) => {
        return {
            label: p.link,
            value: p.link,
        };
    });

    const items = [
        {
            text: "Internal",
            value: "internal",
        },
        {
            text: "External",
            value: "external",
        },
    ];

    const onPropertyChange = useCallback(
        (id, value) => {
            const val = id === "target" ? "_blank" : true;
            if (!value) {
                onChange(id, false);
            } else {
                onChange(id, val);
            }
        },
        [onChange]
    );

    // mark duplicate links without actually having 2 pages with the same link, because we use link as an ID in other parts of code
    const onLinkChange = (id, newValue) => {
        if (updatedConfig.content.pages.find((p) => isSamePage(newValue, p.link))) {
            onChange(id, "/duplicate_page_link" + new Date().getTime(), [{ id: "_attempted_page_url", value: newValue }]);
        } else if (value["_attempted_page_url"]) {
            onChange(id, newValue, [{ id: "_attempted_page_url", value: null }]);
        } else {
            onChange(id, newValue);
        }
    };

    return (
        <PropertyListItem className="property-list-item--link-url flex-column align-center justify-space-between" innerContent>
            <SelectBoxProperty
                id="linkType"
                value={value.linkType ?? LinkType.INTERNAL}
                title={title}
                items={items}
                borderBottom={false}
                hidden={noSelectFromExistingPages}
                onChange={onChange}
                validationRules={[ValidationRule.Required]}
            />
            {(value.linkType === LinkType.INTERNAL || isNil(value.linkType)) && !noSelectFromExistingPages && (
                <SwitchProperty
                    title={"Select from existing pages"}
                    id={"internalPageSelect"}
                    value={value.internalPageSelect ?? false}
                    onChange={(id, value) => onPropertyChange(id, value)}
                    borderBottom={false}
                    fullWidth={false}
                />
            )}
            {value.internalPageSelect && !noSelectFromExistingPages && (value.linkType === LinkType.INTERNAL || isNil(value.linkType)) ? (
                <SelectProperty
                    errors={errors.link}
                    id="link"
                    items={links}
                    value={value.link}
                    onChange={onChange}
                    placeholder="Select url"
                    inline={false}
                    borderBottom={false}
                    className="no-padding"
                    listContainsActiveError={listContainsActiveError}
                />
            ) : (
                <TextProperty
                    errors={errors.link}
                    id="link"
                    value={value["_attempted_page_url"] ?? value.link}
                    fullWidth={true}
                    defaultValue=""
                    borderBottom={false}
                    onChange={onLinkChange}
                    disabled={disabled}
                    listContainsActiveError={listContainsActiveError}
                />
            )}
            {value.linkType === LinkType.EXTERNAL && !noSelectFromExistingPages && (
                <SwitchProperty
                    title={"Open in the new Tab"}
                    id={"target"}
                    value={value.target ?? false}
                    onChange={(id, value) => onPropertyChange(id, value)}
                    borderBottom={false}
                />
            )}
        </PropertyListItem>
    );
});
