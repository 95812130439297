import { decodeHtml } from "components/utils/string";
import { isString } from "lodash";
import React, { useState, memo, useCallback, useMemo, useRef, useEffect } from "react";
import FieldGroupDropdown from "../FieldGroupDropdown";

const DropDownInput = memo((props) => {
    const {
        className,
        name,
        id,
        label,
        title,
        placeholder,
        mobileHeader,
        data,
        value,
        disabled,
        readOnly,
        withPopper,
        popupBoundary,
        inline,
        emptyItem,
        emptyItemLabel,
        required,
        msgInfo,
        msgError,
        msgText,
        noBorders,
        onChange,
        extraInput,
        error,
        lightUpError,
        zIndexOffset,
        popperPadding,
        defaultOpen,
        testId,
    } = props;

    const [isOpen, setIsOpen] = useState(defaultOpen ?? false);
    const [focusedItemIndex, setFocusedItemIndex] = useState(null);
    const [filterValue, setFilterValue] = useState("");

    const ref = useRef();

    const withFilter = (data || []).length > 5;

    const items = useMemo(() => {
        const firstItem = emptyItem
            ? [
                  {
                      label: <EmptyItemLabel>{emptyItemLabel}</EmptyItemLabel>,
                      value: undefined,
                  },
              ]
            : [];

        const filter = (filterValue || "").toLowerCase();

        return firstItem.concat(
            (data || [])
                .filter((item) => filter === "" || (item.label || "").toLowerCase().includes(filter))
                .map((item, index) => ({
                    ...item,
                    label: isString(item.label) ? decodeHtml(item.label) : item.label,
                    selected: item.value === value,
                    focused: index === focusedItemIndex,
                }))
        );
    }, [data, value, emptyItem, emptyItemLabel, filterValue, focusedItemIndex]);

    useEffect(() => {
        if (isOpen && focusedItemIndex === null) {
            const selectedElement = ref.current.querySelector(".dropdown-list-item.selected");

            if (selectedElement) {
                selectedElement.scrollIntoView({
                    block: "nearest",
                    inline: "start",
                });
            }
        }
    }, [isOpen, focusedItemIndex, items]);

    const onListSelect = useCallback(
        (item) => {
            setIsOpen(false);
            setFilterValue("");

            if (onChange) {
                onChange({
                    target: {
                        type: "select",
                        name: name,
                        value: item?.value,
                        label: item?.label,
                    },
                });
            }
        },
        [name, onChange]
    );

    const onListToggle = useCallback(() => {
        (data || []).length && setIsOpen((state) => !state);
    }, [data]);

    const onFilterChange = useCallback((event) => {
        setFilterValue(event.target.value);
    }, []);

    const onListKeyDown = useOnListKeyDown({
        items,
        onListSelect,
        focusedItemIndex,
        setFocusedItemIndex,
        ref,
    });

    const getDisplayValue = () => {
        let result = "";

        if (value === null || value === undefined) {
            result = placeholder;
        } else {
            result = ((data || []).filter((i) => i.value === value)[0] || {}).label || value;
        }

        return isString(result) ? decodeHtml(result) : result;
    };

    return (
        <FieldGroupDropdown
            containerRef={ref}
            className={className}
            id={id}
            required={required}
            error={msgError | error}
            lightUpError={lightUpError}
            inline={inline}
            label={label}
            mobileHeader={mobileHeader}
            dropdownFieldText={getDisplayValue()}
            filled={value !== null && value !== undefined}
            active={isOpen}
            onClick={onListToggle}
            visible={isOpen}
            dropdownFieldIcon={!isOpen ? "shevron-small-down-expand-more" : "shevron-in-circle-up-filled"}
            items={items}
            disabled={disabled}
            readOnly={readOnly}
            onSelect={onListSelect}
            // messages
            msgInfo={msgInfo}
            msgError={msgError}
            msgText={msgText}
            // filter props
            withFilter={withFilter}
            filterValue={filterValue}
            onFilterChange={onFilterChange}
            onListKeyDown={onListKeyDown}
            // dropdown with no borders around
            ghost={noBorders}
            title={title}
            // Render dropdown list in portal with popper
            withPopper={withPopper}
            popupBoundary={popupBoundary}
            extraInput={extraInput}
            zIndexOffset={zIndexOffset}
            popperPadding={popperPadding}
            testId={testId}
        />
    );
});

const EmptyItemLabel = ({ children }) => {
    return <span className="dropdown-list-item--empty">{children}</span>;
};

export const useOnListKeyDown = ({ items, onListSelect, focusedItemIndex, setFocusedItemIndex, ref }) => {
    const focusItem = useCallback(
        (index, element) => {
            if (items.length > index) {
                setFocusedItemIndex(index);
                if (element) {
                    element.scrollIntoView({
                        block: "nearest",
                        inline: "start",
                    });
                }
            }
        },
        [items.length, setFocusedItemIndex]
    );

    return useCallback(
        (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                if (items.length === 1) {
                    onListSelect(items[0]);
                } else {
                    if (focusedItemIndex >= 0) {
                        onListSelect(items[focusedItemIndex]);
                    }
                }
            }

            if (event.key === "ArrowDown") {
                event.preventDefault();
                if (items.length > 0) {
                    if (focusedItemIndex !== null) {
                        const nextItemIndex = focusedItemIndex + 1;

                        if (items.length > nextItemIndex) {
                            const nextItem = ref.current.querySelectorAll(".dropdown-list-item")[nextItemIndex];
                            focusItem(nextItemIndex, nextItem);
                        }
                    } else {
                        const selectedItemIndex = items.findIndex((i) => i.selected);

                        if (selectedItemIndex > -1) {
                            const nextItem = ref.current.querySelector(".dropdown-list-item.selected");
                            focusItem(selectedItemIndex, nextItem);
                        } else {
                            const nextItem = ref.current.querySelector(".dropdown-list-item");
                            focusItem(0, nextItem);
                        }
                    }
                }
            }

            if (event.key === "ArrowUp") {
                event.preventDefault();
                if (items.length > focusedItemIndex && focusedItemIndex > 0) {
                    const nextItemIndex = focusedItemIndex - 1;

                    setFocusedItemIndex(nextItemIndex);
                    const nextItem = ref.current.querySelectorAll(".dropdown-list-item")[nextItemIndex];

                    if (nextItem) {
                        nextItem.scrollIntoView({
                            block: "nearest",
                            inline: "start",
                        });
                        focusItem(nextItemIndex, nextItem);
                    }
                }
            }
        },
        [items, onListSelect, focusedItemIndex, setFocusedItemIndex, ref, focusItem]
    );
};

export default DropDownInput;
