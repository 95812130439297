import { FilterVariablesItem } from "../../utils";
import cn from "classnames";
import { isEmpty } from "lodash";
import { ErrorMessage } from "./ErrorMessage";

export const ValuesRangeBlock: React.FC<{
    item: FilterVariablesItem;
    error?: string;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
}> = ({ item, error, readOnly = false, onChange }) => {
    if (readOnly) {
        return (
            <div className="p-2 border rounded-2 bg-white flex-row align-center fill-width gap-2">
                <div className="bg-grey-200 rounded flex-row gap-1 align-center px-2 py-1">{item.values[0]}</div>
                and
                <div className="bg-grey-200 rounded flex-row gap-1 align-center px-2 py-1">{item.values[1]}</div>
            </div>
        );
    }

    return (
        <div
            className={cn("p-2 border rounded-2 bg-white flex-column fill-width gap-2", {
                "border-danger": !isEmpty(error),
            })}
        >
            {error && <ErrorMessage error={error} />}
            <div className="flex-row align-center fill-width gap-2">
                <input
                    type="number"
                    className={cn("p-2 border", {
                        "border-danger": !isEmpty(error),
                    })}
                    name="value-from"
                    maxLength={100}
                    placeholder="Enter value"
                    aria-label="From value"
                    style={{ width: "100%" }}
                    value={item.values[0]}
                    onChange={(event: { target: { value: string } }) => onChange({ ...item, values: [event.target.value, item.values[1]] })}
                />
                <span>and</span>
                <input
                    type="number"
                    className={cn("p-2 border", {
                        "border-danger": !isEmpty(error),
                    })}
                    name="value-to"
                    maxLength={100}
                    placeholder="Enter value"
                    aria-label="To value"
                    style={{ width: "100%" }}
                    value={item.values[1]}
                    onChange={(event: { target: { value: string } }) => onChange({ ...item, values: [item.values[0], event.target.value] })}
                />
            </div>
        </div>
    );
};
