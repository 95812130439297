import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

/**
 * Portal component.
 *
 * @param {object} props
 * @param {string} [props.className] className
 * @param {HTMLElement} [props.container] container
 * @param {object} props.children
 */
const Portal = ({ className, container, children }) => {
    const portalElementRef = useRef(document.createElement("div"));

    if (className) {
        portalElementRef.current.className = className;
    }

    const containerElement = container ?? document.body;

    useEffect(() => {
        const element = portalElementRef.current;

        element && containerElement.appendChild(element);

        return () => {
            element?.remove();
        };
    }, [containerElement]);

    return ReactDOM.createPortal(children, portalElementRef.current);
};

export default Portal;
