import { FilterSubheader } from "../Header/FilterSubheader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Filter } from "../Filter";
import { FilterErrors, FilterVariable } from "../utils";
import { FilterGroups, FilterGroup, ApplicationFilters, TaskFilters, FilterType } from "../FilterExplore/types";
import { SplitButton } from "../SplitButton";
import Button from "components/ui/Button";

export const FilterManage: React.FC<{
    filterNumber: string;
    readOnly: boolean;
    errors?: FilterErrors;
    activeVariable?: FilterVariable;
    onEdit: () => void;
    onDiscardChanges: () => void;
    onSaveAndShowResults: (filterGroup: FilterGroup, filterType: FilterType) => void;
    onSave: () => void;
    onChange: (variables: FilterVariable[]) => void;
}> = ({ filterNumber, readOnly, errors, activeVariable, onEdit, onDiscardChanges, onSaveAndShowResults, onSave, onChange }) => {
    return (
        <div className="wc-filter-manage flex-column fill-height bg-white pt-8 px-8 pb-2">
            <div className="flex-row justify-between align-center gap-2 pb-8">
                <FilterSubheader title="Manage filter" description="Adjust your saved filters to refine and optimize your results." />
                {readOnly ? (
                    <Button onClick={onEdit}>
                        <div className="flex-row align-center gap-2">
                            <FontAwesomeIcon icon={["fal", "pen-to-square"]} fixedWidth />
                            Edit
                        </div>
                    </Button>
                ) : (
                    <div className="flex-row align-center gap-3 no-shrink">
                        <Button onClick={onDiscardChanges}>Discard all</Button>
                        <SplitButton
                            text="Save and show applications"
                            onClick={() => onSaveAndShowResults(FilterGroups.Applications, ApplicationFilters.Any)}
                            listItems={[
                                {
                                    label: "Show Applications",
                                    onClick: () => onSaveAndShowResults(FilterGroups.Applications, ApplicationFilters.Any),
                                },
                                {
                                    label: "Show Tasks",
                                    onClick: () => onSaveAndShowResults(FilterGroups.Tasks, TaskFilters.Any),
                                },
                            ]}
                        />
                        <Button primary onClick={onSave}>
                            Save changes
                        </Button>
                    </div>
                )}
            </div>
            <Filter filterNumber={filterNumber} readOnly={readOnly} errors={errors} activeVariable={activeVariable} onChange={onChange} />
        </div>
    );
};
