import React, { useState, memo, useContext, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useProgramRights } from "../../../../../../store/resources/useResource";
import { useApprovedEquipment } from "./utils";
import { downloadCSV } from "../../../../../utils/CSV";
import { deleteResource, getResource } from "../../../../../../store/resources/actions";
import { equipmentGridColumnKeys } from "../../../../../views/configureGrids";
import { isApplicationLocked, useEquipmentCalculator } from "../../../../../views/ProjectView/utils";
import { appIsLockedText } from "../utils";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavOpen, sideNavClose } from "../../../../../../store/sideNav/actions";
import { sideNavMode, sideNavSize, getViewSideNavKey, sideNavPosition } from "../../../../SideNav/SideNavRoot";
import SideNavFormContainer from "../../../../SideNavFormContainer";

import EquipmentGrid from "./EquipmentGrid";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import AddEquipment from "./AddEquipment";
import AddApprovedEquipment from "./AddApprovedEquipment";
import CompareEquipmentPanel from "./CompareEquipmentPanel";
import FileUpload from "components/ui/FileUpload";
import { entityType } from "components/utils/entityType";
import { fileType } from "components/utils/fileType";
import { toArray } from "components/utils/array";
import { isNullOrWhitespace } from "components/utils/string";

import { WindowContext } from "../../../../Windows/Window";
import { uploadEquipment } from "store/projects/actions";
import { InfoMessage } from "components/ui/Message";
import Separator from "components/ui/Separator";
import { useReference } from "components/ui/Reference/useReference";
import { referenceTypes } from "components/ui/Reference/referenceTypes";
import { useResource } from "../../../../../../store/resources/useResource";

import { modalOpen } from "store/modal/actions";
import { getData, selectRow, setPage } from "store/dataGrid/actions";
import { ProgramRights } from "components/utils/user";

import "./style.scss";
import "../ProjectCommonStyles.scss";
import useMultiPageRowSelect from "store/dataGrid/useMultiPageRowSelect";
import GridSelectedItemsLabel from "components/ui/DataGrid/GridSelectedItemsLabel";
import Button from "components/ui/Button";

const EquipmentPanel = memo(
    ({
        panel,
        programNumber = panel?.data?.programNumber,
        applicationNumber = panel?.data?.applicationNumber,
        utilityNumber = panel?.data?.utilityNumber,
        showHeader = true,
        showExport = true,
    }) => {
        const dispatch = useDispatch();
        const fileUploadComponentRef = useRef();
        const [programRights = []] = useProgramRights({ programNumber });
        const [selectedRows, setSelectedRows] = useState([]);
        const isAppLocked = isApplicationLocked({ applicationNumber });
        const isAddEquipmentDisabled = isAppLocked || !programRights.includes(ProgramRights.VISIONDSM_ADD_EQUIPMENT);

        const hasEquipmentCalculator = useEquipmentCalculator({
            applicationNumber,
        });

        const equipmentUploadInProgress = useSelector((state) => state.projects.equipmentUploadInProgress[applicationNumber]) ?? false;

        const [approvedEquipment = []] = useApprovedEquipment({
            applicationNumber,
        });
        const dataGridId = `${applicationNumber}-equipment-grid`;
        const gridConfig = useSelector((state) => state.dataGrid[dataGridId]);
        const { viewName } = useContext(WindowContext);
        const entityTypeId = 943;
        const compareEquipmentSideNavId = getViewSideNavKey({
            viewName,
            position: sideNavPosition.right,
        });

        // Preload reference values needed in forms
        useReference(referenceTypes.equipmentValidation);
        useReference(referenceTypes.trueFalse);
        const [files] = useResource({
            resourceName: "fileUploadReqs",
            key: entityTypeId,
            path: {
                entityTypeId: 943,
            },
        });
        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ className: "add-equipment-sidenav-panel" });

        const onRowSelect = useCallback((selectedRows) => {
            setSelectedRows(selectedRows || []);
        }, []);

        const [onRowSelectChange, onPageChanged, onRowSelectClear] = useMultiPageRowSelect({
            dataGridId,
            onRowSelect,
        });

        const handleAdd = useCallback(() => {
            handleOpenSidePanel(
                <AddEquipment
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    onSuccess={handleCloseSidePanel}
                    onClose={handleCloseSidePanel}
                    onRowSelectClear={onRowSelectClear}
                />
            );
        }, [programNumber, applicationNumber, handleOpenSidePanel, handleCloseSidePanel, onRowSelectClear]);

        const handleAddApproved = useCallback(() => {
            handleOpenSidePanel(
                <AddApprovedEquipment
                    applicationNumber={applicationNumber}
                    onClose={handleCloseSidePanel}
                    onRowSelectClear={onRowSelectClear}
                />
            );
        }, [applicationNumber, handleOpenSidePanel, handleCloseSidePanel, onRowSelectClear]);

        const addApprovedEquipmentButton = useCallback(() => {
            if (approvedEquipment && approvedEquipment.length !== 0) {
                return (
                    <IconWithLabel
                        iconWithLabelRight
                        icon={"plus"}
                        disabled={isAddEquipmentDisabled}
                        title={isAppLocked ? appIsLockedText : undefined}
                        onClick={handleAddApproved}
                    >
                        Add Approved Equipment
                    </IconWithLabel>
                );
            }
        }, [approvedEquipment, isAddEquipmentDisabled, isAppLocked, handleAddApproved]);

        const addEquipmentButton = () => {
            return (
                <IconWithLabel
                    iconWithLabelRight
                    icon={"plus"}
                    disabled={isAddEquipmentDisabled}
                    title={isAppLocked ? appIsLockedText : undefined}
                    onClick={handleAdd}
                >
                    Add Equipment
                </IconWithLabel>
            );
        };

        const uploadEquipmentButton = () => {
            if (!hasEquipmentCalculator) {
                return null;
            }

            if (equipmentUploadInProgress) {
                return (
                    <>
                        <InfoMessage>Equipment upload in progress</InfoMessage>
                        <Separator vertical />
                    </>
                );
            }

            return (
                <IconWithLabel
                    iconWithLabelRight
                    icon={"upload"}
                    disabled={isAddEquipmentDisabled}
                    title={isAppLocked ? appIsLockedText : undefined}
                    onClick={handleUpload}
                >
                    Upload Equipment
                </IconWithLabel>
            );
        };

        const onRemoveEquipment = useCallback(
            (equipment) => {
                const index = gridConfig.rows.findIndex((item) => item[equipmentGridColumnKeys.equipmentId] === equipment.equipid);
                dispatch(selectRow({ dataGridId, index: index, isSelected: false }));
                selectedRows.splice(
                    selectedRows.findIndex((item) => item[equipmentGridColumnKeys.equipmentId] !== equipment.equipid),
                    1
                );
            },
            [dataGridId, dispatch, gridConfig, selectedRows]
        );

        const onCompareEquipment = useCallback(() => {
            const equipmentIds = selectedRows.map((item) => item[equipmentGridColumnKeys.equipmentId]);

            if (equipmentIds && equipmentIds.length) {
                const onClose = () => dispatch(sideNavClose({ id: compareEquipmentSideNavId }));

                dispatch(
                    sideNavOpen({
                        id: compareEquipmentSideNavId,
                        props: {
                            mode: sideNavMode.over,
                            backdrop: true,
                            size: sideNavSize.large,
                            children: (
                                <CompareEquipmentPanel
                                    items={equipmentIds}
                                    applicationNumber={applicationNumber}
                                    programNumber={programNumber}
                                    onClose={onClose}
                                    onRemoveEquipment={onRemoveEquipment}
                                />
                            ),
                        },
                    })
                );
            }
        }, [programNumber, applicationNumber, selectedRows, compareEquipmentSideNavId, dispatch, onRemoveEquipment]);

        const onEquipmentUpload = useCallback(
            (fileData) => {
                dispatch(uploadEquipment({ applicationNumber, fileData, onSuccess: handleCloseSidePanel }));
                onRowSelectClear();
            },
            [applicationNumber, dispatch, handleCloseSidePanel, onRowSelectClear]
        );

        const handleUpload = useCallback(() => {
            handleOpenSidePanel(
                <SideNavFormContainer
                    title="Upload Equipment"
                    onClose={handleCloseSidePanel}
                    forwardedFormComponentRef={fileUploadComponentRef}
                    submitText="Upload"
                    titleIcon="upload"
                    fileUpload
                >
                    <FileUpload
                        ref={fileUploadComponentRef}
                        entityTypeId={entityType.calculator}
                        entityId={applicationNumber}
                        fileTypeId={fileType.supportingDocument}
                        withinSideNav
                        withoutHeaderPanel
                        onCancel={handleCloseSidePanel}
                        onFileUploadSubmit={onEquipmentUpload}
                        multiple={false}
                        programNumber={programNumber}
                        useEquipmentTags={true}
                        files={files}
                    />
                </SideNavFormContainer>,
                {
                    size: sideNavSize.small,
                    className: "upload-equipment-sidenav-panel",
                }
            );
        }, [applicationNumber, onEquipmentUpload, handleCloseSidePanel, handleOpenSidePanel, programNumber, files]);

        const onDeleteEquipment = useCallback(() => {
            const equipmentIds = selectedRows.map((item) => item[equipmentGridColumnKeys.equipmentId]);
            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Delete Equipment",
                        overlayClassName: "modal-styled",
                        className: "delete-equipment-confirmation-modal modal-sm",
                        modalIcon: "delete-trash-empty",
                        content: (
                            <p>
                                Are you sure you want to permanently delete <b>{equipmentIds.length}</b> selected equipment? This cannot be
                                undone.
                            </p>
                        ),
                        footerContentCenter: true,
                        onConfirm: async () => {
                            const selectedEquipmentRequestsArr = equipmentIds.map((equipmentId) => {
                                return new Promise((resolve, reject) => {
                                    dispatch(
                                        deleteResource({
                                            resourceName: "equipment",
                                            resourceId: equipmentId,
                                            path: {
                                                appId: applicationNumber,
                                            },
                                            onSuccess: resolve,
                                            onError: reject,
                                        })
                                    );
                                });
                            });
                            await Promise.all(selectedEquipmentRequestsArr);

                            setSelectedRows([]);
                            onRowSelectClear();

                            if (gridConfig?.paging?.skip > 0) {
                                dispatch(
                                    setPage({
                                        dataGridId: dataGridId,
                                        page: {
                                            ...gridConfig.paging,
                                            skip: 0,
                                        },
                                    })
                                );
                            }

                            dispatch(
                                getData({
                                    dataGridId: `${applicationNumber}-equipment-grid`,
                                })
                            );
                        },
                    },
                })
            );
        }, [selectedRows, applicationNumber, dispatch, dataGridId, gridConfig, onRowSelectClear]);

        const exportCSV = () => {
            dispatch(
                getResource({
                    resourceName: "equipmentExport",
                    path: {
                        appId: applicationNumber,
                    },
                    onComplete: (result) => {
                        let data = [];

                        if (result?.data?.equipmentExport) {
                            const equipmentItems = toArray(result.data.equipmentExport?.equipmentItem);

                            let attributeNames = [];
                            let attributeFriendlyNames = {};

                            const equipmentData = equipmentItems.map((item) => {
                                const column = toArray(item.columns.column);

                                column.forEach(function (attr) {
                                    attr.name = attr.name.toUpperCase();
                                });

                                const attributeData = column.reduce((result, attribute) => {
                                    if (!attributeNames.includes(attribute.name)) {
                                        attributeNames.push(attribute.name);
                                    }

                                    if (!isNullOrWhitespace(attribute.friendlyName)) {
                                        if (!attributeFriendlyNames[attribute.name]) {
                                            attributeFriendlyNames[attribute.name] = attribute.friendlyName;
                                        } else {
                                            // Handle case when same attribute has different friendly names
                                            const friendlyNames = attributeFriendlyNames[attribute.name].split("|");
                                            if (!friendlyNames.includes(attribute.friendlyName)) {
                                                attributeFriendlyNames[attribute.name] += "|" + attribute.friendlyName;
                                            }
                                        }
                                    }

                                    return {
                                        ...result,
                                        [attribute.name]: attribute.value ?? "",
                                    };
                                }, {});

                                return {
                                    ID: item.equipid,
                                    "CATALOG ID": item.catalogid,
                                    "EQUIPMENT REFID": item.refId,
                                    "EQUIPMENT CATEGORY": item.category,
                                    "MEASURE NAME": item.name,
                                    QUANTITY: item.quantity,
                                    ...attributeData,
                                };
                            });

                            data = equipmentData.map((item) => {
                                const itemKeys = Object.keys(item);
                                const missingProps = attributeNames
                                    .filter((key) => !itemKeys.includes(key))
                                    .reduce(
                                        (result, key) => ({
                                            ...result,
                                            [key]: "",
                                        }),
                                        {}
                                    );

                                return {
                                    ...item,
                                    ...missingProps,
                                };
                            });

                            // Add friendly Names row
                            if (data.length > 0) {
                                const dataKeys = Object.keys(data[0]);

                                data = [
                                    dataKeys.reduce(
                                        (result, key) =>
                                            (result = {
                                                ...result,
                                                [key]: attributeFriendlyNames[key],
                                            }),
                                        {}
                                    ),
                                ].concat(data);
                            }
                        }

                        downloadCSV({
                            data,
                            fileName: "application_equipment",
                            fileNamePostfix: applicationNumber,
                        });
                    },
                })
            );
        };

        return (
            <div className="panel equipment-panel-large">
                {showHeader && <PanelHeaderLarge title={panel.name} />}
                <div className="data-grid-controls flex-row  justify-space-between">
                    <div className="data-grid-controls__left-side-actions flex-row align-end">
                        {selectedRows?.length ? (
                            <GridSelectedItemsLabel
                                text={
                                    selectedRows?.length ? (
                                        <>
                                            Equipment <br /> selected
                                        </>
                                    ) : (
                                        ""
                                    )
                                }
                                count={selectedRows?.length}
                                onClear={onRowSelectClear}
                            />
                        ) : (
                            <div className="select-label">
                                Select <br /> Equipment
                            </div>
                        )}
                        <Button primary icon="widgets-empty" disabled={selectedRows?.length === 0} onClick={onCompareEquipment}>
                            Compare Equipment
                        </Button>
                        <Button
                            primary
                            icon="delete-trash-empty"
                            disabled={isAddEquipmentDisabled || selectedRows?.length === 0}
                            onClick={onDeleteEquipment}
                        >
                            Delete Equipment
                        </Button>
                    </div>
                    <div className="data-grid-controls__right-side-actions flex-row">
                        {uploadEquipmentButton()}
                        {addApprovedEquipmentButton()}
                        {addEquipmentButton()}
                        {showExport && (
                            <IconWithLabel withHandMadeIcon onClick={() => exportCSV()}>
                                Export CSV
                            </IconWithLabel>
                        )}
                    </div>
                </div>
                <EquipmentGrid
                    onRowSelect={onRowSelect}
                    dataGridId={dataGridId}
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    utilityNumber={utilityNumber}
                    onRowSelectChange={onRowSelectChange}
                    onPageChanged={onPageChanged}
                    onRowSelectClear={onRowSelectClear}
                />
            </div>
        );
    }
);

export default EquipmentPanel;
