import React, { useRef } from "react";
import useOnClickOutside from "../../utils/useOnClickOutside";
import usePopper from "../../utils/usePopper";
import { useIsMobile } from "../../utils/useIsMobile";

/**
 * Encapsulates common popper logic in the wrapper component.
 *
 * @param {functional component} TriggerComponent - The functional component that will trigger the popper
 * @param {functional component} PopupComponent -  The functional component that will be displayed in popper
 * @param {string} placement - Placement parameter for Popper instance (see Popper.js docs)
 * @param {boolean} enabled - Flag to enable Popper
 * @param {object} modifiers - Array of modifiers for Popper instance (see Popper.js docs)
 * @param {function} closePopperCallback the callback that disables the enabled flag
 * @see EditApplicationProjectNameButton.js for a complete implementation example
 */
const PopperTriggerWrapper = ({ TriggerComponent, PopupComponent, placement, enabled, modifiers, closePopperCallback }) => {
    const isMobile = useIsMobile();
    const popperRef = useRef();
    const popupRef = useRef();
    const triggerRef = useRef();
    const defaultModifiers = [
        {
            name: "flip",
            options: {
                padding: 10,
            },
        },
    ];

    useOnClickOutside(popupRef, () => {
        if (popupRef) {
            if (closePopperCallback) {
                closePopperCallback();
            }
            popperRef.current && popperRef.current.forceUpdate();
        }
    });

    usePopper({
        triggerRef,
        popperRef,
        popupRef,
        placement: placement,
        enabled: enabled && !isMobile,
        modifiers: modifiers || defaultModifiers,
    });

    return (
        <>
            <TriggerComponent triggerRef={triggerRef} />
            {enabled && <PopupComponent popupRef={popupRef} />}
        </>
    );
};

export default PopperTriggerWrapper;
