import { useMemo, useState } from "react";
import SearchInput from "components/ui/Input/SearchInput";
import { FilterSubheader } from "../Header/FilterSubheader";
import WaitIcon from "components/ui/WaitIcon";
import { IconButton } from "../IconButton";
import Checkbox from "components/ui/Input/Checkbox";
import { shareFilter, useUserAssignments } from "../utils";
import { useDispatch } from "react-redux";
import { isEmpty, isNil } from "lodash";
import cn from "classnames";
import { Avatar } from "components/ui/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const FilterShare: React.FC<{
    filterNumber: string;
}> = ({ filterNumber }) => {
    const dispatch = useDispatch();
    const [users, isLoadingUsers] = useUserAssignments(filterNumber);

    const [usersToAdd, setUsersToAdd] = useState<string[]>([]);
    const [usersToRemove, setUsersToRemove] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    const [isUpdating, setIsUpdating] = useState(false);

    const [availableUsersList, assignedUsersList] = useMemo(() => {
        const filteredUsers =
            users?.filter((user) => isEmpty(searchTerm?.trim()) || user.userName.toLowerCase().includes(searchTerm.toLowerCase())) ?? [];

        return [
            filteredUsers
                ?.filter((user) => user.assigned === "no")
                .map((user) => ({
                    id: user.userNumber,
                    title: user.userName,
                })) ?? [],
            filteredUsers
                ?.filter((user) => user.assigned !== "no")
                .map((user) => ({
                    id: user.userNumber,
                    title: user.userName,
                })) ?? [],
        ];
    }, [users, searchTerm]);

    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const onSelectUserToAdd = (userNumber: string) => {
        const index = usersToAdd.findIndex((item) => item === userNumber);

        if (index === -1) {
            setUsersToAdd([...usersToAdd, userNumber]);
        } else {
            setUsersToAdd([...usersToAdd.slice(0, index), ...usersToAdd.slice(index + 1)]);
        }
    };

    const onSelectUserToRemove = (userNumber: string) => {
        const index = usersToRemove.findIndex((i) => i === userNumber);

        if (index === -1) {
            setUsersToRemove([...usersToRemove, userNumber]);
        } else {
            setUsersToRemove([...usersToRemove.slice(0, index), ...usersToRemove.slice(index + 1)]);
        }
    };

    const onSelectAllUsersToAdd = () => {
        if (usersToAdd.length === availableUsersList.length) {
            setUsersToAdd([]);
            return;
        }

        const allUsers = availableUsersList.map((user) => user.id);
        setUsersToAdd(allUsers);
    };

    const onSelectAllUsersToRemove = () => {
        if (usersToRemove.length === assignedUsersList.length) {
            setUsersToRemove([]);
            return;
        }

        const allUsers = assignedUsersList.map((user) => user.id);
        setUsersToRemove(allUsers);
    };

    const onAdd = async () => {
        if (usersToAdd.length === 0) {
            return;
        }

        try {
            setIsUpdating(true);
            const assignedUsers =
                users
                    ?.filter((user) => usersToAdd.includes(user.userNumber) || user.assigned !== "no")
                    .map<string>((user) => user.userNumber) ?? [];

            await shareFilter(filterNumber, assignedUsers, dispatch);
            setUsersToAdd([]);
        } catch (error) {
            console.log(error);
        } finally {
            setIsUpdating(false);
        }
    };

    const onRemove = async () => {
        if (usersToRemove.length === 0) {
            return;
        }

        try {
            setIsUpdating(true);
            const assignedUsers =
                users
                    ?.filter((user) => !usersToRemove.includes(user.userNumber) && user.assigned !== "no")
                    .map<string>((user) => user.userNumber) ?? [];

            await shareFilter(filterNumber, assignedUsers, dispatch);
            setUsersToRemove([]);
        } catch (error) {
            console.log(error);
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <div className="wc-filter-share flex-column fill-height bg-white pt-8 px-8">
            <div className="flex-column fill-height fill-width mx-auto pb-8 gap-4" style={{ maxWidth: "720px" }}>
                <div className="flex-row justify-between align-center gap-2">
                    <FilterSubheader title="Share your filter" description="Share your saved filters to collaborate with others." />
                    <SearchInput value={searchTerm} onChange={onSearchTermChange} ariaLabel="Search user" />
                </div>
                {isLoadingUsers && isNil(users) ? (
                    <WaitIcon />
                ) : (
                    <div className="flex-one-in-column flex-row align-center gap-2 fill-width" style={{ minHeight: "180px" }}>
                        <UsersList
                            title="Available Users"
                            countLabel={`${availableUsersList.length} people`}
                            items={availableUsersList}
                            selectedItems={usersToAdd}
                            emptyListPlaceholder={
                                availableUsersList.length > 0 ? undefined : (
                                    <EmptyState
                                        icon={["fas", "users-slash"]}
                                        title="No users available"
                                        description="Available users will show up here"
                                    />
                                )
                            }
                            onSelect={onSelectUserToAdd}
                            onSelectAll={onSelectAllUsersToAdd}
                        />
                        <div className="flex-column fill-height justify-center align-center gap-2" style={{ width: "35px" }}>
                            {isUpdating ? (
                                <div className="flex-row no-shrink">
                                    <WaitIcon />
                                </div>
                            ) : (
                                <>
                                    <IconButton
                                        className={cn({ primary: usersToAdd.length > 0 })}
                                        icon={["fal", "chevron-right"]}
                                        title="Add users"
                                        onClick={onAdd}
                                    />
                                    <IconButton
                                        className={cn({ primary: usersToRemove.length > 0 })}
                                        icon={["fal", "chevron-left"]}
                                        title="Remove users"
                                        onClick={onRemove}
                                    />
                                </>
                            )}
                        </div>
                        <UsersList
                            title="Shared With"
                            countLabel={assignedUsersList.length > 0 ? `${assignedUsersList.length} people` : undefined}
                            items={assignedUsersList}
                            selectedItems={usersToRemove}
                            emptyListPlaceholder={
                                assignedUsersList.length > 0 ? undefined : (
                                    <EmptyState
                                        icon={["fas", "users-slash"]}
                                        title="Filter not shared"
                                        description="Users with access will show here"
                                    />
                                )
                            }
                            onSelect={onSelectUserToRemove}
                            onSelectAll={onSelectAllUsersToRemove}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const UsersList: React.FC<{
    title: string;
    countLabel?: string;
    items: SelectListItem[];
    selectedItems: string[];
    emptyListPlaceholder?: React.ReactNode;
    onSelect: (userNumber: string) => void;
    onSelectAll: () => void;
}> = ({ title, countLabel, items, selectedItems, emptyListPlaceholder, onSelect, onSelectAll }) => {
    const selectAllState = useMemo(() => {
        if (selectedItems.length === items.length && items.length > 0) {
            return true;
        }

        if (selectedItems.length === 0) {
            return false;
        }

        return null;
    }, [selectedItems, items]);

    return (
        <section className="flex-column gap-2 fill-height fill-width" aria-label={title}>
            <div className="flex-row justify-space-between align-center text-secondary">
                <h3 className="fs-14 fw-600 text-secondary">{title}</h3>
                {countLabel && <div className="fs-14 fw-400">{countLabel}</div>}
            </div>
            <div className="flex-one-in-column flex-column border rounded no-scroll">
                {emptyListPlaceholder ? (
                    <>{emptyListPlaceholder}</>
                ) : (
                    <>
                        <div className="p-2 border-bottom sticky top-0">
                            <Checkbox checked={selectAllState} checkedPartly={!selectAllState} onChange={onSelectAll} label="Select All" />
                        </div>
                        <ul className="with-scroll">
                            {items.map((item) => (
                                <li key={item.id} className="p-2 flex-row align-center gap-2">
                                    <Checkbox iconLabelEmpty checked={selectedItems.includes(item.id)} onChange={() => onSelect(item.id)} />
                                    <Avatar firstName={item.title} />
                                    <span>{item.title}</span>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </section>
    );
};

const EmptyState: React.FC<{
    title: string;
    description: string;
    icon: IconProp;
}> = ({ title, description, icon }) => {
    return (
        <div className="flex-column fill-height fill-width align-center justify-center bg-theme-white">
            <div className="flex-column gap-1 align-center text-secondary">
                <FontAwesomeIcon className="pb-1" icon={icon} size="2x" fixedWidth />
                <div className="fs-16 fw-500">{title}</div>
                <p className="fs-14 fw-400">{description}</p>
            </div>
        </div>
    );
};

type SelectListItem = {
    id: string;
    title: string;
};
