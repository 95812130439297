import React, { memo, useCallback } from "react";
import { PropertyListItem } from "../Property/PropertyListItem";
import { SelectBoxProperty } from "../Property/SelectBoxProperty";
import { SwitchProperty } from "../Property/SwitchProperty";
import { TextProperty } from "../Property/TextProperty";
import { LinkType, ValidationRule } from "../../types";
import { isEmpty, isNil } from "lodash";

import "./ButtonWidgetProperty.scss";
import { SelectProperty } from "../Property/SelectProperty";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const ButtonLinkProperty = memo((props) => {
    const { value, onChange, borderBottom = true, errors = {} } = props;

    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);
    const containsActiveError = !isEmpty(errors.href);

    const links = updatedConfig.content.pages.map((p) => {
        return {
            label: p.link,
            value: p.link,
        };
    });

    const items = [
        {
            text: "Internal",
            value: "internal",
        },
        {
            text: "External",
            value: "external",
        },
    ];

    const onPropertyChange = useCallback(
        (id, value) => {
            const val = id === "target" ? "_blank" : true;
            if (!value) {
                onChange(id, false);
            } else {
                onChange(id, val);
            }
        },
        [onChange]
    );

    return (
        <PropertyListItem
            className="property-list-item--button-link flex-column align-center justify-space-between"
            borderBottom={borderBottom}
            innerContent
        >
            <SelectBoxProperty
                id="linkType"
                value={value.linkType}
                defaultValue={LinkType.INTERNAL}
                title="Button URL"
                items={items}
                borderBottom={false}
                onChange={onChange}
                validationRules={[ValidationRule.Required]}
            />
            {value.linkType === LinkType.INTERNAL && (
                <SwitchProperty
                    title={"Select from existing pages"}
                    id={"internalPageSelect"}
                    value={value.internalPageSelect}
                    defaultValue={false}
                    onChange={(id, value) => onPropertyChange(id, value)}
                    borderBottom={false}
                    fullWidth={false}
                />
            )}
            {value.internalPageSelect && (value.linkType === LinkType.INTERNAL || isNil(value.linkType)) ? (
                <SelectProperty
                    errors={errors.href}
                    id="href"
                    items={links}
                    value={value.href}
                    onChange={onChange}
                    placeholder="Select url"
                    inline={false}
                    borderBottom={false}
                    className="no-padding"
                    listContainsActiveError={containsActiveError}
                />
            ) : (
                <TextProperty
                    errors={errors.href}
                    id="href"
                    value={value.href}
                    fullWidth={true}
                    defaultValue=""
                    borderBottom={false}
                    onChange={onChange}
                    listContainsActiveError={containsActiveError}
                />
            )}
            {value.linkType === LinkType.EXTERNAL && (
                <SwitchProperty
                    title={"Open in the new Tab"}
                    id={"target"}
                    value={value.target ?? false}
                    onChange={(id, value) => onPropertyChange(id, value)}
                    borderBottom={false}
                />
            )}
        </PropertyListItem>
    );
});
