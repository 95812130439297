import cn from "classnames";
import { formatFullName } from "components/utils/user";
import { isEmpty } from "lodash";

import "./style.scss";

export const Avatar: React.FC<{
    firstName?: string;
    lastName?: string;
}> = ({ firstName, lastName }) => {
    const text = getAvatarText(firstName, lastName);
    const color = getAvatarColor(firstName, lastName);

    return (
        <div className={cn(`avatar avatar--${color} flex-column no-shrink align-end rounded-full`)} aria-hidden="true">
            <div className="avatar-text flex-column flex-grow align-self-stretch justify-content-center text-center uppercase fs-12 fw-400">
                {text}
            </div>
        </div>
    );
};

export const getAvatarColor = (firstName?: string, lastName?: string) => {
    const colorArray = [
        AvatarColors.PINK,
        AvatarColors.PURPLE,
        AvatarColors.ORANGE,
        AvatarColors.TEAL,
        AvatarColors.BLUE,
        AvatarColors.CYAN,
    ];

    const name = formatFullName(firstName, lastName);
    const nameLength = name.length;
    let sum = 0;

    if (nameLength === 0) {
        return AvatarColors.BLUE;
    }

    for (let i = 0; i < nameLength; i++) {
        sum += name.charCodeAt(i);
    }

    const average = sum / nameLength;
    const offset = Math.floor(Math.abs(average - name.charCodeAt(0)));
    const colorIndex = offset % colorArray.length;

    return colorArray[colorIndex];
};

export const getAvatarText = (firstName?: string, lastName?: string) => {
    if (isEmpty(firstName) && isEmpty(lastName)) {
        return "";
    }

    if (firstName && lastName) {
        return firstName[0] + lastName[0];
    }

    if (firstName) {
        return firstName[0];
    }

    if (lastName) {
        return lastName[0];
    }

    return "";
};

export const AvatarColors = {
    BLUE: "blue",
    PURPLE: "purple",
    PINK: "pink",
    ORANGE: "orange",
    TEAL: "teal",
    CYAN: "cyan",
} as const;

export type AvatarColor = typeof AvatarColors[keyof typeof AvatarColors];
