import { useResource } from "store/resources/useResource";
import { availableGrids, programsFormsGridColumnKeys } from "components/views/configureGrids";
import { toArray } from "components/utils/array";

/**
 * Get program form number.
 *
 * @param {object} params
 * @param {string} [params.entityId] - program number
 * @returns
 */
const useProgramFormNumber = ({ entityId }) => {
    const resourceId = entityId ? availableGrids.programsForms : undefined;
    const key = entityId ? `${entityId}-workflow` : undefined;

    return useResource({
        resourceName: "grid",
        resourceId,
        key,
        query: {
            searchAttr: `${programsFormsGridColumnKeys.programNumber}=${entityId}`,
            pageNum: 1,
            recsPerPage: 1,
            returnType: "JSON",
        },
        forced: false,
        transform: (data) => {
            return toArray(data?.grid?.rows)[0]?.[programsFormsGridColumnKeys.formNumber];
        },
    });
};

export default useProgramFormNumber;
