import cn from "classnames";

export const Switch: React.FC<{
    id?: string;
    className?: string;
    ariaLabel?: string;
    title?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
}> = ({ id, className, checked = false, ariaLabel, title, disabled, onChange }) => {
    return (
        <button
            id={id}
            type="button"
            role="switch"
            aria-checked={checked}
            aria-label={ariaLabel}
            title={title}
            value={checked ? "on" : "off"}
            onClick={() => onChange?.(!checked)}
            disabled={disabled}
            className={cn(
                "inline-flex h-4 w-8 p-0.5 border rounded-full shrink-0 align-center cursor-pointer disabled:cursor-not-allowed",
                className,
                {
                    "bg-primary border-primary": checked,
                    "bg-white": !checked,
                }
            )}
        >
            <span
                className={cn("block h-3 w-3 rounded-full transition-transform", {
                    "bg-white translate-x-4": checked,
                    "bg-theme-light-grey translate-x-0": !checked,
                })}
            ></span>
        </button>
    );
};
