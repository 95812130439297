import {
    FilterVariableForMeasureAttribute,
    FilterVariablesItem,
    getConditionIcon,
    MeasureAttributeConditions,
    MeasureStatuses,
} from "components/views/ApplicationProcessingWorkCenterV2/utils";
import { VariablePath } from "../VariablePath";
import { ConditionBlock } from "../ConditionBlock";
import { CheckboxesBlock } from "../CheckboxesBlock";
import { useProgramCategoriesForDropdown } from "components/utils/program";
import { RadiosBlock } from "../RadiosBlock";
import { useProgramCatalogAttributes } from "store/resources/useResource";
import { useMemo } from "react";
import { ValuesBlock } from "../ValuesBlock";
import cn from "classnames";
import { isNil } from "lodash";

export const VariableBlockItemMeasureAttribute: React.FC<{
    variable: FilterVariableForMeasureAttribute;
    error?: string;
    item: FilterVariablesItem;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
    onEdit: () => void;
    onDelete: () => void;
    onToggle?: () => void;
    onToggleValues?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onEdit, onDelete, onToggle, onToggleValues }) => {
    return (
        <div
            className={cn(
                "flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 focus-within:bg-grey-200 bg-opacity-50 gap-2",
                isNil(onToggle) ? "px-3" : "px-1"
            )}
            data-testid={`variable-block-item-${variable.items.indexOf(item)}`}
        >
            <VariablePath icon={getConditionIcon(variable.type, item.condition)!} />
            <div className={cn("flex-column fill-width bg-opacity-100", isNil(onToggle) ? "pb-3" : "pb-1")}>
                <ConditionBlock item={item} readOnly={readOnly} onEdit={onEdit} onDelete={onDelete} onToggle={onToggle} />
                <ConditionValuesBlock
                    variable={variable}
                    item={item}
                    readOnly={readOnly}
                    error={error}
                    onChange={onChange}
                    onToggle={onToggleValues}
                />
            </div>
        </div>
    );
};

const ConditionValuesBlock: React.FC<{
    variable: FilterVariableForMeasureAttribute;
    item: FilterVariablesItem;
    error?: string;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
    onToggle?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onToggle }) => {
    if (([MeasureAttributeConditions.CategoryIs, MeasureAttributeConditions.CategoryIsNot] as string[]).includes(item.condition)) {
        return (
            <CategoriesBlock variable={variable} item={item} readOnly={readOnly} error={error} onChange={onChange} onToggle={onToggle} />
        );
    }

    if (
        ([MeasureAttributeConditions.MeasureStatusIs, MeasureAttributeConditions.MeasureStatusIsNot] as string[]).includes(item.condition)
    ) {
        return (
            <RadiosBlock
                items={Object.values(MeasureStatuses).map((v) => ({ label: v, isChecked: item.values.includes(v), id: v }))}
                error={error}
                readOnly={readOnly || !isNil(onToggle)}
                onChange={(values) => onChange({ ...item, values })}
            />
        );
    }

    if (([MeasureAttributeConditions.AttributeIs, MeasureAttributeConditions.AttributeIsNot] as string[]).includes(item.condition)) {
        return (
            <AttributesBlock variable={variable} item={item} readOnly={readOnly} error={error} onChange={onChange} onToggle={onToggle} />
        );
    }

    if (
        ([MeasureAttributeConditions.AttributeValueIs, MeasureAttributeConditions.AttributeValueIsNot] as string[]).includes(item.condition)
    ) {
        return (
            <ValuesBlock
                items={item.values}
                error={error}
                readOnly={readOnly}
                onChange={(values) => onChange({ ...item, values })}
                onToggle={onToggle}
            />
        );
    }

    return null;
};

const CategoriesBlock: React.FC<{
    variable: FilterVariableForMeasureAttribute;
    item: FilterVariablesItem;
    error?: string;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
    onToggle?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onToggle }) => {
    const [categories, isLoading] = useProgramCategoriesForDropdown({
        resourceName: "programCatalogCategories",
        programNumber: variable.programNumber,
    });

    const items = (categories ?? []).map((category) => ({
        label: category.categoryName,
        id: category.categoryNumber,
        isChecked: item.values.includes(category.categoryNumber),
    }));

    return (
        <CheckboxesBlock
            items={items}
            error={error}
            readOnly={readOnly}
            isLoading={isLoading}
            emptyStateMessage="No categories available."
            onChange={(values) => onChange({ ...item, values })}
            onToggle={onToggle}
        />
    );
};

const AttributesBlock: React.FC<{
    variable: FilterVariableForMeasureAttribute;
    item: FilterVariablesItem;
    error?: string;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
    onToggle?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onToggle }) => {
    const [attributes, isLoading] = useProgramCatalogAttributes({
        programNumber: variable.programNumber,
    });

    const items = useMemo(
        () =>
            (attributes ?? []).map((attribute) => ({
                label: attribute.productAttrDescription,
                id: attribute.productAttrStandardNumber,
                isChecked: item.values.includes(attribute.productAttrStandardNumber),
            })),
        [attributes, item.values]
    );

    return (
        <CheckboxesBlock
            items={items}
            error={error}
            readOnly={readOnly}
            onToggle={onToggle}
            isLoading={isLoading}
            emptyStateMessage="No attributes available."
            onChange={(values) => onChange({ ...item, values })}
        />
    );
};
