export const FilterSubheader: React.FC<{
    title: string;
    description: string;
}> = ({ title, description }) => {
    return (
        <div className="flex-row fill-width">
            <div className="flex-column gap-1 fill-width">
                <h2 className="fs-20 fw-500">{title}</h2>
                <p className="fs-16 fw-400 text-secondary">{description}</p>
            </div>
        </div>
    );
};
