import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserList } from "../utils";
import { FilterGroup, FilterType } from "./types";
import React, { useState } from "react";
import Portal from "components/ui/Portal";
import PopperTriggerWrapper from "components/ui/PopperTriggerWrapper";
import { FilterTypesList, useFilterTypeSelection } from "./FilterTypesList";
import Button from "components/ui/Button";

export const FilterTypeSelector: React.FC<{
    filterGroup: FilterGroup;
    filterType: FilterType;
    userNumber?: string;
    onChange: (filterGroup: FilterGroup, filterType: FilterType, userNumber: string | undefined) => void;
}> = ({ filterGroup, filterType, userNumber, onChange }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <PopperTriggerWrapper
            TriggerComponent={({ triggerRef }: { triggerRef: React.RefObject<HTMLButtonElement> }) => (
                <TriggerComponent
                    filterGroup={filterGroup}
                    filterType={filterType}
                    userNumber={userNumber}
                    triggerRef={triggerRef}
                    onClick={() => setExpanded((prev) => !prev)}
                />
            )}
            PopupComponent={({ popupRef }: { popupRef: React.RefObject<HTMLDivElement> }) => (
                <PopupComponent
                    filterGroup={filterGroup}
                    filterType={filterType}
                    userNumber={userNumber}
                    popupRef={popupRef}
                    onChange={onChange}
                    onClose={() => setExpanded(false)}
                />
            )}
            placement="bottom-start"
            enabled={expanded}
            modifiers={[
                {
                    name: "flip",
                    options: {
                        padding: 8,
                    },
                },
                {
                    name: "offset",
                    options: {
                        offset: [0, 8],
                    },
                },
            ]}
            closePopperCallback={() => setExpanded(false)}
        />
    );
};

const TriggerComponent: React.FC<{
    filterGroup: FilterGroup;
    filterType: FilterType;
    userNumber?: string;
    triggerRef: React.RefObject<HTMLButtonElement>;
    onClick: () => void;
}> = ({ filterGroup, filterType, userNumber, triggerRef, onClick }) => {
    const [users = []] = useUserList();
    const userName = users.find((user) => user.userNumber === userNumber)?.userName;
    const filterName = filterType + (userName ? ` ${userName}` : "");

    return (
        <button ref={triggerRef} className="wc-btn border rounded px-2 py-1 bg-white flex-row align-center gap-2" onClick={onClick}>
            <span>{filterGroup}</span>
            <span className="border border-grey-400 rounded px-2 py-1 bg-grey-200">{filterName}</span>
            <FontAwesomeIcon className="expand-icon rotated" icon={["far", "chevron-right"]} fixedWidth size="sm" />
        </button>
    );
};

const PopupComponent: React.FC<{
    filterGroup: FilterGroup;
    filterType: FilterType;
    userNumber?: string;
    popupRef: React.RefObject<HTMLDivElement>;
    onChange: (filterGroup: FilterGroup, filterType: FilterType, userNumber: string | undefined) => void;
    onClose: () => void;
}> = ({ filterGroup, filterType, userNumber, popupRef, onChange, onClose }) => {
    const { activeFilterGroup, activeFilterType, selectedUserNumber, onSelectFilterType, onSelectUser } = useFilterTypeSelection(
        filterGroup,
        filterType,
        userNumber
    );

    const handleUserSelect = (userNumber: string, showResults: boolean = false) => {
        onSelectUser(userNumber);

        if (showResults) {
            onApplyChanges();
        }
    };

    const onApplyChanges = () => {
        onChange(activeFilterGroup, activeFilterType, selectedUserNumber);
        onClose();
    };

    return (
        <Portal className="workcenter-v2-view">
            <div ref={popupRef} className="flex-column z-10 bg-white rounded border no-scroll" style={{ height: 580 }}>
                <FilterTypesList
                    className="rounded-0 border-0"
                    activeFilterGroup={activeFilterGroup}
                    activeFilterType={activeFilterType}
                    selectedUserNumber={selectedUserNumber}
                    onSelectFilterType={onSelectFilterType}
                    onSelectUser={handleUserSelect}
                    inPopup
                />
                <div className="flex-row border-top p-4 gap-2 justify-end">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button primary onClick={onApplyChanges}>
                        Apply Changes
                    </Button>
                </div>
            </div>
            <div className="content-wrap-backdrop"></div>
        </Portal>
    );
};
