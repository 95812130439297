import { useCallback, useId, useMemo, useRef, useState } from "react";
import { VariableFilter } from "./VariableFilter";
import { ProgramFilter } from "./ProgramFilter";
import { UtilityFilter } from "./UtilityFilter";
import {
    FilterColumn,
    useFilterUtilities,
    ProgramItem,
    useFilterConfig,
    UtilityItem,
    useFilterPrograms,
    useFilterVariables,
    FilterVariable,
    VariableType,
    FilterVariablesItem,
    VariableTypes,
    FilterErrors,
    isFormVariable,
    isMeasureVariable,
    FilterVariableForMeasureAttribute,
    FilterVariableForFormField,
    getConditionInitialValues,
    MeasureAttributeConditions,
    Rule,
    VariableCondition,
} from "../utils";
import WaitIcon from "components/ui/WaitIcon";
import cn from "classnames";
import { cloneDeep, isEmpty, isEqual, isNil } from "lodash";
import { VariableBlock } from "./VariableBlock";
import { openConfirmModal } from "components/ui/Modal/utils";

import "./styles.scss";

export const NewFilter: React.FC<{
    noFilters?: boolean;
    errors?: FilterErrors;
    onChange: (variables: FilterVariable[]) => void;
}> = ({ noFilters, errors, onChange }) => {
    return (
        <div className="py-2 px-8 fill-height bg-white">
            <FilterConfig noFilters={noFilters} errors={errors} onChange={onChange} />
        </div>
    );
};

export const Filter: React.FC<{
    filterNumber: string;
    readOnly: boolean;
    errors?: FilterErrors;
    activeVariable?: FilterVariable;
    onChange: (variables: FilterVariable[]) => void;
}> = ({ filterNumber, readOnly, errors, activeVariable, onChange }) => {
    const [filterConfig, isLoadingConfig] = useFilterConfig(filterNumber, false);
    const [utilities, isLoadingUtilities] = useFilterUtilities(filterConfig);
    const [programs, isLoadingPrograms] = useFilterPrograms(filterConfig);
    const variables = useFilterVariables(filterConfig);

    if (isLoadingConfig || isLoadingUtilities || isLoadingPrograms) {
        return (
            <div className="mx-auto pt-8">
                <WaitIcon />
            </div>
        );
    }

    if (!filterConfig) {
        return <div>No filter</div>;
    }

    const initialUtility = utilities.find((utility) => utility.utilityNumber === activeVariable?.utilityNumber) ?? utilities[0];
    const initialProgram = programs.find((program) => program.programNumber === activeVariable?.programNumber) ?? programs[0];
    const initialVariable =
        variables?.find((variable) => variable.programNumber === activeVariable?.programNumber && variable.type === activeVariable?.type) ??
        variables?.[0];

    return (
        <FilterConfig
            utilities={utilities}
            programs={programs}
            variables={variables}
            initialUtility={initialUtility}
            initialProgram={initialProgram}
            initialVariable={initialVariable}
            errors={errors}
            readOnly={readOnly}
            onChange={onChange}
        />
    );
};

export const FilterConfig: React.FC<{
    utilities?: UtilityItem[];
    programs?: ProgramItem[];
    variables?: FilterVariable[];
    errors?: FilterErrors;
    initialUtility?: UtilityItem;
    initialProgram?: ProgramItem;
    initialVariable?: FilterVariable;
    noFilters?: boolean;
    readOnly?: boolean;
    onChange: (variables: FilterVariable[]) => void;
}> = ({
    utilities = [],
    programs = [],
    variables = [],
    initialUtility = null,
    initialProgram = null,
    initialVariable = null,
    noFilters,
    readOnly,
    errors,
    onChange,
}) => {
    const [utilityFilter, setUtilityFilter] = useState<UtilityItem[]>(utilities);
    const [programFilter, setProgramFilter] = useState<ProgramItem[]>(programs);
    const [variableFilter, setVariableFilter] = useState<FilterVariable[]>(variables);

    const [selectedUtility, setSelectedUtility] = useState<UtilityItem | null>(initialUtility);
    const [selectedProgram, setSelectedProgram] = useState<ProgramItem | null>(initialProgram);
    const [selectedVariable, setSelectedVariable] = useState<FilterVariable | null>(initialVariable);

    const selectedVariableIndex = useMemo(
        () => variableFilter.findIndex((v) => v === selectedVariable),
        [selectedVariable, variableFilter]
    );

    const showOnboarding = useRef(noFilters && !localStorage.getItem("workcenter-onboarding-complete"));

    const [activeColumn, setActiveColumn] = useState<FilterColumn | null>("Utility");

    const [isProgramDropdownOpen, setIsProgramDropdownOpen] = useState(false);
    const [isVariableDropdownOpen, setIsVariableDropdownOpen] = useState(false);
    const [isUtilityDropdownOpen, setIsUtilityDropdownOpen] = useState(
        activeColumn === "Utility" && !showOnboarding.current && !readOnly && !initialVariable
    );

    const hasDropdownOpen = isUtilityDropdownOpen || isProgramDropdownOpen || isVariableDropdownOpen;
    const variableBlockContainerId = useId();

    const filteredVariableFilter = useMemo(() => {
        if (selectedProgram) {
            return variableFilter.filter((variable) => variable.programNumber === selectedProgram.programNumber);
        } else {
            return [];
        }
    }, [selectedProgram, variableFilter]);

    const filteredProgramFilter = useMemo(() => {
        if (selectedUtility) {
            return programFilter.filter((program) => program.utilityNumber === selectedUtility.utilityNumber);
        } else {
            return [];
        }
    }, [selectedUtility, programFilter]);

    const newItemId = useId();

    const showCatalogSelect = selectedVariable && isMeasureVariable(selectedVariable) && !isCatalogSelected(selectedVariable) && !readOnly;

    const showFormFieldSelect = selectedVariable && isFormVariable(selectedVariable) && !isFormFieldSelected(selectedVariable) && !readOnly;

    const [showConditionSelect, setShowConditionSelect] = useState(
        selectedVariable?.items.length === 0 && !showCatalogSelect && !showFormFieldSelect
    );

    const onAddUtility = useCallback(
        (utility: { label: string; value: string }) => {
            setSelectedProgram(null);
            setSelectedVariable(null);

            const newUtility = {
                utilityNumber: utility.value,
                utility: utility.label,
            };

            setUtilityFilter([...utilityFilter, newUtility]);
            setSelectedUtility(newUtility);

            setActiveColumn("Program");
        },
        [utilityFilter]
    );

    const onSelectUtility = useCallback(
        (index: number) => {
            const utilityNumber = utilityFilter[index]?.utilityNumber;

            if (utilityNumber) {
                setSelectedUtility(utilityFilter.find((utility) => utility.utilityNumber === utilityNumber) ?? null);
                setSelectedProgram(programFilter.find((program) => program.utilityNumber === utilityNumber) ?? null);
                setSelectedVariable(variableFilter.find((variable) => variable.utilityNumber === utilityNumber) ?? null);
                setActiveColumn("Utility");
            }
        },
        [utilityFilter, programFilter, variableFilter]
    );

    const onAddProgramToUtility = useCallback(() => {
        if (selectedUtility) {
            setIsProgramDropdownOpen(true);
            setActiveColumn("Program");
        }
    }, [selectedUtility]);

    const onRemoveUtility = useCallback(
        (index: number) => {
            // remove utility
            const utilityNumber = utilityFilter[index].utilityNumber;
            openConfirmModal({
                title: "Remove Variable",
                modalIcon: "delete-trash-empty",
                message: (
                    <p>
                        Are you sure you want to remove <strong>{selectedUtility?.utility}</strong> program?
                    </p>
                ),
                onConfirm: () => {
                    setUtilityFilter(utilityFilter.filter((_, i) => i !== index));

                    // remove related programs
                    const updatedPrograms = programFilter.filter((program) => program.utilityNumber !== utilityNumber);
                    setProgramFilter(updatedPrograms);

                    // remove related variables
                    const updatedVariables = variableFilter.filter((variable) => variable.utilityNumber !== utilityNumber);
                    setVariableFilter(updatedVariables);

                    setSelectedUtility(null);
                    setSelectedProgram(null);
                    setSelectedVariable(null);

                    onChange(updatedVariables);
                },
            });
        },
        [utilityFilter, programFilter, variableFilter, selectedUtility, onChange]
    );

    const onAddProgram = useCallback(
        (program: { label: string; value: string }) => {
            if (selectedUtility) {
                const newProgram = {
                    programNumber: program.value,
                    program: program.label,
                    utilityNumber: selectedUtility.utilityNumber,
                };

                setProgramFilter([...programFilter, newProgram]);
                setSelectedProgram(newProgram);
                setActiveColumn("Variable");
            }
        },
        [programFilter, selectedUtility]
    );

    const onSelectProgram = useCallback(
        (index: number) => {
            const utilityNumber = filteredProgramFilter[index]?.utilityNumber;

            if (utilityNumber) {
                setSelectedUtility(utilityFilter.find((utility) => utility.utilityNumber === utilityNumber) ?? null);
                setSelectedProgram(index > -1 ? filteredProgramFilter[index] : null);
                setSelectedVariable(
                    variableFilter.find((variable) => variable.programNumber === filteredProgramFilter[index]?.programNumber) ?? null
                );
                setActiveColumn("Program");
            }
        },
        [utilityFilter, filteredProgramFilter, variableFilter]
    );

    const onAddVariableToProgram = useCallback(() => {
        if (selectedProgram) {
            setIsVariableDropdownOpen(true);
            setActiveColumn("Variable");
        }
    }, [selectedProgram]);

    const onRemoveProgram = useCallback(
        (index: number) => {
            const utilityNumber = programFilter[index].utilityNumber;
            openConfirmModal({
                title: "Remove Variable",
                modalIcon: "delete-trash-empty",
                message: (
                    <p>
                        Are you sure you want to remove <strong>{selectedProgram?.program}</strong> program?
                    </p>
                ),
                onConfirm: () => {
                    setProgramFilter(programFilter.filter((_, i) => i !== index));

                    // remove related variables
                    const updatedVariables = variableFilter.filter((variable) => variable.utilityNumber !== utilityNumber);
                    setVariableFilter(updatedVariables);

                    setSelectedProgram(null);
                    setSelectedVariable(null);

                    onChange(updatedVariables);
                },
            });
        },
        [programFilter, variableFilter, onChange, selectedProgram]
    );

    const onClearPrograms = useCallback(
        (index: number) => {
            const utilityNumber = utilityFilter[index].utilityNumber;
            // remove related variables
            const updatedVariables = variableFilter.filter((variable) => variable.utilityNumber !== utilityNumber);
            setVariableFilter(updatedVariables);

            // remove related programs
            const updatedPrograms = programFilter.filter((program) => program.utilityNumber !== utilityNumber);
            setProgramFilter(updatedPrograms);

            setSelectedProgram(null);
            setSelectedVariable(null);

            onChange(updatedVariables);
        },
        [programFilter, utilityFilter, variableFilter, onChange]
    );

    const onAddVariable = useCallback(
        (type: { label: string; value: string }) => {
            if (!selectedUtility || !selectedProgram) {
                return;
            }

            const variableType = type.value as VariableType;

            const newVariable = {
                type: variableType,
                utilityNumber: selectedUtility?.utilityNumber,
                programNumber: selectedProgram?.programNumber,
                ...(variableType === VariableTypes.MeasureAttribute && { catalogNumber: undefined }),
                ...(variableType === VariableTypes.FormFields && { pageNumber: undefined, fieldNumber: undefined, fieldType: undefined }),
                items: [],
            } as FilterVariable;

            const updatedVariables = [...variableFilter, newVariable];

            setVariableFilter(updatedVariables);
            setSelectedVariable(newVariable);
            setShowConditionSelect(!isMeasureVariable(newVariable) && !isFormVariable(newVariable));

            onChange(updatedVariables);
        },
        [variableFilter, selectedUtility, selectedProgram, onChange]
    );

    const onSelectVariable = useCallback(
        (variable: FilterVariable, index: number) => {
            // do not close the dropdown if the variable is already selected
            if (variable === selectedVariable) {
                return;
            }

            // select utility
            setSelectedUtility(utilityFilter.find((utility) => utility.utilityNumber === variable.utilityNumber) ?? null);

            // select program
            setSelectedProgram(programFilter.find((program) => program.programNumber === variable.programNumber) ?? null);

            // select variable
            setSelectedVariable(index > -1 ? filteredVariableFilter[index] : null);

            const openFirstConditionDropdown =
                variable.items.length === 0 &&
                ((isMeasureVariable(variable) && !isNil(variable.catalogNumber)) ||
                    (isFormVariable(variable) && !isNil(variable.fieldNumber)));

            setShowConditionSelect(openFirstConditionDropdown);
            setActiveColumn("Variable");
        },
        [utilityFilter, programFilter, filteredVariableFilter, selectedVariable]
    );

    const onChangeVariable = useCallback(
        (variable: FilterVariable) => {
            if (variableFilter[selectedVariableIndex]) {
                const updatedVariables = cloneDeep(variableFilter);
                updatedVariables[selectedVariableIndex] = variable;
                setVariableFilter(updatedVariables);
                setSelectedVariable(variable);

                const openFirstConditionDropdown =
                    variable.items.length === 0 &&
                    ((isMeasureVariable(variable) && !isNil(variable.catalogNumber)) ||
                        (isFormVariable(variable) && !isNil(variable.fieldNumber)));

                setShowConditionSelect(openFirstConditionDropdown);

                onChange(updatedVariables);
            }
        },
        [variableFilter, selectedVariableIndex, onChange]
    );

    /**
     * The index is provided when deleting variable from the variables list filtered by selected program.
     */
    const onRemoveVariable = useCallback(
        (index?: number) => {
            const variables = isNil(index)
                ? variableFilter
                : variableFilter.filter((variable) => variable.programNumber === selectedProgram?.programNumber);

            const variableIndex = isNil(index) ? selectedVariableIndex : variableFilter.findIndex((v) => isEqual(v, variables[index]));
            const variable = variableFilter[variableIndex];
            const selectedVariable = variableFilter[selectedVariableIndex];

            if (variable) {
                openConfirmModal({
                    title: "Delete Variable",
                    modalIcon: "delete-trash-empty",
                    message: (
                        <p>
                            Are you sure you want to delete <strong>{variable?.type}</strong> variable?
                        </p>
                    ),
                    onConfirm: () => {
                        const updatedVariables = variableFilter.filter((_, i) => i !== variableIndex);
                        setVariableFilter(updatedVariables);

                        if (isNil(index)) {
                            // Select first variable in selected program.
                            setSelectedVariable(updatedVariables.find((v) => v.programNumber === selectedProgram?.programNumber) ?? null);
                        } else {
                            // Update reference to already selected variable.
                            setSelectedVariable(
                                // Select variable that was already selected.
                                updatedVariables.find((v) => isEqual(v, selectedVariable)) ??
                                    // If not found select first variable in selected program.
                                    updatedVariables.find((v) => v.programNumber === selectedProgram?.programNumber) ??
                                    null
                            );
                        }

                        onChange(updatedVariables);
                    },
                });
            }
        },
        [variableFilter, selectedVariableIndex, selectedProgram?.programNumber, onChange]
    );

    const onClearVariables = useCallback(
        (index: number) => {
            const utilityNumber = programFilter[index].utilityNumber;

            // remove related variables
            const updatedVariables = variableFilter.filter((variable) => variable.utilityNumber !== utilityNumber);
            setVariableFilter(updatedVariables);

            setSelectedProgram(null);
            setSelectedVariable(null);

            onChange(updatedVariables);
        },
        [programFilter, variableFilter, onChange]
    );

    const onAddVariableItem = useCallback(
        (item: FilterVariablesItem) => {
            setVariableFilter((prevValue) => {
                if (!prevValue[selectedVariableIndex]) {
                    return prevValue;
                }

                const items = cloneDeep(prevValue[selectedVariableIndex].items);
                prevValue[selectedVariableIndex].items = [...items, item];

                setTimeout(() => onChange(prevValue));

                return prevValue;
            });
        },
        [selectedVariableIndex, onChange]
    );

    const onChangeVariableItem = useCallback(
        (itemIndex: number, item: FilterVariablesItem) => {
            setVariableFilter((prevValue) => {
                if (prevValue[selectedVariableIndex]?.items[itemIndex]) {
                    const updatedVariables = cloneDeep(prevValue);
                    updatedVariables[selectedVariableIndex].items[itemIndex] = item;

                    setSelectedVariable(updatedVariables[selectedVariableIndex]);
                    setTimeout(() => onChange(updatedVariables));

                    return updatedVariables;
                }

                return prevValue;
            });
        },
        [selectedVariableIndex, onChange]
    );

    const onDeleteVariableItem = useCallback(
        (itemIndex: number) => {
            setVariableFilter((prevValue) => {
                if (prevValue[selectedVariableIndex]?.items[itemIndex]) {
                    prevValue[selectedVariableIndex].items = prevValue[selectedVariableIndex].items.filter((_, i) => i !== itemIndex);

                    // Remove rule from first variable item
                    if (prevValue[selectedVariableIndex].items[0]?.rule) {
                        delete prevValue[selectedVariableIndex].items[0].rule;
                    }

                    setTimeout(() => onChange(prevValue));
                }

                return prevValue;
            });
        },
        [selectedVariableIndex, onChange]
    );

    /**
     * The index is provided when clearing variable from the variables list filtered by selected program.
     */
    const onClearVariableItems = useCallback(
        (index?: number) => {
            const variables = isNil(index)
                ? variableFilter
                : variableFilter.filter((variable) => variable.programNumber === selectedProgram?.programNumber);

            const variableIndex = isNil(index) ? selectedVariableIndex : variableFilter.findIndex((v) => isEqual(v, variables[index]));

            if (variableFilter[variableIndex]) {
                const updatedVariables = cloneDeep(variableFilter);
                const variable = updatedVariables[variableIndex];

                variable.items = [];

                if (isMeasureVariable(variable)) {
                    variable.catalogNumber = undefined!;
                    setShowConditionSelect(false);
                }

                if (isFormVariable(variable)) {
                    variable.fieldNumber = undefined!;
                    variable.pageNumber = undefined!;
                    setShowConditionSelect(false);
                }

                setVariableFilter(updatedVariables);

                if (isNil(index)) {
                    setSelectedVariable(variable);
                } else {
                    // Update reference to already selected variable.
                    setSelectedVariable(updatedVariables[selectedVariableIndex]);
                }

                onChange(updatedVariables);
            }
        },
        [variableFilter, selectedVariableIndex, selectedProgram?.programNumber, onChange]
    );

    const onConditionSelect = () => {
        // Do nothing if already selecting something
        if (showCatalogSelect || showFormFieldSelect || showConditionSelect) {
            return;
        }

        setShowConditionSelect(true);
    };

    const onVariableConditionAdd = (condition: VariableCondition, rule?: Rule) => {
        setShowConditionSelect(false);

        onAddVariableItem({
            _id: newItemId + Date.now(),
            condition,
            rule,
            values: getConditionInitialValues(condition),
        });

        // Focus input field if condition is for the values block
        if (
            ([MeasureAttributeConditions.AttributeValueIs, MeasureAttributeConditions.AttributeValueIsNot] as string[]).includes(condition)
        ) {
            setTimeout(() => {
                const inputs = document.querySelectorAll<HTMLInputElement>("[name='attribute-value']");
                inputs?.[inputs.length - 1]?.scrollIntoView();
                inputs?.[inputs.length - 1]?.focus();
            }, 100);
        }
    };

    return (
        <div className="filter-container bg-white flex-row fill-width fill-height no-scroll">
            <div
                className={cn("filter-navigation bg-white flex-row px-1", {
                    isVariableSelected: !isEmpty(selectedVariable),
                    hasDropdownOpen,
                })}
            >
                <UtilityFilter
                    filter={utilityFilter}
                    selectedUtility={selectedUtility}
                    setActiveColumn={setActiveColumn}
                    isActiveColumn={activeColumn === "Utility"}
                    isDropdownOpen={isUtilityDropdownOpen}
                    setIsDropdownOpen={setIsUtilityDropdownOpen}
                    showOnboarding={showOnboarding}
                    noFilters={noFilters}
                    onAddProgramToUtility={onAddProgramToUtility}
                    onAdd={onAddUtility}
                    onSelect={onSelectUtility}
                    onRemove={onRemoveUtility}
                    onClear={onClearPrograms}
                    readOnly={readOnly}
                    errors={errors}
                />
                <ProgramFilter
                    filter={filteredProgramFilter}
                    selectedProgram={selectedProgram}
                    selectedUtility={selectedUtility}
                    setActiveColumn={setActiveColumn}
                    isActiveColumn={activeColumn === "Program"}
                    onAddVariableToProgram={onAddVariableToProgram}
                    isDropdownOpen={isProgramDropdownOpen}
                    setIsDropdownOpen={setIsProgramDropdownOpen}
                    onAdd={onAddProgram}
                    onClear={onClearVariables}
                    onSelect={onSelectProgram}
                    onRemove={onRemoveProgram}
                    readOnly={readOnly}
                    errors={errors}
                />
                <VariableFilter
                    variableFilter={filteredVariableFilter}
                    selectedVariable={selectedVariable}
                    setActiveColumn={setActiveColumn}
                    isActiveColumn={activeColumn === "Variable"}
                    selectedProgram={selectedProgram}
                    isDropdownOpen={isVariableDropdownOpen}
                    setIsDropdownOpen={setIsVariableDropdownOpen}
                    onAdd={onAddVariable}
                    onAddCondition={onConditionSelect}
                    onSelect={onSelectVariable}
                    onRemove={onRemoveVariable}
                    onClear={onClearVariableItems}
                    onConditionSelectDisabled={showCatalogSelect || showFormFieldSelect || showConditionSelect}
                    readOnly={readOnly}
                />
            </div>
            <div key={selectedVariableIndex} id={variableBlockContainerId} className="bg-theme-white w-1/2 rounded">
                {selectedVariable && (
                    <div className="bg-dotted rounded h-full w-full p-4 with-scroll">
                        <VariableBlock
                            scrollContainerId={variableBlockContainerId}
                            variable={selectedVariable}
                            errors={errors?.[selectedVariableIndex]}
                            readOnly={readOnly}
                            onItemChange={onChangeVariableItem}
                            onItemDelete={onDeleteVariableItem}
                            onItemsClear={onClearVariableItems}
                            onVariableChange={onChangeVariable}
                            onVariableDelete={onRemoveVariable}
                            showConditionSelect={showConditionSelect ?? false}
                            showFormFieldSelect={showFormFieldSelect ?? false}
                            showCatalogSelect={showCatalogSelect ?? false}
                            setShowConditionSelect={setShowConditionSelect}
                            onVariableConditionAdd={onVariableConditionAdd}
                            onConditionSelect={onConditionSelect}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const isCatalogSelected = (variable: FilterVariableForMeasureAttribute) => {
    return !isNil(variable.catalogNumber);
};

const isFormFieldSelected = (variable: FilterVariableForFormField) => {
    return !isNil(variable.fieldNumber);
};
