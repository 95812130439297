import { FilterSubheader } from "../Header/FilterSubheader";
import { useState } from "react";
import Button from "components/ui/Button";
import { FilterExploreResults } from "./FilterExploreResults";
import { FilterTypesList, useFilterTypeSelection } from "./FilterTypesList";
import { ApplicationFilters, FilterExploreOptionalParams, FilterGroups } from "./types";
import { FilterVariable } from "../utils";

export const FilterExplore: React.FC<
    {
        filterNumber: string;
        onVariableEdit: (variable: FilterVariable) => void;
    } & FilterExploreOptionalParams
> = (props) => {
    const { filterNumber, onVariableEdit } = props;
    const [showResults, setShowResults] = useState(props.showResults);

    const { activeFilterGroup, activeFilterType, selectedUserNumber, onSelectFilterType, onSelectUser } = useFilterTypeSelection(
        props.filterGroup ?? FilterGroups.Applications,
        ApplicationFilters.Any
    );

    const handleUserSelect = (userNumber: string, showResults: boolean = false) => {
        onSelectUser(userNumber);
        setShowResults(showResults);
    };

    if (showResults) {
        return (
            <FilterExploreResults
                key={`${filterNumber}-${activeFilterGroup}-${activeFilterType}-${selectedUserNumber}`}
                filterNumber={filterNumber}
                filterGroup={activeFilterGroup}
                filterType={activeFilterType}
                userNumber={selectedUserNumber}
                onVariableEdit={onVariableEdit}
                selectedUserNumber={selectedUserNumber}
                onSelectFilterType={onSelectFilterType}
                onSelectUser={onSelectUser}
            />
        );
    }

    return (
        <div className="wc-filter-explore flex-column fill-height bg-white pt-8 px-8">
            <div className="flex-column fill-height fill-width mx-auto gap-3 pb-8" style={{ maxWidth: "480px" }}>
                <FilterSubheader title="Start exploring" description="Use your saved filters to see tailored results." />
                <FilterTypesList
                    onSelectFilterType={onSelectFilterType}
                    onSelectUser={handleUserSelect}
                    activeFilterGroup={activeFilterGroup}
                    activeFilterType={activeFilterType}
                    selectedUserNumber={selectedUserNumber}
                />
                <div className="flex-row justify-end">
                    <Button primary onClick={() => setShowResults(true)}>
                        Show results
                    </Button>
                </div>
            </div>
        </div>
    );
};
