import { useRef, useState } from "react";
import Input from "../Input";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty, isNil } from "lodash";

/**
 * A search input field.
 * @param {Object} props - The properties object.
 * @param {string} [props.className] - Additional CSS class names to apply to the outermost div of the component.
 * @param {string} [props.value] - The current value of the input field.
 * @param {string} [props.placeholder] - The placeholder text to display in the input field. Defaults to "Search".
 * @param {string} [props.ariaLabel] - Provides an accessible label for the input element. Defaults to "Search".
 * @param {boolean} [props.expandable] - Whether the input field should be expandable.
 * @param {boolean | undefined} [props.expanded] - Whether the input field should be expanded by default.
 * @param {boolean} [props.autoFocus] - Whether the input field should be focused automatically when the component is mounted.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.onChange - Function to handle changes in the input value. Receives the new value as its argument.
 * @param {(value?: string) => void} [props.onSubmit] - Function to handle the submission of the search input. Receives the current input value as its argument.
 */
const SearchInput = ({
    className,
    value,
    placeholder = "Search",
    ariaLabel = "Search",
    expandable = false,
    expanded,
    autoFocus,
    onChange,
    onSubmit,
}) => {
    // Show expanded if the input field is not expandable and "expanded" prop is not provided.
    const [isExpanded, setIsExpanded] = useState(isNil(expanded) ? !expandable : expanded);
    const inputRefCurrent = useRef(null);

    const handleIconClick = (e) => {
        e.stopPropagation();

        if (!expandable) {
            return;
        }

        setIsExpanded(true);
        setTimeout(() => {
            inputRefCurrent.current?.focus();
        }, 300);
    };

    const handleInputBlur = () => {
        if (!value && expandable) {
            setIsExpanded(false);
        }
    };

    const handleClearClick = (e) => {
        e.stopPropagation();

        if (inputRefCurrent.current) {
            onChange({
                target: { value: "" },
            });

            inputRefCurrent.current.value = "";

            if (!expandable) {
                setTimeout(() => {
                    inputRefCurrent.current?.focus();
                }, 100);
            }
        }

        if (expandable) {
            setIsExpanded(false);
        }
    };

    const onInputKeyPress = (event) => {
        if (event.key === "Enter") {
            onSubmit?.(event.target.value);
        }
    };

    return (
        <div
            className={cn("relative flex items-center transition-all duration-300 ease-in-out", className, {
                "max-w-8": !isExpanded,
                "max-w-full": isExpanded,
            })}
        >
            <button className="focus:outline-none absolute w-8 left-0 h-full" title="Search" onClick={handleIconClick}>
                <FontAwesomeIcon icon={["far", "magnifying-glass"]} />
            </button>

            <div className={cn("w-full", isExpanded ? "overflow-visible" : "overflow-hidden")}>
                <Input
                    type="text"
                    placeholder={isExpanded ? placeholder : undefined}
                    inputRef={inputRefCurrent}
                    value={value}
                    onChange={onChange}
                    onKeyPress={onInputKeyPress}
                    ariaLabel={ariaLabel}
                    role="search"
                    autoFocus={autoFocus}
                    onBlur={handleInputBlur}
                    className={cn("!pl-[30px]", {
                        "!pr-7": isExpanded,
                        "!pr-0": !isExpanded,
                    })}
                />
            </div>
            {!isEmpty(value) && (
                <button className="focus:outline-none absolute w-8 right-0 h-full" title="Clear" onClick={handleClearClick}>
                    <FontAwesomeIcon icon={["fas", "circle-x"]} />
                </button>
            )}
        </div>
    );
};

export default SearchInput;
