import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetHistory } from "../utils";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { HistorySidePanel } from "./HistorySidePanel";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import { isEmpty } from "lodash";
import { HistoryListItem } from "./HistoryListItem";
import WaitIcon from "components/ui/WaitIcon";

export const Dashboard = ({ onNewFilterOpen }: { onNewFilterOpen: () => void }) => {
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        className: "history-modal-workcenter",
        size: sideNavSize.small,
    });

    const [historyItems, isLoadingHistory] = useGetHistory();

    const openFullHistory = () => {
        handleOpenSidePanel(<HistorySidePanel historyItems={historyItems} onClose={handleCloseSidePanel} />);
    };

    return (
        <div className="wc-dashboard h-full px-8">
            <div className="flex-column max-w-[480px] w-full mx-auto pt-36">
                <button
                    className="flex-column align-center justify-center rounded-lg shrink-0 p-8 gap-2 bg-primary-semi-transparent border border-transparent hover:border-primary-light focus:border-primary-light active:border-primary-light focus:box-shadow active:box-shadow"
                    title="Create new filter"
                    aria-label="Create new filter"
                    onClick={onNewFilterOpen}
                >
                    <div className="text-white w-8 h-8 rounded-full flex align-center justify-center bg-primary">
                        <FontAwesomeIcon icon={["fas", "plus"]} fixedWidth />
                    </div>
                    <h2 className="fs-20 fw-500">New Filter</h2>
                    <span className="text-secondary">Configure and save a new custom view</span>
                </button>
                {isLoadingHistory && <WaitIcon className="m-auto mt-8" />}
                {!isEmpty(historyItems) && !isLoadingHistory && (
                    <section aria-label="Recent filters" className="p-4 w-full">
                        <h3 className="p-3 fw-600 fs-14 text-secondary">Recent</h3>
                        {historyItems?.slice(0, 5).map((historyItem, index) => (
                            <HistoryListItem key={index} historyItem={historyItem} />
                        ))}
                        <IconWithLabel
                            onClick={openFullHistory}
                            ariaHasPopup="dialog"
                            ariaLabel="Show all history"
                            className="fw-600 p-3 pt-4 fs-14"
                        >
                            Show all history
                        </IconWithLabel>
                    </section>
                )}
            </div>
        </div>
    );
};
