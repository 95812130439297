import React, { Suspense, lazy, memo, useMemo, useCallback, useContext, useEffect, useRef } from "react";
import WaitIcon from "../WaitIcon";
import { useSelector } from "react-redux";
import { isNil } from "lodash";
import JsonSchemaForm from "../../ui/Form/JsonSchema/JsonSchemaFormV2";
import {
    getWidgetSchema,
    getItemValuesSchema,
    getItemValuesUISchema,
    getItemValuesInitialValues,
    useCurrentPosition,
    onFieldPropertiesChange,
    getRequiredFields,
    getAppFormUiSchema,
    getTypeSchema,
    getAppFormValidationSchema,
    onFieldPropertiesValidate,
    getTypeUISchema,
    getFieldGroupUISchema,
    getFieldGroupSchema,
    fieldGroups,
    getWidgetOptionsSchema,
    getConditionsUiSchema,
    transformFieldPropertiesErrors,
} from "./utils";
import { formatRule, getSchemaIds, pickNumberFromClassifier, pickString, referenceToAnyOf, submitByRef } from "../../utils/form";
import { getSelectedElement, getSelectedElementUiParams, getFormBuilder } from "../../../store/formBuilder/selectors";
import { useProgramFormGenProcs, useProgramFormGenProc } from "../../../store/resources/useResource";
import { ApplicationFormPagesContext } from "../../views/ApplicationFormPages";
import { useReference } from "../Reference/useReference";
import { referenceTypes } from "../Reference/referenceTypes";
import { FIELD_DESCRIPTOR_TEXT } from "store/formBuilder/utils";
import { isChildProgram } from "components/views/ProgramView/utils";
import ModuleLoadError from "components/ui/ModuleLoadError";

const helpModalIcons = ["info", "circle-info", "question", "circle-question"];

const IconSelectModal = lazy(() =>
    import("components/ui/Icons/IconSelectModal")
        .then((module) => ({ default: module.IconSelectModal }))
        .catch((error) => ({
            default: () => <ModuleLoadError error={error} />,
        }))
);

const FieldProperties = memo(({ formRef, instanceId, disabled, onChange }) => {
    const { programNumber, friendlyNames } = useContext(ApplicationFormPagesContext) || {};
    const isLocked = isChildProgram({ programNumber });

    const formBuilder = useSelector((state) => getFormBuilder({ instanceId, state: state.formBuilder }));
    const selectedElementId = formBuilder.selectedElementId;
    const rules = formBuilder.rules || [];
    const formUiSchema = formBuilder.uiSchema;

    const element = getSelectedElement(formBuilder);
    const elementUiParams = getSelectedElementUiParams(formBuilder);

    const fieldId = selectedElementId.split("_").slice(1).join(".");
    const fieldType = element["type"];

    const fieldWidget = elementUiParams["ui:widget"] || "text";

    const otherFields = useMemo(() => {
        return getSchemaIds(formBuilder.schema, true).filter((item) => item.id !== fieldId);
    }, [fieldId, formBuilder.schema]);

    const currentPosition = useCurrentPosition({
        selectedElementId,
        elementUiParams,
    });

    const [fieldDescriptors = [], isLoadingFieldDescriptors] = useReference(referenceTypes.fielddescriptor);
    const [validationTypes = [], isLoadingValidationTypes] = useReference(referenceTypes.formfieldvalidation);

    const [genProcs = [], isLoadingGenProcs] = useProgramFormGenProcs({
        programNumber,
    });

    const preselectedProcId = genProcs?.length === 1 ? genProcs[0].val : undefined;
    const procId = elementUiParams["ui:procId"] ?? preselectedProcId;
    const [selectedGenProc, isLoadingGenProc] = useProgramFormGenProc({
        programNumber,
        procId,
    });

    const onPropertiesChange = useCallback(
        (props) => {
            onFieldPropertiesChange({
                instanceId,
                selectedElementId,
                programNumber,
                ...props,
            });
            onChange && onChange({ instanceId, selectedElementId, ...props });
        },
        [instanceId, selectedElementId, programNumber, onChange]
    );

    const onValidate = useCallback(
        (formData, errors) => {
            return onFieldPropertiesValidate(formData, errors, friendlyNames);
        },
        [friendlyNames]
    );

    const schema = useMemo(() => {
        const required = getRequiredFields(fieldWidget, elementUiParams, selectedGenProc);

        return {
            type: "object",
            required: required,
            properties: {
                title: {
                    type: "string",
                    title: "Title",
                },
                ...(fieldWidget === "signature"
                    ? {
                          confirmCheckboxTitle: {
                              type: "string",
                              title: "Checkbox Title",
                          },
                      }
                    : {}),
                ...getWidgetSchema(),
                ...getWidgetOptionsSchema(fieldWidget),
                ...getTypeSchema(fieldWidget),
                ...getFieldGroupSchema(fieldWidget, fieldGroups),
                friendlyName: {
                    type: "string",
                    title: "Friendly Name",
                    maxLength: 150,
                },
                ...getItemValuesSchema(fieldWidget, elementUiParams, isLoadingGenProcs, genProcs, selectedGenProc, isLoadingGenProc),
                placeholder: {
                    type: "string",
                    title: "Placeholder",
                },
                help: {
                    type: "string",
                    title: "Help Text",
                },
                ...([
                    "auditequipmentblock",
                    "equipmentblock",
                    "applicationcontacts",
                    "additionalcontacts",
                    "rebatetotalgrid",
                    "nontextspacer",
                    "readonlyvalue",
                    "spacer",
                    "statement",
                    "image",
                    "generalprocedure",
                    "workflow",
                ].includes(fieldWidget)
                    ? {}
                    : {
                          helpModalTitle: {
                              type: "string",
                              title: "Help Modal Title",
                          },
                          helpModalContent: {
                              type: "string",
                              title: "Help Modal Content",
                          },
                          helpModalIcon: {
                              type: "string",
                              title: "Help Modal Icon",
                          },
                      }),
                order: {
                    type: "integer",
                    title: "Order",
                },
                disabled: {
                    type: "boolean",
                    title: "Disabled",
                },
                fieldNumber: {
                    type: "string",
                    title: "Field Number",
                },
                ...(["text", "textarea"].includes(fieldWidget)
                    ? {}
                    : {
                          fieldDescriptor: {
                              type: "string",
                              title: "Field Descriptor",
                              ...(isLoadingFieldDescriptors
                                  ? {}
                                  : {
                                        anyOf: referenceToAnyOf({
                                            list: fieldDescriptors,
                                            type: "string",
                                        }),
                                    }),
                          },
                          dbString: {
                              type: "string",
                              title: "DB String",
                          },
                          separator: {
                              type: "string",
                              title: "Separator",
                          },
                      }),
                ...(fieldWidget === "image"
                    ? {}
                    : {
                          validation: {
                              type: "object",
                              title: "Validation",
                              required: ["text", "signature", "encryptedtext"].includes(fieldWidget) ? ["maxLength"] : [],
                              properties: {
                                  required: {
                                      type: "boolean",
                                      title: "Required",
                                  },
                                  ...(isNil(fieldType) || fieldType === "string"
                                      ? {
                                            ...(["date", "defaultdate"].includes(fieldWidget)
                                                ? {
                                                      formatMinimum: {
                                                          type: "string",
                                                          title: "Minimum Value",
                                                      },
                                                      formatMaximum: {
                                                          type: "string",
                                                          title: "Maximum Value",
                                                      },
                                                  }
                                                : {
                                                      fieldFormat: {
                                                          type: "string",
                                                      },
                                                  }),
                                        }
                                      : {}),

                                  ...(["integer", "number", "string"].includes(fieldType)
                                      ? {
                                            minLength: {
                                                type: "integer",
                                                title: "Minimum Value Length",
                                                maxLength: 20,
                                                minimum: 0,
                                            },
                                            maxLength: {
                                                type: "integer",
                                                title: "Maximum Value Length",
                                                maxLength: 20,
                                                minimum: 0,
                                            },
                                            minimum: {
                                                type: "integer",
                                                title: "Minimum Value",
                                            },
                                            maximum: {
                                                type: "integer",
                                                title: "Maximum Value",
                                            },
                                        }
                                      : {}),
                                  ...(["string"].includes(fieldType) || ["checkboxes", "largecheckboxes"].includes(fieldWidget)
                                      ? {
                                            pattern: {
                                                type: "string",
                                                title: "Regular expression",
                                            },
                                        }
                                      : {}),
                              },
                          },
                      }),
                ...getAppFormValidationSchema({
                    elementUiParams,
                    validationTypes,
                    otherFieldIds: otherFields,
                    isLoadingValidationTypes,
                    formUiSchema,
                }),
                ...(fieldWidget === "image"
                    ? {
                          image: {
                              type: "string",
                          },
                      }
                    : {}),
                showif: {
                    type: "array",
                    title: "Show If",
                    items: {
                        type: "string",
                    },
                },
                removeif: {
                    type: "array",
                    title: "Remove If",
                    items: {
                        type: "string",
                    },
                },
                requireif: {
                    type: "array",
                    title: "Require If",
                    items: {
                        type: "string",
                    },
                },
            },
        };
    }, [
        fieldWidget,
        fieldType,
        elementUiParams,
        fieldDescriptors,
        isLoadingFieldDescriptors,
        formUiSchema,
        genProcs,
        isLoadingGenProc,
        isLoadingGenProcs,
        validationTypes,
        isLoadingValidationTypes,
        otherFields,
        selectedGenProc,
    ]);

    const initialValues = useInitialValues({
        selectedElementId,
        element,
        elementUiParams,
        rules,
        preselectedProcId,
        procId,
        selectedGenProc,
        isLoadingGenProc,
        currentPosition,
        fieldId,
        fieldDescriptors,
        validationTypes,
        isLoadingFieldDescriptors,
        isLoadingValidationTypes,
    });

    const uiSchema = useMemo(() => {
        const validationUiSchema = ["date", "defaultdate"].includes(fieldWidget)
            ? {
                  validation: {
                      formatMinimum: {
                          "ui:widget": "daterangevalidation",
                          "ui:placeholder": "Set Date",
                          "ui:overlap": true,
                      },
                      formatMaximum: {
                          "ui:widget": "daterangevalidation",
                          "ui:placeholder": "Set Date",
                          "ui:overlap": true,
                      },
                  },
              }
            : {
                  validation: {
                      fieldFormat: {
                          "ui:widget": "fieldformat",
                          "ui:options": {
                              displayLabel: false,
                          },
                      },
                  },
              };
        return {
            ...getTypeUISchema(fieldWidget, isLocked),
            ...getItemValuesUISchema(fieldWidget, selectedGenProc, isLoadingGenProc),
            ...validationUiSchema,
            showif: getConditionsUiSchema(otherFields),
            removeif: getConditionsUiSchema(otherFields),
            requireif: getConditionsUiSchema(otherFields),
            ...getFieldGroupUISchema(fieldWidget),
            ...getAppFormUiSchema({
                elementUiParams,
                isLoadingFieldDescriptors,
                isLoadingValidationTypes,
                isLocked,
                fieldWidget,
            }),
            title: {
                "ui:widget": "textarea",
            },
            placeholder: {
                "ui:widget": fieldWidget === "image" ? "hidden" : "text",
            },
            help: {
                "ui:widget": fieldWidget === "image" ? "hidden" : "text",
            },
            helpModalTitle: {
                "ui:widget": fieldWidget === "image" ? "hidden" : "text",
            },
            helpModalContent: {
                "ui:widget": "HtmlEditorWidget",
                "ui:toolbar": "lite",
                "ui:forcePasteAsPlainText": true,
            },
            helpModalIcon: {
                "ui:widget": (props) => (
                    <Suspense fallback={<WaitIcon />}>
                        <IconSelectModal {...props} warnEmpity={!!initialValues?.helpModalContent} iconList={helpModalIcons} />
                    </Suspense>
                ),
            },
            lookup: {
                "ui:widget": fieldWidget === "image" ? "hidden" : "text",
            },
            separator: {
                "ui:widget": fieldWidget === "image" ? "hidden" : "text",
            },

            image: {
                "ui:widget": "imageProperties",
            },
        };
    }, [
        fieldWidget,
        isLocked,
        selectedGenProc,
        isLoadingGenProc,
        otherFields,
        elementUiParams,
        isLoadingFieldDescriptors,
        isLoadingValidationTypes,
        initialValues?.helpModalContent,
    ]);

    useGenProcParameterNamesListInit({
        isLoadingGenProc,
        selectedGenProc,
        elementUiParams,
        formRef,
        onPropertiesChange,
    });

    return (
        <div className="element-properties fill-width with-scroll">
            <JsonSchemaForm
                formRef={formRef}
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                disabled={isLocked || disabled}
                validate={onValidate}
                onChange={onPropertiesChange}
                transformErrors={transformFieldPropertiesErrors}
                liveValidate={!isNil(formBuilder.validationErrors[elementUiParams["ui:fieldNumber"]])}
                noActions
                debounce
            />
        </div>
    );
});

const useInitialValues = ({
    selectedElementId,
    element,
    elementUiParams,
    rules,
    procId,
    preselectedProcId,
    selectedGenProc,
    isLoadingGenProc,
    currentPosition,
    fieldId,
    fieldDescriptors,
    validationTypes,
    isLoadingFieldDescriptors,
    isLoadingValidationTypes,
}) => {
    const fieldWidget = elementUiParams["ui:widget"] || "text";
    const validationType = elementUiParams["af:validationType"];
    const validationPivot = elementUiParams["af:validationPivot"];
    const validationAdd = elementUiParams["af:validationAdd"];

    const initialValuesRef = useRef();

    const getValue = () => {
        const elementRelativeId = selectedElementId.split("_").pop();
        const isRequired = elementUiParams["required"].indexOf(elementRelativeId) > -1;
        const fieldDescriptor = elementUiParams["af:fieldDescriptor"];
        const validationType = elementUiParams["af:validationType"];

        return {
            type: element["type"],
            title: element["title"],
            confirmCheckboxTitle: elementUiParams["ui:confirmCheckboxTitle"],
            widget: fieldWidget,
            ...(["date"].includes(fieldWidget) ? { dateFormat: element["format"] } : {}),
            ...getItemValuesInitialValues(element, elementUiParams, fieldWidget, procId, selectedGenProc, isLoadingGenProc),
            placeholder: elementUiParams["ui:placeholder"],
            help: elementUiParams["ui:help"],
            helpModalTitle: elementUiParams["ui:helpModalTitle"],
            helpModalContent: elementUiParams["ui:helpModalContent"],
            helpModalIcon: elementUiParams["ui:helpModalIcon"],
            disabled: elementUiParams["ui:disabled"],
            order: currentPosition,
            key: elementUiParams["ui:key"],
            showif: rules.filter((rule) => rule.event.params.field === fieldId && rule.event.type === "show").map(formatRule),
            removeif: rules.filter((rule) => rule.event.params.field === fieldId && rule.event.type === "remove").map(formatRule),
            requireif: rules.filter((rule) => rule.event.params.field === fieldId && rule.event.type === "require").map(formatRule),
            fieldNumber: pickString(elementUiParams["ui:fieldNumber"]),
            fieldGroup: pickNumberFromClassifier(elementUiParams["af:fieldGroup"], fieldGroups),
            friendlyName: pickString(elementUiParams["af:friendlyName"]),
            separator: pickString(elementUiParams["ui:separator"]), // changed "af:" to "ui:" to get access to prop in widget.
            dbString: pickString(elementUiParams["af:dbString"]),
            fieldDescriptor: isLoadingFieldDescriptors
                ? pickString(fieldDescriptor)
                : fieldDescriptors.filter((i) => i.val === fieldDescriptor).map((i) => i.val)[0] ?? FIELD_DESCRIPTOR_TEXT,
            validationType: isLoadingValidationTypes
                ? pickString(validationType)
                : validationTypes.filter((i) => i.val === validationType).map((i) => i.val)[0],
            validationAdd: pickString(elementUiParams["af:validationAdd"]),
            validationPivot: pickString(elementUiParams["af:validationPivot"]),
            validation: {
                required: isRequired,
                minLength: element["minLength"],
                maxLength: element["maxLength"],
                minimum: element["minimum"],
                maximum: element["maximum"],
                pattern: element["pattern"],
                formatMinimum: element["formatMinimum"],
                formatMaximum: element["formatMaximum"],
                fieldFormat: element["format"],
            },
            image: JSON.stringify({
                imageSrc: elementUiParams["ui:imageSrc"],
                imageAltText: elementUiParams["ui:imageAltText"],
                imageSize: elementUiParams["ui:imageSize"],
                imageLink: elementUiParams["ui:imageLink"],
                openInNewTab: elementUiParams["ui:openInNewTab"],
            }),
        };
    };

    if (
        shouldUpdateFieldPropertiesFormValues({
            initialValues: initialValuesRef.current,
            fieldWidget,
            procId,
            preselectedProcId,
            validationType,
            validationPivot,
            validationAdd,
            rules,
        })
    ) {
        initialValuesRef.current = getValue();
    }

    return initialValuesRef.current;
};

const useGenProcParameterNamesListInit = ({ isLoadingGenProc, selectedGenProc, elementUiParams, formRef, onPropertiesChange }) => {
    const parameterNamesListInitRef = useRef(false);

    return useEffect(() => {
        if (!isLoadingGenProc && selectedGenProc && !elementUiParams["ui:parameterNamesList"] && !parameterNamesListInitRef.current) {
            parameterNamesListInitRef.current = true;
            submitByRef(formRef, (errors, formData) => {
                onPropertiesChange({
                    formData,
                    errorSchema: errors,
                });
            });
        }
    }, [isLoadingGenProc, selectedGenProc, elementUiParams, formRef, onPropertiesChange]);
};

export const shouldUpdateFieldPropertiesFormValues = ({
    initialValues,
    fieldWidget,
    procId,
    preselectedProcId,
    validationType,
    validationPivot,
    validationAdd,
    rules,
}) => {
    const isRuleChanged = false;

    if (
        initialValues?.widget !== fieldWidget ||
        (initialValues?.procId !== procId && procId !== preselectedProcId) ||
        initialValues?.validationType !== validationType ||
        initialValues?.validationPivot !== validationPivot ||
        initialValues?.validationAdd !== validationAdd ||
        isRuleChanged
    ) {
        return true;
    }

    return false;
};

export default FieldProperties;
