import Checkbox from "components/ui/Input/Checkbox";
import { useId, useMemo, useState } from "react";
import { Divider } from "./Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import SearchInput from "components/ui/Input/SearchInput";
import { isEmpty, isNil } from "lodash";
import WaitIcon from "components/ui/WaitIcon";
import { ErrorMessage } from "./ErrorMessage";
import { Switch } from "../../Switch";

export const CheckboxesBlock: React.FC<{
    items: { label: string; isChecked: boolean; id: string }[];
    error?: string;
    readOnly: boolean;
    isLoading?: boolean;
    emptyStateMessage: string;
    onChange: (values: string[]) => void;
    onToggle?: (values: string[]) => void;
}> = ({ items, readOnly, isLoading = false, emptyStateMessage, error, onChange, onToggle }) => {
    const [expanded, setExpanded] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const listId = useId();

    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const selectedItems = useMemo(() => items.filter((item) => item.isChecked), [items]);

    const filteredItems = useMemo(() => {
        if (isEmpty(searchTerm)) {
            return items;
        }

        return items.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [items, searchTerm]);

    return (
        <div
            className={cn("p-2 border rounded-2 bg-white", {
                "border-danger": !isEmpty(error),
            })}
        >
            {items.length === 0 && isLoading && <WaitIcon />}
            {items.length === 0 && !isLoading && (
                <div className="flex-row align-center gap-2">
                    <FontAwesomeIcon icon={["far", "magnifying-glass"]} fixedWidth size="sm" />
                    {emptyStateMessage}
                </div>
            )}
            {items.length > 0 && (
                <div className="flex-column fill-width gap-2">
                    <div className="flex-row align-center justify-space-between">
                        <button
                            className="wc-btn rounded flex-row align-center px-2 gap-2 text-secondary"
                            onClick={() => setExpanded(!expanded)}
                            aria-expanded={expanded}
                            aria-controls={listId}
                        >
                            <div className="py-1">
                                <FontAwesomeIcon
                                    className={cn("expand-icon", { rotated: expanded })}
                                    icon={["far", "chevron-right"]}
                                    fixedWidth
                                    size="sm"
                                />
                            </div>
                            <span>
                                {selectedItems.length} {selectedItems.length === 1 ? "Item" : "Items"}
                            </span>
                        </button>
                        <SearchInput className="w-40" value={searchTerm} onChange={onSearchTermChange} expandable={!isNil(onToggle)} />
                    </div>
                    {expanded && (
                        <>
                            {error && <ErrorMessage error={error} />}
                            {readOnly && <ReadOnlyCheckboxList listId={listId} items={filteredItems} />}
                            {onToggle && <ConfigurableCheckboxList listId={listId} items={filteredItems} onChange={onToggle} />}
                            {!onToggle && !readOnly && <CheckboxList listId={listId} items={filteredItems} onChange={onChange} />}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export const CheckboxList: React.FC<{
    listId: string;
    items: { label: string; isChecked: boolean; id: string }[];
    onChange: (values: string[]) => void;
}> = ({ listId, items, onChange }) => {
    const selectedItems = useMemo(() => items.filter((item) => item.isChecked), [items]);

    const selectAllState = useMemo(() => {
        const itemCount = items?.length ?? 0;
        const selectedItemCount = selectedItems.length;

        let state: boolean | null = false;

        if (selectedItemCount > 0) {
            state = true;

            if (selectedItemCount !== itemCount) {
                state = null;
            }
        }

        return state;
    }, [items, selectedItems]);

    const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if (selectAllState) {
            onChange([]);
        } else {
            onChange(items.map((item) => item.id));
        }
    };

    const onSelect = (id: string) => {
        if (selectedItems.some((item) => item.id === id)) {
            onChange(selectedItems.filter((item) => item.id !== id).map((item) => item.id));
        } else {
            onChange([...selectedItems.map((item) => item.id), id]);
        }
    };

    return (
        <div id={listId} className="ps-2 py-1 relative" style={{ left: -1 }}>
            <Checkbox label="Select All" checked={selectAllState} onChange={onSelectAll} />
            <Divider />
            <div className="flex-column fill-width gap-2 with-scroll" style={{ maxHeight: 200 }}>
                {items.map((item) => (
                    <Checkbox key={item.id} label={item.label} checked={item.isChecked} onChange={() => onSelect(item.id)} />
                ))}
            </div>
        </div>
    );
};

export const ConfigurableCheckboxList: React.FC<{
    listId: string;
    items: { label: string; isChecked: boolean; id: string }[];
    onChange: (values: string[]) => void;
}> = ({ listId, items, onChange }) => {
    const onSelect = (item: { label: string; isChecked: boolean; id: string }) => {
        const values = items.filter((item) => item.isChecked).map((item) => item.id);

        if (item.isChecked) {
            values.splice(values.indexOf(item.id), 1);
        } else {
            values.push(item.id);
        }

        onChange(values);
    };

    return (
        <div id={listId} className="ps-2 py-1 relative" style={{ left: -1 }}>
            <div className="flex-column fill-width gap-2 with-scroll" style={{ maxHeight: 200 }}>
                {items.map((item) => (
                    <div key={item.id} className="flex items-center space-x-2">
                        <Switch id={item.id} checked={item.isChecked} onChange={() => onSelect(item)} />
                        <label htmlFor={item.id}>{item.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export const ReadOnlyCheckboxList: React.FC<{
    listId: string;
    items: { label: string; id: string; isChecked: boolean }[];
}> = ({ listId, items }) => {
    return (
        <ul id={listId} className="flex-column fill-width px-2 py-1">
            {items
                .filter((i) => i.isChecked)
                .map((item) => (
                    <li key={item.id} className="border-bottom py-2 fw-400 fs-14">
                        {item.label}
                    </li>
                ))}
        </ul>
    );
};
