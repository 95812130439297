import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import cn from "classnames";

import "./style.scss";
import { modalClose } from "../../../store/modal/actions";
import { ReactNode } from "react";

export const PlainDialog = ({
    className,
    content,
    overlayClassName,
}: {
    className: string;
    content: ReactNode;
    overlayClassName: string;
}) => {
    const dispatch = useDispatch();

    return (
        <ReactModal
            isOpen
            overlayClassName={cn("modal-styled", overlayClassName)}
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => dispatch(modalClose())}
            className={`modal modal-sm plain-dialog ${className}`}
        >
            <div className="modal-content">{content}</div>
        </ReactModal>
    );
};

export default PlainDialog;
