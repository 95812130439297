import React from "react";

import FieldGroupInput from "../FieldGroupInput";

/**
 * Text input component.
 *
 * @component
 * @param {object} props - The props for the TextInput component.
 * @param {string} [props.id] - The ID of the input element.
 * @param {string} [props.className] - The CSS class name for the input element.
 * @param {string} [props.type="text"] - The type of the input element.
 * @param {string} [props.name] - The name of the input element.
 * @param {boolean} [props.inline] - Whether the input should be displayed inline.
 * @param {string} [props.label] - The label for the input element.
 * @param {boolean} [props.labelInside] - Whether the label should be displayed inside the input element.
 * @param {string} [props.placeholder] - The placeholder text for the input element.
 * @param {boolean} [props.required] - Whether the input is required.
 * @param {string} [props.value] - The value of the input element.
 * @param {boolean} [props.disabled] - Whether the input is disabled.
 * @param {boolean} [props.readOnly] - Whether the input is read-only.
 * @param {number} [props.minLength] - The minimum length of the input value.
 * @param {number} [props.maxLength] - The maximum length of the input value.
 * @param {boolean} [props.error] - Whether the input has an error.
 * @param {string} [props.msgText] - The error message text.
 * @param {string} [props.title] - The title attribute for the input element.
 * @param {function} [props.onChange] - The event handler for the input's change event.
 * @param {function} [props.onFocus] - The event handler for the input's focus event.
 * @param {function} [props.onBlur] - The event handler for the input's blur event.
 * @param {function} [props.onClick] - The event handler for the input's click event.
 * @param {function} [props.onEnterKey] - The event handler for the Enter key press event.
 * @param {function} [props.onKeyDown] - The event handler for the input's keydown event.
 * @param {boolean} [props.focus] - Whether the input should be focused.
 * @param {boolean} [props.autoFocus] - Whether the input should be automatically focused.
 * @param {object} [props.inputRef] - A ref object for the input element.
 * @param {string} [props.icon] - The icon for the input element.
 * @param {boolean} [props.iconRight] - Whether the icon should be displayed on the right side of the input.
 * @param {string} [props.iconTitle] - The title attribute for the input's icon.
 * @param {function} [props.onIconClick] - The event handler for the input's icon click event.
 * @param {string} [props.autoComplete] - The autocomplete attribute for the input element.
 * @param {React.CSSProperties} [props.style] - custom styles
 * @returns {JSX.Element} The rendered TextInput component.
 */
const TextInput = ({
    id,
    className,
    type = "text",
    name,
    inline,
    label,
    labelInside,
    placeholder,
    required,
    value,
    disabled,
    readOnly,
    minLength,
    maxLength,
    error,
    msgText,
    title,
    onChange,
    onFocus,
    onBlur,
    onClick,
    onEnterKey,
    onKeyDown,
    focus,
    autoFocus,
    inputRef,
    icon,
    iconRight,
    iconTitle,
    onIconClick,
    autoComplete,
    testId,
    style,
}) => {
    const onInputKeyDown = (event) => {
        if (event.key === "Enter") {
            onEnterKey && onEnterKey(event);
        }

        onKeyDown && onKeyDown(event);
    };

    return (
        <FieldGroupInput
            id={id}
            inputRef={inputRef}
            className={className}
            inline={inline}
            label={label}
            labelInside={labelInside}
            type={type}
            name={name}
            placeholder={placeholder}
            labelRequired={required}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            icon={icon}
            iconRight={iconRight}
            iconTitle={iconTitle}
            title={title}
            onIconClick={onIconClick}
            minLength={minLength}
            maxLength={maxLength}
            error={error}
            msgText={msgText}
            msgInfo={!error && msgText}
            focus={focus}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onKeyDown={onInputKeyDown}
            autoComplete={autoComplete}
            testId={testId}
            style={style}
        />
    );
};

export default TextInput;
