import React, { useState } from "react";
import { useGetFilters } from "./utils";
import Button from "components/ui/Button";
import { ErrorMessage } from "components/ui/Message";
import { SplitButton } from "./SplitButton";
import { ApplicationFilters, FilterType, FilterGroup, FilterGroups, TaskFilters } from "./FilterExplore/types";

const FilterCreateModal: React.FC<{
    onSubmit: (filterName: string, filterGroup?: FilterGroup, filterType?: FilterType) => void;
    onCancel: () => void;
}> = ({ onSubmit, onCancel }) => {
    const [dataSources = []] = useGetFilters(false);

    const [error, setError] = useState<string>();

    const onSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setError(undefined);

        const inputField = e.currentTarget.querySelector<HTMLInputElement>("[name='filter-name']");

        const filterName = inputField?.value;

        // Can not save empty filter name
        if (!filterName?.trim()) {
            setError("Filter name cannot be empty");
            return;
        }

        // Can not save duplicate filter name
        if (dataSources.some((ds) => !ds.isShared && ds.datasourceName === filterName)) {
            setError("Filter name must be unique");
            return;
        }

        // Check what button was clicked
        const formEvent = e.nativeEvent as SubmitEvent;
        const submitter = formEvent.submitter as HTMLButtonElement;

        if (submitter?.name === FilterGroups.Applications) {
            onSubmit(filterName, FilterGroups.Applications, ApplicationFilters.Any);
        } else if (submitter?.name === FilterGroups.Tasks) {
            onSubmit(filterName, FilterGroups.Tasks, TaskFilters.Any);
        } else {
            onSubmit(filterName);
        }
    };

    return (
        <form className="flex-column gap-4 fill-width" onSubmit={onSave}>
            <div className="flex-column gap-2 ">
                <label htmlFor="filter-name" className="fs-14 fw-400">
                    Enter a name for the new filter
                </label>
                <input className="p-2" name="filter-name" autoFocus maxLength={100} />
                {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>

            <div className="flex-row gap-2">
                <Button primary type="submit">
                    Save
                </Button>
                <SplitButton
                    type="submit"
                    name={FilterGroups.Applications}
                    text="Save and show applications"
                    listItems={[
                        {
                            label: "Show Applications",
                            type: "submit",
                            name: FilterGroups.Applications,
                        },
                        {
                            label: "Show Tasks",
                            type: "submit",
                            name: FilterGroups.Tasks,
                        },
                    ]}
                />
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </form>
    );
};

export default FilterCreateModal;
