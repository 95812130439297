import React, { memo, useContext } from "react";
import { GridContext } from "components/ui/DataGrid";
import { hasCustomFilter, isFilterChanged } from "components/utils/datagrid";

import NothingFoundBlock from "../../NothingFoundBlock";
import { GridListFilterContext } from ".";

const GridListNoDataRow = memo(({ show }) => {
    const context = useContext(GridContext) ?? {};
    const { filter } = useContext(GridListFilterContext) ?? {};
    const { dataGridId, columns, showedColumnsKeys, filterRequired, isDataRequested, needFilter } = context;

    if (!show) {
        return null;
    }

    // Show "Nothing found" if there is a custom filter or when the filter is cleared without data request.
    const isFiltered = hasCustomFilter(filter, columns, showedColumnsKeys) || isFilterChanged(dataGridId);
    const getNoDataMessage = () => {
        if (filterRequired && (needFilter || !isDataRequested)) {
            return "Please specify filters to get data";
        } else if (isFiltered && isDataRequested) {
            return "Nothing Found";
        } else {
            return "No Data";
        }
    };

    return (
        <div className="grid-list-row no-data">
            <NothingFoundBlock nothingFoundBlockNoBorder icon="search-off" title={getNoDataMessage()} />
        </div>
    );
});

export default GridListNoDataRow;
