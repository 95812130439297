import React, { useState } from "react";

import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import { JSONTree } from "react-json-tree";

const JsonStateErrors = ({ data, onToggleVisible }) => {
    const [isStateDetailsVisible, setIsStateDetailsVisible] = useState(false);

    const openDetails = () => {
        setIsStateDetailsVisible(!isStateDetailsVisible);
        onToggleVisible(!isStateDetailsVisible);
    };

    return (
        <div className="json-state-errors-wrap">
            <IconWithLabel
                iconWithLabelRight
                iconWithLabel={isStateDetailsVisible ? "shevron-in-circle-left-filled--before" : "shevron-in-circle-right-empty--after"}
                onClick={openDetails}
            >
                {isStateDetailsVisible ? "Hide State Details" : "Open State Details"}
            </IconWithLabel>
            <div className="json-state-errors" hidden={!isStateDetailsVisible}>
                <JSONTree
                    hideRoot
                    theme={{
                        extend: {
                            base00: "#00000000",
                        },
                    }}
                    data={JSON.parse(data)}
                />
            </div>
        </div>
    );
};

export default JsonStateErrors;
