import React, { useCallback, useMemo, memo } from "react";
import { useSelector } from "react-redux";

import { useResource } from "../../../../../../../../store/resources/useResource";
import { referenceTypes } from "../../../../../../Reference/referenceTypes";
import { STEP_TYPES_ATTRIBUTES } from "../../../../../constants/step-types";
import { hasPermission, systemUserRights } from "../../../../../../../utils/user";

import WaitIcon from "../../../../../../WaitIcon";
import JsonSchemaForm from "../../../../../../Form/JsonSchema/JsonSchemaForm";
import { referenceToAnyOf } from "components/utils/form";

const GeneralProcedureForm = memo(
    ({ entityId, entityIdType, attributes, content, stepAttributeTypesGetResourceOptions, onChange, onCloseModal }) => {
        const user = useSelector((state) => state.user);

        const [storedProcedures = [], isStoredProceduresLoading] = useResource({
            resourceName: "referenceFilter",
            key: `${entityId}-ICF_WF_SPs`,
            query: {
                type: referenceTypes.storedProcedure,
                outputType: "string",
                filterType: entityIdType,
                entityNumber: entityId,
            },
            transform: (data) => {
                return referenceToAnyOf({
                    list: data?.referenceResults ?? [],
                    type: "string",
                });
            },
        });

        const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

        const showProcedure =
            hasPermission(user, systemUserRights.VISIONDSM_WORKFLOW_RULES) || hasPermission(user, systemUserRights.VISIONDSM_MANAGE_SYSTEM);
        const showProcedureList =
            showProcedure &&
            hasPermission(user, systemUserRights.VISIONDSM_WORKFLOW_RULES) &&
            !hasPermission(user, systemUserRights.VISIONDSM_MANAGE_SYSTEM);

        const getAttribute = useCallback(
            ({ number = null, code = null }) => {
                if (number) {
                    return workflowStepAttributeTypes.find((i) => i.number === number);
                } else if (code) {
                    return workflowStepAttributeTypes.find((i) => i.code === code);
                }
            },
            [workflowStepAttributeTypes]
        );

        const schema = useMemo(
            () => ({
                type: "object",
                properties: {
                    ...(showProcedure
                        ? {
                              proc: {
                                  type: "string",
                                  title: getAttribute({
                                      code: STEP_TYPES_ATTRIBUTES.STORED_PROCEDURE,
                                  }).name,
                                  ...(showProcedureList
                                      ? {
                                            anyOf: storedProcedures,
                                        }
                                      : {}),
                              },
                          }
                        : {}),
                    emailSubject: {
                        type: "string",
                        title: getAttribute({
                            code: STEP_TYPES_ATTRIBUTES.EMAIL_SUBJECT,
                        }).name,
                    },
                    content: {
                        type: "string",
                        title: "Content",
                    },
                },
            }),
            [storedProcedures, showProcedure, showProcedureList, getAttribute]
        );

        const uiSchema = useMemo(
            () => ({
                classNames: "inline-form form-columns-2",
                ...(showProcedureList
                    ? {
                          proc: {
                              "ui:placeholder": isStoredProceduresLoading ? "Loading..." : "Existing Correspondence",
                              "ui:disabled": isStoredProceduresLoading || !storedProcedures.length,
                          },
                      }
                    : {}),
                content: {
                    classNames: "workflow-step-types-widget__form-field workflow-step-types-widget__form-field-textarea fill-width",
                    "ui:widget": "textarea",
                },
            }),
            [isStoredProceduresLoading, storedProcedures, showProcedureList]
        );

        const initialValues = useMemo(() => {
            const { proc, emailSubject } = attributes.reduce(
                (acc, { typeNumber, value }) => {
                    const attribute = getAttribute({ number: typeNumber });

                    if (attribute.code === STEP_TYPES_ATTRIBUTES.STORED_PROCEDURE) {
                        if (!(showProcedureList && isStoredProceduresLoading) && value) {
                            acc.proc = value;
                        }
                    } else if (attribute.code === STEP_TYPES_ATTRIBUTES.EMAIL_SUBJECT) {
                        if (value) {
                            acc.emailSubject = value;
                        }
                    }

                    return acc;
                },
                { proc: null, emailSubject: "" }
            );

            return {
                proc,
                emailSubject,
                content,
            };
        }, [attributes, content, isStoredProceduresLoading, showProcedureList, getAttribute]);

        const handleClickSave = useCallback(
            (formData) => {
                const { content, proc, emailSubject } = formData;

                const attributeSP = getAttribute({
                    code: STEP_TYPES_ATTRIBUTES.STORED_PROCEDURE,
                });
                const attributeEmail = getAttribute({
                    code: STEP_TYPES_ATTRIBUTES.EMAIL_SUBJECT,
                });

                onChange({
                    content,
                    attributes: [
                        {
                            typeNumber: attributeSP.number,
                            name: attributeSP.name,
                            value: proc || "",
                        },
                        {
                            typeNumber: attributeEmail.number,
                            name: attributeEmail.name,
                            value: emailSubject,
                        },
                    ],
                });

                onCloseModal();
            },
            [getAttribute, onChange, onCloseModal]
        );

        if (isLoadingWorkflowStepAttributeTypes) {
            return <WaitIcon />;
        }

        return (
            <JsonSchemaForm
                className="workflow-step-types-widget__form"
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                withCancel
                noValidate
                noReset
                centeredFooter
                submitText="Save"
                onSubmit={handleClickSave}
                onCancel={onCloseModal}
            />
        );
    }
);

export default GeneralProcedureForm;
