import { useMemo, useState } from "react";
import { useUserList, WorkcenterUser } from "../utils";
import { Avatar } from "components/ui/Avatar";
import SearchInput from "components/ui/Input/SearchInput";
import WaitIcon from "components/ui/WaitIcon";
import { isEmpty } from "lodash";
import cn from "classnames";
import { useSelector } from "react-redux";
import ViewPlaceholder from "components/ui/ViewPlaceholder";

export const UsersList: React.FC<{
    selectedUserNumber?: string;
    onSelectUser: (userNumber: string, showResults?: boolean) => void;
}> = ({ selectedUserNumber, onSelectUser }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [users = [], isLoadingUsers] = useUserList();
    const currentUserNumber = useSelector((state: any) => state.user.userNumber);

    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const onSearchSubmit = (value?: string) => {
        if (filteredUsers.length === 1) {
            onSelectUser(filteredUsers[0].userNumber, true);
        }
    };

    const filteredUsers = useMemo(() => {
        return users?.filter((user) => isEmpty(searchTerm?.trim()) || user.userName.toLowerCase().includes(searchTerm.toLowerCase())) ?? [];
    }, [users, searchTerm]);

    const suggestedUsers = useMemo(() => {
        return filteredUsers.filter((user) => user.userNumber === currentUserNumber);
    }, [filteredUsers, currentUserNumber]);

    const allUsers = useMemo(() => {
        return filteredUsers.filter((user) => !suggestedUsers.some((suggestedUser) => suggestedUser.userNumber === user.userNumber));
    }, [filteredUsers, suggestedUsers]);

    if (isLoadingUsers) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    if (users.length === 0) {
        return (
            <ViewPlaceholder>
                <div className="text-secondary fs-14 fw-400">No users found</div>
            </ViewPlaceholder>
        );
    }

    return (
        <div className="flex-column fill-height no-scroll">
            <div className="p-4">
                <SearchInput
                    value={searchTerm}
                    onChange={onSearchTermChange}
                    onSubmit={onSearchSubmit}
                    ariaLabel="Search users"
                    autoFocus
                />
            </div>
            <div className="with-scroll">
                {suggestedUsers.length > 0 && (
                    <>
                        <h3 className="fs-14 fw-600 text-secondary px-4 py-2 sticky top-0 bg-white">Suggested</h3>
                        <ul>
                            {suggestedUsers.map((user) => (
                                <UsersListItem
                                    key={user.userNumber}
                                    user={user}
                                    isActive={selectedUserNumber === user.userNumber}
                                    onClick={onSelectUser}
                                />
                            ))}
                        </ul>
                    </>
                )}
                <h3 className="fs-14 fw-600 text-secondary px-4 py-2 sticky top-0 bg-white">All Users</h3>
                <ul>
                    {allUsers.map((user) => (
                        <UsersListItem
                            key={user.userNumber}
                            user={user}
                            isActive={selectedUserNumber === user.userNumber}
                            onClick={onSelectUser}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

const UsersListItem: React.FC<{
    user: WorkcenterUser;
    isActive?: boolean;
    onClick: (userNumber: string) => void;
}> = ({ user, isActive, onClick }) => {
    return (
        <li>
            <button
                className={cn("wc-btn px-4 py-2 flex-row align-center gap-2 fill-width", {
                    active: isActive,
                })}
                onClick={() => onClick(user.userNumber)}
            >
                <Avatar firstName={user.userName} />
                <span className="truncate">{user.userName}</span>
            </button>
        </li>
    );
};
