import DatePicker from "components/ui/Input/DatePicker";
import { FilterVariablesItem } from "../../utils";
import { datePartFromJsonDate, dateToJson, getDateValueForInput, localizeJsonDate } from "components/utils/date";
import { isDate, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "./ErrorMessage";
import cn from "classnames";

export const DateBlock: React.FC<{
    item: FilterVariablesItem;
    readOnly?: boolean;
    error?: string;
    onChange: (item: FilterVariablesItem) => void;
}> = ({ item, readOnly = false, error, onChange }) => {
    const onPickerChange = (data: { value: Date | null }) => {
        if (isDate(data.value)) {
            const value = datePartFromJsonDate(dateToJson(data.value));
            onChange({ ...item, values: [value!] });
        } else {
            onChange({ ...item, values: [] });
        }
    };

    if (readOnly) {
        return (
            <div className="p-2 border rounded-2 bg-white flex-row fill-width">
                <DateBadge date={item.values[0]} />
            </div>
        );
    }

    return (
        <div
            className={cn("p-2 border rounded-2 bg-white flex-column fill-width gap-2", {
                "border-danger": !isEmpty(error),
            })}
        >
            {error && <ErrorMessage error={error} />}
            <div>
                <DatePicker
                    // @ts-ignore
                    wrapperClassName="w-auto"
                    error={!isEmpty(error)}
                    placeholder="Select date"
                    value={getDateValueForInput({ value: item.values[0], isFormatDate: true })}
                    onChange={onPickerChange}
                />
            </div>
        </div>
    );
};

export const DateRangeBlock: React.FC<{
    item: FilterVariablesItem;
    readOnly?: boolean;
    error?: string;
    onChange: (item: FilterVariablesItem) => void;
}> = ({ item, readOnly = false, error, onChange }) => {
    const onStartDateChange = (data: { value: Date | null }) => {
        if (isDate(data.value)) {
            const value = datePartFromJsonDate(dateToJson(data.value));
            onChange({ ...item, values: [value!, item.values[1]] });
        } else {
            onChange({ ...item, values: [undefined!, item.values[1]] });
        }
    };

    const onEndDateChange = (data: { value: Date | null }) => {
        if (isDate(data.value)) {
            const value = datePartFromJsonDate(dateToJson(data.value));
            onChange({ ...item, values: [item.values[0], value!] });
        } else {
            onChange({ ...item, values: [item.values[0], undefined!] });
        }
    };

    if (readOnly) {
        return (
            <div className="p-2 border rounded-2 bg-white flex-row align-center fill-width gap-2">
                <DateBadge date={item.values[0]} />
                and
                <DateBadge date={item.values[1]} />
            </div>
        );
    }

    return (
        <div
            className={cn("p-2 border rounded-2 bg-white flex-column fill-width gap-2", {
                "border-danger": !isEmpty(error),
            })}
        >
            {error && <ErrorMessage error={error} />}
            <div className="flex-row align-center fill-width gap-2">
                <div>
                    <DatePicker
                        // @ts-ignore
                        wrapperClassName="w-auto"
                        error={!isEmpty(error)}
                        placeholder="Select date"
                        value={getDateValueForInput({ value: item.values[0], isFormatDate: true })}
                        onChange={onStartDateChange}
                    />
                </div>
                <span>and</span>
                <div>
                    <DatePicker
                        // @ts-ignore
                        wrapperClassName="w-auto"
                        error={!isEmpty(error)}
                        placeholder="Select date"
                        value={getDateValueForInput({ value: item.values[1], isFormatDate: true })}
                        onChange={onEndDateChange}
                    />
                </div>
            </div>
        </div>
    );
};

const DateBadge: React.FC<{
    date: string;
}> = ({ date }) => {
    return (
        <div className="bg-grey-200 rounded flex-row gap-1 align-center px-2 py-1">
            <FontAwesomeIcon className="relative bottom-px" icon={["far", "calendar"]} size="sm" />
            <span>{localizeJsonDate(date)}</span>
        </div>
    );
};
