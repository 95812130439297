import { isEmpty, isNil, orderBy } from "lodash";
import { distinct, toArray } from "components/utils/array";
import {
    getAttributeSchema,
    getAttributeUiSchema,
    getValidationTypeName,
    getAttributeTitle,
    getNormalizedAttributeValue,
    ATTRIBUTE_MAX_LENGTH,
} from "components/utils/attributes";
import { store } from "store/configureStore";
import { getResource } from "store/resources/actions";
import { useResource } from "store/resources/useResource";
import { getApprovedEquipmentResourceParams } from "store/configureResources";

/**
 * Get attributes form schema.
 *
 * @param {Object} equipment - equipment Object
 * @param {Array} validationTypes - values from references
 * @param {Array} trueFalseTypes - values from references
 * @param {Boolean} hasRightsToEdit - user has rights to edit attributes
 * @param {Boolean} hasRightsToEditAll - user has rights to edit all attributes including disabled
 * @returns schema object
 */
export const getSchema = (equipment, validationTypes, trueFalseTypes, hasRightsToEdit, hasRightsToEditAll) => {
    const attributes = toArray(equipment?.attributes ?? []).filter((a) => hasRightsToEditAll || a.showAll === "1");

    const attributesSchema = attributes.reduce((result, attribute) => {
        const attributeName = (attribute.attributename ?? attribute.attributeName).toLowerCase();
        const validationTypeName = getValidationTypeName(attribute, validationTypes);
        const friendlyName = attribute.friendlyName;
        const lookupValues = toArray(attribute.lookupValues?.lookupValue ?? []);
        const lowerLimit = attribute.lowerLimit;
        const upperLimit = attribute.upperLimit;

        const attributeSchema = getAttributeSchema({
            attributeName,
            friendlyName,
            validationTypeName,
            lookupValues,
            lowerLimit,
            upperLimit,
            attributeMaxLength: ATTRIBUTE_MAX_LENGTH,
        });

        return {
            ...result,
            ...attributeSchema,
        };
    }, {});

    const isRequiredId = trueFalseTypes.filter((t) => t.display.toLowerCase() === "true").map((t) => Number(t.val))[0];

    const attributesRequired = attributes
        .filter((attribute) => Number(attribute.ValidationRequired) === isRequiredId)
        .map((attribute) => (attribute.attributename ?? attribute.attributeName).toLowerCase())
        .filter(distinct);

    return {
        type: "object",
        required: attributesRequired,
        properties: attributesSchema,
    };
};

/**
 * Get attributes form ui schema.
 *
 * If showall='Y' or VEAE then allow the attribute to be seen
 * If (editall='Y' and VAE) OR VEAE then allow the attribute to be edited
 *
 * @param {Object} equipment - equipment Object
 * @param {Array} validationTypes - values from references
 * @param {Boolean} hasRightsToEdit - user has rights to edit attributes
 * @param {Boolean} hasRightsToEditAll - user has rights to edit all attributes including disabled
 * @returns uiSchema object
 */
export const getUiSchema = (equipment, validationTypes, hasRightsToEdit, hasRightsToEditAll) => {
    const attributes = toArray(equipment?.attributes ?? [])
        // Take only attributes configured as visible if user has no special rights to see all attributes
        .filter((a) => hasRightsToEditAll || a.showAll === "1");

    const attributesUiSchema = attributes.reduce((result, attribute) => {
        const attributeName = (attribute.attributename ?? attribute.attributeName).toLowerCase();
        const validationTypeName = getValidationTypeName(attribute, validationTypes);
        const friendlyNameToolTip = attribute.toolTip;
        const lookupValues = toArray(attribute.lookupValues?.lookupValue ?? []);

        // showAll with value "0" will be visible but marked as hidden attribute
        const showAll = attribute.showAll === "1" ? "Y" : "N";
        const editAll = (attribute.editAll === "1" && hasRightsToEdit) || hasRightsToEditAll ? "Y" : "N";

        const attributeUiSchema = getAttributeUiSchema({
            attributeName,
            validationTypeName,
            friendlyNameToolTip,
            lookupValues,
            editAll,
            showAll,
        });

        return {
            ...result,
            ...attributeUiSchema,
        };
    }, {});

    const attributesOrder = orderBy(attributes, [(item) => Number(item.itemOrder)])
        .map((attribute) => (attribute.attributename ?? attribute.attributeName).toLowerCase())
        .filter(distinct);

    return {
        ...attributesUiSchema,
        "ui:order": ["*", ...attributesOrder],
    };
};

/**
 * Get attributes form initial values.
 *
 * @param {Object} equipment - equipment Object
 * @param {Array} validationTypes - values from references
 * @param {Boolean} hasRightsToEdit - user has rights to edit attributes
 * @param {Boolean} hasRightsToEditAll - user has rights to edit all attributes including disabled
 * @returns form values object
 */
export const getInitialValues = (
    equipment,
    validationTypes,
    hasRightsToEdit,
    hasRightsToEditAll,
    values,
    schema,
    isNewEquipment = false
) => {
    const attributes = toArray(equipment?.attributes ?? []).filter((a) => hasRightsToEditAll || a.showAll === "1");

    const attributeValues = attributes.reduce((result, attribute) => {
        const attributeName = (attribute.attributename ?? attribute.attributeName).toLowerCase();
        const validationTypeName = getValidationTypeName(attribute, validationTypes);
        const isNumericTypeMismatch = schema.properties?.[attributeName]?.isNumericTypeMismatch ?? false;

        const value = getNormalizedAttributeValue({
            value: values ? values[attributeName] : attribute.value,
            validationTypeName,
            isNumericTypeMismatch,
        });

        const defaultValue = getNormalizedAttributeValue({
            value: attribute.defaultValue,
            validationTypeName,
        });

        let attributeValue = value;

        // Use default value only when adding equipment
        if (isNewEquipment) {
            // Apply default value only if form is not touched jet ( values === null )
            attributeValue = value ?? (values === null ? defaultValue : value);
        }

        return {
            ...result,
            [attributeName]: attributeValue,
        };
    }, {});

    return attributeValues;
};

export const getApprovedEquipmentAttributesSchema = (equipment) => {
    const attributes = toArray(equipment?.attributes ?? []);

    const attributesSchema = attributes.reduce((result, attribute) => {
        if (!isEmpty(attribute.value)) {
            const attributeName = (attribute.attributename ?? attribute.attributeName).toLowerCase();

            return {
                ...result,
                [attributeName]: {
                    type: "string",
                    title: getAttributeTitle(null, attributeName),
                },
            };
        }

        return result;
    }, {});

    return {
        type: "object",
        properties: attributesSchema,
    };
};

export const getApprovedEquipmentAttributesUiSchema = (equipment) => {
    const attributes = toArray(equipment?.attributes ?? []);

    const attributesUiSchema = attributes.reduce((result, attribute) => {
        if (!isEmpty(attribute.value)) {
            const attributeName = (attribute.attributename ?? attribute.attributeName).toLowerCase();

            return {
                ...result,
                [attributeName]: {
                    "ui:widget": "ViewOnlyWidget",
                },
            };
        }

        return result;
    }, {});

    return {
        classNames: "inline-form form-columns-4",
        "ui:rootFieldId": "equipment",
        ...attributesUiSchema,
    };
};

export const getApprovedEquipmentAttributesInitialValues = (equipment) => {
    const attributes = toArray(equipment?.attributes ?? []);

    const attributeValues = attributes.reduce((result, attribute) => {
        const attributeName = (attribute.attributename ?? attribute.attributeName).toLowerCase();

        if (!isEmpty(attribute.value)) {
            return {
                ...result,
                [attributeName]: attribute.value,
            };
        }

        return result;
    }, {});

    return attributeValues;
};

export const useEquipment = ({ equipmentId, applicationNumber, forced = false, showErrorNotification = true }) => {
    // Prevent API call if invalid applicationNumber
    const resourceId = isNil(applicationNumber) ? undefined : equipmentId;

    return useResource({
        resourceName: "equipment",
        resourceId,
        path: {
            appId: applicationNumber,
        },
        forced,
        showErrorNotification,
        transform: (data) => data?.equipment,
    });
};

export const useEquipmentCatalog = ({ catalogNumber, applicationNumber, forced = false }) => {
    // Prevent API call if invalid applicationNumber
    const resourceId = isNil(applicationNumber) ? undefined : catalogNumber;

    return useResource({
        resourceName: "equipmentCatalog",
        resourceId,
        path: {
            appId: applicationNumber,
        },
        forced,
        transform: (data) => data?.equipment,
    });
};

export const useApprovedEquipment = ({ catalogNumber, applicationNumber, forced = false }) => {
    return useResource({
        ...getApprovedEquipmentResourceParams({
            applicationNumber,
            catalogNumber,
        }),
        forced,
    });
};

export const refreshSidebarEquipment = ({ applicationNumber, showErrorNotification = true }) => {
    store.dispatch(
        getResource({
            resourceName: "applicationEquipmentWidget",
            key: `${applicationNumber}-equipment-widget`,
            path: {
                appId: applicationNumber,
            },
            showErrorNotification,
        })
    );
};
