import { useMemo } from "react";
import { FilterConfig, VariableType, FilterVariable, useProgramName } from "../../utils";
import { VariablesListItem } from "./VariablesListItem";

export const ProgramVariables: React.FC<{
    utilityNumber: string;
    programNumber: string;
    filterConfig: FilterConfig;
    variableType?: VariableType;
    selectedVariable?: FilterVariable;
    onSelectVariable: (variable: FilterVariable | undefined) => void;
    onVariableToggle: (variable: FilterVariable) => void;
}> = ({ utilityNumber, programNumber, filterConfig, variableType, selectedVariable, onSelectVariable, onVariableToggle }) => {
    const variables = useMemo(
        () =>
            filterConfig
                .filter((variable) => variable.programNumber === programNumber)
                .filter((variable) => !variableType || variable.type === variableType),
        [filterConfig, programNumber, variableType]
    );

    const programName = useProgramName(utilityNumber, programNumber);

    if (variables.length === 0) {
        return null;
    }

    return (
        <div className="flex-column">
            <span className="fs-14 fw-600 uppercase text-secondary truncate px-4 py-2 no-shrink">{programName}</span>
            <ul className="flex-column">
                {variables.map((variable, index) => (
                    <VariablesListItem
                        key={index}
                        variable={variable}
                        isActive={variable === selectedVariable}
                        onClick={() => onSelectVariable(variable)}
                        onToggle={() => onVariableToggle(variable)}
                    />
                ))}
            </ul>
        </div>
    );
};
