import React from "react";
import WorkCenterIllustration from "assets/img/workcenter_illustration_2x.webp";
import Button from "components/ui/Button";
import { closeModal } from "components/ui/Modal/utils";

export const OnboardingModal: React.FC<{
    onClose: () => void;
}> = ({ onClose }) => {
    return (
        <div className="flex">
            <img src={WorkCenterIllustration} alt="Filter creation" />
            <div className="flex flex-column p-4">
                <div className="getting-started fw-600 pb-2">Getting Started</div>
                <h5 className="pb-6 fs-20">Welcome to Workcenter</h5>
                <div>
                    Manage your workload with custom views. Create and share filters, search for variables, and find tailored results.
                </div>
                <Button
                    primary
                    onClick={() => {
                        closeModal();
                        onClose();
                    }}
                    className="me-auto mt-auto"
                >
                    Let's Start
                </Button>
            </div>
        </div>
    );
};
