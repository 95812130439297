import React from "react";
import { isNil, isString } from "lodash";
import FieldGroupRadio from "../FieldGroupRadio";
import uuidv1 from "uuid/v1";
import { decodeHtml } from "components/utils/string";

/**
 * Radio button.
 * @param {Object} params
 * @param {string} [params.id] - Radio id
 * @param {string} [params.name] - Radio name
 * @param {string} params.label - Radio label
 * @param {boolean} params.checked - Radio checked
 * @param {string} params.value - Radio value
 * @param {boolean} [params.required] - Radio required
 * @param {boolean} [params.disabled] - Radio disabled
 * @param {function} params.onChange - Radio onChange
 * @returns
 */
const Radio = ({ id, name, label, checked, value, required, disabled, onChange }) => {
    const radioId = id || uuidv1().toUpperCase();
    const radioLabel = !isNil(label) ? isString(label) ? decodeHtml(label) : label : <>&nbsp;</>;

    return (
        <FieldGroupRadio
            disabled={disabled}
            label={radioLabel}
            required={required}
            name={name}
            id={radioId}
            checked={checked === null ? true : checked}
            labelIcon={!checked ? "circle-radio-button-unchecked-empty" : "radio-button-checked"}
            value={value}
            onChange={onChange}
        />
    );
};

export default Radio;
