import React, { memo, useState, useCallback } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { getResource, optimisticUpdateItem } from "../../../store/resources/actions";
import SearchInput from "../Input/SearchInput";
import TabList from "../List/TabList";
import InlinePanelFooter from "../Dashboard/Panel/InlinePanelFooter";
import { InfoMessage } from "../Message";
import CommonItemsTab from "./CommonItemsTab";
import RecentItemsTab from "./RecentItemsTab";
import { SelectFromCatalogTab } from "./SelectFromCatalogTab";
import { useIsMobile } from "components/utils/useIsMobile";

const tabs = [
    {
        id: "all",
        title: "All",
    },
    {
        id: "recent",
        title: "Recently Used",
    },
    {
        id: "common",
        title: "Most Common",
    },
];

const CatalogSearch = memo(
    ({
        applicationNumber,
        itemType,
        title,
        usageSummaryResourceName,
        categoriesResourceName,
        categoryItemsResourceName,
        categorySearchResourceName,
        categoryItemsResourceParams,
        categoryItemNumberKey,
        categoryItemNameKey,
        onSelect,
        onClose,
    }) => {
        const dispatch = useDispatch();

        const isMobile = useIsMobile();

        const [activeTab, setActiveTab] = useState(tabs[0]);
        const [searchTerm, setSearchTerm] = useState("");
        const [hasNoCategories, setHasNoCategories] = useState(false);

        const searchResults = useSelector((state) => state.resources[categorySearchResourceName].itemsById[applicationNumber]);

        const onSearch = (term) => {
            if (term) {
                dispatch(
                    getResource({
                        resourceName: categorySearchResourceName,
                        key: applicationNumber,
                        path: {
                            appId: applicationNumber,
                        },
                        query: {
                            searchTerm: term,
                        },
                    })
                );
            }
        };

        const onSearchTermChange = (event) => {
            setSearchTerm(event.target.value);

            // Clear previous search results
            if (searchResults) {
                dispatch(
                    optimisticUpdateItem({
                        resourceName: categorySearchResourceName,
                        resourceId: applicationNumber,
                        value: undefined,
                    })
                );
            }
        };

        const onResetSearch = useCallback(() => {
            setSearchTerm("");

            // Clear previous search results
            if (searchResults) {
                dispatch(
                    optimisticUpdateItem({
                        resourceName: categorySearchResourceName,
                        resourceId: applicationNumber,
                        value: undefined,
                    })
                );
            }
        }, [applicationNumber, categorySearchResourceName, searchResults, dispatch]);

        const onNoCategories = useCallback(() => {
            setHasNoCategories(true);
        }, []);

        return (
            <div className="equipment-or-event-catalog fill-height">
                {hasNoCategories ? (
                    <NoCategoriesMessage />
                ) : (
                    <div className="catalog-selector fill-height flex-column no-scroll">
                        <div
                            className={cn("toolbar bg-theme-semi-white-diagonal", {
                                "flex-row align-center pe-4": activeTab.id === "all" && !isMobile,
                                "flex-column": activeTab.id === "all" && isMobile,
                            })}
                        >
                            <TabList items={tabs} activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
                            {activeTab.id === "all" && (
                                <div
                                    className={cn("flex-shrink-0", {
                                        "w-1/3": !isMobile,
                                        "w-full px-4 py-2 border-top": isMobile,
                                    })}
                                >
                                    <SearchInput value={searchTerm} onChange={onSearchTermChange} onSubmit={onSearch} />
                                </div>
                            )}
                        </div>
                        {activeTab.id === "all" && (
                            <SelectFromCatalogTab
                                applicationNumber={applicationNumber}
                                itemType={itemType}
                                title={title}
                                categoriesResourceName={categoriesResourceName}
                                categorySearchResourceName={categorySearchResourceName}
                                categoryItemsResourceParams={categoryItemsResourceParams}
                                categoryItemNumberKey={categoryItemNumberKey}
                                categoryItemNameKey={categoryItemNameKey}
                                searchTerm={searchTerm}
                                onResetSearch={onResetSearch}
                                onSelect={onSelect}
                                onNoCategories={onNoCategories}
                            />
                        )}
                        {activeTab.id === "recent" && (
                            <RecentItemsTab
                                title={title}
                                itemType={itemType}
                                categoryItemNumberKey={categoryItemNumberKey}
                                applicationNumber={applicationNumber}
                                usageSummaryResourceName={usageSummaryResourceName}
                                onSelectItem={onSelect}
                            />
                        )}
                        {activeTab.id === "common" && (
                            <CommonItemsTab
                                title={title}
                                itemType={itemType}
                                categoryItemNumberKey={categoryItemNumberKey}
                                applicationNumber={applicationNumber}
                                usageSummaryResourceName={usageSummaryResourceName}
                                onSelectItem={onSelect}
                            />
                        )}
                    </div>
                )}
                {onClose && <InlinePanelFooter btnText="Cancel" onClose={onClose} />}
            </div>
        );
    }
);

const NoCategoriesMessage = () => {
    return <InfoMessage spaceAround>Program has no categories configured.</InfoMessage>;
};

export default CatalogSearch;
