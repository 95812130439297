import { useEffect, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty, isNil, sortBy } from "lodash";

import { store } from "../configureStore";
import { getResource, createResource } from "./actions";
import { getDefinedDashboard, transformDashboardResponse } from "../../components/utils/dashboard";
import useUnmounted from "../../components/utils/useUnmounted";
import { toArray } from "../../components/utils/array";
import { CONTACT_TYPE } from "components/utils/constants";
import {
    getApplicationResourceParams,
    getApplicationTagsResourceParams,
    getApplicationFlagsResourceParams,
    getContractorsResourceParams,
    getProgramFormFriendlyNamesResourceParams,
    getProgramRightsResourceParams,
    getEventCategoriesResourceParams,
    getEventsInCategoryResourceParams,
    getResourceKey,
    getInvoiceDocumentResourceParams,
    getFastTagsResourceParams,
    getUtilitiesContractsInvoiceDocumentsFlagsResourceParams,
    getPowerBiReportResourceParams,
    getProgramsApplicationTransferResourceParams,
    getProgramWorkflowStatusesResourceParams,
    getApplicationFormsResourceParams,
    getApplicationFormDetailsResourceParams,
    getApplicationWorkflowStatusTransferResourceParams,
    getProgramWorkflowStatusApplicationTransferResourceParams,
    getInvoiceItemsResourceParams,
    getInvoiceDocumentsResourceParams,
    getInvoiceNotesResourceParams,
    getCustomerResourceParams,
    getCustomerContactsResourceParams,
    getCustomerContactResourceParams,
    getFolderListResourceParams,
    getCalculationTestInputsResourceParams,
    getClientRoleRightsResourceParams,
    getProgramFormPageFieldsResourceParams,
    getBudgetLinesResourceParams,
    getContractAttributesResourceParams,
    getBudgetLinesAmountsResourceParams,
    getBudgetLinesInvoicesResourceParams,
    getBudgetLookupsResourceParams,
    getApplicationAvailableDocumentsResourceParams,
    getApplicationRebatesResourceParams,
    getUtilityRightsResourceParams,
    getClientRoleUsersResourceParams,
} from "../configureResources";

/**
 * A custom hook that provides a resource and its loading state.
 *
 * @param {Object} options - The options object.
 * @param {string} options.resourceName - The name of the resource.
 * @param {boolean} [options.showSuccessNotification] - Whether to show a success notification.
 * @param {boolean} [options.showErrorNotification] - Whether to show an error notification.
 * @param {string} [options.resourceId] - The ID of the resource.
 * @param {string} [options.key] - The key of the resource.
 * @param {Object} [options.path] - The path of the resource.
 * @param {Object} [options.query] - The query of the resource.
 * @param {Function} [options.transform] - The function to transform the resource.
 * @param {boolean} [options.forced] - Whether to force a request for the resource.
 * @param {Function} [options.onError] - The function to handle errors.
 * @returns {[any, boolean]} - The resource and its loading state.
 */
export const useResource = ({
    resourceName,
    showSuccessNotification,
    showErrorNotification,
    resourceId,
    key,
    path = {},
    query = {},
    transform,
    forced,
    onError,
}) => {
    const dispatch = useDispatch();
    const resourceKey = getResourceKey({ resourceId, key });

    const resource = useSelector((state) =>
        isNil(resourceKey) ? undefined : get(state, `resources.${resourceName}.itemsById[${resourceKey}]`)
    );
    const loading = useSelector((state) =>
        isNil(resourceKey) ? false : get(state, `resources.${resourceName}.itemsById[${resourceKey}-is-loading]`, false)
    );

    const isRequested = useRef(false);
    const lastResourceValue = useRef(resource);
    const unmounted = useUnmounted();

    // Check if need to make a request.
    const canRequest = useCallback(() => {
        return !isEmpty(resourceKey) && (isNil(resource) || forced) && !isRequested.current;
    }, [resourceKey, resource, forced]);

    // Set loading state if resource is already loading or will be loaded from useEffect.
    const isLoading = Boolean(loading || canRequest());

    // Request again if resource key has changed
    useEffect(() => {
        if (forced && isRequested.current === true) {
            isRequested.current = false;
            lastResourceValue.current = undefined;
        }
    }, [resourceKey, forced]);

    // Request again if resource is not available any more
    useEffect(() => {
        if (isNil(resource) && !isNil(lastResourceValue.current) && isRequested.current && !loading) {
            isRequested.current = false;
        }

        lastResourceValue.current = resource;
    }, [resource, loading]);

    useEffect(() => {
        if (canRequest()) {
            isRequested.current = true;

            dispatch(
                getResource({
                    resourceName,
                    resourceId,
                    key,
                    path,
                    query,
                    showSuccessNotification,
                    showErrorNotification,
                    onError: (action) => {
                        !unmounted.current && onError && onError(action);
                    },
                })
            );
        }
    }, [
        resource,
        forced,
        resourceName,
        resourceId,
        key,
        path,
        query,
        onError,
        unmounted,
        canRequest,
        showSuccessNotification,
        showErrorNotification,
        dispatch,
    ]);

    const transformedData = transform ? transform(resource) : resource;

    return [transformedData, isLoading];
};

/**
 * Returns a promise that dispatches a getResource action and resolves with the action data on success or rejects with the action on error.
 * @param {Object} options - The options object.
 * @param {string} options.resourceName - The name of the resource to get.
 * @param {string} [options.resourceId] - The ID of the resource to get.
 * @param {string} [options.key] - The key of the resource to get.
 * @param {Object} [options.path] - The path of the resource to get.
 * @param {Object} [options.query] - The query of the resource to get.
 * @param {Boolean} [options.showSuccessNotification] - Show a success notification.
 * @param {Boolean} [options.showErrorNotification] - Show an error notification.
 * @param {Function} [options.transform] - The function to transform the resource data.
 * @returns {Promise} A promise that resolves with the action data on success or rejects with the action on error.
 */
export const getResourcePromise = ({
    resourceName,
    resourceId,
    key,
    path = {},
    query = {},
    showSuccessNotification = undefined,
    showErrorNotification = undefined,
    transform = undefined,
}) =>
    new Promise((resolve, reject) => {
        store.dispatch(
            getResource({
                resourceName,
                resourceId,
                key,
                path,
                query,
                transform,
                showSuccessNotification,
                showErrorNotification,
                onSuccess: (action) => resolve(action.data),
                onError: (action) => reject(action),
            })
        );
    });

export const createResourcePromise = ({
    resourceName,
    resourceId,
    key,
    path = {},
    query = {},
    body,
    showSuccessNotification,
    showErrorNotification,
    onSuccess,
    onError,
    onComplete,
}) =>
    new Promise((resolve, reject) => {
        store.dispatch(
            createResource({
                resourceName,
                resourceId,
                key,
                path,
                query,
                body,
                showSuccessNotification,
                showErrorNotification,
                onSuccess: (action) => resolve(action.data),
                onError: (action) => reject(action),
                onComplete: () => onComplete && onComplete(),
            })
        );
    });

export const useUtility = ({ utilityNumber, forced = true, canViewUtility = true }) => {
    return useResource({
        resourceName: "utilities",
        resourceId: canViewUtility ? utilityNumber : undefined,
        path: {
            utilityNumber,
        },
        forced,
    });
};

/**
 * Get all utilities.
 * @param {Object} options - The options object.
 * @param {boolean} [options.forced=true] - Whether to reload on mount.
 * @returns {[data: { utilityNumber: string, utility: string }[] | undefined, isLoading: boolean]} - Utilities and loading state.
 */
export const useUtilities = ({ forced = true } = {}) => {
    return useResource({
        resourceName: "utilities",
        key: "utilities",
        forced,
    });
};

export const useCisUtilities = ({ forced = true } = {}) => {
    return useResource({
        resourceName: "cisUtilities",
        key: "cisUtilities",
        forced,
    });
};

export const useProgram = ({ programNumber, forced = true, canViewProgram = true }) => {
    return useResource({
        resourceName: "programs",
        resourceId: canViewProgram ? programNumber : undefined,
        forced,
    });
};

/**
 * Get all programs.
 * @param {Object} params - The parameters object.
 * @param {string} [params.utilityNumber] - Utility number.
 * @param {boolean} [params.forced=false] - Whether to reload data on mount.
 * @returns {[data: { programNumber: string, program: string, isActive: boolean }[] | undefined, isLoading: boolean]} - Programs and loading state.
 */
export const usePrograms = ({ utilityNumber, forced = false }) => {
    return useResource({
        resourceName: "programs",
        key: utilityNumber,
        query: {
            utilityNumber,
        },
        forced,
        transform: (data) => {
            return data?.programList ?? [];
        },
    });
};

export const useProgramsApplicationTransfer = ({ utilityNumber, forced = false } = {}) => {
    return useResource({
        ...getProgramsApplicationTransferResourceParams({ utilityNumber }),
        forced,
    });
};

export const useApplication = ({ applicationNumber, forced = false }) => {
    return useResource({
        ...getApplicationResourceParams({ applicationNumber }),
        forced,
    });
};

export const useApplicationTags = ({ applicationNumber, forced = true }) => {
    return useResource({
        ...getApplicationTagsResourceParams({ applicationNumber }),
        forced,
    });
};

export const useApplicationFlags = ({ applicationNumber, forced = true }) => {
    return useResource({
        ...getApplicationFlagsResourceParams({ applicationNumber }),
        forced,
    });
};

export const useApplicationAvailableDocuments = ({ applicationNumber, isEmail, forced = false }) => {
    return useResource({
        ...getApplicationAvailableDocumentsResourceParams({
            applicationNumber,
            isEmail,
        }),
        forced,
    });
};

export const useApplicationPayments = ({ applicationNumber, forced = false }) => {
    return useResource({
        resourceName: "applicationRebatesWidget",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        forced,
        transform: (data) => {
            return data?.rebates;
        },
    });
};

export const useApplicationPayment = ({ applicationNumber, rebateNumber, forced = false }) => {
    return useResource({
        ...getApplicationRebatesResourceParams({
            applicationNumber,
            rebateNumber,
        }),
        forced,
    });
};

export const useGridColumns = ({ dataGridId, forced = false }) => {
    return useResource({
        resourceName: "gridColumns",
        key: dataGridId,
        path: {
            dataGridId,
        },
        forced,
    });
};

/**
 * Get list of catalogs for a program.
 *
 * @param {object} params
 * @param {string} params.programNumber - program number
 * @returns {[data: { catalogNumber: string, name: string }[] | undefined, isLoading: boolean]} - Catalogs and loading state.
 */
export const useProgramCatalogs = ({ programNumber }) => {
    return useResource({
        resourceName: "programCatalogs",
        key: programNumber,
        path: {
            programNumber,
        },
    });
};

/**
 *
 * @param {object} params
 * @param {string} params.programNumber - program number
 * @param {string} [params.catalogNumber] - catalog number
 * @param {boolean} [params.forced=true] - whether to force a request on mount
 * @returns {[data: { catalogNumber: string, name: string } | undefined, isLoading: boolean]} - Catalog and loading state.
 */
export const useProgramCatalog = ({ programNumber, catalogNumber, forced = true }) => {
    return useResource({
        resourceName: "programCatalogs",
        resourceId: catalogNumber,
        path: {
            programNumber,
        },
        forced,
    });
};

/**
 * Get list of program events.
 *
 * @param {object} params
 * @param {string} params.programNumber
 * @param {boolean} [params.forced=true]
 * @returns  {[data: { programEventNumber: string, name: string }[] | undefined, isLoading: boolean]} - Program events and loading state.
 */
export const useProgramEvents = ({ programNumber, forced = true }) => {
    return useResource({
        resourceName: "programEvents",
        key: programNumber,
        path: {
            programNumber,
        },
        forced,
    });
};

export const useProgramEvent = ({ programNumber, resourceId, forced = true }) => {
    return useResource({
        resourceName: "programEvents",
        resourceId,
        path: {
            programNumber,
        },
        forced,
    });
};

export const useProgramEventTypes = ({ programNumber, forced }) => {
    return useResource({
        resourceName: "programEventCategories",
        key: `${programNumber}-event-types`,
        path: {
            programNumber,
        },
    });
};

export const useProgramCatalogCategory = ({ programNumber, categoryNumber, forced = true }) => {
    return useResource({
        resourceName: "programCatalogCategoriesv2",
        resourceId: categoryNumber,
        path: {
            programNumber,
        },
        forced,
    });
};

export const useProgramEventCategory = ({ programNumber, categoryNumber, forced = true }) => {
    return useResource({
        resourceName: "programEventCategories",
        resourceId: categoryNumber,
        path: {
            programNumber,
        },
        forced,
    });
};

export const useProgramCategories = ({ programNumber, resourceName, forced = true }) => {
    return useResource({
        resourceName,
        key: programNumber,
        path: {
            programNumber,
        },
        query: {
            showAll: true,
        },
        forced,
    });
};

export const useProgramAttributes = ({ programNumber, resourceName }) => {
    return useResource({
        resourceName,
        key: programNumber,
        path: {
            programNumber,
        },
    });
};

/**
 * Get list of available catalog attributes for a program.
 *
 * @param {object} params
 * @param {string} params.programNumber - program number
 * @param {boolean} [params.forced=true] - whether to force a request on mount
 * @returns {[data: { productAttrStandardNumber: string, productAttrDescription: string }[] | undefined, isLoading: boolean]} - Catalog attributes and loading state.
 */
export const useProgramCatalogAttributes = ({ programNumber, forced = true }) => {
    return useResource({
        resourceName: "programCatalogAttributes",
        key: programNumber,
        path: {
            programNumber,
        },
        forced,
    });
};

export const useProgramCatalogApprovedMeasures = ({ programNumber, catalogNumber, forced = true }) => {
    return useResource({
        resourceName: "programCatalogApprovedMeasures",
        key: catalogNumber,
        path: {
            programNumber,
            catalogNumber,
        },
        forced,
    });
};

export const useProgramCatalogBudgetLines = ({ programNumber, forced = true }) => {
    return useResource({
        resourceName: "programCatalogBudgetLines",
        key: programNumber,
        path: {
            programNumber,
        },
        forced,
    });
};

export const useProgramForm = ({ programNumber, forced = true }) => {
    return useResource({
        resourceName: "programForms",
        key: programNumber,
        path: {
            programNumber,
        },
        forced,
        transform: (data) => {
            return data && data[0];
        },
    });
};

export const useProgramForms = ({ formNumber, forced = true }) => {
    return useResource({
        resourceName: "programForm",
        resourceId: formNumber,
        path: {
            formNumber,
        },
        forced,
    });
};

export const useBudgetLines = ({ utilityNumber, contractNumber, forced = false }) => {
    return useResource({
        ...getBudgetLinesResourceParams({ utilityNumber, contractNumber }),
        forced,
    });
};

export const useBudgetLine = ({ utilityNumber, contractNumber, budgetLineNumber }) => {
    return useResource({
        resourceName: "utilitiesContractsBudgetLines",
        resourceId: budgetLineNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
        forced: true,
    });
};

export const useBudgetLinePrograms = ({ utilityNumber, contractNumber, budgetLineNumber }) => {
    return useResource({
        resourceName: "utilitiesContractsBudgetLinesPrograms",
        key: budgetLineNumber,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
        forced: true,
        transform: (data) => {
            return (data && data.budgetLineProgramList) || [];
        },
    });
};

export const useBudgetLineInvoice = ({ utilityNumber, contractNumber, budgetLineNumber, resourceId }) => {
    return useResource({
        resourceName: "utilitiesContractsBudgetLinesInvoices",
        resourceId,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
        forced: true,
    });
};

export const useBudgetLineInvoices = ({ utilityNumber, contractNumber, budgetLineNumber }) => {
    return useResource({
        ...getBudgetLinesInvoicesResourceParams({
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        }),
        forced: true,
    });
};

export const useBudgetLineAmount = ({ utilityNumber, contractNumber, budgetLineNumber, resourceId }) => {
    return useResource({
        resourceName: "utilitiesContractsBudgetLinesAmounts",
        resourceId,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
        forced: true,
    });
};

export const useBudgetLineAmounts = ({ utilityNumber, contractNumber, budgetLineNumber }) => {
    return useResource({
        ...getBudgetLinesAmountsResourceParams({
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        }),
        forced: true,
    });
};

export const useBudgetLookups = ({ utilityNumber, contractNumber }) => {
    return useResource({
        ...getBudgetLookupsResourceParams({ utilityNumber, contractNumber }),
        forced: true,
    });
};

export const useContractsLookups = ({ programNumber, applicationNumber }) => {
    return useResource({
        ...getContractorsResourceParams({ programNumber, applicationNumber }),
        forced: true,
        transform: (data) => {
            return (data && data.items) || [];
        },
    });
};

export const useContracts = ({ utilityNumber }) => {
    return useResource({
        resourceName: "utilitiesContracts",
        key: utilityNumber,
        path: {
            utilityNumber,
        },
        transform: (data) => {
            return (data && data.contractList) || [];
        },
    });
};

export const useContract = ({ utilityNumber, contractNumber, forced = false }) => {
    return useResource({
        resourceName: "utilitiesContracts",
        resourceId: contractNumber,
        path: {
            utilityNumber,
        },
        forced,
    });
};

export const useContractAttributes = ({ utilityNumber, contractNumber }) => {
    return useResource({
        ...getContractAttributesResourceParams({
            utilityNumber,
            contractNumber,
        }),
        forced: true,
    });
};

export const useInvoiceItems = ({ utilityNumber, contractNumber, documentNumber, forced = false }) => {
    return useResource({
        ...getInvoiceItemsResourceParams({
            utilityNumber,
            contractNumber,
            documentNumber,
        }),
        transform: (data) => (data && data.invoiceList) || [],
        forced,
    });
};

export const useInvoiceDocuments = ({ utilityNumber, contractNumber, forced = false }) => {
    return useResource({
        ...getInvoiceDocumentsResourceParams({ utilityNumber, contractNumber }),
        transform: (data) => (data && data.invoiceDocumentList) || [],
        forced,
    });
};

export const useInvoiceDocument = ({ documentNumber, forced = false }) => {
    return useResource({
        ...getInvoiceDocumentResourceParams(),
        resourceId: documentNumber,
        forced,
    });
};

export const useInvoiceDocumentAttributes = ({ utilityNumber, contractNumber, documentNumber }) => {
    return useResource({
        resourceName: "utilitiesContractsInvoiceDocumentsAttributes",
        key: documentNumber,
        path: {
            utilityNumber,
            contractNumber,
            documentNumber,
        },
        transform: (data) => {
            let list = (data && data.invoiceDocumentAttributeList) || [];

            return sortBy(list, ["itemOrder", "attributeName"]);
        },
    });
};

export const useInvoiceNotes = ({ utilityNumber, contractNumber, documentNumber }) => {
    return useResource({
        ...getInvoiceNotesResourceParams({
            utilityNumber,
            contractNumber,
            documentNumber,
        }),
        transform: (data) => data || [],
        forced: true,
    });
};

export const useDataGridData = ({ dataGridId, key, filter, page = 1, pageSize = 1000, forced = true }) => {
    const shouldLoad = !isNil(dataGridId) && !isNil(key);
    const resourceId = shouldLoad ? dataGridId : undefined;
    const resourceKey = shouldLoad ? key : undefined;

    return useResource({
        resourceName: "grid",
        resourceId,
        key: resourceKey,
        query: {
            searchAttr: filter,
            pageNum: page,
            recsPerPage: pageSize,
            returnType: "JSON",
        },
        forced,
        transform: (data) => {
            return toArray(data?.grid?.rows ?? []);
        },
    });
};

export const useGlobalScanQueue = ({ forced = false } = {}) => {
    return useResource({
        resourceName: "scanGroupProgramOrder",
        key: "global-scans-queue",
        forced,
    });
};

export const useScanGroups = ({ forced = false } = {}) => {
    return useResource({
        resourceName: "scanGroups",
        key: "scans-queue-groups",
        forced,
    });
};

export const useScanGroupPrograms = ({ groupNumber, forced = false }) => {
    return useResource({
        resourceName: "scanGroupPrograms",
        key: "scans-queue-group-programs",
        path: {
            groupNumber,
        },
        forced: forced,
        transform: (data) => {
            return (data && data.scanGroupProgramList) || [];
        },
    });
};

export const useScanGroupUsers = ({ groupNumber, forced = false }) => {
    return useResource({
        resourceName: "scanGroupUsers",
        key: "scans-queue-group-users",
        path: {
            groupNumber,
        },
        forced: forced,
        transform: (data) => {
            return (data && data.scanGroupUserList) || [];
        },
    });
};

export const useUserGroups = ({ forced = true } = {}) => {
    return useResource({
        resourceName: "groups",
        key: "system-user-groups",
        forced: forced,
        transform: (data) => {
            return (data && data.groupList) || [];
        },
    });
};

export const useProgramFormPages = ({ programNumber, formNumber, forced = false }) => {
    const key = programNumber && formNumber && `${programNumber}-${formNumber}`;

    return useResource({
        resourceName: "programFormPages",
        key,
        path: {
            programNumber,
            formNumber,
        },
        forced,
    });
};

export const useProgramFormPage = ({ programNumber, pageNumber, forced = false }) => {
    const [form, isLoadingProgramForm] = useProgramForm({
        programNumber,
        forced: false,
    });

    const formNumber = form?.formNumber;
    const resourceId = programNumber && formNumber && pageNumber ? pageNumber : undefined;

    const [page, isLoadingPage] = useResource({
        resourceName: "programFormPages",
        resourceId,
        path: {
            programNumber,
            formNumber,
        },
        forced,
    });

    const isLoading = programNumber && pageNumber && (isLoadingProgramForm || isLoadingPage);

    return [page, isLoading];
};

export const getProgramFormPage = async (programNumber, pageNumber) => {
    const form = await getProgramForm(programNumber);
    const formNumber = form?.formNumber;
    const resourceId = programNumber && formNumber && pageNumber ? pageNumber : undefined;
    const page = await getResourcePromise({
        resourceName: "programFormPages",
        resourceId,
        path: {
            programNumber,
            formNumber,
        },
    });

    return page;
};

const getProgramForm = async (programNumber) => {
    const programForm = await getResourcePromise({
        resourceName: "programForms",
        key: programNumber,
        path: {
            programNumber,
        },
        transform: (data) => {
            return data && data[0];
        },
    });
    return programForm;
};
// For Target status
export const getProgramWorkflowStatues = async (programNumber) => {
    const programWorkflowStatues = await getResourcePromise({
        resourceName: "programWorkflowStatus",
        key: programNumber,
        path: {
            programNumber,
        },
    });
    return programWorkflowStatues;
};

export const useEventCategories = ({ applicationNumber, categoryNumber, forced = false }) => {
    const resourceParams = getEventCategoriesResourceParams({
        applicationNumber,
        categoryNumber,
    });

    return useResource({
        ...resourceParams,
        forced,
    });
};

export const useEventsInCategory = ({ applicationNumber, categoryNumber, forced = false }) => {
    const resourceParams = getEventsInCategoryResourceParams({
        applicationNumber,
        categoryNumber,
    });

    return useResource({
        ...resourceParams,
        forced,
    });
};

export const useFastTags = ({ entityNumber, entityType }) => {
    return useResource({
        ...getFastTagsResourceParams({ entityNumber, entityType }),
        transform: (data) => {
            return ((data && data.friendlyNames) || []).map((fastTag) => fastTag.friendlyName);
        },
    });
};

export const useRebatesAvailable = ({ applicationNumber, forced = true }) => {
    return useResource({
        resourceName: "applicationRebatesAvailable",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        forced,
    });
};

export const useInvoiceDocumentFlags = ({ utilityNumber, contractNumber, documentNumber, forced = true }) => {
    return useResource({
        ...getUtilitiesContractsInvoiceDocumentsFlagsResourceParams({
            utilityNumber,
            contractNumber,
            documentNumber,
        }),
        key: documentNumber,
        forced,
    });
};

export const usePowerBiReport = ({ reportID, isPaginated, entityTypeID, entityNumber, forced = true }) => {
    return useResource({
        ...getPowerBiReportResourceParams({
            reportID,
            isPaginated,
            entityTypeID,
            entityNumber,
        }),
        forced,
    });
};

export const useApplicationForms = ({ applicationNumber, forced = true }) => {
    return useResource({
        ...getApplicationFormsResourceParams({ applicationNumber }),
        forced,
    });
};

export const useApplicationFormDetails = ({ applicationNumber, applicationFormId, forced = false }) => {
    return useResource({
        ...getApplicationFormDetailsResourceParams({
            applicationNumber,
            applicationFormId,
        }),
        forced,
    });
};

export const useApplicationWorkflowStatusTransfer = ({ applicationNumber, forced = false }) => {
    return useResource({
        ...getApplicationWorkflowStatusTransferResourceParams({
            applicationNumber,
        }),
        forced,
    });
};

export const useProgramWorkflowStatusApplicationTransfer = ({ programNumber, forced = false }) => {
    return useResource({
        ...getProgramWorkflowStatusApplicationTransferResourceParams({
            programNumber,
        }),
        forced,
    });
};

/**
 * Get list of project statuses for a program.
 *
 * @param {object} parameters
 * @param {string} parameters.programNumber - Program number.
 * @param {boolean} [parameters.forced=false] - Whether to reload data on mount.
 * @returns {[data: { workflowNumber: string, status: string }[] | undefined, isLoading: boolean]} - Project statuses and loading state.
 */
export const useProgramWorkflowStatuses = ({ programNumber, forced = false }) => {
    return useResource({
        ...getProgramWorkflowStatusesResourceParams({ programNumber }),
        forced,
    });
};

export const useCustomer = ({ utilityNumber, customerNumber, forced = false }) => {
    return useResource({
        ...getCustomerResourceParams({ utilityNumber, customerNumber }),
        forced,
    });
};

export const useCustomerContacts = ({ utilityNumber, customerNumber, forced = false }) => {
    return useResource({
        ...getCustomerContactsResourceParams({ utilityNumber, customerNumber }),
        forced,
    });
};

export const useCustomerContact = ({ utilityNumber, customerNumber, contactNumber, forced = false }) => {
    return useResource({
        ...getCustomerContactResourceParams({
            utilityNumber,
            customerNumber,
            contactNumber,
        }),
        forced,
    });
};

export const useFolders = ({ entityTypeId, entityId, forced = false }) => {
    return useResource({
        ...getFolderListResourceParams({ entityTypeId, entityId }),
        forced,
    });
};

export const useApplicationAssignedContacts = ({ applicationNumber, forced = false }) => {
    const [assignedContacts = [], isLoading] = useResource({
        resourceName: "applicationContacts",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        forced,
        transform: (data) => {
            //TODO: Align properties with datagrid row object.
            return (data || []).map((item) => ({
                contacttype: item.contactType,
                contactnumber: item.contactNumber,
                acct_number: item.accountnumber,
                address: item.address,
                address_cont: item.address_cont,
                cell: item.cell,
                city: item.city,
                company: item.company,
                contacttitle: item.contactTitle,
                email: item.email,
                fax: item.fax,
                firstname: item.firstName,
                lastname: item.lastName,
                phone: item.phone,
                premiseid: item.premiseid,
                state: item.state,
                taxid: item.taxid,
                workflowTargetGroupId: item.workflowTargetGroupId,
                workflowgroup: item.workflowTargetGroupName,
                zip: item.zip,
                meterid: item.meterid,
                refappid: item.refappid,
                hascis: item.hascis,
                cmpAppId: item.cmpAppId,
            }));
        },
    });

    const premiseContact = useMemo(() => {
        return assignedContacts.find((c) => c.contacttype?.toLowerCase() === CONTACT_TYPE.PREMISE);
    }, [assignedContacts]);

    const primaryContact = useMemo(() => {
        return assignedContacts.find((c) => c.contacttype?.toLowerCase() === CONTACT_TYPE.PRIMARY);
    }, [assignedContacts]);

    const contractorContact = useMemo(() => {
        return assignedContacts.find((c) => c.contacttype?.toLowerCase() === CONTACT_TYPE.CONTRACTOR);
    }, [assignedContacts]);

    return [premiseContact, primaryContact, contractorContact, isLoading];
};

export const useCalculationTestInputs = ({ calculationNumber }) => {
    return useResource({
        ...getCalculationTestInputsResourceParams({ calculationNumber }),
        transform: (data) => {
            return (data || []).filter((i) => !isEmpty(i.item));
        },
    });
};

export const useDashboards = ({ dashboardType }) => {
    const definedDashboard = getDefinedDashboard(dashboardType);

    const [dashboard, isLoading] = useResource({
        resourceName: "dashboards",
        key: definedDashboard ? undefined : dashboardType,
        query: {
            dashboardType,
        },
    });

    if (definedDashboard) {
        return [transformDashboardResponse([definedDashboard]), false];
    }

    return [dashboard, isLoading];
};

export const useProgramFormGenProcs = ({ programNumber, forced = false } = {}) => {
    return useResource({
        resourceName: "programFormGenProcs",
        key: programNumber,
        path: {
            programNumber,
        },
        forced,
    });
};

export const useProgramFormGenProc = ({ programNumber, procId, forced = false }) => {
    return useResource({
        resourceName: "programFormGenProcs",
        resourceId: procId,
        path: {
            programNumber,
        },
        forced,
    });
};

export const useSearchOptions = () => {
    return useResource({
        resourceName: "searchOptions",
        key: "searchOptions",
    });
};

export const useSearchHistory = ({ forced = false }) => {
    return useResource({
        resourceName: "searchHistory",
        key: "searchHistory",
        forced,
    });
};

export const useProgramFormFriendlyNames = ({ programNumber }) => {
    return useResource({
        ...getProgramFormFriendlyNamesResourceParams({ programNumber }),
        transform: (data) => {
            return data?.fieldFriendlyNameList ?? [];
        },
    });
};

export const useProgramFormPageFields = ({ programNumber, formNumber, pageNumber, forced = false }) => {
    return useResource({
        ...getProgramFormPageFieldsResourceParams({
            programNumber,
            formNumber,
            pageNumber,
        }),
        forced,
    });
};

export const getProgramFormPageFields = async (programNumber, formNumber, pageNumber, forced = true) => {
    const fields = await getResourcePromise({
        ...getProgramFormPageFieldsResourceParams({
            programNumber,
            formNumber,
            pageNumber,
        }),
        forced,
    });

    return fields;
};

export const getFormPageFields = async (programNumber, pageNumber) => {
    const form = await getProgramForm(programNumber);
    const formNumber = form?.formNumber;
    const formpageFields = await getProgramFormPageFields(programNumber, formNumber, pageNumber);

    return formpageFields;
};

export const useClientRoleRights = ({ clientRoleId, forced = false }) => {
    return useResource({
        ...getClientRoleRightsResourceParams({ clientRoleId }),
        forced,
        transform: (data) => {
            return data?.roleRights ?? [];
        },
    });
};

export const useClientRoleUsers = ({ clientRoleId, entityTypeId, entityNumber, forced = false }) => {
    return useResource({
        ...getClientRoleUsersResourceParams({ clientRoleId, entityTypeId, entityNumber }),
        forced,
        transform: (data) => {
            return data?.userList ?? [];
        },
    });
};

export const useProgramRights = ({ programNumber, forced = false }) => {
    return useResource({
        ...getProgramRightsResourceParams({ programNumber }),
        forced,
        transform: (data) => {
            return data?.rights ?? [];
        },
    });
};

export const useUtilityRights = ({ utilityNumber, forced = false }) => {
    return useResource({
        ...getUtilityRightsResourceParams({ utilityNumber }),
        forced,
        transform: (data) => {
            return data?.rights ?? [];
        },
    });
};

export const useExternalApplication = ({ targetUserNumber }) => {
    return useResource({
        resourceName: "externalApps",
        key: targetUserNumber,
        query: {
            targetUserNumber: targetUserNumber,
        },
    });
};
