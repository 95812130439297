import React, { memo, useMemo } from "react";
import ClassNames from "classnames";

import { getUserTheme, DEFAULT_THEME_SIGHTLINE, THEME_SIGHTLINE_HC } from "../../utils/settings";

import SightLineLogo from "../../../assets/img/Sightline-logo-color.png";
import SightLineLogoSimplified from "../../../assets/img/sightline-logo-simplified.png";
import SightLineLoginScreenBackground from "../../../assets/img/sightline-login-screen-background-img.jpg";
import VisionLoginScreenBackground from "../../../assets/img/golden_bridge.png";

import "./style.scss";

const LoginFormWrap = memo((props) => {
    const theme = useMemo(() => getUserTheme({ isUserAuthenticated: false }), []);

    const className = ClassNames("login-form-wrap", props.className, {
        "expanded-width": props.expandedWidth,
    });

    if (theme === DEFAULT_THEME_SIGHTLINE || theme === THEME_SIGHTLINE_HC) {
        return (
            <div className="login-screen sightline-login">
                <div className="login-screen-left-block">
                    <img className="logo" src={SightLineLoginScreenBackground} alt="" width="" height="" />
                    <div className="text-block-wrap">
                        <div className="img-wrap">
                            <img className="logo-simplified" src={SightLineLogoSimplified} alt="" width="" height="" />
                        </div>
                        <span>On-demand platform and insights for energy utility companies</span>
                    </div>
                </div>
                <div className="login-screen-right-block">
                    <div className="login-form-container">
                        <div className={className}>
                            <div className="welcome">Welcome to</div>
                            <img className="logo" src={SightLineLogo} alt="" width="280" height="46" />
                            <div className="login-square">{props.children}</div>
                        </div>
                    </div>
                    <div className="aeg">Applied Energy Group, Inc</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="login-screen vision-login">
                <div className="login-screen-left-block">
                    <img className="logo" src={VisionLoginScreenBackground} alt="" width="" height="" />
                    <div className="text-block-wrap">
                        <div className="logo-icon"></div>
                        <span>
                            The industry-leading <br /> portfolio management <br /> platform
                        </span>
                    </div>
                </div>
                <div className="login-screen-right-block">
                    <div className="login-form-container">
                        <div className={className}>
                            <div className="logo"></div>
                            <div className="login-square">{props.children}</div>
                        </div>
                    </div>
                    <div className="aeg">Applied Energy Group, Inc</div>
                </div>
            </div>
        );
    }
});

export default LoginFormWrap;
