import {
    FilterVariableForFormField,
    FilterVariablesItem,
    FormFieldRequirements,
    FormFieldsConditions,
    getConditionIcon,
} from "components/views/ApplicationProcessingWorkCenterV2/utils";
import { VariablePath } from "../VariablePath";
import { ConditionBlock } from "../ConditionBlock";
import { ValuesBlock } from "../ValuesBlock";
import { RadiosBlock } from "../RadiosBlock";
import { ValuesRangeBlock } from "../ValuesRangeBlock";
import cn from "classnames";
import { isNil } from "lodash";

export const VariableBlockItemFormFields: React.FC<{
    variable: FilterVariableForFormField;
    error?: string;
    item: FilterVariablesItem;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
    onEdit: () => void;
    onDelete: () => void;
    onToggle?: () => void;
    onToggleValues?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onEdit, onDelete, onToggle, onToggleValues }) => {
    return (
        <div
            className={cn(
                "flex-row fill-width rounded-2 text-secondary group hover:bg-grey-200 focus-within:bg-grey-200 bg-opacity-50 gap-2",
                isNil(onToggle) ? "px-3" : "px-1"
            )}
            data-testid={`variable-block-item-${variable.items.indexOf(item)}`}
        >
            <VariablePath icon={getConditionIcon(variable.type, item.condition)!} />
            <div className={cn("flex-column fill-width bg-opacity-100", isNil(onToggle) ? "pb-3" : "pb-1")}>
                <ConditionBlock item={item} readOnly={readOnly} onEdit={onEdit} onDelete={onDelete} onToggle={onToggle} />
                <ConditionValuesBlock
                    variable={variable}
                    item={item}
                    readOnly={readOnly}
                    error={error}
                    onChange={onChange}
                    onToggle={onToggleValues}
                />
            </div>
        </div>
    );
};

const ConditionValuesBlock: React.FC<{
    variable: FilterVariableForFormField;
    item: FilterVariablesItem;
    error?: string;
    readOnly?: boolean;
    onChange: (item: FilterVariablesItem) => void;
    onToggle?: (values: string[]) => void;
}> = ({ variable, item, readOnly = false, error, onChange, onToggle }) => {
    if (
        item.condition === FormFieldsConditions.AnswerIs ||
        item.condition === FormFieldsConditions.AnswerIsNot ||
        item.condition === FormFieldsConditions.AnswerIsGreaterThan ||
        item.condition === FormFieldsConditions.AnswerIsGreaterThanOrEqualTo ||
        item.condition === FormFieldsConditions.AnswerIsLowerThan ||
        item.condition === FormFieldsConditions.AnswerIsLowerThanOrEqualTo
    ) {
        return (
            <ValuesBlock
                items={item.values}
                error={error}
                readOnly={readOnly}
                onChange={(values) => onChange({ ...item, values })}
                onToggle={onToggle}
            />
        );
    }

    if (item.condition === FormFieldsConditions.AnswerIsBetween) {
        return <ValuesRangeBlock item={item} error={error} readOnly={readOnly} onChange={onChange} />;
    }

    if (item.condition === FormFieldsConditions.RequirementIs) {
        return (
            <RadiosBlock
                items={Object.values(FormFieldRequirements).map((v) => ({ label: v, isChecked: item.values.includes(v), id: v }))}
                error={error}
                readOnly={readOnly || !isNil(onToggle)}
                onChange={(values) => onChange({ ...item, values })}
            />
        );
    }

    return null;
};
