import {
    FilterVariable,
    FilterVariablesItem,
    getConditionInitialValues,
    isFormVariable,
    isMeasureVariable,
    VariableCondition,
    VariableTypes,
} from "../../../utils";
import { VariableBlockItemProjectStatuses } from "./VariableBlockItemProjectStatuses";
import { VariableBlockItemApplicationDetails } from "./VariableBlockItemApplicationDetails";
import { VariableBlockItemFormFields } from "./VariableBlockItemFormFields";
import { VariableBlockItemMeasureAttribute } from "./VariableBlockItemMeasureAttribute";
import { useState } from "react";
import { ConditionDeletedLine, ConditionSelectLine, RuleLine } from "../ConditionBlock";
import { isNil, omit } from "lodash";

export const VariableBlockItem: React.FC<{
    variable: FilterVariable;
    error?: string;
    item: FilterVariablesItem;
    itemIndex: number;
    readOnly?: boolean;
    scrollContainerId?: string;
    onChange?: (index: number, item: FilterVariablesItem) => void;
    onDelete?: (index: number) => void;
    onToggle?: (index: number) => void;
    onToggleItemValues?: (index: number, values: string[]) => void;
}> = ({ variable, item, itemIndex, readOnly, error, scrollContainerId, onChange, onDelete, onToggle, onToggleItemValues }) => {
    const [editCondition, setEditCondition] = useState(false);

    const onSelectCondition = (condition: VariableCondition) => {
        if (condition !== item.condition) {
            const values = getConditionInitialValues(condition);
            onChange?.(itemIndex, { ...item, condition, values });
        }

        setEditCondition(false);
    };

    const onEditItem = () => setEditCondition(true);

    const onDeleteItem = () => {
        onChange?.(itemIndex, { ...item, _deletedAt: Date.now() });
    };

    const onUndoDeleteItem = () => {
        onChange?.(itemIndex, omit(item, "_deletedAt"));
    };

    const onToggleItem = () => {
        onToggle?.(itemIndex);
    };

    const onToggleValues = (values: string[]) => {
        onToggleItemValues?.(itemIndex, values);
    };

    if (readOnly && editCondition) {
        setEditCondition(false);
    }

    if (item._deletedAt) {
        return (
            <ConditionDeletedLine
                title="Condition deleted."
                deletedAt={item._deletedAt}
                onUndo={onUndoDeleteItem}
                onDelete={() => onDelete?.(itemIndex)}
            />
        );
    }

    if (editCondition) {
        return (
            <>
                {item.rule && <RuleLine item={item} onChange={(data) => onChange?.(itemIndex, data)} />}
                <ConditionSelectLine
                    type={variable.type}
                    initialCondition={item.condition}
                    scrollContainerId={scrollContainerId}
                    onSelect={onSelectCondition}
                    onCancel={() => setEditCondition(false)}
                    onDelete={onDeleteItem}
                />
            </>
        );
    }

    return (
        <>
            {item.rule && (
                <RuleLine
                    item={item}
                    readOnly={readOnly}
                    configurable={!isNil(onToggle)}
                    onChange={(data) => onChange?.(itemIndex, data)}
                />
            )}
            {variable.type === VariableTypes.ApplicationDetails && (
                <VariableBlockItemApplicationDetails
                    variable={variable}
                    error={error}
                    item={item}
                    readOnly={readOnly}
                    onChange={(data) => onChange?.(itemIndex, data)}
                    onEdit={onEditItem}
                    onDelete={onDeleteItem}
                    onToggle={onToggle && onToggleItem}
                    onToggleValues={onToggleItemValues && onToggleValues}
                />
            )}
            {isFormVariable(variable) && (
                <VariableBlockItemFormFields
                    variable={variable}
                    error={error}
                    item={item}
                    readOnly={readOnly}
                    onChange={(data) => onChange?.(itemIndex, data)}
                    onEdit={onEditItem}
                    onDelete={onDeleteItem}
                    onToggle={onToggle && onToggleItem}
                    onToggleValues={onToggleItemValues && onToggleValues}
                />
            )}
            {isMeasureVariable(variable) && (
                <VariableBlockItemMeasureAttribute
                    variable={variable}
                    error={error}
                    item={item}
                    readOnly={readOnly}
                    onChange={(data) => onChange?.(itemIndex, data)}
                    onEdit={onEditItem}
                    onDelete={onDeleteItem}
                    onToggle={onToggle && onToggleItem}
                    onToggleValues={onToggleItemValues && onToggleValues}
                />
            )}
            {variable.type === VariableTypes.ProjectStatuses && (
                <VariableBlockItemProjectStatuses
                    variable={variable}
                    error={error}
                    item={item}
                    readOnly={readOnly}
                    onChange={(data) => onChange?.(itemIndex, data)}
                    onEdit={onEditItem}
                    onDelete={onDeleteItem}
                    onToggle={onToggle && onToggleItem}
                    onToggleValues={onToggleItemValues && onToggleValues}
                />
            )}
        </>
    );
};
