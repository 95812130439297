import { useProgramFormPageFields } from "store/resources/useResource";
import useProgramFormNumber from "./useProgramFormNumber";

/**
 * Get program form page fields list
 * @param {object} params - The parameters object.
 * @param {string} [params.entityId] - The programNumber.
 * @param {string} [params.pageNumber] - The page number. Request will not be sent if not provided.
 * @returns {[data: { fieldId: string, friendlyName: string }[] | undefined, isLoading: boolean]} - form page fields and loading state.
 */
const useFormPageFields = ({ entityId, pageNumber }) => {
    const [formNumber, isLoadingForm] = useProgramFormNumber({
        entityId: pageNumber ? entityId : undefined,
    });
    const [fields = [], isLoadingFields] = useProgramFormPageFields({
        programNumber: entityId,
        formNumber,
        pageNumber,
        forced: true,
    });

    return [fields, isLoadingForm || isLoadingFields];
};

export default useFormPageFields;
