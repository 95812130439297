import React from "react";
import { connect } from "react-redux";
import { isEqual, isEmpty } from "lodash";
import cn from "classnames";

import * as dataGridActions from "../../../store/dataGrid/actions";

import WaitIcon from "../WaitIcon";
import GridList from "../List/GridList";
import GridDetailsPanel from "./GridDetailsPanel";
import { getDefaultFilter, getSelectedRows, getEnabledGridActions, hasCustomFilter, isFilterChanged } from "components/utils/datagrid";

export const GridContext = React.createContext();

export class DataGrid extends React.Component {
    constructor(props) {
        super(props);

        this.onGridAction = this.onGridAction.bind(this);
        this.onRowAction = this.onRowAction.bind(this);

        this.getColumns = this.getActiveColumns.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getGridActions = this.getGridActions.bind(this);
        this.getAction = this.getAction.bind(this);

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onColumnReorder = this.onColumnReorder.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onRowSelectChange = this.onRowSelectChange.bind(this);

        this.DetailsContent = null;
        this.activeColumns = null;

        this.gridActions = [];
    }
    componentDidMount() {
        // Note: If dispatch is not defined then you imported dataGrid as { DataGrid } from...
        const { name, config, limit, dispatch } = this.props;
        dispatch(dataGridActions.init({ name, config, pageSize: limit }));
    }
    componentWillUnmount() {
        const { name, dispatch } = this.props;
        dispatch(dataGridActions.unmount({ dataGridId: name }));
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (isEqual(this.props.dataGrid, nextProps.dataGrid) && isEqual(this.props.customRowActions, nextProps.customRowActions)) {
            return false;
        }

        return true;
    }

    onGridAction(action) {
        const { dataGrid, dispatch, filterRequired } = this.props;

        switch (action.name) {
            case "filter":
                if (!dataGrid || (dataGrid.filter && isEmpty(dataGrid.filter.filters))) {
                    dispatch(
                        dataGridActions.setFilter({
                            dataGridId: dataGrid.name,
                            filter: getDefaultFilter(dataGrid.name),
                        })
                    );

                    dispatch(
                        dataGridActions.filter({
                            dataGridId: dataGrid.name,
                            filterRequired,
                        })
                    );
                    this.props.onFilterChanged?.();
                } else {
                    dispatch(
                        dataGridActions.filter({
                            dataGridId: dataGrid.name,
                            filterRequired,
                        })
                    );
                    this.props.onFilterChanged?.();
                }
                break;

            case "refresh":
                dispatch(
                    dataGridActions.getData({
                        dataGridId: dataGrid.name,
                    })
                );
                this.props.onRefresh?.();
                break;

            case "clear-filter":
                dispatch(
                    dataGridActions.setFilter({
                        dataGridId: dataGrid.name,
                        filter: getDefaultFilter(dataGrid.name),
                    })
                );

                dispatch(
                    dataGridActions.filter({
                        dataGridId: dataGrid.name,
                        filterRequired,
                    })
                );
                this.props.onFilterChanged?.();

                break;

            default:
                break;
        }
    }

    onRowAction(action) {
        const { onRowAction } = this.props;
        const { dataIndex, dataItem } = action;

        if (onRowAction) {
            onRowAction(action, (expandedContent) => this.onExpand({ dataIndex, dataItem }, expandedContent));
        }
    }

    getActiveColumns() {
        const { dataGrid, showedColumnsKeys } = this.props;

        let columns = [];

        if (dataGrid.columns) {
            columns = dataGrid.columns
                .map((column) => ({
                    ...column,
                    active: showedColumnsKeys ? showedColumnsKeys.includes(column.key) : column.active,
                }))
                .filter((c) => c.hidecolumn !== "true")
                .filter((c) => c.active || c.active === undefined);
        }

        if (!isEqual(columns, this.activeColumns)) {
            this.activeColumns = columns;
        }

        return this.activeColumns;
    }

    getRows(dataGrid) {
        const { config } = this.props;
        let rows = [];

        if (dataGrid.rows) {
            rows = dataGrid.rows;
        }

        if (rows.length === 0 && config.rows) {
            rows = config.rows;
        }

        return rows;
    }

    getGridActions(isFilterable, filter) {
        const gridActions = [
            isFilterable && filter?.filters?.length > 0
                ? {
                      name: "clear-filter",
                      enabled: true,
                      icon: "remove",
                      title: "Clear Filter",
                  }
                : null,
            {
                name: "refresh",
                enabled: true,
                icon: isFilterable ? "search" : "update-refresh",
                title: isFilterable ? "Search" : "Refresh",
            },
            this.getAction("columnmanagement"),
        ].filter((a) => a !== null);

        if (!isEqual(gridActions, this.gridActions)) {
            this.gridActions = gridActions;
        }

        return this.gridActions;
    }

    getAction(name) {
        const { dataGrid, showedColumnsKeys } = this.props;
        let action = null;

        if (showedColumnsKeys && name === "columnmanagement") {
            return action;
        }

        let list = getEnabledGridActions(dataGrid).filter((action) => action.name === name);

        if (list.length) {
            action = list[0];
        }

        return action;
    }

    onFilterChange(event) {
        const { dataGrid, dispatch } = this.props;

        dispatch(
            dataGridActions.setFilter({
                dataGridId: dataGrid.name,
                filter: event.filter,
            })
        );
    }

    onSortChange(event) {
        const { dataGrid, dispatch } = this.props;

        dispatch(
            dataGridActions.sort({
                dataGridId: dataGrid.name,
                sort: event.sort,
            })
        );
    }
    onColumnReorder(event) {
        const { dataGrid } = this.props;

        let disabledCount = 0;
        dataGrid.columns.forEach((column) => {
            if (!column.active) {
                column.order = event.columns.length + disabledCount;
                disabledCount = disabledCount + 1;
            } else {
                event.columns
                    .filter((evColumn) => evColumn.field === column.key)
                    .forEach((evColumn) => (column.order = evColumn.orderIndex));
            }
        });

        dataGrid.columns.sort(function (a, b) {
            return a.order - b.order;
        });
    }
    onPageChange(event) {
        const { dataGrid, onPageChanged, dispatch } = this.props;

        dispatch(
            dataGridActions.paginate({
                dataGridId: dataGrid.name,
                page: event.page,
                onSuccess: () => {
                    onPageChanged && onPageChanged(event);
                },
            })
        );
    }
    onExpand = (event, ExpandedContent) => {
        const { dataGrid, customRowActions, dispatch } = this.props;

        // Preserve function to not recreate on every ExpandedContent rerender.
        const onClose = () => this.onExpand(event);

        const expandedContent = ExpandedContent
            ? (props) => (
                  <ExpandedContent
                      {...props}
                      columns={this.getActiveColumns()}
                      actions={customRowActions ?? []}
                      onRowAction={this.onRowAction}
                      onClose={onClose}
                  />
              )
            : undefined;

        dispatch(
            dataGridActions.onExpandChange({
                dataGridId: dataGrid.name,
                row: event.dataItem,
                index: event.dataIndex,
                expandedContent,
            })
        );
    };
    onTreeExpandChange = (event) => {
        const { dataGrid, dispatch } = this.props;

        dispatch(
            dataGridActions.onTreeExpandChange({
                dataGridId: dataGrid.name,
                row: event.dataItem,
                index: event.dataIndex,
            })
        );
    };
    onRowSelectChange(event) {
        const { onRowSelectChange, dataGrid, dispatch } = this.props;

        // Select All clicked
        if (!event) {
            dispatch(
                dataGridActions.selectRow({
                    dataGridId: dataGrid.name,
                })
            );
        } else {
            dispatch(
                dataGridActions.selectRow({
                    dataGridId: dataGrid.name,
                    index: event.index,
                    isSelected: !event.dataItem._selected,
                })
            );
        }

        if (onRowSelectChange) {
            setTimeout(() => {
                const selectedRows = getSelectedRows(dataGrid.name);
                onRowSelectChange(selectedRows);
            }, 0);
        }
    }

    render() {
        const {
            dataGrid,
            UpdateForm,
            rowGroups,
            groupRender,
            columnWidth,
            columnHeaderContent,
            columnFilterContent,
            columnCellContent,
            noFilter = false,
            noSort = false,
            canExpandRow = false,
            canSelectRow = false,
            resizableHeaders = true,
            showActions = true,
            onlyItems = false,
            renderFooter,
            renderItem,
            className,
            showExpandedRow = false,
            pagingCompact,
            notExpandableRowIcon,
            draggableRows,
            onDragEnd,
            isWidget,
            customRowActions,
            customRowClassName,
            virtualized,
            showedColumnsKeys,
            filterRequired,
            withResizeDetector,
        } = this.props;

        if (dataGrid) {
            // Columns
            const columns = this.getActiveColumns();

            // rows
            const rows = this.getRows(dataGrid);

            // Sorting
            const sorting = this.getAction("sorting");
            const isSortable =
                sorting !== null && !noSort && !(filterRequired && !hasCustomFilter(dataGrid.filter, dataGrid.columns, showedColumnsKeys));
            const sort = dataGrid.sort;

            // Paging
            const paging = this.getAction("paging");
            const isPageable =
                paging !== null && !(filterRequired && !hasCustomFilter(dataGrid.filter, dataGrid.columns, showedColumnsKeys));
            let skip = 0;
            let take = 0;
            let total = 0;

            if (dataGrid.paging) {
                skip = dataGrid.paging.skip;
                take = dataGrid.paging.take;
                total = dataGrid.paging.total;
            }

            // Filtering
            const filtering = this.getAction("filter");
            // Show filter if filter is enabled in config and there are any rows or when something is entered in filter
            const isFilterable =
                filtering !== null &&
                !noFilter &&
                (rows.length > 0 ||
                    hasCustomFilter(dataGrid.filter, dataGrid.columns, showedColumnsKeys) ||
                    isFilterChanged(dataGrid.name) ||
                    filterRequired);
            const filter = dataGrid.filter ?? [];

            const gridActions = this.getGridActions(isFilterable, filter);
            const rowActions = customRowActions ?? [];

            // Details form
            if (this.DetailsContent === null) {
                this.DetailsContent = UpdateForm
                    ? (props) => (
                          <UpdateForm
                              {...props}
                              columns={columns}
                              actions={rowActions}
                              onRowAction={this.onRowAction}
                              onClose={() => this.onExpand(props)}
                          />
                      )
                    : (props) => <GridDetailsPanel {...props} columns={columns} onClose={() => this.onExpand(props)} />;
            }

            const noData =
                rows.length === 0 && (filterRequired || (!dataGrid.isConstructing && !dataGrid.isReading && dataGrid.isDataRequested));
            return dataGrid.isConstructing ? (
                <WaitIcon />
            ) : (
                <GridContext.Provider
                    value={{
                        dataGridId: dataGrid.name,
                        columns: dataGrid.columns,
                        needFilter: dataGrid.needFilter,
                        showedColumnsKeys,
                        filterRequired,
                        isDataRequested: dataGrid.isDataRequested,
                    }}
                >
                    <GridList
                        // Data grid instance id.
                        dataGridId={dataGrid.name}
                        // Data grid key used in backend to store column configuration.
                        dataGridKey={dataGrid.dataGridId}
                        columns={columns}
                        rows={rows}
                        DetailsContent={this.DetailsContent}
                        onExpandChange={this.onExpand}
                        rowActions={rowActions}
                        gridActions={gridActions}
                        onRowAction={this.onRowAction}
                        onGridAction={this.onGridAction}
                        isReading={dataGrid.isReading}
                        rowGroups={rowGroups}
                        groupRender={groupRender}
                        filterRequired={filterRequired}
                        resizableHeaders={resizableHeaders}
                        showActions={showActions}
                        onlyItems={onlyItems}
                        renderItem={renderItem}
                        className={className}
                        renderFooter={renderFooter}
                        customRowClassName={customRowClassName}
                        // paging
                        pageable={isPageable}
                        pagingCompact={pagingCompact}
                        skip={skip}
                        take={take}
                        total={total}
                        onPageChange={this.onPageChange}
                        // filtering
                        filterable={isFilterable}
                        filter={filter}
                        onFilterChange={this.onFilterChange}
                        // Sorting
                        sortable={isSortable}
                        sort={sort}
                        onSortChange={this.onSortChange}
                        columnWidth={columnWidth}
                        columnHeaderContent={columnHeaderContent}
                        columnFilterContent={columnFilterContent}
                        columnCellContent={columnCellContent}
                        canExpandRow={canExpandRow}
                        canSelectRow={canSelectRow}
                        onRowSelectChange={this.onRowSelectChange}
                        showExpandedRow={showExpandedRow}
                        notExpandableRowIcon={notExpandableRowIcon}
                        onTreeExpandChange={this.onTreeExpandChange}
                        noData={noData}
                        draggableRows={draggableRows}
                        onDragEnd={onDragEnd}
                        isWidget={isWidget}
                        virtualized={virtualized}
                        withResizeDetector={withResizeDetector}
                    />
                </GridContext.Provider>
            );
        }

        return <WaitIcon />;
    }
}

function mapStateToProps(state, ownProps) {
    const dataGrid = state.dataGrid[ownProps.name];

    return {
        dataGrid,
    };
}

const ConnectedDataGrid = connect(mapStateToProps, null, null, {
    forwardRef: true,
})(DataGrid);

export default ConnectedDataGrid;

export const ViewOnlyDataGrid = (props) => {
    return (
        <ConnectedDataGrid
            {...props}
            className={cn("view-only", props.className)}
            noSort
            noFilter
            showActions={false}
            resizableHeaders={false}
        />
    );
};
