import React, { useRef, useMemo, useState } from "react";
import cn from "classnames";

import FieldGroup from "../FieldGroup";
import FieldWrap from "../FieldWrap";
import DropdownField from "../DropdownField";
import DropdownList from "../DropdownList";
import DropdownListItem from "../DropdownList/DropdownListItem";
import StatusMsg from "../StatusMsg";
import Label from "../Label";
import DropdownListWrapper from "../DropdownList/DropdownListWrapper";
import usePopper from "../../utils/usePopper";
import useOnClickOutside from "../../utils/useOnClickOutside";

import "./style.scss";

const FieldGroupDropdown = (props) => {
    const triggerRef = useRef();
    const popupRef = useRef();
    const popperRef = useRef();
    const [isListOnTop, setIsListOnTop] = useState(false);

    useOnClickOutside(popupRef, (event) => {
        if (props.active && event.target !== triggerRef.current) {
            props.onClick(event);
            popperRef.current && popperRef.current.forceUpdate();
        }
    });

    const modifiers = useMemo(
        () => [
            {
                name: "preventOverflow",
                options: {
                    boundary: triggerRef.current?.closest(".popup-boundary") ?? undefined,
                },
            },
            {
                name: "eventListeners",
                options: {
                    scroll: props.visible,
                    resize: props.visible,
                },
            },
            {
                name: "onFlip", // Custom modifier
                phase: "afterWrite",
                enabled: true,
                fn({ state }) {
                    if (props.visible) {
                        setIsListOnTop(state.placement === "top-start");
                    }
                },
            },
            {
                name: "flip",
                options: {
                    padding: props.popperPadding ?? 10, // Without this prop the list sometimes not flip if overflows boundary only a little
                },
            },
        ],
        [props.visible, props.popperPadding]
    );

    usePopper({
        triggerRef,
        popperRef,
        popupRef,
        placement: "bottom-start",
        enabled: props.withPopper,
        modifiers,
    });

    const onPopupClick = (event) => {
        props.onClick && props.onClick(event);
        popperRef.current && popperRef.current.forceUpdate();
    };

    return (
        <FieldGroup
            className={cn(props.className, {
                "dropdown-list-up": isListOnTop,
            })}
            fieldGroupRef={props.containerRef}
            inline={props.inline}
            title={props.title}
            testId={props.testId}
        >
            {props.label && <Label labelRequired={props.required}>{props.label}</Label>}
            <FieldWrap dropdown>
                <DropdownField
                    elementRef={triggerRef}
                    id={props.id}
                    active={props.active}
                    ghost={props.ghost}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    error={props.error}
                    lightUpError={props.lightUpError}
                    filled={props.filled}
                    dropdownFieldIcon={props.dropdownFieldIcon}
                    onClick={onPopupClick}
                    dropdownFieldText={props.dropdownFieldText}
                    onListKeyDown={props.onListKeyDown}
                    visible={props.visible}
                    extraInput={props.extraInput}
                    onChange={(val) =>
                        props.onSelect &&
                        props.onSelect({
                            value: val,
                            label: val,
                        })
                    }
                />
                <DropdownListWrapper
                    className={props.className}
                    triggerRef={triggerRef}
                    popupRef={popupRef}
                    popperRef={popperRef}
                    enabled={props.withPopper}
                    visible={props.visible}
                    filled={props.filled}
                    zIndexOffset={props.zIndexOffset}
                >
                    <DropdownList
                        elementRef={props.withPopper ? undefined : popupRef}
                        visible={props.visible}
                        onClose={onPopupClick}
                        mobileHeader={props.mobileHeader}
                        withFilter={props.withFilter}
                        filterValue={props.filterValue}
                        onFilterChange={props.onFilterChange}
                        onListKeyDown={props.onListKeyDown}
                    >
                        {props.visible &&
                            props.items.map((item, i) => (
                                <DropdownListItem
                                    key={i}
                                    selected={item.selected}
                                    disabled={item.disabled}
                                    focused={item.focused}
                                    onClick={() => !item.disabled && props.onSelect && props.onSelect(item)}
                                >
                                    {item.labelMarkup || item.label}
                                </DropdownListItem>
                            ))}
                    </DropdownList>
                </DropdownListWrapper>
            </FieldWrap>
            {props.msgText && <StatusMsg msgFieldStatus msgInfo={props.msgInfo} msgError={props.msgError} msgText={props.msgText} />}
            {props.children}
        </FieldGroup>
    );
};

export default FieldGroupDropdown;
