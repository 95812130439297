import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef, useState } from "react";
import cn from "classnames";
import useOnClickOutside from "components/utils/useOnClickOutside";
import usePopper from "components/utils/usePopper";
import DropdownListWrapper from "components/ui/DropdownList/DropdownListWrapper";
import DropdownList from "components/ui/DropdownList";

export const FilterList: React.FC<{
    items: FilterListItem[];
    selectedIndex?: number;
    readOnly?: boolean;
    addItemLabel: string;
    onSelectItem: (item: FilterListItem) => void;
    onAddItem?: () => void;
    onClearItem: (index: number) => void;
    onRemoveItem: (index: number) => void;
}> = ({ items, selectedIndex, readOnly, addItemLabel, onSelectItem, onAddItem, onClearItem, onRemoveItem }) => {
    return (
        <ul className="filter-list flex-column bg-white">
            {items.map((item: FilterListItem, index) => (
                <ListItem
                    index={index}
                    key={`filter-item-${index}`}
                    item={item}
                    isActive={index === selectedIndex}
                    readOnly={readOnly}
                    addItemLabel={addItemLabel}
                    onSelect={onSelectItem}
                    onAdd={onAddItem}
                    onClear={onClearItem}
                    onRemove={onRemoveItem}
                />
            ))}
        </ul>
    );
};

const ListItem: React.FC<{
    index: number;
    item: FilterListItem;
    isActive: boolean;
    readOnly?: boolean;
    addItemLabel: string;
    onAdd?: () => void;
    onClear: (index: number) => void;
    onSelect: (item: FilterListItem) => void;
    onRemove: (index: number) => void;
}> = ({ index, item, isActive, readOnly, addItemLabel, onSelect, onAdd, onClear, onRemove }) => {
    const [isHovered, setIsHovered] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout>();

    return (
        <li
            className={cn("filter-item flex-row align-center rounded", { "bg-blue": isActive })}
            onClick={() => onSelect(item)}
            aria-labelledby={item.label}
            onFocusCapture={(e) => {
                setIsHovered(true);
                clearTimeout(timeoutRef.current);
            }}
            onBlurCapture={(e) => {
                timeoutRef.current = setTimeout(() => {
                    setIsHovered(false);
                }, 10);
            }}
            onMouseOverCapture={(e) => {
                setIsHovered(true);
                clearTimeout(timeoutRef.current);
            }}
            onMouseOutCapture={(e) => {
                timeoutRef.current = setTimeout(() => {
                    setIsHovered(false);
                }, 10);
            }}
        >
            <div className="flex-row fill-width justify-space-between align-center">
                <div className="flex-row align-center truncate">
                    <div className={cn("px-1", { invisible: !isActive })}>
                        <div className="bg-primary" style={{ width: 3, height: 16, borderRadius: 1 }} />
                    </div>
                    <div className="flex-row align-center ps-1 truncate">
                        <FontAwesomeIcon className="item-icon bg-theme-light rounded" icon={item.icon} color="white" fixedWidth size="xs" />
                        <span className="item-label truncate px-2 fw-600 fs-14">{item.label}</span>
                    </div>
                </div>
                {isHovered && !readOnly && (
                    <ListItemDropdown
                        index={index}
                        addItemLabel={addItemLabel}
                        onAdd={onAdd}
                        onClear={onClear}
                        onRemove={onRemove}
                        onSelect={() => onSelect(item)}
                    />
                )}
                {!isHovered && isActive && <FontAwesomeIcon className="rounded" icon={["fal", "chevron-right"]} fixedWidth size="lg" />}
            </div>
        </li>
    );
};

const ListItemDropdown: React.FC<{
    index: number;
    addItemLabel: string;
    onClear: (index: number) => void;
    onAdd?: () => void;
    onSelect: () => void;
    onRemove: (index: number) => void;
}> = ({ index, addItemLabel, onClear, onAdd, onRemove, onSelect }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const triggerRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<any>(null);

    const close = (event: any) => {
        if (isDropdownOpen && (!event || event.target !== triggerRef.current)) {
            setIsDropdownOpen(false);
        }
    };

    useOnClickOutside(popupRef, (event: any) => {
        if (event?.target !== triggerRef?.current) {
            close(event);
            popperRef?.current?.forceUpdate();
        }
    });

    usePopper({
        triggerRef,
        popperRef,
        popupRef,
        placement: "right-start",
        enabled: true,
        modifiers: {},
    });

    const onAddClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            setIsDropdownOpen(false);
            onAdd?.();
        },
        [onAdd]
    );

    const onClearClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();

            setIsDropdownOpen(false);
            onClear(index);
        },
        [onClear, index]
    );

    const onToggleMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        onSelect();
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <button
                ref={triggerRef}
                aria-label="Open item dropdown"
                className={cn("dropdown-button rounded", { open: isDropdownOpen })}
                onClick={onToggleMenu}
            >
                <FontAwesomeIcon className="py-2 px-1" icon={["fal", "ellipsis"]} fixedWidth size="lg" />
            </button>
            <DropdownListWrapper
                triggerRef={triggerRef}
                popupRef={popupRef}
                popperRef={popperRef}
                enabled={true}
                visible={isDropdownOpen}
                className="list-item-dropdown"
                filled={undefined}
                multipleChoice={undefined}
                zIndexOverride={9999}
                zIndexOffset={undefined}
            >
                <DropdownList className="dropdown-menu" alone elementRef={popupRef} visible={isDropdownOpen}>
                    <div className="d-flex flex-column gap-2 p-2">
                        {onAdd && (
                            <button onClick={onAddClick} className="flex-row align-center justify-start gap-2 p-1">
                                <FontAwesomeIcon icon={["fal", "circle-plus"]} fixedWidth size="sm" />
                                <span>{addItemLabel}</span>
                            </button>
                        )}
                        <button onClick={onClearClick} className="flex-row align-center justify-start gap-2 p-1">
                            <FontAwesomeIcon icon={["fal", "broom-wide"]} fixedWidth size="sm" />
                            <span>Clear All</span>
                        </button>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onRemove(index);
                            }}
                            style={{ color: "red" }}
                            className="flex-row align-center justify-start gap-2 p-1"
                        >
                            <FontAwesomeIcon icon={["fal", "trash"]} fixedWidth size="sm" />
                            <span>Delete</span>
                        </button>
                    </div>
                </DropdownList>
            </DropdownListWrapper>
        </>
    );
};

type FilterListItem = {
    label: string;
    value: string;
    icon: IconProp;
};
