import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { UsersList } from "./UsersList";
import { FilterGroup, ApplicationFilters, TaskFilters, FilterGroups, FilterType } from "./types";
import { useState } from "react";
import { useSelector } from "react-redux";

export const FilterTypesList: React.FC<{
    className?: string;
    activeFilterGroup?: FilterGroup;
    activeFilterType?: FilterType;
    selectedUserNumber?: string;
    onSelectFilterType: (filterGroup: FilterGroup, filterType: FilterType) => void;
    onSelectUser: (userNumber: string, showResults?: boolean) => void;
    inPopup?: boolean;
}> = ({ className, activeFilterGroup, activeFilterType, selectedUserNumber, onSelectFilterType, onSelectUser, inPopup = false }) => {
    const showUserList = ([ApplicationFilters.AssignedTo, ApplicationFilters.CreatedBy, TaskFilters.OwnedBy] as string[]).includes(
        activeFilterType ?? ""
    );

    return (
        <section
            className={cn(className, "flex-row border rounded fill-height fill-width no-scroll")}
            aria-label={"Applications and Tasks filter"}
            style={inPopup ? { maxWidth: showUserList ? 680 : 240 } : {}}
        >
            <div
                className={cn("flex-column flex-grow with-scroll", {
                    "flex-one-in-row": !inPopup,
                })}
            >
                <h3 className="fs-14 fw-600 text-secondary p-2 px-4 sticky top-0 bg-white">{FilterGroups.Applications}</h3>
                <ul>
                    {Object.values(ApplicationFilters).map((filterType) => (
                        <FilterTypesListItem
                            key={filterType}
                            title={filterType}
                            icon={["far", "file-check"]}
                            isActive={activeFilterType === filterType && activeFilterGroup === FilterGroups.Applications}
                            onClick={() => onSelectFilterType(FilterGroups.Applications, filterType)}
                        />
                    ))}
                </ul>
                <h3 className="fs-14 fw-600 text-secondary p-2 px-4 sticky top-0 bg-white">{FilterGroups.Tasks}</h3>
                <ul>
                    {Object.values(TaskFilters).map((filterType) => (
                        <FilterTypesListItem
                            key={filterType}
                            title={filterType}
                            isActive={activeFilterType === filterType && activeFilterGroup === FilterGroups.Tasks}
                            icon={["far", "list-check"]}
                            onClick={() => onSelectFilterType(FilterGroups.Tasks, filterType)}
                        />
                    ))}
                </ul>
            </div>
            {showUserList && (
                <div
                    className={cn("flex-column with-scroll", {
                        "flex-one-in-row": !inPopup,
                    })}
                    style={inPopup ? { width: 440 } : {}}
                >
                    <UsersList selectedUserNumber={selectedUserNumber} onSelectUser={onSelectUser} />
                </div>
            )}
        </section>
    );
};

const FilterTypesListItem: React.FC<{
    title: FilterType;
    isActive?: boolean;
    icon: IconProp;
    onClick: () => void;
}> = ({ title, isActive, icon, onClick }) => {
    return (
        <li>
            <button
                className={cn("wc-btn p-2 px-4 flex-row align-center gap-2 fill-width", {
                    active: isActive,
                })}
                onClick={onClick}
            >
                <FontAwesomeIcon icon={icon} fixedWidth />
                <span className="py-1">{title}</span>
            </button>
        </li>
    );
};

export const useFilterTypeSelection = (filterGroup: FilterGroup, filterType: FilterType, userNumber?: string) => {
    const currentUserNumber = useSelector((state: any) => state.user.userNumber);

    const [activeFilterGroup, setActiveFilterGroup] = useState<FilterGroup>(filterGroup);
    const [activeFilterType, setActiveFilterType] = useState<FilterType>(filterType);
    const [selectedUserNumber, setSelectedUserNumber] = useState<string | undefined>(userNumber);

    const onSelectFilterType = (filterGroup: FilterGroup, filterType: FilterType) => {
        setActiveFilterGroup(filterGroup);
        setActiveFilterType(filterType);

        if (([ApplicationFilters.AssignedTo, ApplicationFilters.CreatedBy, TaskFilters.OwnedBy] as string[]).includes(filterType)) {
            setSelectedUserNumber(currentUserNumber);
        } else {
            setSelectedUserNumber(undefined);
        }
    };

    const onSelectUser = (userNumber: string | undefined) => {
        setSelectedUserNumber(userNumber);
    };

    return { activeFilterGroup, activeFilterType, selectedUserNumber, onSelectFilterType, onSelectUser };
};
