import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { WorkcenterUser } from "../utils";
import { AssignmentActions, TaskOrApplicationRow } from "./types";
import { useDispatch, useSelector } from "react-redux";
import IconWrap from "components/ui/Icons";
import { closeModal } from "components/ui/Modal/utils";
import SearchInput from "components/ui/Input/SearchInput";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Input/Checkbox";
import { Avatar } from "components/ui/Avatar";
import { updateResource } from "store/resources/actions";
import { getData } from "store/dataGrid/actions";

export const AssignmentModal: React.FC<{
    instanceId: string;
    dataGridId: string;
    selectedRowsAsObject: TaskOrApplicationRow[];
    assignment: AssignmentActions;
    searchTerm: string;
    addUserList: WorkcenterUser[];
    resetSelectedRows: () => void;
    onSearchTermChange: (t: ChangeEvent<HTMLInputElement>) => void;
}> = ({ instanceId, searchTerm, onSearchTermChange, resetSelectedRows, selectedRowsAsObject, assignment, addUserList }) => {
    const currentUserNumber = useSelector((state: any) => state.user.userNumber);

    const [usersToAdd, setUsersToAdd] = useState<string[]>([]);
    const dispatch = useDispatch();
    const suggestedUsers = useMemo(() => {
        return addUserList.filter((user) => user.userNumber === currentUserNumber);
    }, [addUserList, currentUserNumber]);

    const onSelectUserToAdd = useCallback(
        (userNumber: string) => {
            const index = usersToAdd.findIndex((item) => item === userNumber);

            if (index === -1) {
                setUsersToAdd([...usersToAdd, userNumber]);
            } else {
                setUsersToAdd([...usersToAdd.slice(0, index), ...usersToAdd.slice(index + 1)]);
            }
        },
        [usersToAdd]
    );

    const title = useMemo(() => {
        switch (assignment) {
            case AssignmentActions.addAssignment:
                return "Assign application";
            case AssignmentActions.removeAssignment:
                return "Clear assignment";
            case AssignmentActions.assignOwner:
                return "Assign owner";
            default:
                return "";
        }
    }, [assignment]);

    const subTitle = useMemo(() => {
        switch (assignment) {
            case AssignmentActions.addAssignment:
                return "Select a user to assign the selected applications";
            case AssignmentActions.removeAssignment:
                return "Select a user to clear the assignment for the selected applications";
            case AssignmentActions.assignOwner:
                return "Select a user to assign as the owner of selected task";
            default:
                return "";
        }
    }, [assignment]);

    const onUpdateApps = useCallback(
        (assignment: AssignmentActions) => {
            dispatch(
                // @ts-ignore
                updateResource({
                    resourceName: "workcenterItemUsers",
                    body: {
                        tgtUserNumbers: usersToAdd,
                        action: assignment,
                        items: selectedRowsAsObject.map((r: TaskOrApplicationRow) =>
                            assignment === AssignmentActions.addAssignment || assignment === AssignmentActions.removeAssignment
                                ? r.appId
                                : r.taskNumber
                        ),
                    },
                    onSuccess: () => {
                        resetSelectedRows();
                        dispatch(
                            // @ts-ignore
                            getData({
                                dataGridId: instanceId,
                            })
                        );
                    },
                })
            );
        },
        [dispatch, instanceId, resetSelectedRows, selectedRowsAsObject, usersToAdd]
    );

    return (
        <section className="flex-column fill-height fill-width" aria-label={title}>
            <div className="px-3 flex-column gap-2 pt-4">
                <div className="flex-row justify-space-between align-center text-secondary">
                    <h5 className="fw-500 text-xl">{title}</h5>
                    <div
                        role="button"
                        title="Close"
                        tabIndex={0}
                        aria-label="Close"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        <IconWrap icon="clear-close" />
                    </div>
                </div>
                <p className="text-base">{subTitle}</p>
                <SearchInput value={searchTerm} onChange={onSearchTermChange} onSubmit={() => {}} ariaLabel="Search user" />
            </div>
            <div className="flex-column rounded no-scroll">
                {addUserList.length > 0 && (
                    <div className="with-scroll  mx-3 mt-3">
                        {suggestedUsers.length > 0 && (
                            <>
                                <div className="p-2 fs-14 fw-600 text-secondary">Suggested</div>
                                <ul>
                                    {suggestedUsers.map((item) => (
                                        <UsersListItem
                                            key={item.userNumber}
                                            user={item}
                                            isActive={usersToAdd.includes(item.userNumber)}
                                            onClick={() => {
                                                onSelectUserToAdd(item.userNumber);
                                            }}
                                        />
                                    ))}
                                </ul>
                            </>
                        )}
                        <div className="p-2 fs-14 fw-600 text-secondary">All Users</div>
                        <ul>
                            {(addUserList.filter((user) => user.userNumber !== currentUserNumber) ?? []).map((item) => (
                                <UsersListItem
                                    key={item.userNumber}
                                    user={item}
                                    isActive={usersToAdd.includes(item.userNumber)}
                                    onClick={() => {
                                        onSelectUserToAdd(item.userNumber);
                                    }}
                                />
                            ))}
                        </ul>
                    </div>
                )}
                {addUserList.length === 0 && (
                    <div className="text-secondary fs-14 fw-400 p-5 flex no-users-message justify-center items-center">No users found</div>
                )}

                <div className="flex p-3 border-top assign-modal-buttons">
                    {addUserList.length > 0 && (
                        <Button
                            disabled={!usersToAdd.length}
                            className="me-2"
                            primary
                            onClick={() => {
                                onUpdateApps(assignment);
                                closeModal();
                            }}
                        >
                            Confirm
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </section>
    );
};

const UsersListItem: React.FC<{
    user: WorkcenterUser;
    isActive: boolean;
    onClick: (userNumber: string) => void;
}> = ({ user, isActive, onClick }) => {
    return (
        <li key={user.userNumber} className="p-2 flex-row align-center gap-2">
            <Checkbox
                iconLabelEmpty
                checked={isActive}
                onChange={() => {
                    onClick(user.userNumber);
                }}
            />
            <Avatar firstName={user.userName} />
            <span>{user.userName}</span>
        </li>
    );
};
