import DropDownInput from "components/ui/Input/DropDownInput";
import SearchInput from "components/ui/Input/SearchInput";
import { uniqBy } from "lodash";
import { useState, useMemo } from "react";
import { FilterConfig, FilterVariable, VariableType, VariableTypes } from "../../utils";
import { ProgramVariables } from "./ProgramVariables";

const variableTypesList = Object.values(VariableTypes).map((type) => ({
    value: type,
    label: type,
}));

export const VariablesList: React.FC<{
    filterConfig: FilterConfig;
    selectedVariable?: FilterVariable;
    onSelectVariable: (variable: FilterVariable | undefined) => void;
    onVariableToggle: (variable: FilterVariable) => void;
}> = ({ filterConfig, selectedVariable, onSelectVariable, onVariableToggle }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedVariableType, setSelectedVariableType] = useState<VariableType>();
    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const variableGroups = useMemo(
        () =>
            uniqBy(
                filterConfig.map((variable) => ({
                    utilityNumber: variable.utilityNumber,
                    programNumber: variable.programNumber,
                })),
                "programNumber"
            ),
        [filterConfig]
    );

    return (
        <div className="flex-column border-right w-60">
            <div className="flex-column p-4 gap-2">
                <SearchInput
                    value={searchTerm}
                    onChange={onSearchTermChange}
                    placeholder="Search programs"
                    ariaLabel="Search programs"
                    autoFocus
                />
                <DropDownInput
                    // @ts-ignore
                    data={variableTypesList}
                    value={selectedVariableType}
                    emptyItem
                    emptyItemLabel="All Variables"
                    placeholder="All Variables"
                    onChange={(event: { target: { value: VariableType } }) => setSelectedVariableType(event.target.value)}
                />
            </div>
            <div className="flex-column with-scroll">
                {variableGroups.map(({ utilityNumber, programNumber }) => (
                    <ProgramVariables
                        key={programNumber}
                        utilityNumber={utilityNumber}
                        programNumber={programNumber}
                        filterConfig={filterConfig}
                        variableType={selectedVariableType}
                        selectedVariable={selectedVariable}
                        onSelectVariable={onSelectVariable}
                        onVariableToggle={onVariableToggle}
                    />
                ))}
            </div>
        </div>
    );
};
